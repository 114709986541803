<template>
  <address-add-dialog
    v-if="showAddDialog && model"
    v-model="showAddDialog"
    @request-send="handleSendRequest"
    :coin="props.defaultValue?.coin"
    :address="props.defaultValue?.address"
    :network="props.defaultValue?.network"
  />
  <two-factor-verification-modal
    v-if="show2FADialog"
    v-model="show2FADialog"
    :request="requestResponse"
    @confirm="handleConfirm2FA"
  />
</template>
<script setup>
import { ref, defineModel, watch, defineProps } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { postHttpRequest } from "@/api/api";
import AddressAddDialog from "@/common/AddAddress/AddressAddDialog.vue";
import TwoFactorVerificationModal from "@/common/TwoFactorVerificationModal/TwoFactorVerificationModal.vue";
import { isEmpty } from "@/utils/utils";

const props = defineProps({
  defaultValue: Object,
});

const model = defineModel();
const store = useStore();
const { t } = useI18n();

const showAddDialog = ref(true);
const requestResponse = ref(null);
const addAddressData = ref(null);
const show2FADialog = ref(false);

watch(
  () => showAddDialog.value,
  (newVal) => {
    if (!newVal) {
      model.value = false;
    }
  }
);

watch(
  () => model.value,
  (newVal) => {
    if (newVal) {
      showAddDialog.value = true;
    }
  }
);

const handleSendRequest = ({ data, response }) => {
  addAddressData.value = data;
  requestResponse.value = response;
  show2FADialog.value = true;
};

const handleConfirm2FA = async (data) => {
  const requestData = {
    ...addAddressData.value,
    send_to_email: 0,
    send_to_phone: 0,
    ...(!isEmpty(data.emailPin) && { email_code: data.emailPin }),
    ...(!isEmpty(data.phonePin) && { phone_code: data.phonePin }),
    ...(!isEmpty(data.googlePin) && { google_code: data.googlePin }),
  };
  const res = await postHttpRequest(store)(
    "/api/account/add-book",
    requestData
  );
  if (res.status) {
    store.dispatch("setToast", {
      type: "success",
      message: t("translation.profile.addressBook.addAddressSuccess"),
    });
    store.dispatch("fetchAccountInfo");
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: t("translation.profile.addressBook.addAddressError"),
    });
  }
  show2FADialog.value = false;
};
</script>
