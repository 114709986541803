<template>
  <div
    class="flex flex-col dark:bg-navy-700 bg-white dark:border-none border border-navy-10 dark:shadow-none shadow-2xl shadow-black/[0.08] rounded-2xl sm:px-6 px-4 py-4 w-full"
  >
    <div class="flex flex-row items-center">
      <img :src="props.headerImg" class="mr-3 w-8 h-8 rounded-full" />
      <div class="dark:text-white text-navy-800 text-lg font-semibold">
        {{ props.headerLabel }}
      </div>
    </div>
    <div class="my-4 border-t dark:border-navy-500 border-navy-10" />
    <table class="w-full">
      <tbody>
        <tr
          v-for="(item, index) in props.itemData.data"
          :key="item.id"
          class="cursor-pointer"
          @click="handleRowClicked(item.name)"
        >
          <td class="text-left py-3">
            <div class="flex flex=row items-center">
              <span class="text-navy-200 mr-2">{{ index + 1 }}.</span>
              <img :src="item.img" class="size-6 mr-2 rounded-full" />
              <div
                class="dark:text-white text-navy-800 text-sm font-semibold mr-1"
              >
                {{ item.token1 }}
              </div>
              <div class="text-navy-100 text-sm font-semibold">
                {{ item.token2 }}
              </div>
            </div>
          </td>
          <td class="text-right py-3">${{ item.price }}</td>
          <td class="text-right py-3">
            <div class="w-full flex justify-end">
              <percent-display :value="item.percent" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import PercentDisplay from "@/common/PairTable/PercentDisplay.vue";

const props = defineProps(["headerImg", "headerLabel", "itemData"]);
const router = useRouter();

const handleRowClicked = (id) => {
  router.push({ name: "trading", params: { pair: id } });
};
</script>
