<template>
  <div>
    <p v-if="label" class="font-semibold mb-2 text-sm">
      {{ props.label }}
    </p>
    <div
      :class="[
        `flex justify-between border w-full ${sizeClass} ${roundedClass}`,
        !filled
          ? `bg-white ${!props.error && 'border-navy-50'} dark:bg-navy-700 ${
              !props.error && 'dark:border-navy-400'
            }`
          : `bg-navy-10 ${
              !props.error && 'dark:border-navy-300 border-navy-50'
            } dark:bg-navy-500`,
        noBorder ? 'border-transparent focus-within:border' : '',
        props.error ? 'border-red-500' : 'focus-within:border-primary-700',
      ]"
    >
      <slot name="prefix" />
      <div class="relative w-full">
        <input
          :class="[
            'w-full focus:outline-none placeholder:text-navy-200 appearance-none custom-scrollbar font-medium z-[2] relative',
            fontClass,
            topPlaceholder
              ? 'bg-transparent'
              : !filled
              ? currentTheme === 'light'
                ? 'bg-white input-light-mode'
                : 'bg-navy-700 input-dark-mode'
              : currentTheme === 'light'
              ? 'bg-navy-10'
              : 'bg-navy-500',
            props.alignText === 'right' ? 'text-right' : '',
            props.textEllipsis
              ? 'text-ellipsis whitespace-nowrap overflow-hidden'
              : '',
            props.inputClass,
          ]"
          v-model="inputValue"
          :disabled="disabled"
          :placeholder="props.placeholder ?? ''"
          :type="props.type"
          @focus="handleFocus"
        />
        <div
          class="absolute top-0 left-0 floatingLabel z-[1] transition-all duration-75"
          v-if="topPlaceholder"
        >
          <slot name="floatingLabel" />
        </div>
      </div>
      <slot name="suffix" />
    </div>
    <p v-if="props.error" class="text-red-500 mt-2 text-base font-medium">
      {{ props.error }}
    </p>
  </div>
</template>

<script setup>
import { computed, defineModel, defineProps, defineEmits } from "vue";

const props = defineProps({
  value: String,
  placeholder: String,
  label: String,
  error: String,
  type: String,
  filled: Boolean,
  size: String,
  rounded: String,
  disabled: Boolean,
  alignText: String,
  textEllipsis: Boolean,
  inputClass: String,
  borderColor: String,
  topPlaceholder: Boolean,
  noBorder: Boolean,
});

const emit = defineEmits(["focus"]);

const inputValue = defineModel();
const sizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "px-3 py-2";
    case "md":
      return "px-4 py-2.5";
    default:
      return "p-4";
  }
});

const fontClass = computed(() => {
  switch (props.size) {
    case "md":
      return "text-sm";
    case "sm":
    default:
      return "text-base";
  }
});
const roundedClass = computed(() => `rounded-${props.rounded}`);

const handleFocus = () => {
  emit("focus");
};

// const borderColorClass = computed(() => {
//   switch (props.borderColor) {
//     case "gray":
//       return "border-navy-50 dark:border-navy-200";
//     default:
//       return "border-navy-10 dark:border-navy-500";
//   }
// });
</script>
<style scoped>
input:focus-within ~ .floatingLabel,
input:not(:placeholder-shown) ~ .floatingLabel {
  @apply transform scale-50 -translate-y-4 origin-left;
}
</style>
