<template>
  <div class="mb-24 flex flex-col w-full">
    <div class="flex flex-row items-center justify-stretch w-full">
      <div class="relative sm:w-[104px] w-[72px] mr-4 sm:mr-6">
        <div
          class="sm:size-24 size-16 dark:bg-navy-500 bg-primary-300 rounded-full flex items-center justify-center"
        >
          <user-avatar-icon
            class="sm:size-10 size-7 rounded-full dark:text-white text-primary-700"
          />
        </div>
        <!-- <div
          class="absolute sm:top-14 sm:left-16 top-[34px] left-9 rounded-full border-4 dark:border-navy-800 border-white bg-primary-700 sm:size-10 size-9 flex items-center justify-center box-content"
        >
          <arrow-up-tray-icon class="sm:size-6 size-5 stroke-2 text-white" />
        </div> -->
      </div>
      <div class="flex flex-col items-start">
        <div class="text-navy-200 text-base font-medium sm:text-lg">
          {{ $t("translation.profile.dashboard.title") }},
        </div>
        <div class="flex flex-row items-center sm:mb-2 mb-1">
          <div
            class="dark:text-white text-xl sm:text-2xl font-semibold text-navy-800 mr-2"
          >
            {{ masked_email }}
          </div>
          <!-- <div>
            <edit-icon class="size-6 text-navy-200 cursor-pointer" />
          </div> -->
        </div>
        <div class="flex flex-row items-center">
          <div class="text-navy-100 sm:text-base font-medium text-sm mr-2">
            UID: {{ user_id }}
          </div>
          <div class="cursor-pointer" @click="handleCopyClick">
            <copy-icon class="size-5 text-navy-200" />
          </div>
        </div>
      </div>
      <div class="grow" />
      <div class="self-start shrink-0">
        <div
          class="text-primary-700 rounded-full sm:px-6 py-2.5 px-2.5 flex flex-row items-center gap-2 border border-primary-700 cursor-pointer"
          @click="handleHideInfoClick"
        >
          <div class="text-sm font-semibold max-sm:hidden">
            {{
              showInfo
                ? $t("translation.profile.dashboard.hideInfo.0")
                : $t("translation.profile.dashboard.hideInfo.1")
            }}
          </div>
          <eye-slash-icon class="size-5 stroke-2" v-if="!showInfo" />
          <eye-icon class="size-5 stroke-2" v-else />
        </div>
      </div>
    </div>
    <div
      class="flex flex-row items-center p-6 dark:border dark:border-navy-500 dark:bg-transparent bg-primary-100 sm:mt-8 mt-6 rounded-2xl w-full justify-start gap-x-16 gap-y-4 flex-wrap"
    >
      <div class="flex flex-col items-start min-w-20">
        <div class="text-sm font-medium dark:text-navy-100 text-navy-200">
          {{ $t("translation.profile.dashboard.idverify") }}
        </div>
        <div
          class="sm:text-base text-sm font-semibold dark:text-white text-navy-800"
        >
          {{ showInfo ? verification_state : "******" }}
        </div>
      </div>
      <div class="flex flex-col items-start min-w-20">
        <div class="text-sm font-medium dark:text-navy-100 text-navy-200">
          {{ $t("translation.profile.dashboard.phone") }}
        </div>
        <div
          class="sm:text-base text-sm font-semibold dark:text-white text-navy-800"
        >
          {{ showInfo ? phone : "******" }}
        </div>
      </div>
      <div class="flex flex-col items-start min-w-20">
        <div class="text-sm font-medium dark:text-navy-100 text-navy-200">
          {{ $t("translation.profile.dashboard.security") }}
        </div>
        <div
          v-if="showInfo"
          :class="[
            'sm:text-base text-sm font-semibold flex flex-row items-center gap-1',
            !is_risky
              ? 'dark:text-green-400 text-green-500'
              : 'dark:text-red-400 text-red-500',
          ]"
        >
          <shield-check-icon class="size-5 stroke-2" />
          <div>{{ !is_risky ? "High" : " Low" }}</div>
        </div>
        <span v-else>******</span>
      </div>
      <div class="flex flex-col items-start min-w-20">
        <div class="text-sm font-medium dark:text-navy-100 text-navy-200">
          {{ $t("translation.profile.dashboard.country") }}
        </div>
        <div
          class="sm:text-base text-sm font-semibold dark:text-white text-navy-800"
        >
          {{ showInfo ? country : "******" }}
        </div>
      </div>
      <div class="flex flex-col items-start grow">
        <div class="text-sm font-medium dark:text-navy-100 text-navy-200">
          {{ $t("translation.profile.dashboard.lastLogin") }}
        </div>
        <div
          class="sm:text-base text-sm font-semibold dark:text-white text-navy-800"
        >
          {{ showInfo ? lastLoginHistory : "******" }}
        </div>
      </div>
    </div>
    <div
      class="flex sm:flex-row flex-col sm:items-center items-start mt-12 sm:mt-14 w-full justify-between gap-y-3"
    >
      <div class="dark:text-white text-2xl font-semibold text-navy-800">
        {{ $t("translation.profile.dashboard.assetTitle") }}
      </div>
      <div class="flex flex-row items-center gap-4 max-sm:w-full">
        <div class="shrink-0">
          <fill-button
            color="primary"
            size="md"
            width="fit"
            @click="handleDepositClick"
          >
            <arrow-down-tray-icon class="size-5 stroke-2 mr-2" />
            <div>{{ $t("translation.profile.dashboard.deposit") }}</div>
          </fill-button>
        </div>
        <div class="shrink-0">
          <outline-button
            color="primary"
            size="md"
            width="fit"
            @click="handleWithdrawClick"
          >
            {{ $t("translation.profile.dashboard.withdraw") }}
          </outline-button>
        </div>
        <div class="shrink-0 max-sm:hidden">
          <outline-button
            color="primary"
            size="md"
            width="fit"
            @click="handleBuyCryptoClick"
          >
            {{ $t("translation.profile.dashboard.buyCrypto") }}
          </outline-button>
        </div>
        <div class="grow sm:hidden" />
        <div class="shrink-0 sm:hidden">
          <div
            class="size-10 flex items-center justify-center border border-primary-700 rounded-full text-primary-700 cursor-pointer"
          >
            <ellipsis-vertical-icon class="size-5 stroke-2" />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full sm:mt-8 mt-4">
      <asset-balance size="lg" />
    </div>
    <div class="sm:mt-14 mt-12 w-full flex flex-row items-start gap-6">
      <div class="flex flex-col grow items-start">
        <div class="text-xl font-semibold dark:text-white text-navy-800">
          {{ $t("translation.profile.dashboard.marketTitle") }}
        </div>
        <div class="my-6 w-full flex flex-row justify-between items-start">
          <underline-tab
            :items="marketItems"
            font-size="md"
            :default-selected="marketItems[0]"
            :bottom-line="false"
            @change="handleChangeSelectedTab"
          />
          <div
            class="flex flex-row items-center gap-1 text-primary-700 cursor-pointer"
            @click="handleMarketClick"
          >
            <div class="text-sm font-medium">
              {{ $t("translation.profile.dashboard.viewMore") }}
            </div>
            <chevron-right-icon class="size-3 stroke-2" />
          </div>
        </div>
        <pair-small-table
          :display-data="displayData"
          v-if="selectedTab !== 2"
        />
        <new-listing-small-table :display-data="newListingData" v-else />
      </div>
      <div class="w-[328px] shrink-0 flex flex-col max-sm:hidden">
        <!-- <banner-component /> -->
        <div class="mt-10 flex flex-row justify-between items-center mb-6">
          <div class="text-base font-semibold dark:text-white text-navy-800">
            {{ $t("translation.profile.dashboard.announcementTitle") }}
          </div>
          <div
            class="flex flex-row items-center gap-1 text-primary-700 cursor-pointer"
            @click="handleAnnouncementClick"
          >
            <div class="text-sm font-medium">
              {{ $t("translation.profile.dashboard.viewMore") }}
            </div>
            <chevron-right-icon class="size-3 stroke-2" />
          </div>
        </div>
        <announcement-preview />
        <div
          class="mt-10 dark:text-white text-navy-800 text-base font-semibold mb-4"
        >
          {{ $t("translation.profile.dashboard.qrTitle") }}
        </div>
        <div
          class="w-full flex flex-row items-center p-4 rounded-2xl border dark:border-navy-500 border-navy-50 gap-6"
        >
          <div
            class="p-3 rounded-xl dark:bg-navy-500 bg-primary-300 border-[0.5px] dark:border-primary-700 border-primary-500"
          >
            <qr-image class="dark:text-white text-navy-800" />
          </div>
          <div class="flex flex-col items-start">
            <div class="dark:text-white text-base font-semibold text-navy-800">
              {{ $t("translation.profile.dashboard.qrLabel1") }}
            </div>
            <div class="text-sm font-medium text-navy-200">
              {{ $t("translation.profile.dashboard.qrLabel2") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { copyToClipboard, isEmpty } from "@/utils/utils";
// import BannerComponent from "@/components/Banner/BannerComponent.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";
import AnnouncementPreview from "@/common/Announcement/AnnouncementPreview.vue";
import PairSmallTable from "@/common/PairTable/PairSmallTable.vue";
import NewListingSmallTable from "@/common/PairTable/NewListingSmallTable.vue";
import CopyIcon from "@/assets/icons/common/CopyIcon.vue";
//import EditIcon from "@/assets/icons/common/EditIcon.vue";
import QrImage from "@/assets/icons/common/QrImage.vue";
import UserAvatarIcon from "@/assets/icons/common/UserAvatarIcon.vue";
import {
  ArrowDownTrayIcon,
  // ArrowUpTrayIcon,
  EyeIcon,
  ShieldCheckIcon,
  EllipsisVerticalIcon,
  ChevronRightIcon,
  EyeSlashIcon,
} from "@heroicons/vue/24/outline";

const { t } = useI18n();
const selectedTab = ref(0);
const showInfo = ref(true);
const store = useStore();
const router = useRouter();
const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const favoritePairs = computed(() => store.getters.favoritePairs);
const loggedIn = computed(() => store.getters.isLoggedIn);
const newListing = computed(() => store.getters.newListing);
const profile = computed(() => store.getters.profile);
const verification = computed(() => store.getters.verificationInfo);
const loginHistory = computed(() => store.getters.loginHistoryList);

store.dispatch("fetchLoginHistory");

const handleChangeSelectedTab = (item) => {
  selectedTab.value = item.id;
};

const masked_email = computed(() => profile.value?.profile?.email_masked ?? "");
const user_id = computed(() => profile.value?.profile?.id ?? "");
const verification_state = computed(() =>
  verification.value?.verification_state === "VERIFIED"
    ? "Verified"
    : "Not Verified"
);
const phone = computed(() => profile.value?.profile?.phone ?? "*******");
const is_risky = computed(() => profile.value?.profile?.isRisky ?? false);
const country = computed(() => profile.value?.profile?.country_of_residence);
const lastLoginHistory = computed(() => {
  if (isEmpty(loginHistory.value)) {
    return "";
  }
  const item = loginHistory.value[0];
  return `${moment(item.login_time * 1000).format("MM/DD/YYYY, hh:mm:ss")} ${
    item.location
  }`;
});

const displayData = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item) => {
      return {
        id: item.name,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
        volume: item.volume_world_f,
        volume24h: item.volume_f,
        imgUrl: activeCurrencies.value[item.main.id].icon,
        favorite:
          loggedIn.value === true
            ? favoritePairs.value.includes(item.name)
            : false,
      };
    })
    .filter(
      (item) => selectedTab.value === 1 || favoritePairs.value.includes(item.id)
    );
});

const newListingData = computed(() => {
  if (
    isEmpty(allPairs.value) ||
    isEmpty(activeCurrencies.value) ||
    isEmpty(favoritePairs.value) ||
    isEmpty(newListing.value)
  ) {
    return [];
  }
  return newListing.value.map((item) => {
    const pairData = allPairs.value.find((pair) => pair.name === item.iso3);
    return {
      id: item.iso3,
      token1: pairData.main.iso3,
      token2: pairData.second.iso3,
      imgUrl: item.image,
      timeStart: item.time_start,
      rate: item.rate_usd,
      percent: item.ticker.percent_f,
      volume24h: pairData.volume_f,
      marketCap: pairData.volume,
    };
  });
});

const handleCopyClick = () => {
  copyToClipboard(user_id.value);
  store.dispatch("setToast", {
    type: "success",
    message: t("translation.profile.dashboard.idCopySuccess"),
  });
};

const handleDepositClick = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const handleWithdrawClick = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const handleBuyCryptoClick = () => {
  router.push({ name: "buy-sell" });
};

const handleMarketClick = () => {
  router.push({ name: "markets" });
};

const handleAnnouncementClick = () => {
  router.push({ name: "announcement" });
};

const handleHideInfoClick = () => {
  showInfo.value = !showInfo.value;
};

onBeforeMount(() => {
  store.dispatch("fetchNewListings");
});

const marketItems = computed(() => [
  { id: 0, name: t("translation.profile.dashboard.marketTabHeaders.0") },
  { id: 1, name: t("translation.profile.dashboard.marketTabHeaders.1") },
  { id: 2, name: t("translation.profile.dashboard.marketTabHeaders.2") },
]);
</script>
