<template>
  <dialog-view
    contain-header
    :title="$t('translation.profile.addressBook.addDialog.title')"
    :is-open="model"
    bg-color="gray"
    @change-is-open="handleCloseAdddAddress"
  >
    <template #content>
      <div class="mt-6 flex flex-col gap-6">
        <select-token-dropdown
          :label-text="
            $t('translation.profile.addressBook.addDialog.assetDropdown')
          "
          :default-coin="selectedToken"
          :currencies="activeCurrencies"
          :error="error.token"
          @select="handleSelectToken"
          :filled="currentTheme === 'dark'"
        />
        <text-edit
          :label="$t('translation.profile.addressBook.addDialog.addressLabel')"
          size="lg"
          rounded="xl"
          :placeholder="
            $t('translation.profile.addressBook.addDialog.addressPlaceholder')
          "
          v-model="walletAddress"
          :error="error.address"
          :filled="currentTheme === 'dark'"
        >
          <template #suffix>
            <link-button
              class="font-semibold"
              color="primary"
              :label="
                $t('translation.profile.addressBook.addDialog.pasteButton')
              "
              @click="handleClickPaste"
            />
          </template>
        </text-edit>

        <select-network-dropdown
          :label-text="
            $t('translation.profile.addressBook.addDialog.networkDropdown')
          "
          :show-search-icon="false"
          :default-network="selectedNetwork"
          :networks="selectedToken?.networks ?? []"
          :error="error.network"
          :filled="currentTheme === 'dark'"
          @select="handleSelectNetwork"
        />
        <text-edit
          rounded="xl"
          :label="$t('translation.profile.addressBook.addDialog.whiteLabel')"
          :placeholder="
            $t('translation.profile.addressBook.addDialog.whitePlaceholder')
          "
          v-model="whiteLabel"
          :error="error.white"
          :filled="currentTheme === 'dark'"
        >
        </text-edit>
        <fill-button
          class="mt-6"
          color="primary"
          width="full"
          size="lg"
          @click="handleConfirm"
        >
          {{ $t("translation.profile.addressBook.addDialog.confirmButton") }}
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import {
  ref,
  watch,
  computed,
  defineModel,
  defineProps,
  defineEmits,
} from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { postHttpRequest } from "@/api/api";
import { checkFormValidation, isEmpty } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import SelectTokenDropdown from "@/common/SelectCurrencyDropdown/SelectTokenDropdown.vue";
import SelectNetworkDropdown from "@/common/SelectNetworkDropdown/SelectNetworkDropdown.vue";

const { t } = useI18n();
const store = useStore();
const model = defineModel();
const emit = defineEmits(["requestSend"]);

const props = defineProps({
  coin: Object,
  address: String,
  network: Object,
});

const activeCurrencies = computed(() =>
  Object.values(store.getters.activeCurrencies)
);

watch(
  () => props.coin,
  (newValue) => {
    selectedToken.value = newValue;
  }
);

watch(
  () => props.address,
  (newValue) => {
    walletAddress.value = newValue;
  }
);

watch(
  () => props.network,
  (newValue) => {
    selectedNetwork.value = newValue;
  }
);

const selectedToken = ref(props.coin);
const walletAddress = ref(props.address);
const selectedNetwork = ref(props.network);
const whiteLabel = ref();

const error = ref({
  token: null,
  address: null,
  network: null,
  white: null,
});

// Handle Select Token
const handleSelectToken = (token) => {
  selectedToken.value = token;
};

// Handle Click Paste Address
const handleClickPaste = async () => {
  try {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      // Get the clipboard content (text)
      walletAddress.value = await navigator.clipboard.readText();
    }
  } catch (error) {
    console.error(error);
  }
};

// Handle Select Network
const handleSelectNetwork = (network) => {
  selectedNetwork.value = network;
};

// Handle Confirm Add Address
const handleConfirm = async () => {
  if (isEmpty(selectedToken.value))
    error.value.token = t(
      "translation.profile.addressBook.addDialog.validationError.0"
    );
  else error.value.token = null;
  if (isEmpty(walletAddress.value))
    error.value.address = t(
      "translation.profile.addressBook.addDialog.validationError.1"
    );
  else error.value.address = null;
  if (isEmpty(selectedNetwork.value))
    error.value.network = t(
      "translation.profile.addressBook.addDialog.validationError.2"
    );
  else error.value.network = null;
  if (isEmpty(whiteLabel.value))
    error.value.white = t(
      "translation.profile.addressBook.addDialog.validationError.3"
    );
  else error.value.white = null;
  if (checkFormValidation(error.value)) {
    try {
      const data = {
        iso: selectedToken.value.iso3,
        name: whiteLabel.value,
        address: walletAddress.value,
        network_type: selectedNetwork.value.id,
        is_white: 1,
        send_to_email: 1,
        send_to_phone: 1,
      };
      //console.log(data);
      const res = await postHttpRequest(store)("/api/account/add-book", data);
      //console.log(res);
      if (res.status) {
        emit("requestSend", { data, response: res.data });
        model.value = false;
      } else {
        store.dispatch("setToast", {
          type: "error",
          message: t("translation.profile.addressBook.addDialog.errorToast"),
        });
      }
    } catch (error) {
      store.dispatch("setToast", {
        type: "error",
        message: error,
      });
    }
  }
};

const handleCloseAdddAddress = () => {
  model.value = !model.value;
};
</script>
