<template>
  <div>
    <phone-input
      :label="$t('translation.auth.forgetPassword.phoneNumber.phoneLabel')"
      :placeholder="
        $t('translation.auth.forgetPassword.phoneNumber.phonePlaceholder')
      "
      v-model="phone"
    />
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from "vue";
import PhoneInput from "@/components/PhoneInput/PhoneInput.vue";

const phone = ref("");
const emit = defineEmits(["changePhone"]);

watch(phone, (newPhone) => {
  emit("changePhone", newPhone);
});
</script>
