<template>
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0H0L6.58579 6.58579C7.36684 7.36684 8.63316 7.36684 9.41421 6.58579L16 0Z"
      fill="currentColor"
    />
  </svg>
</template>
