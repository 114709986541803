<template>
  <div
    class="mx-auto container w-full flex flex-col items-center sm:px-16 px-4"
  >
    <div
      class="sm:mt-28 mt-6 text-[32px] leading-[40px] -tracking-[0.8px] w-full text-center font-bold sm:text-[56px] sm:leading-[64px] sm:-tracking-[1.4px] dark:text-white text-navy-800"
    >
      FAQ
    </div>
    <div class="mt-12 sm:mt-16 w-full max-w-[1088px] mb-[104px] sm:mb-52">
      <FaqComponent :faq-data="faqData" />
    </div>
  </div>
</template>
<script setup>
import FaqComponent from "@/common/FAQ/FaqComponent.vue";
import { ref } from "vue";

const faqData = ref();

(async () => {
  try {
    const response = await fetch("/mock/faq/faq.json");
    const data = await response.json();
    faqData.value = data;
  } catch (error) {
    console.error(error);
  }
})();
</script>
