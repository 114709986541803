<template>
  <div
    class="dark:text-white sm:text-sx text-sm sm:leading-[18px] leading-5 sm:h-52 custom-scrollbar-light"
  >
    {{ $t("translation.profile.idverify.exposedPersonDialog.text") }}
  </div>
  <fill-button class="w-full mt-12" @click="handleClickNextButton">
    {{ $t("translation.profile.idverify.exposedPersonDialog.button") }}
  </fill-button>
</template>
<script setup>
import { defineEmits } from "vue";

const emits = defineEmits(["next", "close"]);

const handleClickNextButton = () => {
  emits("next");
};
</script>
