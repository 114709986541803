import { computed, ref } from "vue";
import { useStore } from "vuex";
import { USER_SERVICE_API_URL } from "@/constants/constants";
import session_manager from "@/utils/SessionManager";
import { useI18n } from "vue-i18n";
import { postHttpRequest } from "@/api/api";
import router from "@/router";

export const useVerification = () => {
  const store = useStore();
  const { t } = useI18n();

  const error = ref();
  const code = computed(() => store.getters.code);

  const handleVerifyCode = async (newCode) => {
    try {
      error.value = "";
      if (code.value.context === "signin") {
        const res = await postHttpRequest(store)(
          `${USER_SERVICE_API_URL}/signin/verify`,
          {
            exziId: code.value.exziId,
            phoneOtp: newCode,
            emailOtp: newCode,
            token: code.value.token,
            context: code.value.context,
          }
        );

        if (res.status) {
          const privateKey = {
            exziId: res.data.exziId,
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          };
          session_manager.setPrivateKey(privateKey);
          session_manager.remove2FactorRequirement();
          store.dispatch("verifyCode");
          router.push({ name: "home" });
        } else {
          error.value = t("translation.auth.verificationCode.emailError");
        }
      } else if (code.value.context === "signup") {
        const res = await postHttpRequest(store)(
          `${USER_SERVICE_API_URL}/signup/verify`,
          {
            exziId: code.value.exziId,
            phoneOtp: newCode,
            emailOtp: newCode,
          }
        );
        if (res.status) {
          store.dispatch("verifyCode");
          store.dispatch("setToast", {
            type: "success",
            message: res.data.message,
          });
          router.push({ name: "signin" });
        } else {
          error.value = t("translation.auth.verificationCode.emailError");
        }
      } else if (code.value.context === "recovery") {
        const res = await postHttpRequest(store)(
          `${USER_SERVICE_API_URL}/forget-password/verify-otp`,
          {
            exziId: code.value.exziId,
            token: code.value.token,
            emailOtp: newCode,
            phoneOtp: newCode,
          }
        );
        if (res.status) {
          const privateKey = {
            exziId: res.data.exziId,
            resetPasswordToken: res.data.token,
          };
          session_manager.setPrivateKey(privateKey);
          store.dispatch("setAuthInfo", privateKey);
          store.dispatch("verifyCode");
          router.push({ name: "recovery" });
        } else {
          error.value = t("translation.auth.verificationCode.emailError");
        }
      }
    } catch (err) {
      error.value = err.data.error;
    }
  };

  const handleResendCodeAsync = async () => {
    try {
      error.value = "";
      const res = await postHttpRequest(store)(
        `${USER_SERVICE_API_URL}/resend-otp/${code.value.codeSentTo}`,
        {
          exziId: code.value.exziId,
          context: code.value.context,
        }
      );

      if (res.status) {
        const codeInfo = {
          ...code.value,
          timeSentVerificationCode: new Date(),
        };

        session_manager.set2FactorCodeInfo(codeInfo);
        store.dispatch("setCode", codeInfo);
        return true;
      } else {
        error.value = t("translation.auth.verificationCode.emailError");
      }
    } catch (err) {
      console.log(err);
      error.value = err.data.error;
    }
  };

  return {
    code,
    error,
    handleVerifyCode,
    handleResendCodeAsync,
  };
};
