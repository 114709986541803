import session_manager from "@/utils/SessionManager";
import { isEmpty } from "@/utils/utils";

const state = {
  code: session_manager.get2FactorCodeInfo(),
  authInfo: session_manager.getTokenInfo(),
};

const mutations = {
  SET_CODE(state, code) {
    state.code = code;
  },
  VERIFY_CODE(state) {
    state.code = {
      exziId: "",
      email: "",
      phone: "",
      required: false,
      requiredType: false,
      codeSentTo: "",
      timeBeforeNewCode: 0,
      token: "",
      context: "",
      timeSentVerificationCode: null,
    };
  },
  SET_AUTHINFO(state, authInfo) {
    state.authInfo = authInfo;
  },
  CLEAR_AUTHINFO(state) {
    state.authInfo = null;
  },
};

const actions = {
  // Set Codes
  setCode({ commit }, code) {
    commit("SET_CODE", code);
  },
  // Remove Codes
  verifyCode({ commit }) {
    commit("VERIFY_CODE");
  },
  // Set Authentication
  setAuthInfo({ commit }, authInfo) {
    commit("SET_AUTHINFO", authInfo);
  },

  clearAuthInfo({ commit }) {
    commit("CLEAR_AUTHINFO");
    session_manager.removeToken();
  },
};

const getters = {
  code: (state) => state.code,
  authInfo: (state) => state.authInfo,
  isLoggedIn: (state) => {
    return (
      !isEmpty(state.authInfo?.token) && !isEmpty(session_manager.getToken())
    );
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
