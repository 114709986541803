<template>
  <div class="relative">
    <div class="absolute w-full h-fit -z-50">
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-30% from-white dark:to-transparent to-60% -z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent -z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0 -z-50"
      />
    </div>
    <div class="absolute -z-50 w-full -top-12">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/blog/ellipse-dark.svg'
            : '/assets/images/blog/ellipse-light.svg'
        "
        class="w-full"
      />
    </div>
    <div class="container mx-auto w-full px-2 flex flex-col sm:px-16">
      <div class="mt-10 flex flex-row items-center text-xs font-medium">
        <div
          class="mr-2 dark:text-navy-100 cursor-pointer"
          @click="router.push({ name: 'blog' })"
        >
          {{ $t("translation.blog.breadcrumb.0") }}
        </div>
        <div class="w-4 h-4 mr-2">
          <chevron-right-icon class="w-4 h-4 stroke-2 text-navy-100" />
        </div>
        <div class="text-navy-200 dark:text-navy-10 cursor-pointer">
          {{ $t("translation.blog.breadcrumb.1") }}
        </div>
      </div>
      <div class="flex lg:flex-row flex-col mt-6 gap-20">
        <div class="flex flex-col basis-2/3">
          <div class="text-[32px] font-semibold dark:text-white text-navy-800">
            {{ articleData?.title ?? "" }}
          </div>
          <div
            class="mt-6 flex flex-row text-xs font-medium dark:text-white text-navy-800 items-center flex-wrap"
          >
            <div>{{ articleDate }}</div>
            <div class="flex flex-row">
              <text-badge
                :label="item"
                class="px-3 py-2 dark:bg-[#333C57] bg-[#ECEEF4] text-xs font-medium ml-2 dark:text-white text-navy-800"
                v-for="item in Badges"
                :key="item"
              />
            </div>
          </div>
          <div class="flex flex-row gap-4 lg:hidden items-center mt-6">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.blog.share") }}
            </div>
            <div class="flex flex-row gap-4 dark:text-white text-navy-300">
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <telegram-icon />
              </div>
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <twitter-icon />
              </div>
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <linkedin-icon />
              </div>
            </div>
          </div>
          <div class="mt-[72px] lg:hidden">
            <carousel-component>
              <template v-for="item in topQuestionData" :key="item.id">
                <img :src="item.imageURL" />
              </template>
            </carousel-component>
            <div class="flex gap-3 items-center ml-4 mb-6">
              <fire-icon class="text-red-500 w-6 h-6 stroke-2" />
              <div
                class="font-semibold text-[20px] dark:text-white text-navy-800"
              >
                {{ $t("translation.blog.topTopics") }}
              </div>
            </div>
            <div class="mt-6">
              <carousel-component>
                <template v-for="item in topQuestionData" :key="item.id">
                  <article-summary-item
                    :heading="item.heading"
                    :update-time="item.time"
                    :content="item.content"
                    size="sm"
                    @click="handleMenuItemClick(item.url)"
                  />
                </template>
              </carousel-component>
            </div>
          </div>
          <img
            :src="articleData?.image ?? ''"
            class="mt-10 w-full max-lg:hidden rounded-lg"
          />
          <div class="mt-10">
            <html-preview :html="articleData?.text ?? ''" />
          </div>
        </div>
        <div class="mb-10"></div>
        <div class="flex flex-col flex-1 max-lg:hidden min-w-[416px]">
          <div class="flex flex-col gap-4 ml-[-96px]">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.blog.share") }}
            </div>
            <div class="flex flex-row gap-4 dark:text-white text-navy-300">
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <telegram-icon />
              </div>
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <twitter-icon />
              </div>
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <linkedin-icon />
              </div>
            </div>
          </div>
          <div class="mt-[72px]">
            <div
              class="text-2xl font-semibold dark:text-white text-navy-800 px-4"
            >
              <div class="flex gap-3 items-center ml-4 mb-6">
                <fire-icon class="text-red-500 w-6 h-6 stroke-2" />
                <div
                  class="font-semibold text-[20px] dark:text-white text-navy-800"
                >
                  {{ $t("translation.blog.topTopics") }}
                </div>
              </div>
            </div>
            <div class="flex flex-col mt-6 w-full">
              <template v-for="(item, index) in topQuestionData" :key="item.id">
                <div
                  v-if="index !== 0"
                  class="my-2 dark:border-t-navy-500 border-t-navy-10 border-t"
                ></div>
                <article-summary-item
                  :heading="item.heading"
                  :update-time="item.time"
                  :content="item.content"
                  size="sm"
                  @click="handleMenuItemClick(item.url)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="sm:mt-10 mt-25 w-12 h-12 dark:bg-navy-500 bg-navy-10 flex items-center justify-center rounded-full cursor-pointer"
        v-on:click="handleBlogVoteClick"
      >
        <hand-thumb-up-icon
          class="dark:text-white text-black w-6 h- stroke-2"
        />
      </div>
      <div class="ml-5 mb-10 mt-3">
        {{ 3 + voteCount }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref, computed, watch } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import ArticleSummaryItem from "@/views/Blog/Items/ArticleSummaryItem.vue";
import { useStore } from "vuex";
import { isEmpty, scrollToTop } from "@/utils/utils";
import TextBadge from "@/components/Badge/TextBadge.vue";
import CarouselComponent from "@/components/Carousel/CarouselComponent.vue";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import LinkedinIcon from "@/assets/icons/common/LinkedinIcon.vue";
import { FireIcon, HandThumbUpIcon } from "@heroicons/vue/24/outline";
import HtmlPreview from "@/components/Preview/HtmlPreview.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const voteCount = ref(0);

store.dispatch("getTopTopics");
store.dispatch("getLatestArticles");
const latestArticles = computed(() => store.getters.latestArticles);
const topQuestionArticles = computed(() => store.getters.topTopics);

const Badges = ["Spot", "Latest Events", "ETH"];

const topQuestionData = computed(() => {
  if (isEmpty(topQuestionArticles.value)) {
    return [];
  }
  return topQuestionArticles.value.map((item, index) => {
    const date = new Date(item.time_create * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return {
      id: index,
      heading: item.title,
      content: item.short_text,
      url: item.url,
      imageURL: item.image_default,
      time: `Updated on ${formattedDate}`,
    };
  });
});

const articleData = computed(() => {
  if (isEmpty(route.params.url)) return null;
  return latestArticles.value.find((value) => value?.url === route.params?.url);
});

const articleDate = computed(() => {
  if (isEmpty(articleData.value)) {
    return;
  }
  const date = new Date(articleData.value.time_create * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
});

onMounted(() => {
  scrollToTop();
});

watch(
  () => route.params.url,
  () => {
    scrollToTop();
  }
);

const handleMenuItemClick = (url) => {
  router.push({
    name: "blog-detail",
    params: {
      url: url,
    },
  });
};

const handleBlogVoteClick = () => {
  voteCount.value = !voteCount.value;
};
</script>
