import parsePhoneNumberFromString from "libphonenumber-js";

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const uppercasePattern = /[A-Z]/;
  const numberPattern = /\d.*/;
  const symbolPattern = /[!@#$%^&*(),.?":{}|<>].*/;
  const validation = [false, false, false, false];
  if (password.length >= 12) {
    validation[0] = true;
  }
  if (uppercasePattern.test(password)) {
    validation[1] = true;
  }
  if (numberPattern.test(password)) {
    validation[2] = true;
  }
  if (symbolPattern.test(password)) {
    validation[3] = true;
  }

  return validation;
};

export const validatePhoneNumber = (phoneNumber) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedPhoneNumber?.isValid();
};

export const validateName = (name) => {
  const re = /^(?![_-])[a-zA-Z0-9_-]{3,16}(?<![_-])$/;
  return re.test(String(name));
};
