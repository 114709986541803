<template>
  <div>
    <div class="text-title font-semibold">
      {{ $t("translation.auth.recoverPassword.title") }}
    </div>
    <div class="mt-3 text-lg font-medium dark:text-navy-100">
      {{ $t("translation.auth.recoverPassword.subTitle") }}
    </div>
    <div class="sm:h-10 h-6" />
    <password-input
      :label="$t('translation.auth.recoverPassword.newPasswordLabel')"
      :placeholder="
        $t('translation.auth.recoverPassword.newPasswordPlaceholder')
      "
      v-model="password"
      :error="passwordError"
    />
    <div class="h-6" />
    <password-input
      :label="$t('translation.auth.recoverPassword.confirmPasswordLabel')"
      :placeholder="
        $t('translation.auth.recoverPassword.confirmPasswordPlaceholder')
      "
      v-model="confirmPassword"
      :error="confirmPasswordError"
    />
    <div class="h-10" />
    <filled-button
      :label="$t('translation.auth.recoverPassword.setPassword')"
      :disabled="isDisabled"
      :isLoading="isLoading"
      @click="handleSetPassword"
    />
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { validatePassword } from "@/views/Auth/validation";
import { useRecoveryPassword } from "./recoveryPassword";

const { t } = useI18n();
const { password, isLoading, handleSetPassword } = useRecoveryPassword();
const confirmPassword = ref("");
const passwordError = computed(() => validatePassword(password.value));

const confirmPasswordError = computed(() => {
  const isValid = validatePassword(confirmPassword.value);
  return isValid.every((item) => item === true)
    ? confirmPassword.value === password.value
      ? isValid
      : t("translation.auth.recoverPassword.passwordError")
    : isValid;
});

const isDisabled = computed(() => {
  return !(
    validatePassword(password.value).every((item) => item === true) &&
    confirmPassword.value === password.value
  );
});
</script>
