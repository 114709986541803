<template>
  <dialog-view
    v-if="model"
    title="Filter"
    :contain-header="true"
    :is-open="model"
    @change-is-open="handleOpenChange"
  >
    <template #content>
      <div class="flex flex-col w-full">
        <div class="text-sm font-semibold mb-2">Coin</div>
        <combo-box
          placeholder="Coin"
          :items="coinComboItem"
          v-model="coinSelect"
        />
        <div class="mt-6 mb-2 font-semibold text-sm">Type</div>
        <combo-box
          placeholder="Type"
          :items="typeComboItem"
          v-model="typeSelect"
        />
        <div class="mt-6 mb-2 font-semibold text-sm">Status</div>
        <combo-box
          placeholder="Status"
          :items="statusComboItem"
          v-model="statusSelect"
        />
        <div class="mt-8 w-full">
          <fill-button size="md" width="full" @click="handleApplyClick">
            Apply
          </fill-button>
        </div>
        <div class="mt-4 mx-auto">
          <link-button label="Reset" @click="handleResetClick"></link-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { computed, defineModel, defineEmits, inject, ref, watch } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel(false);
const emit = defineEmits(["reset"]);

const coinSelectParent = inject("coin");
const typeSelectParent = inject("type");
const statusSelectParent = inject("status");

const coinSelect = ref(coinSelectParent.value);
watch(
  () => coinSelectParent.value,
  (newVal) => {
    coinSelect.value = newVal;
  }
);
const typeSelect = ref(typeSelectParent.value);
watch(
  () => typeSelectParent.value,
  (newVal) => {
    typeSelect.value = newVal;
  }
);
const statusSelect = ref(statusSelectParent.value);
watch(
  () => statusSelectParent.value,
  (newVal) => {
    statusSelect.value = newVal;
  }
);

const store = useStore();

const allPairsList = computed(() => store.getters.activeCurrencies);

const coinComboItem = computed(() => {
  return Object.values(allPairsList.value).map((item) => ({
    id: item.id,
    label: item.iso3,
  }));
});

watch(
  () => coinComboItem.value,
  (newVal) => {
    if (!isEmpty(newVal)) {
      coinSelect.value = newVal[0];
    }
  }
);

const handleOpenChange = () => {
  model.value = false;
};

const handleResetClick = () => {
  emit("reset");
};

const handleApplyClick = () => {
  coinSelectParent.value = coinSelect.value;
  typeSelectParent.value = typeSelect.value;
  statusSelectParent.value = statusSelect.value;
  model.value = false;
};

const typeComboItem = [
  { id: "DEPOSIT", label: "Deposit" },
  { id: "TRADE", label: "Trade" },
  { id: "WITHDRAW", label: "Withdraw" },
  { id: "INTERNAL_TRANSFER", label: "Internal Transfer" },
];

const statusComboItem = [
  { id: 1, label: "Completed" },
  { id: 2, label: "Canceled" },
];
</script>
