<template>
  <div
    :class="[
      'h-12 sm:px-6 px-4 flex flex-row justify-between items-center sticky top-0 z-[100] w-full',
      bgClass,
    ]"
  >
    <img
      :src="
        currentTheme === 'dark'
          ? require('@/assets/images/navbar/navbar_logo_dark.svg')
          : require('@/assets/images/navbar/navbar_logo_light.svg')
      "
      alt="Exzi Logo"
      class="sm:mr-12 cursor-pointer"
      @click="handleLogoClick"
    />
    <div class="lg:flex flex-row items-center hidden gap-8">
      <navbar-menu-item
        v-for="item in navbarData"
        :key="item.id"
        :disabled="item.disabled"
        :alignment="item.alignment"
        :isSubMenu="item.isSubMenu"
        :label="item.label"
        :pathName="item.pathName"
        :sub-menu="item.subMenu"
        :sub-menu-props="item.subMenuProps"
      />
    </div>
    <div class="max-2xl:hidden ml-8">
      <!-- <filled-button
        class="bg-primary-700 bg-opacity-[18%] px-3 h-8 dark:text-primary-500 text-primary-800 text-sm hover:bg-opacity-100 hover:text-white"
        :label="$t('translation.header.navbar.rewardsHub')"
        type="iconLabel"
        @click="handleRewardsHubClick"
      >
        <template #icon>
          <gift-icon class="stroke-2" />
        </template>
      </filled-button> -->
      <filled-button
        class="bg-primary-700 px-3 py-1.5 text-sm leading-5 font-semibold text-white hover:bg-primary-800"
        :label="$t('translation.header.navbar.deposit')"
        type="iconLabel"
        @click="handleSignUpClick"
      >
        <template #icon>
          <arrow-down-tray-icon class="stroke-2" />
        </template>
      </filled-button>
    </div>
    <div class="flex-grow" />
    <div class="mr-2" v-if="!showSubMenu">
      <navbar-menu-item
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="SearchDropdown"
        alignment="center"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <magnifying-glass-icon class="stroke-2 w-6 h-6" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <!-- <div v-if="!isLoggedIn" class="max-sm:hidden mr-2 px-4">
      <navbar-menu-item
        :is-sub-menu="false"
        :label="$t('translation.header.navbar.authButtons.login')"
        pathName="signin"
      />
    </div> -->
    <div v-if="!isLoggedIn && !showSubMenu" class="mr-3 max-sm:hidden">
      <fill-button size="sm" color="primary" @click="handleSignUpClick">
        <p class="px-2">
          {{ $t("translation.header.navbar.authButtons.signup") }}
        </p>
      </fill-button>
    </div>
    <div v-if="isLoggedIn" class="max-xl:hidden mr-7">
      <filled-button
        type="iconLabel"
        class="bg-primary-700 text-white h-8 px-3 text-sm"
        :label="$t('translation.header.navbar.buttonBar.deposit')"
        @click="goToDeposit"
      >
        <template #icon>
          <arrow-down-tray-icon class="stroke-2"></arrow-down-tray-icon>
        </template>
      </filled-button>
    </div>
    <div v-if="isLoggedIn" class="max-sm:hidden mr-4">
      <navbar-menu-item
        :isSubMenu="true"
        :sub-menu="AssetsDropdown"
        alignment="center"
        :label="$t('translation.header.navbar.buttonBar.assets')"
      />
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-6">
      <navbar-menu-item
        :isSubMenu="true"
        :mobile-adapted="true"
        :sub-menu="ProfileDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <span
            :class="[
              'flex items-center justify-center cursor-pointer rounded-full w-8 h-8 my-2 text-center align-middle text-sm font-medium border border-navy-300 hover:bg-navy-300  hover:text-white',
              activated
                ? 'bg-navy-300 text-white'
                : 'dark:bg-navy-500 dark:text-white bg-navy-50 text-navy-800',
            ]"
            @click="handleContentClick"
          >
            <user-avatar-icon class="size-6 rounded-full" />
          </span>
        </template>
      </navbar-menu-item>
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="NotificationDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <bell-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden pr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :sub-menu="DownloadDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <arrow-down-tray-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden pr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :sub-menu="LanguageDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <globe-alt-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden">
      <icon-button @click="onThemeChange">
        <sun-icon class="w-6 h-6 stroke-2" />
      </icon-button>
    </div>
    <div class="sm:hidden">
      <icon-button @click="showSubMenu = !showSubMenu">
        <bars3-icon v-if="!showSubMenu" class="w-6 h-6 stroke-2" />
        <x-mark-icon v-else class="w-6 h-6 stroke-2" />
      </icon-button>
    </div>
    <app-navbar-mobile
      :is-show="showSubMenu"
      :no-animation="noAnimation"
      @close-click="handleMobileClick"
      @download-click="showDownload = true"
      @language-click="showLanguage = true"
    />
    <download-modal v-model="showDownload" />
    <language-modal v-model="showLanguage" />
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import IconButton from "@/components/Button/IconButton.vue";
import { allPairItemsFromData } from "@/utils/utils";
import NotificationDropdown from "@/layout/AppNavbar/dropdown/NotificationDropdown.vue";
import ProfileDropdown from "@/layout/AppNavbar/dropdown/ProfileDropdown.vue";
import DownloadDropdown from "@/layout/AppNavbar/dropdown/DownloadDropdown.vue";
import AssetsDropdown from "@/layout/AppNavbar/dropdown/AssetsDropdown.vue";
import LanguageDropdown from "@/layout/AppNavbar/dropdown/LanguageDropdown.vue";
import SearchDropdown from "@/layout/AppNavbar/dropdown/SearchDropdown.vue";
import LanguageModal from "@/layout/AppNavbar/modal/LanguageModal.vue";
import DownloadModal from "@/layout/AppNavbar/modal/DownloadModal.vue";
import NavbarMenuItem from "@/layout/AppNavbar/item/NavbarMenuItem.vue";
import TradeDropdown from "@/layout/AppNavbar/dropdown/TradeDropdown.vue";
import MoreDropdown from "@/layout/AppNavbar/dropdown/MoreDropdown.vue";
import AppNavbarMobile from "@/layout/AppNavbar/AppNavbarMobile.vue";
import UserAvatarIcon from "@/assets/icons/common/UserAvatarIcon.vue";
import {
  ArrowDownTrayIcon,
  Bars3Icon,
  BellIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();
const isAtTop = ref(false);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const showSubMenu = ref(false);
const showLanguage = ref(false);
const showDownload = ref(false);
const noAnimation = ref(false);
const { t } = useI18n();

const onThemeChange = () => {
  store.dispatch("toggleTheme", store.getters.currentTheme);
};

watch(
  () => showLanguage.value,
  (newVal) => {
    if (newVal === false) {
      noAnimation.value = false;
    }
  }
);

watch(
  () => showDownload.value,
  (newVal) => {
    if (newVal === false) {
      noAnimation.value = false;
    }
  }
);

const allPairsModified = computed(() => store.getters.allPairsModified);
const activeCurrencies = computed(() => store.getters.activeCurrencies);

const allPairItems = computed(() =>
  allPairItemsFromData(allPairsModified.value, activeCurrencies.value)
);

const handleLogoClick = () => {
  router.push({ name: "home" });
};

const handleSignUpClick = () => {
  router.push({ name: "signup" });
};

// const handleRewardsHubClick = () => {
//   router.push({ name: "rewards-hub" });
// };

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

onMounted(() => {
  window.addEventListener("scroll", handleScrollEvent);
  handleScrollEvent();
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScrollEvent);
});

const handleScrollEvent = () => {
  isAtTop.value = window.scrollY === 0;
};

const handleMobileClick = (noAni) => {
  if (noAni === true) {
    showSubMenu.value = false;
    noAnimation.value = true;
  } else {
    showSubMenu.value = false;
    noAnimation.value = false;
  }
};

const bgClass = computed(() =>
  isAtTop.value
    ? "bg-transparent"
    : showSubMenu.value
    ? "dark:bg-navy-800 bg-white"
    : "dark:bg-navy-800 bg-white"
);

const navbarData = computed(() => [
  {
    id: 0,
    label: t("translation.header.navbar.buttonBar.buy"),
    // disabled: true,
    isSubMenu: false,
    pathName: "buy-sell",
  },
  {
    id: 1,
    label: t("translation.header.navbar.buttonBar.market"),
    pathName: "markets",
    isSubMenu: false,
  },
  {
    id: 2,
    label: t("translation.header.navbar.buttonBar.trade"),
    isSubMenu: true,
    subMenu: TradeDropdown,
    subMenuProps: { allPairItems },
    alignment: "center",
  },
  // {
  //   id: 3,
  //   label: t("translation.header.navbar.buttonBar.referral"),
  //   isSubMenu: false,
  //   pathName: "referral",
  // },
  {
    id: 3,
    label: t("translation.header.navbar.buttonBar.support"),
    isSubMenu: false,
    pathName: "help-center",
  },
  {
    id: 4,
    label: t("translation.header.navbar.buttonBar.more"),
    isSubMenu: true,
    subMenu: MoreDropdown,
    alignment: "center",
  },
]);
</script>
