<template>
  <div class="w-full px-4 sm:px-16">
    <div class="max-w-[1088px] container mx-auto flex flex-col mb-52">
      <div
        class="mt-16 font-bold text-5xl leading-[64px] -tracking-[1.2px] text-center w-full"
      >
        {{ $t("translation.feeSchedule.title") }}
      </div>
      <div class="mt-16 w-full">
        <underline-tab
          :items="tabTitles"
          :default-selected="tabTitles[0]"
          @change="handleTabChange"
        />
      </div>
      <div class="mt-12 w-full custom-scrollbar" v-if="currentTab === 1">
        <table class="sm:w-full mt-6 mb-4 table-auto w-max text-sm">
          <thead>
            <tr class="h-10">
              <th
                v-for="item in tableHeaderItems"
                :key="item.id"
                :class="[
                  'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
                  item.hide === false ? '' : `max-${item.hide}:hidden`,
                ]"
              >
                <div
                  :class="[
                    'flex items-center cursor-pointer',
                    item.align === 'left' ? 'justify-start' : '',
                    item.align === 'right' ? 'justify-end' : '',
                    ,
                  ]"
                  @click="
                    () => {
                      if (item.sortable === true) {
                        handleSortClicked(item.id);
                      }
                    }
                  "
                >
                  <div>{{ item.label }}</div>
                  <img
                    v-if="item.sortable === true"
                    src="@/assets/icons/vector.svg"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="mt-1">
            <tr
              v-for="item in tableData"
              :key="item.iso3"
              class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer text-sm font-semibold -tracking-[0.2px]"
            >
              <td class="px-3 rounded-l-xl">
                <div class="flex flex-row w-full items-center gap-2">
                  <img :src="item.icon" class="size-6 shrink-0" />
                  <div>{{ item.iso3 }}</div>
                </div>
              </td>
              <td class="px-3 py-6">
                <div class="flex flex-col w-full gap-2">
                  <div v-for="(net, index) in item.networks" :key="index">
                    {{ net }}
                  </div>
                </div>
              </td>
              <td class="px-3 py-6">
                <div class="flex flex-col w-full gap-2">
                  <div v-for="(net, index) in item.withdrawFee" :key="index">
                    {{ net }}
                  </div>
                </div>
              </td>
              <td class="px-3 py-6">
                <div class="flex flex-col w-full gap-2">
                  <div v-for="(net, index) in item.depositFee" :key="index">
                    {{ net }}
                  </div>
                </div>
              </td>
              <td class="px-3 py-6">
                <div class="flex flex-col w-full gap-2 text-right">
                  <div
                    v-for="(net, index) in item.minimumWithdrawal"
                    :key="index"
                  >
                    {{ net }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-12 w-full" v-else-if="currentTab === 2">
        <div class="font-bold text-2xl leading-9 -tracking-[0.4px] w-full">
          {{ $t("translation.feeSchedule.subTitle") }}
        </div>
        <div
          class="mt-2 w-full text-navy-200 font-normal text-sm -tracking-[0.2px]"
        >
          {{ $t("translation.feeSchedule.label") }}
        </div>
        <div class="mt-8 w-full grid sm:grid-cols-2 grid-cols-1 gap-8">
          <div
            class="py-8 flex flex-col w-full rounded-3xl border border-navy-600 text-center items-center gap-2"
            style="backdrop-filter: blur(56px)"
          >
            <div class="font-semibold text-sm -tracking-[0.2px]">
              {{ $t("translation.feeSchedule.orderLabel.1") }}
            </div>
            <div class="font-bold text-xl leading-8 -tracking-[0.4px]">
              0.26%
            </div>
          </div>
          <div
            class="py-8 flex flex-col w-full rounded-3xl border border-navy-600 text-center items-center gap-2"
            style="backdrop-filter: blur(56px)"
          >
            <div class="font-semibold text-sm -tracking-[0.2px]">
              {{ $t("translation.feeSchedule.orderLabel.2") }}
            </div>
            <div class="font-bold text-xl leading-8 -tracking-[0.4px]">
              0.2%
            </div>
          </div>
        </div>
        <div class="mt-8 grid xl:grid-cols-9 sm:grid-cols-6 grid-cols-3">
          <div
            v-for="item in currencies.slice(0, 18)"
            :key="item"
            class="w-full flex items-center justify-center py-6 border border-navy-600 border-collapse font-semibold text-sm -tracking-[0.2px]"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { useI18n } from "vue-i18n";

const currentTab = ref(1);
const store = useStore();
const { t } = useI18n();

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const handleTabChange = (value) => {
  currentTab.value = value.id;
};

const sortState = ref({
  id: 1,
  asc: true,
});

const handleSortClicked = (id) => {
  if (id === sortState.value.id) {
    sortState.value.asc = !sortState.value.asc;
    return;
  }
  sortState.value = {
    id: id,
    asc: false,
  };
};

const tableData = computed(() => {
  if (isEmpty(activeCurrencies.value)) {
    return [];
  }
  return Object.values(activeCurrencies.value)
    .map((item) => {
      return {
        icon: item.icon,
        iso3: item.iso3,
        networks: Object.values(item.networks).map((net) => net.nice_name),
        withdrawFee: Object.values(item.networks).map((net) => {
          if (net.withdraw.enabled === false) {
            return t("translation.feeSchedule.withdrawDisabled");
          }
          if (net.withdraw.fee.fixed === 0) {
            if (net.withdraw.fee.percent === 0) {
              return t("translation.feeSchedule.withdrawFree");
            }
            return `${net.withdraw.fee.percent} %`;
          } else {
            return `${net.withdraw.fee.fixed} ${item.iso3}`;
          }
        }),
        depositFee: Object.values(item.networks).map((net) => {
          if (net.deposit.enabled === false) {
            return t("translation.feeSchedule.depositDisabled");
          }
          if (net.deposit.fee.fixed === 0) {
            if (net.deposit.fee.percent === 0) {
              return t("translation.feeSchedule.depositFree");
            }
            return `${net.deposit.fee.percent} %`;
          } else {
            return `${net.deposit.fee.fixed} ${item.iso3}`;
          }
        }),
        minimumWithdrawal: Object.values(item.networks).map((net) => {
          if (net.withdraw.enabled === false) {
            return t("translation.feeSchedule.withdrawDisabled");
          }
          return `${net.withdraw.min_amount} ${item.iso3}`;
        }),
      };
    })
    .sort((valA, valB) =>
      sortState.value.asc
        ? valA.iso3.localeCompare(valB.iso3)
        : valB.iso3.localeCompare(valA.iso3)
    );
});

const currencies = computed(() => {
  if (isEmpty(activeCurrencies.value)) {
    return [];
  }
  return Object.values(activeCurrencies.value).map((item) => item.iso3);
});

const tabTitles = computed(() => [
  {
    id: 1,
    name: t("translation.feeSchedule.tabNames.1"),
  },
  {
    id: 2,
    name: t("translation.feeSchedule.tabNames.2"),
  },
]);

const tableHeaderItems = computed(() => [
  {
    id: 1,
    label: t("translation.feeSchedule.subTitle"),
    align: "left",
    hide: false,
    sortable: true,
  },
  {
    id: 2,
    label: t("translation.feeSchedule.tableHeaderNames.2"),
    align: "left",
    hide: false,
    sortable: false,
  },
  {
    id: 3,
    label: t("translation.feeSchedule.tableHeaderNames.3"),
    align: "left",
    hide: false,
    sortable: false,
  },
  {
    id: 4,
    label: t("translation.feeSchedule.tableHeaderNames.4"),
    align: "left",
    hide: false,
    sortable: false,
  },
  {
    id: 5,
    label: t("translation.feeSchedule.tableHeaderNames.5"),
    align: "right",
    hide: false,
    sortable: false,
  },
]);
</script>
