<template>
  <dialog-view
    :contain-header="true"
    title="FAQ"
    :is-open="model"
    v-if="model"
    @change-is-open="model = false"
  >
    <template #content>
      <div
        class="w-full min-h-[390px] dark:bg-navy-700 bg-white rounded-2xl p-4"
        @click="
          (e) => {
            e.stopPropagation();
          }
        "
      >
        <div v-for="(item, index) in faqData" :key="item.id" class="w-full">
          <div
            v-if="index != 0"
            class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-4"
          />
          <collapse-view
            :is-show="collapseState[index]"
            @header-click="
              (value) => {
                collapseState.fill(false);
                collapseState[index] = value;
              }
            "
          >
            <template #header="{ handleContentClick, isShowingContent }">
              <div
                :class="[
                  isShowingContent
                    ? 'text-primary-700'
                    : 'dark:text-navy-100 text-navy-800',
                  'text-xs font-medium  cursor-pointer w-fit',
                ]"
                @click="handleContentClick"
              >
                {{ item.title }}
              </div>
            </template>
            <template #content>
              <div
                class="mt-3 text-xs font-medium dark:text-white text-navy-800"
              >
                <html-preview :html="item.content" />
              </div>
            </template>
          </collapse-view>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { onBeforeMount, ref, defineModel } from "vue";
import CollapseView from "@/components/Collapse/CollapseView.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import HtmlPreview from "@/components/Preview/HtmlPreview.vue";

const faqData = ref([]);
const model = defineModel(false);
const collapseState = ref(Array(faqData.value?.length ?? 0).fill(false));

onBeforeMount(async () => {
  try {
    const response = await fetch("/mock/faq/faq.json");
    const data = await response.json();
    faqData.value = data.slice(0, 5);
    collapseState.value = Array(faqData.value?.length ?? 0).fill(false);
  } catch (error) {
    console.error(error);
  }
});
</script>
