<template>
  <dropdown-select
    alignment="end"
    vertical-alignment="top"
    :is-hover-trigger="true"
    width="full"
  >
    <template #header="{ handleContentClick }">
      <div
        :class="[
          'flex flex-row px-4 py-3 gap-4 rounded-xl cursor-pointer dark:hover:bg-navy-500 dark:sm:bg-[#1B1F2DC2]',
          props.topLabel || props.mainlabel ? '' : 'sm:bg-primary-200',
        ]"
        @click="handleContentClick"
      >
        <component
          :is="props.icon"
          :class="`w-10 h-10 dark:text-white ${
            props.mainlabel || props.topLabel
              ? 'text-primary-700'
              : 'text-navy-800'
          }`"
        />
        <div
          v-if="props.topLabel || props.mainlabel"
          class="flex flex-col justify-between max-sm:hidden"
        >
          <div class="text-sm font-medium dark:text-navy-50 text-navy-300">
            {{ props.topLabel }}
          </div>
          <div class="text-base font-semibold dark:text-white text-navy-800">
            {{ props.mainlabel }}
          </div>
        </div>
      </div>
    </template>
    <template #menu>
      <component
        class="mb-2 size-[192px]"
        v-if="!isEmpty(props.popup)"
        :is="props.popup"
      />
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps } from "vue";
import { isEmpty } from "@/utils/utils";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";

const props = defineProps({
  topLabel: String,
  mainlabel: String,
  icon: Object,
  popup: Object,
});
</script>
