const state = {
  self_service_articles: [],
  top_question_articles: [],
  beginner_guide_articles: [],
  popular_articles: [],
};

const mutations = {
  SET_SELF_SERVICE_ARTICLES(state, value) {
    state.self_service_articles = value;
  },
  SET_TOP_QUESTIONS_ARTICLES(state, value) {
    state.top_question_articles = value;
  },
  SET_BEGINNER_GUIDE_ARTICLES(state, value) {
    state.beginner_guide_articles = value;
  },
  SET_POPULAR_ARTICLES(state, value) {
    state.popular_articles = value;
  },
};

const actions = {
  async getSelfServiceArticles({ commit }) {
    try {
      const response = await fetch(
        "/mock/help-center/help_center_articles_v2.json"
      );
      const data = await response.json();
      commit("SET_SELF_SERVICE_ARTICLES", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getTopQuestionArticles({ commit }) {
    try {
      const response = await fetch(
        "/mock/help-center/help_center_articles_v2.json"
      );
      const data = await response.json();
      commit("SET_TOP_QUESTIONS_ARTICLES", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getBeginnerGuideArticles({ commit }) {
    try {
      const response = await fetch(
        "/mock/help-center/help_center_articles_v2.json"
      );
      const data = await response.json();
      commit("SET_BEGINNER_GUIDE_ARTICLES", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getPopularArticles({ commit }) {
    try {
      const response = await fetch(
        "/mock/help-center/help_center_articles_v2.json"
      );
      const data = await response.json();
      commit("SET_POPULAR_ARTICLES", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  help_center_articles: (state) => state.help_center_articles,
  selfServiceArticles: (state) => state.self_service_articles,
  topQuestionArticles: (state) => state.top_question_articles,
  beginnerGuideArticles: (state) => state.beginner_guide_articles,
  popularArticles: (state) => state.popular_articles,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
