<template>
  <div
    class="flex flex-row items-start rounded-2xl p-2 gap-x-6 dark:bg-navy-600 border dark:border-navy-400 dark:shadow-md dark:shadow-black/[0.32] bg-white shadow-xl shadow-black/[0.16] w-max"
  >
    <div class="flex flex-col gap-y-2">
      <template v-for="item in moreSubmenuData1" :key="item.id">
        <div class="w-[366px]">
          <navbar-submenu-item v-bind="item" @click="handleMenuItemClick" />
        </div>
      </template>
    </div>
    <div class="flex flex-col gap-y-2">
      <template v-for="item in moreSubmenuData2" :key="item.id">
        <div class="w-[366px]">
          <navbar-submenu-item v-bind="item" @click="handleMenuItemClick" />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, computed } from "vue";
import { useI18n } from "vue-i18n";
import BlogIcon from "@/assets/icons/common/BlogIcon.vue";
import LaunchpadIcon from "@/assets/icons/common/LaunchpadIcon.vue";
import HelpIcon from "@/assets/icons/common/HelpIcon.vue";
import RewardsIcon from "@/assets/icons/common/RewardsIcon.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import PeopleIcon from "@/assets/icons/common/PeopleIcon.vue";
import NavbarSubmenuItem from "@/layout/AppNavbar/item/NavbarSubmenuItem.vue";

const emit = defineEmits(["menuClick"]);
const { t } = useI18n();

const moreSubmenuData1 = computed(() => [
  {
    id: 0,
    title: t("translation.header.navbar.moreDropdown.3.title"),
    label: t("translation.header.navbar.moreDropdown.3.label"),
    icon: BlogIcon,
    pathName: "blog",
  },
  {
    id: 2,
    title: t("translation.header.navbar.moreDropdown.4.title"),
    label: t("translation.header.navbar.moreDropdown.4.label"),
    icon: HelpIcon,
    pathName: "help-center",
    disabled: true,
  },
  {
    id: 6,
    title: t("translation.header.navbar.moreDropdown.6.title"),
    label: t("translation.header.navbar.moreDropdown.6.label"),
    icon: PeopleIcon,
    pathName: "career",
  },
]);

const moreSubmenuData2 = computed(() => [
  {
    id: 5,
    title: t("translation.header.navbar.moreDropdown.2.title"),
    label: t("translation.header.navbar.moreDropdown.2.label"),
    icon: AnnouncementIcon,
    pathName: "announcement",
  },
  {
    id: 1,
    title: t("translation.header.navbar.moreDropdown.0.title"),
    label: t("translation.header.navbar.moreDropdown.0.label"),
    icon: LaunchpadIcon,
    pathName: "launchpad",
    disabled: true,
  },
  {
    id: 3,
    title: t("translation.header.navbar.moreDropdown.1.title"),
    label: t("translation.header.navbar.moreDropdown.1.label"),
    icon: RewardsIcon,
    pathName: "rewards-hub",
    disabled: true,
  },
]);

const handleMenuItemClick = () => {
  emit("menuClick");
};
</script>
