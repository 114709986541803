import { ref } from "vue";
import { useStore } from "vuex";
import session_manager from "@/utils/SessionManager";
import {
  USER_SERVICE_API_URL,
  VERIFICATION_TIME_LEFT,
} from "@/constants/constants";
import { useRouter } from "vue-router";
import axios from "@/api/axios";

export const useSignin = () => {
  const store = useStore();
  const router = useRouter();
  const isLoading = ref(false);
  const email = ref("");
  const phone = ref("");
  const password = ref("");

  const requestLogin = async () => {
    try {
      const loginData = {
        email: email.value,
        password: password.value,
      };
      const res = await axios.post(`${USER_SERVICE_API_URL}/signin`, loginData);
      if (res.data.success === false) {
        return;
      }
      store.dispatch("setToast", {
        type: "success",
        message: res.data.message,
      });
      const codeInfo = {
        exziId: res.data.exziId,
        required: true,
        requiredType: true,
        codeSentTo: "email",
        timeBeforeNewCode: VERIFICATION_TIME_LEFT,
        token: res.data.token,
        email: email.value,
        phone: phone.value,
        context: "signin",
        timeSentVerificationCode: new Date(),
      };
      session_manager.set2FactorCodeInfo(codeInfo);
      store.dispatch("setCode", codeInfo);
      router.push({ name: "verification-code", query: { back: "home" } });
    } catch (error) {
      store.dispatch("setToast", {
        type: "error",
        message: error?.data?.message ?? error,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    email,
    phone,
    password,
    requestLogin,
  };
};
