<template>
  <div>
    <div
      class="bg-gradient-to-t from-primary-800 to-navy-400 rounded-tl-3xl rounded-tr-3xl -m-6"
    >
      <div class="flex justify-between items-center px-6 py-4 mt-6">
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ $t("translation.profile.idverify.faceOvalDialog.title") }}
        </h3>
        <button
          @click="handleClickCloseButton"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <x-mark-icon class="size-8" />
        </button>
      </div>
      <div class="p-6">
        <horizontal-circle-step :step-length="3" :current-active-step="2" />
        <div class="text-white text-base mt-6">
          {{ $t("translation.profile.idverify.faceOvalDialog.subTitle") }}
        </div>
      </div>
    </div>
    <div class="mt-12">
      <take-face-photo @change="handlePhtoChange" />
    </div>
  </div>
  <div class="flex gap-6 mt-12">
    <outline-button color="primary" @click="handleClickCloseButton">
      {{ $t("translation.profile.idverify.faceOvalDialog.cancelButton") }}
    </outline-button>
    <fill-button class="w-full" @click="handleClickNextButton">
      {{ $t("translation.profile.idverify.faceOvalDialog.nextButton") }}
    </fill-button>
  </div>
</template>
<script setup>
import { defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import HorizontalCircleStep from "@/components/HorizontalCircleStep/HorizontalCircleStep.vue";
import TakeFacePhoto from "@/common/TakeFacePhoto/TakeFacePhoto.vue";

const photo = ref();
const store = useStore();
const emits = defineEmits(["close", "next"]);

const handleClickCloseButton = () => {
  emits("close");
};

const handleClickNextButton = () => {
  store.dispatch("setSelfies", photo.value);
  store.dispatch("submitKYC");
  emits("next");
};

const handlePhtoChange = (value) => {
  photo.value = value;
};
</script>
