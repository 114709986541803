<template>
  <div
    class="sm:w-[328px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 rounded-2xl shadow-xl dark:bg-navy-600 border dark:border-navy-400 dark:shadow-black/[0.32] bg-white shadow-black/[0.16]"
  >
    <div class="px-4 pt-4 flex flex-row">
      <div
        class="size-14 dark:bg-navy-500 bg-primary-300 rounded-full flex items-center justify-center"
      >
        <user-avatar-icon
          class="size-10 rounded-full dark:text-white text-primary-700"
        />
      </div>
      <div class="p-2" />
      <div>
        <div
          class="text-lg font-bold dark:text-white text-navy-800 text-wrap break-all"
        >
          {{ loggedInEmail }}
        </div>
        <div class="text-sm dark:text-navy-100 text-navy-300">
          UID: {{ profile.profile?.id ?? "" }}
        </div>
      </div>
    </div>
    <div
      v-if="!isVerified"
      class="my-4 bg-red-500 p-4 mx-4 bg-opacity-10 rounded-lg"
      @click="handleClickVerify"
    >
      <div class="font-medium text-sm dark:text-white text-red-800">
        {{ $t("translation.header.navbar.profileDropdown.notVerifiedWarning") }}
      </div>
      <div class="flex flex-row mt-3.5 items-center justify-between">
        <text-badge
          label="Unverified"
          class="bg-red-200 text-red-800 font-semibold text-xs"
        >
          <img src="/assets/icons/unverified_warning.svg" class="w-4 h-4" />
        </text-badge>
        <div
          class="flex flex-row items-center gap-2 cursor-pointer text-sm font-semibold flex-none dark:text-white text-red-800"
        >
          <div>
            {{ $t("translation.header.navbar.profileDropdown.verifyNow") }}
          </div>
          <arrow-right-icon class="stroke-2 w-5 h-5" />
        </div>
      </div>
    </div>
    <div class="mx-4 my-6" v-else>
      <div class="flex flex-row">
        <text-badge
          :label="$t('translation.header.navbar.profileDropdown.verified')"
          class="bg-green-200 text-green-900 font-semibold text-xs"
          :icon="DiamondIcon"
        >
        </text-badge>
        <div class="px-1.5" />
        <text-badge
          label="VIP 0"
          class="bg-orange-200 text-orange-900 font-semibold text-xs"
          :icon="DiamondIcon"
        >
        </text-badge>
      </div>
    </div>
    <div class="mx-4 flex flex-col gap-2">
      <profile-menu-item
        v-for="item in profileMenuData"
        :label="item.label"
        :icon="item.icon"
        :key="item.id"
        :path-name="item.pathName"
      />
    </div>
    <div class="my-3 mx-4 border dark:border-navy-500 border-navy-10"></div>
    <div class="mx-4 pb-4">
      <profile-menu-item
        :label="$t('translation.header.navbar.profileDropdown.logout')"
        :icon="LogoutIcon"
        @click="logout"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import TextBadge from "@/components/Badge/TextBadge.vue";
import ProfileMenuItem from "@/layout/AppNavbar/item/ProfileMenuItem.vue";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import DiamondIcon from "@/assets/icons/common/DiamondIcon.vue";
import IdentificationIcon from "@/assets/icons/common/IdentificationIcon.vue";
import SecurityIcon from "@/assets/icons/common/SecurityIcon.vue";
import ReferralsIcon from "@/assets/icons/common/ReferralsIcon.vue";
import SupportIcon from "@/assets/icons/common/SupportIcon.vue";
import AddressBookIcon from "@/assets/icons/common/AddressBookIcon.vue";
import LogoutIcon from "@/assets/icons/common/LogoutIcon.vue";
import UserAvatarIcon from "@/assets/icons/common/UserAvatarIcon.vue";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const loggedInEmail = computed(() => store.getters.authInfo?.email ?? "");
const isVerified = computed(
  () => store.getters.verificationInfo?.verification_state === "VERIFIED"
);
const profile = computed(() => store.getters.profile);

const logout = () => {
  store.dispatch("clearAuthInfo");
  router.push({ name: "home" });
};

const handleClickVerify = () => {
  router.push({ path: "/profile/identity-verification" });
};

const profileMenuData = computed(() => [
  {
    id: 0,
    label: t("translation.header.navbar.profileDropdown.menus.0"),
    icon: DiamondIcon,
    pathName: "dashboard",
  },
  {
    id: 1,
    label: t("translation.header.navbar.profileDropdown.menus.1"),
    icon: IdentificationIcon,
    pathName: "identity-verification",
  },
  {
    id: 2,
    label: t("translation.header.navbar.profileDropdown.menus.2"),
    icon: SecurityIcon,
    pathName: "security",
  },
  {
    id: 3,
    label: t("translation.header.navbar.profileDropdown.menus.3"),
    icon: ReferralsIcon,
    pathName: "referral",
  },
  {
    id: 4,
    label: t("translation.header.navbar.profileDropdown.menus.4"),
    icon: SupportIcon,
    pathName: "help-center",
  },
  {
    id: 5,
    label: t("translation.header.navbar.profileDropdown.menus.5"),
    icon: AddressBookIcon,
    pathName: "address-book",
  },
]);
</script>
