<template>
  <div
    :class="[
      props.isShow ? 'scale-y-100' : 'scale-y-0',
      props.noAnimation === true ? 'duration-0' : 'duration-300',
      'w-full h-[calc(100vh-48px)] z-[100] bg-gray-800 bg-opacity-95 left-0 top-12 absolute overscroll-y-none transition-transform delay-75 ease-in-out origin-top sm:hidden',
    ]"
  >
    <div
      class="w-full h-[calc(100vh-48px)] flex flex-col dark:bg-navy-800 bg-white left-0 custom-scrollbar overscroll-y-none pt-4 relative z-0"
    >
      <div class="flex flex-col px-8 relative w-full mt-4 pb-16">
        <div
          class="absolute w-full bottom-0 left-0 right-0 -z-10 dark:visible invisible"
        >
          <img
            class="w-full"
            src="/assets/images/navbar/mobile_ellipse_dark.svg"
          />
        </div>
        <div class="absolute w-full bottom-0 left-0 right-0 -z-10">
          <img
            src="/assets/images/navbar/navbar_mobile_background.svg"
            class="w-full"
          />
        </div>
        <div class="mb-4" v-if="!isLoggedIn">
          <outline-button
            disabled
            size="md"
            width="full"
            color="primary"
            @click="router.push({ name: 'signin' })"
          >
            {{ $t("translation.header.navbar.authButtons.login") }}
          </outline-button>
        </div>
        <div v-if="!isLoggedIn">
          <fill-button
            disabled
            size="md"
            width="full"
            @click="router.push({ name: 'signup' })"
          >
            {{ $t("translation.header.navbar.authButtons.signup") }}
          </fill-button>
        </div>
        <div class="flex flex-col">
          <template v-for="item in navbarData" :key="item.id">
            <div
              v-if="!item.isSubMenu && !item.disabled"
              class="text-base font-semibold dark:text-white text-navy-800 py-1.5 mt-4 cursor-pointer hover:text-primary-700"
              @click="handleMenuClick(item.pathName)"
            >
              {{ item.label }}
            </div>
            <div
              v-else-if="item.disabled"
              class="text-base font-semibold text-navy-100 dark:text-navy-200 py-1.5 mt-4 cursor-not-allowed"
            >
              {{ item.label }}
            </div>
            <collapse-view v-else>
              <template
                #header="{
                  handleContentClick: handleButtonClick,
                  isShowingContent,
                }"
              >
                <div
                  class="flex flex-row mt-4 py-1.5 cursor-pointer items-center justify-between group"
                  @click="handleButtonClick"
                >
                  <div
                    class="text-base font-semibold dark:text-white text-navy-800 group-hover:text-primary-700"
                  >
                    {{ item.label }}
                  </div>
                  <div class="group-hover:text-primary-700">
                    <chevron-up-icon
                      v-if="isShowingContent"
                      class="w-6 h-6 stroke-2"
                    />
                    <chevron-down-icon v-else class="w-6 h-6 stroke-2" />
                  </div>
                </div>
              </template>
              <template #content>
                <div class="flex flex-col gap-2 w-full items-stretch mt-2">
                  <navbar-submenu-item
                    :padding="false"
                    v-for="subitem in item.subMenuData"
                    :key="subitem.id"
                    :title="subitem.title"
                    :label="subitem.label"
                    :icon="subitem.icon"
                    :path-name="subitem.pathName"
                    :disabled="subitem.disabled"
                    @click="handleSubMenuClick"
                  />
                </div>
              </template>
            </collapse-view>
          </template>
        </div>
        <div class="mb-12 mt-4">
          <filled-button
            class="px-3 py-3 text-sm leading-5 font-semibold bg-navy-10 text-navy-100 dark:bg-navy-400 dark:text-navy-200 cursor-not-allowed"
            :label="$t('translation.header.navbar.deposit')"
            type="iconLabel"
            :disabled="true"
            @click="handleRewardsHubClick"
          >
            <template #icon>
              <arrow-down-tray-icon class="stroke-2" />
            </template>
          </filled-button>
        </div>
        <div class="flex flex-col dark:text-white text-navy-800 gap-4">
          <template v-for="item in bottomIconData" :key="item.id">
            <div
              class="flex flex-row items-center cursor-pointer py-1.5 group"
              @click="item.click(item)"
            >
              <component
                :is="item.icon"
                class="w-6 h-6 stroke-2 mr-2 group-hover:text-primary-700"
              />
              <div class="text-base font-medium group-hover:text-primary-700">
                {{ item.label }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, computed, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import CollapseView from "@/components/Collapse/CollapseView.vue";
import NavbarSubmenuItem from "@/layout/AppNavbar/item/NavbarSubmenuItem.vue";
import {
  ChevronDownIcon,
  SunIcon,
  GlobeAltIcon,
  ArrowDownTrayIcon,
  ChevronUpIcon,
} from "@heroicons/vue/24/outline";
import ConvertIcon from "@/assets/icons/common/ConvertIcon.vue";
import SpotTradingIcon from "@/assets/icons/common/SpotTradingIcon.vue";
import BlogIcon from "@/assets/icons/common/BlogIcon.vue";
import LaunchpadIcon from "@/assets/icons/common/LaunchpadIcon.vue";
import HelpIcon from "@/assets/icons/common/HelpIcon.vue";
import RewardsIcon from "@/assets/icons/common/RewardsIcon.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import PeopleIcon from "@/assets/icons/common/PeopleIcon.vue";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const currentTheme = computed(() => store.getters.currentTheme);
const props = defineProps(["isShow", "noAnimation"]);
const emit = defineEmits(["closeClick", "languageClick", "downloadClick"]);

watch(
  () => props.isShow,
  (newVal) => {
    if (newVal) {
      //document.body.classList.add("no-scroll");
    } else {
      //document.body.classList.remove("no-scroll");
    }
  }
);

const handleMenuClick = (pathName) => {
  router.push({ name: pathName });
  emit("closeClick");
};

const handleSubMenuClick = () => {
  emit("closeClick");
};

const handleThemeChange = (item) => {
  item.label =
    currentTheme.value === "dark"
      ? t("translation.header.navbar.mobileMenu.darkMode")
      : t("translation.header.navbar.mobileMenu.lightMode");
  store.dispatch("toggleTheme", store.getters.currentTheme);
  emit("closeClick");
};

const handleRewardsHubClick = () => {
  router.push({ name: "rewards-hub" });
  emit("closeClick");
};

const handleShowLanguage = () => {
  emit("closeClick", true);
  emit("languageClick");
};

const handleDownload = () => {
  emit("closeClick", true);
  emit("downloadClick");
};

const bottomIconData = computed(() => [
  {
    id: 0,
    icon: SunIcon,
    label:
      currentTheme.value === "dark"
        ? t("translation.header.navbar.mobileMenu.lightMode")
        : t("translation.header.navbar.mobileMenu.darkMode"),
    click: handleThemeChange,
  },
  {
    id: 1,
    icon: GlobeAltIcon,
    label: t("translation.header.navbar.mobileMenu.language"),
    click: handleShowLanguage,
  },
  {
    id: 2,
    icon: ArrowDownTrayIcon,
    label: t("translation.header.navbar.mobileMenu.download"),
    click: handleDownload,
  },
]);

const navbarData = computed(() => [
  {
    id: 5,
    label: t("translation.header.navbar.buttonBar.assets"),
    // disabled: true,
    pathName: "asset-overview",
    isSubMenu: false,
  },
  {
    id: 0,
    label: t("translation.header.navbar.buttonBar.buy"),
    // disabled: true,
    pathName: "buy-sell",
    isSubMenu: false,
  },
  {
    id: 1,
    label: t("translation.header.navbar.buttonBar.market"),
    pathName: "markets",
    isSubMenu: false,
  },
  {
    id: 2,
    label: t("translation.header.navbar.buttonBar.trade"),
    // disabled: true,
    isSubMenu: true,
    alignment: "center",
    subMenuData: [
      {
        id: 1,
        title: t("translation.header.navbar.tradeDropdown.1.title"),
        label: t("translation.header.navbar.tradeDropdown.1.label"),
        icon: ConvertIcon,
        pathName: "convert",
      },
      {
        id: 0,
        title: t("translation.header.navbar.tradeDropdown.0.title"),
        label: t("translation.header.navbar.tradeDropdown.0.label"),
        icon: SpotTradingIcon,
        pathName: "trading",
      },
    ],
  },
  {
    id: 3,
    label: t("translation.header.navbar.buttonBar.referral"),
    isSubMenu: false,
    pathName: "referral",
  },
  {
    id: 4,
    label: t("translation.header.navbar.buttonBar.more"),
    isSubMenu: true,
    alignment: "center",
    subMenuData: [
      {
        id: 1,
        title: t("translation.header.navbar.moreDropdown.0.title"),
        label: t("translation.header.navbar.moreDropdown.0.label"),
        icon: LaunchpadIcon,
        pathName: "launchpad",
        disabled: true,
      },
      {
        id: 3,
        title: t("translation.header.navbar.moreDropdown.1.title"),
        label: t("translation.header.navbar.moreDropdown.1.label"),
        icon: RewardsIcon,
        pathName: "rewards-hub",
        disabled: true,
      },
      {
        id: 5,
        title: t("translation.header.navbar.moreDropdown.2.title"),
        label: t("translation.header.navbar.moreDropdown.2.label"),
        icon: AnnouncementIcon,
        pathName: "announcement",
      },
      {
        id: 0,
        title: t("translation.header.navbar.moreDropdown.3.title"),
        label: t("translation.header.navbar.moreDropdown.3.label"),
        icon: BlogIcon,
        pathName: "blog",
      },
      {
        id: 2,
        title: t("translation.header.navbar.moreDropdown.4.title"),
        label: t("translation.header.navbar.moreDropdown.4.label"),
        icon: HelpIcon,
        pathName: "help-center",
        disabled: true,
      },
      {
        id: 6,
        title: t("translation.header.navbar.moreDropdown.6.title"),
        label: t("translation.header.navbar.moreDropdown.6.label"),
        icon: PeopleIcon,
        pathName: "career",
      },
    ],
  },
]);
</script>
