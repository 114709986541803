<template>
  <div class="relative">
    <div class="absolute w-full h-fit -z-50 top-24">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/background-texture-dark.svg'
            : '/assets/images/help-center/background-texture-light.svg'
        "
        class="w-full"
      />
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-30% from-white dark:to-transparent to-60% z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0"
      />
    </div>
    <div class="absolute -z-50 -top-12 w-full">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/ellipse-dark.svg'
            : '/assets/images/help-center/ellipse-light.svg'
        "
        class="w-full"
      />
    </div>
    <div
      class="container mx-auto w-full px-4 flex flex-col items-center sm:px-16"
    >
      <div class="sm:mt-[64px] mt-[40px]">
        <div
          class="flex flex-col sm:gap-[40px] gap-[24px] max-w-[768px] items-center"
        >
          <div class="flex flex-col sm:gap-[24px] gap-[12px]">
            <div
              class="text-center text-navy-800 dark:text-white text-4xl font-bold sm:text-[56px]"
            >
              {{ $t("translation.blog.title") }}
            </div>
            <div
              class="text-lg font-medium sm:text-xl sm:font-normal text-navy-300 dark:text-navy-50 text-center"
            >
              {{ $t("translation.blog.subTitle") }}
            </div>
          </div>
          <div class="sm:w-[367px] w-full">
            <text-edit
              type="text"
              rounded="full"
              :placeholder="$t('translation.blog.searchPlaceholder')"
              :filled="currentTheme === 'dark' ? true : false"
              v-model="searchText"
            >
              <template #prefix>
                <div class="mr-2">
                  <magnifying-glass-icon
                    :class="[
                      searchText.length > 0
                        ? 'dark:text-white text-navy-800'
                        : 'text-navy-200',
                      'w-6 h-6',
                    ]"
                  />
                </div>
              </template>
              <template #suffix v-if="searchText.length > 0">
                <div
                  class="ml-2"
                  @click="
                    () => {
                      searchText = '';
                    }
                  "
                >
                  <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
                </div>
              </template>
            </text-edit>
          </div>
        </div>
      </div>
      <div class="sm:mt-20 mt-10 flex w-full gap-8 justify-between items-start">
        <div
          class="flex flex-col sm:basis-7/12 flex-1 overflow-hidden items-start"
        >
          <div class="w-full flex flex-col">
            <carousel-component>
              <img
                v-for="item in topTopics"
                :key="item.id"
                :src="item.image"
                class="rounded-[16px]"
              />
            </carousel-component>
            <div class="h-4" />
            <article-summary-item
              :heading="topTopics[0]?.title"
              :update-time="changeUnixTime(topTopics[0]?.time_create)"
              :content="topTopics[0]?.short_text"
              size="sm"
              @click="handleBlogItemClick(topTopics[0]?.url)"
            />
          </div>
        </div>
        <div
          class="flex flex-col flex-1 max-lg:hidden min-w-[416px] basis-5/12"
        >
          <div
            class="text-2xl font-semibold dark:text-white text-navy-800 px-4"
          >
            <div class="flex gap-3 items-center mb-6">
              <fire-icon class="text-red-500 w-6 h-6 stroke-2" />
              <div
                class="font-semibold text-[20px] dark:text-white text-navy-800"
              >
                {{ $t("translation.blog.topTopics") }}
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-6 w-full max-lg:hidden">
            <template v-for="(item, index) in topTopics" :key="item.id">
              <div
                v-if="index !== 0"
                class="my-2 dark:border-t-navy-500 border-t-navy-10 border-t"
              />
              <article-summary-item
                :heading="item.title"
                :update-time="changeUnixTime(item.time_create)"
                :content="item.short_text"
                size="sm"
                @click="handleBlogItemClick(item.url)"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="mt-[72px] lg:hidden w-full">
        <div class="flex gap-3 items-center ml-4 mb-6">
          <fire-icon class="text-red-500 w-6 h-6 stroke-2" />
          <div class="font-semibold text-[20px] dark:text-white text-navy-800">
            {{ $t("translation.blog.topTopics") }}
          </div>
        </div>
        <div class="mt-6">
          <carousel-component>
            <article-summary-item
              v-for="item in topTopics"
              :key="item.id"
              :heading="item.title"
              :update-time="changeUnixTime(item.time_create)"
              :content="item.short_text"
              size="sm"
              @click="handleBlogItemClick(item.url)"
            />
          </carousel-component>
        </div>
      </div>
      <div class="w-full mt-32">
        <div
          class="dark:text-white text-navy-800 text-[32px] font-semibold mb-6 sm:mb-10 w-full"
        >
          {{ $t("translation.blog.latestArticles") }}
        </div>
        <div class="flex gap-4">
          <radio-button-list
            :items="LATEST_ARTICLES_BADGET"
            @change="handleChangeLatestAritclesBadget"
          />
        </div>
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <article-item
            :imageURL="item.image"
            :header="item.title"
            :content="item.short_text"
            :updateTime="item.time_create"
            v-for="item in latestArticles.slice(startIndex, endIndex + 1)"
            :key="item"
            @click="handleBlogItemClick(item.url)"
          />
        </div>
        <div class="w-full mt-14">
          <pagination-component
            @change="handlePageChange"
            :initial-page="1"
            :total="Math.ceil(latestArticles.length / 10)"
            :step="10"
            :step-disabled="true"
          />
        </div>
      </div>
      <div class="w-full mt-52">
        <div
          class="flex flex-col justify-between sm:items-center sm:flex-row items-start gap-6"
        >
          <div
            class="dark:text-white text-navy-800 text-[32px] font-semibold w-full"
          >
            {{ $t("translation.blog.cryptoInsight") }}
          </div>
          <div class="w-full sm:w-fit flex-shrink-0">
            <outline-button color="primary" size="md" width="full">
              <div class="font-semibold text-sm" @click="handleViewAllClick">
                {{
                  cryptoInsightsViewAll
                    ? $t("translation.blog.viewButton.0")
                    : $t("translation.blog.viewButton.1")
                }}
              </div>
            </outline-button>
          </div>
        </div>
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <article-item
            :imageURL="item.image"
            :header="item.title"
            :content="item.short_text"
            :updateTime="item.time_create"
            v-for="item in cryptoInsightsViewAll
              ? cryptoInsights
              : cryptoInsights.slice(0, 3)"
            :key="item"
            @click="handleBlogItemClick(item.url)"
          />
        </div>
      </div>
      <div
        class="mt-40 mb-40 sm:h-[296px] h-[360px] overflow-hidden flex items-center justify-center rounded-lg w-full relative"
      >
        <div class="absolute w-full h-full">
          <img
            class="w-full h-full object-cover"
            :src="
              currentTheme === 'dark'
                ? '/assets/images/blog/background-start-trading-dark-sm.svg'
                : '/assets/images/blog/background-start-trading-light-sm.svg'
            "
          />
        </div>
        <div class="absolute w-full h-full md:hidden">
          <img
            class="w-full h-full object-cover"
            :src="
              currentTheme === 'dark'
                ? '/assets/images/blog/background-start-trading-dark.svg'
                : '/assets/images/blog/background-start-trading-light.svg'
            "
          />
        </div>
        <div class="w-full flex rounded-xl items-center justify-center">
          <div class="flex flex-col z-10">
            <div
              class="font-semibold text-[40px] mb-12 text-center sm:text-5xl sm:font-bold dark:text-white text-navy-800"
            >
              {{ $t("translation.blog.footerTitle") }}
            </div>
            <div class="mx-auto">
              <fill-button size="lg" @click="handleClickStartTrading">
                {{ $t("translation.blog.footerSubtitle") }}
              </fill-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { changeUnixTime } from "@/utils/utils";
import RadioButtonList from "@/components/Selector/RadioButtonList.vue";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import CarouselComponent from "@/components/Carousel/CarouselComponent.vue";
import ArticleSummaryItem from "@/views/Blog/Items/ArticleSummaryItem.vue";
import ArticleItem from "@/views/Blog/Items/ArticleItem.vue";
import { FireIcon } from "@heroicons/vue/24/solid";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const topTopics = computed(() => store.getters.topTopics);
const latestArticles = computed(() => store.getters.latestArticles);
const cryptoInsights = computed(() => store.getters.cryptoInsights);
const startIndex = ref(0);
const endIndex = ref(9);
const searchText = ref("");
const cryptoInsightsViewAll = ref(false);

const LATEST_ARTICLES_BADGET = [
  { label: t("translation.blog.selector.0"), id: "All" },
  { label: t("translation.blog.selector.1"), id: "Ecosystem" },
  { label: t("translation.blog.selector.2"), id: "Market Insight" },
  { label: t("translation.blog.selector.3"), id: "Learn with Exzi" },
];

const handleChangeLatestAritclesBadget = (value) => value;

const handlePageChange = ([currentPage, pageSize]) => {
  startIndex.value = (currentPage - 1) * pageSize;
  endIndex.value = currentPage * pageSize - 1;
};

onMounted(() => {
  store.dispatch("getTopTopics");
  store.dispatch("getLatestArticles");
  store.dispatch("getCryptoInsights");
});

const handleBlogItemClick = (url) => {
  router.push({
    name: "blog-detail",
    params: {
      url: url,
    },
  });
};

const handleViewAllClick = () => {
  cryptoInsightsViewAll.value = !cryptoInsightsViewAll.value;
};

const handleClickStartTrading = () => {
  router.push({
    name: "trading",
  });
};
</script>
