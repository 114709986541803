<template>
  <div class="relative text-center my-16">
    <div class="flex flex-col mx-auto items-center max-w-[963px]">
      <p class="text-[64px] leading-[64px] -tracking-[1.4px] font-bold">
        {{ $t("translation.waitList.title") }}
      </p>
      <p class="text-[64px] leading-[64px] -tracking-[1.4px] font-bold mb-8">
        {{ $t("translation.waitList.subTitle") }}
      </p>
      <p
        class="text-2xl -tracking-[0.4px] font-normal dark:text-navy-50 text-navy-300 mb-5"
      >
        {{ $t("translation.waitList.content") }}
      </p>
      <p class="text-xl -tracking-[0.4px] font-bold mb-8">
        {{ $t("translation.waitList.joinTitle") }}
      </p>
      <div class="flex justify-center items-center gap-8">
        <div class="w-[336px]">
          <text-edit
            rounded="xl"
            :placeholder="$t('translation.waitList.emailInputPlaceholder')"
            v-model="emailAddress"
          ></text-edit>
        </div>
        <fill-button
          color="primary"
          width="fit"
          size="lg"
          @click="handleJoin"
          :disabled="!emailRegex.test(emailAddress)"
        >
          {{ $t("translation.waitList.joinButtonLabel") }}
        </fill-button>
      </div>
      <div class="w-[458px] h-[324px] mt-16" v-if="currentTheme === 'dark'">
        <animation-player path="/assets/animations/Rocket.json" />
      </div>
      <div class="w-[458px] h-[324px] mt-16" v-else>
        <animation-player path="/assets/animations/Rocket_light.json" />
      </div>
    </div>
    <img
      v-if="currentTheme === 'dark'"
      class="absolute bottom-0 left-0 -z-10"
      src="@/assets/images/waitlist/waitlist_bg_dark.svg"
    />
    <img
      v-else
      class="absolute bottom-0 left-0 -z-10"
      src="@/assets/images/waitlist/waitlist_bg_light.svg"
    />
    <waitlist-success-dialog
      v-if="isSuccessDialogShow"
      v-model="isSuccessDialogShow"
      :header-title="$t('translation.waitList.successDialogTitle')"
    >
      <div class="p-6 pb-0">
        <p class="mb-12">
          {{ $t("translation.waitList.successDialogContent") }}
        </p>
        <fill-button color="primary" width="full" @click="handleGotit">
          <p class="font-semibold text-base -tracking-[0.2px]">
            {{ $t("translation.waitList.successDialogButtonLabel") }}
          </p>
        </fill-button>
      </div>
    </waitlist-success-dialog>
  </div>
</template>
<script setup>
import { ref } from "vue";
import WaitlistSuccessDialog from "@/views/Auth/Waitlist/WaitlistSuccessDialog.vue";
import AnimationPlayer from "@/components/Animation/AnimationPlayer.vue";
import { emailRegex } from "@/constants/constants";

const emailAddress = ref("");
const isSuccessDialogShow = ref(false);
const handleJoin = () => {
  isSuccessDialogShow.value = true;
};
const handleGotit = () => {
  isSuccessDialogShow.value = false;
};
</script>
