<template>
  <div>
    <iframe
      ref="iframe"
      width="100%"
      :allowtransparency="true"
      scrolling="no"
      :srcdoc="html"
      @load="handleLoading"
    />
  </div>
</template>
<script setup>
import {
  defineProps,
  onMounted,
  useTemplateRef,
  computed,
  watch,
  onUpdated,
} from "vue";
import { useStore } from "vuex";

const store = useStore();
const currentTheme = computed(() => store.getters.currentTheme);

defineProps({
  html: String,
});

const iframeRef = useTemplateRef("iframe");

onMounted(() => {
  if (iframeRef.value) {
    handleLoading();
  }
});

onUpdated(() => {
  if (iframeRef.value) {
    handleLoading();
  }
});

watch(
  () => currentTheme.value,
  () => {
    changeTextColor();
  }
);

const handleLoading = () => {
  handleFont();
  handleBodyStyle();
  handleLinks();
  changeTextColor();
  handleImages();
  adjustIframeHeight();
};

const handleFont = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc =
      iframeEl.contentDocument || iframeEl.contentWindow.document;
    const fontLink = document.createElement("link");
    fontLink.href =
      "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
    fontLink.rel = "stylesheet";
    iframeDoc.head.appendChild(fontLink);
  }
};

const adjustIframeHeight = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc = iframeEl.contentWindow.document;
    const contentHeight = iframeDoc.body.scrollHeight + 20;
    iframeEl.style.height = contentHeight + "px";
  }
};

const handleBodyStyle = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc = iframeEl.contentWindow.document;
    iframeDoc.body.style.margin = "0px";
    iframeDoc.body.style.padding = "0px";
    iframeDoc.body.style.maxWidth = "max-content";
    iframeDoc.body.style.backgroundColor = "transparent";
  }
};

const handleLinks = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc = iframeEl.contentWindow.document;
    const links = iframeDoc.querySelectorAll("a");
    links.forEach((link) => {
      link.target = "_blank";
    });
  }
};

const handleImages = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc = iframeEl.contentWindow.document;
    const imgs = iframeDoc.querySelectorAll("img");
    imgs.forEach((img) => {
      img.style.width = "100%";
    });
  }
};

const changeTextColor = () => {
  const iframeEl = iframeRef.value;
  if (iframeEl && iframeEl.contentWindow) {
    const iframeDoc = iframeEl.contentWindow.document;
    const elements = iframeDoc.querySelectorAll("*");
    elements.forEach((element) => {
      const style = window.getComputedStyle(element);
      element.style.setProperty(
        "font-family",
        "Montserrat, sans-serif",
        "important"
      );
      if (currentTheme.value === "dark") {
        if (
          style.color === "rgb(0, 0, 0)" ||
          style.color === "#000000" ||
          style.color === "black" ||
          style.color === "rgb(14, 17, 27)"
        ) {
          element.style.color = "white";
        }
      } else {
        if (
          style.color === "rgb(255, 255, 255)" ||
          style.color === "#ffffff" ||
          style.color === "white"
        ) {
          element.style.color = "#0e111b";
        }
      }
    });
  }
};
</script>
