<template>
  <div
    :class="[
      'h-10 flex flex-row items-center px-3 justify-between hover:rounded-lg cursor-pointer',
      checked === true
        ? 'dark:bg-navy-500 rounded-lg bg-navy-10'
        : 'dark:hover:bg-navy-500 hover:bg-navy-10',
    ]"
    @click="handleClick"
  >
    <span :class="['text-sm font-medium dark:text-white text-navy-800']">{{
      props.label
    }}</span>
    <span v-if="checked" class="text-primary-700 w-5 h-5">
      <check-circle-icon />
    </span>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";

const store = useStore();
const props = defineProps({
  label: String,
  locale: String,
});

const checked = computed(() => store.getters.currentLanguage === props.label);
const handleClick = async () => {
  store.dispatch("setLanguage", {
    label: props.label,
    locale: props.locale,
  });
};
</script>
