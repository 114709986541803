<template>
  <div class="w-full mb-24">
    <div class="w-full flex flex-col">
      <div class="text-2xl font-semibold">Invoice</div>
      <div class="mt-4 sm:mt-8">
        <radio-button-list :items="yearListData" />
      </div>
      <div class="mt-6 sm:mt-12 flex flex-col w-full">
        <div class="text-xl font-semibold text-navy-100">2024</div>
        <div class="mt-6 sm:mt-8 flex flex-col w-full">
          <template v-for="(item, index) in monthListData" :key="item.id">
            <div
              v-if="index !== 0"
              class="my-6 w-full border-t dark:border-t-navy-500 border-t-navy-10"
            />
            <div
              class="flex flex-row justify-between w-full items-center dark:text-white text-navy-800"
            >
              <div class="text-base font-semibold">
                {{ item.label }}
              </div>
              <chevron-right-icon class="size-4 stroke-2" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import RadioButtonList from "@/components/Selector/RadioButtonList.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const yearListData = computed(() => [
  {
    id: 1,
    label: "2024",
  },
  {
    id: 2,
    label: "2023",
  },
  {
    id: 3,
    label: "2022",
  },
  {
    id: 4,
    label: "2021",
  },
]);

const monthListData = computed(() => [
  { id: 1, label: "January" },
  { id: 2, label: "February" },
  { id: 3, label: "March" },
]);
</script>
