<template>
  <div>
    <template v-if="inputData.type_trade === 2">
      <!-- Stop Limit -->
      <token-input
        v-model="inputData.stop_rate"
        :placeholder="$t('translation.trading.buySell.stopPlaceholder')"
        :unit="selectedPair?.second.iso3"
      />
      <div class="h-3" />
    </template>

    <template v-if="inputData.type_trade !== 1">
      <!-- Price -->
      <token-input
        v-model="inputData.rate"
        :placeholder="$t('translation.trading.buySell.pricePlaceholder')"
        :unit="selectedPair?.second.iso3"
      />
      <div class="h-3" />
    </template>

    <template v-else>
      <fill-button width="full" size="sm" disabled>
        <p class="text-base">
          {{ $t("translation.trading.buySell.marketPrice") }}
        </p>
      </fill-button>
      <div class="h-4" />

      <circle-tab :items="actionItems" @select="handleSelectActionTab" />
      <div class="h-4" />
    </template>

    <template
      v-if="
        inputData.type_trade !== 1 ||
        (inputData.type_trade === 1 && activeAmountTab.id === actionItems[0].id)
      "
    >
      <!-- Amount -->
      <token-input
        v-model="inputData.volume"
        :placeholder="$t('translation.trading.buySell.amountPlaceholder')"
        :unit="selectedPair?.main.iso3"
        :error="volumeError"
      />
      <div class="h-4" />
    </template>

    <!-- Percent: need to disable when loged out -->
    <div class="px-2">
      <slider-component
        enable-drag
        v-model="percent"
        :disabled="activeAmountTab.id === actionItems[1].id"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, defineModel } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import CircleTab from "@/components/Tab/CircleTab.vue";
import TokenInput from "@/common/TokenInput/TokenInput.vue";
import SliderComponent from "@/components/Slider/SliderComponent.vue";

const { t } = useI18n();

const actionItems = computed(() => [
  {
    id: 0,
    name: "Amount",
    label: t("translation.trading.buySell.actionTypeAmount"),
    value: "amount",
    bgColor: "dark:bg-navy-500 bg-navy-50",
    textColor: "dark:text-white text-navy-800 !font-medium",
  },
  {
    id: 1,
    name: "Total",
    label: t("translation.trading.buySell.acionTypeTotal"),
    value: "total",
    bgColor: "dark:bg-navy-500 bg-navy-50 !font-medium",
    textColor: "text-white",
  },
]);

const inputData = defineModel();
const store = useStore();
const selectedPair = computed(() => store.getters.selectedPair);
const percent = ref(0);
const volumeError = ref("");
const activeAmountTab = ref(actionItems.value[0]);

const volumeChanged = ref(false);
const percentChanged = ref(false);

const currentSelectedPrice = computed(() => store.getters.currentSelectedPrice);

watch(
  () => inputData.value.volume,
  (cur) => {
    if (volumeChanged.value) {
      volumeChanged.value = false;
    } else {
      if (cur > inputData.value.max) {
        volumeError.value = t("translation.trading.buySell.errorNotEnough");
        percent.value = 100;
        percentChanged.value = true;
      } else {
        volumeError.value = "";
        percent.value = (cur * 100) / inputData.value.max;
        percentChanged.value = true;
      }
    }
  }
);

watch(percent, () => {
  if (percentChanged.value) {
    percentChanged.value = false;
  } else {
    volumeError.value = "";
    inputData.value.volume = (inputData.value.max * percent.value) / 100;
    volumeChanged.value = true;
  }
});

watch(currentSelectedPrice, () => {
  inputData.value.rate = currentSelectedPrice.value;
});

const handleSelectActionTab = (item) => {
  activeAmountTab.value = item;
  if (item.id === actionItems.value[1].id) {
    percent.value = 100;
    percentChanged.value = true;
  }
};
</script>
