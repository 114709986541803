<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    bg-color="gray"
    :contain-header="true"
    :title="$t('translation.profile.security.deleteAskDialog.title')"
    @change-is-open="handleChangeOpen"
  >
    <template #content>
      <div class="w-full flex flex-col">
        <p>
          {{ $t("translation.profile.security.deleteAskDialog.subTitle") }}
        </p>
        <div
          class="flex sm:flex-row flex-col w-full sm:gap-6 gap-4 sm:mt-12 mt-8"
        >
          <div class="w-full">
            <outline-button color="primary" width="full" @click="handleCancel">
              {{
                $t("translation.profile.security.deleteAskDialog.cancelButton")
              }}
            </outline-button>
          </div>
          <div class="w-full">
            <fill-button color="red" width="full" @click="handleDelete">
              {{
                $t("translation.profile.security.deleteAskDialog.deleteButton")
              }}
            </fill-button>
          </div>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineEmits } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();
const emit = defineEmits(["confirm"]);

const handleChangeOpen = () => {
  model.value = false;
};

const handleCancel = () => {
  model.value = false;
};

const handleDelete = () => {
  model.value = false;
  emit("confirm");
};
</script>
