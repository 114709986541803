<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 6.75H9C8.00544 6.75 7.05161 7.14509 6.34835 7.84835C5.64509 8.55161 5.25 9.50544 5.25 10.5V21C5.25 31.1794 10.185 37.3537 14.325 40.7419C18.7706 44.3775 23.22 45.6169 23.4075 45.6713C23.7955 45.7762 24.2045 45.7762 24.5925 45.6713C24.78 45.6188 29.2294 44.3775 33.675 40.7419C37.815 37.3537 42.75 31.1794 42.75 21V10.5C42.75 9.50544 42.3549 8.55161 41.6517 7.84835C40.9484 7.14509 39.9946 6.75 39 6.75ZM38.25 21C38.25 27.6956 35.7956 33.1294 30.9544 37.1531C28.8792 38.8655 26.5274 40.2116 24 41.1338C21.4723 40.2121 19.1205 38.8659 17.0456 37.1531C12.2044 33.1294 9.75 27.6956 9.75 21V11.25H38.25V21ZM14.9081 27.0919C14.6988 26.8826 14.5328 26.6341 14.4195 26.3607C14.3063 26.0872 14.248 25.7941 14.248 25.4981C14.248 25.2021 14.3063 24.909 14.4195 24.6356C14.5328 24.3621 14.6988 24.1137 14.9081 23.9044C15.1174 23.6951 15.3659 23.5291 15.6393 23.4158C15.9128 23.3025 16.2059 23.2442 16.5019 23.2442C16.7979 23.2442 17.091 23.3025 17.3644 23.4158C17.6379 23.5291 17.8863 23.6951 18.0956 23.9044L21 26.8125L29.9081 17.9025C30.3308 17.4798 30.9041 17.2423 31.5019 17.2423C32.0996 17.2423 32.6729 17.4798 33.0956 17.9025C33.5183 18.3252 33.7558 18.8985 33.7558 19.4963C33.7558 20.094 33.5183 20.6673 33.0956 21.09L22.5956 31.59C22.3866 31.7998 22.1382 31.9662 21.8647 32.0798C21.5912 32.1933 21.298 32.2518 21.0019 32.2518C20.7057 32.2518 20.4125 32.1933 20.139 32.0798C19.8655 31.9662 19.6172 31.7998 19.4081 31.59L14.9081 27.0919Z"
      fill="currentColor"
    />
  </svg>
</template>
