<template>
  <div class="p-3">
    <p class="text-sm font-normal text-navy-400 dark:text-navy-100 p-3">
      {{ $t("translation.trading.chartSetting.alertPage.text") }}
    </p>
    <div class="mt-6">
      <div
        v-for="setting in alertSettings"
        :key="setting.id"
        class="flex text-sm font-medium items-center justify-between px-3 py-2 mb-3"
      >
        <p>{{ setting.text }}</p>
        <switch-component />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import SwitchComponent from "@/components/Switch/SwitchComponent.vue";
const { t } = useI18n();

const alertSettings = computed(() => [
  { id: 1, text: t("translation.trading.chartSetting.alertPage.items[0]") },
  { id: 2, text: t("translation.trading.chartSetting.alertPage.items[1]") },
  { id: 3, text: t("translation.trading.chartSetting.alertPage.items[2]") },
  { id: 4, text: t("translation.trading.chartSetting.alertPage.items[3]") },
  { id: 5, text: t("translation.trading.chartSetting.alertPage.items[4]") },
]);
</script>
