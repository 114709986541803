<template>
  <div class="w-full relative">
    <div class="w-full absolute -z-50">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/project_detail_dark.svg'
            : '/assets/images/launchpad/project_detail_light.svg',
        ]"
        class="w-full"
      />
    </div>
    <div class="w-full container mx-auto px-4 sm:px-16">
      <div
        class="flex sm:flex-row flex-col items-center justify-stretch w-full gap-x-24"
      >
        <div class="flex flex-col items-start mt-4 sm:mt-10 shrink">
          <breadcrumb-menu :items="breadCrumbMenuData" />
          <div class="mt-4 sm:mt-6 flex flex-row items-center justify-start">
            <img :src="projectDetail.image" class="w-16 h-16 mr-5" />
            <div
              class="font-semibold text-2xl dark:text-white text-navy-800 mr-4"
            >
              {{ projectDetail.name }}
            </div>
            <text-badge
              :icon="ClockIcon"
              size="md"
              class="bg-orange-200 text-orange-900"
              label="Ongoing"
            />
          </div>
          <div
            class="text-base font-normal dark:text-navy-50 text-navy-300 sm:mt-8 mt-6 text-wrap break-words"
          >
            {{ projectDetail.description }}
          </div>
          <div class="flex flex-row flex-wrap items-center mt-6 sm:mt-8 gap-2">
            <text-badge
              label="Official Website"
              class="dark:bg-navy-500 bg-navy-10 dark:text-white text-navy-800"
              :icon="GlobeAltIcon"
            />
            <text-badge
              label="X"
              class="dark:bg-navy-500 bg-navy-10 dark:text-white text-navy-800"
            />
            <text-badge
              label="Discord"
              class="dark:bg-navy-500 bg-navy-10 dark:text-white text-navy-800"
            />
            <text-badge
              label="Whitepaper"
              class="dark:bg-navy-500 bg-navy-10 dark:text-white text-navy-800"
            />
            <text-badge
              label="View Detailed Rules"
              class="dark:bg-navy-500 bg-navy-10 dark:text-white text-navy-800"
            />
          </div>
          <div
            class="flex flex-row items-start justify-between shrink-0 gap-12 max-md:w-full mt-14"
          >
            <div
              class="flex flex-col items-start justify-start text-sm font-medium dark:text-white text-navy-800 gap-2"
            >
              <div>
                {{ $t("translation.launchpad.launchpadDetail.tokensOffered") }}
              </div>
              <div>
                {{ $t("translation.launchpad.launchpadDetail.salePrice") }}
              </div>
              <div>
                {{ $t("translation.launchpad.launchpadDetail.participants") }}
              </div>
              <div>
                {{ $t("translation.launchpad.launchpadDetail.totalCommited") }}
              </div>
            </div>
            <div
              class="flex flex-col items-start justify-start text-sm font-medium dark:text-navy-100 text-navy-300 gap-2"
            >
              <div>50,000,000 ARKM</div>
              <div>1 ARKM = 0.00042176 USDT</div>
              <div>114.546</div>
              <div>10,182,983 USDT</div>
            </div>
          </div>
        </div>
        <div
          class="dark:bg-navy-700 bg-white rounded-[32px] sm:p-6 p-4 sm:mt-20 sm:w-[526px] mt-8 grow shrink-0"
          :style="[
            currentTheme === 'dark'
              ? 'box-shadow: 0px 0px 40px 0px rgba(66, 77, 112, 0.64)'
              : 'box-shadow:2px 4px 16px 0px rgba(0,0,0,0.08)',
          ]"
        >
          <div class="relative w-full">
            <button
              :class="[
                'font-semibold text-sm  w-1/2 rounded-2xl py-4 overflow-visible',
                currentStepState < 3
                  ? 'dark:bg-navy-200 text-white bg-primary-700'
                  : ' dark:bg-navy-500 text-navy-200 bg-navy-10',
              ]"
              style="
                clip-path: polygon(
                  0% 30.769%,
                  0% 30.769%,
                  0.081% 25.778%,
                  0.314% 21.044%,
                  0.687% 16.629%,
                  1.188% 12.597%,
                  1.803% 9.012%,
                  2.52% 5.937%,
                  3.326% 3.434%,
                  4.21% 1.569%,
                  5.157% 0.403%,
                  6.155% 0%,
                  89.442% 0%,
                  89.442% 0%,
                  89.797% 0.068%,
                  90.149% 0.272%,
                  90.494% 0.607%,
                  90.833% 1.072%,
                  91.162% 1.662%,
                  91.483% 2.376%,
                  91.791% 3.211%,
                  92.087% 4.163%,
                  92.369% 5.231%,
                  92.635% 6.41%,
                  100% 41.666%,
                  100% 41.666%,
                  100.256% 43.114%,
                  100.456% 44.708%,
                  100.598% 46.411%,
                  100.683% 48.187%,
                  100.712% 50%,
                  100.683% 51.812%,
                  100.598% 53.589%,
                  100.456% 55.292%,
                  100.256% 56.886%,
                  100% 58.334%,
                  92.635% 93.59%,
                  92.635% 93.59%,
                  92.369% 94.769%,
                  92.087% 95.836%,
                  91.791% 96.789%,
                  91.483% 97.624%,
                  91.162% 98.338%,
                  90.833% 98.928%,
                  90.494% 99.393%,
                  90.149% 99.728%,
                  89.797% 99.932%,
                  89.442% 100%,
                  6.155% 100%,
                  6.155% 100%,
                  5.157% 99.597%,
                  4.21% 98.431%,
                  3.326% 96.565%,
                  2.52% 94.063%,
                  1.803% 90.988%,
                  1.188% 87.403%,
                  0.687% 83.371%,
                  0.314% 78.956%,
                  0.081% 74.222%,
                  0% 69.231%,
                  0% 50%,
                  0% 30.769%
                );
              "
            >
              {{ $t("translation.launchpad.launchpadDetail.subscription") }}
            </button>
            <button
              :class="[
                'font-semibold text-sm py-4 rounded-2xl w-[calc(50%)] relative -left-5',
                currentStepState >= 3
                  ? 'dark:bg-navy-200 text-white bg-primary-700'
                  : ' dark:bg-navy-500 text-navy-200 bg-navy-10',
              ]"
              style="
                clip-path: polygon(
                  0.326% 0%,
                  3.165% 0%,
                  3.165% 0%,
                  3.551% 0.068%,
                  3.933% 0.272%,
                  4.309% 0.607%,
                  4.676% 1.072%,
                  5.035% 1.662%,
                  5.383% 2.376%,
                  5.718% 3.211%,
                  6.04% 4.163%,
                  6.346% 5.231%,
                  6.636% 6.41%,
                  14.641% 41.666%,
                  14.641% 41.666%,
                  14.92% 43.114%,
                  15.136% 44.708%,
                  15.291% 46.411%,
                  15.384% 48.187%,
                  15.415% 50%,
                  15.384% 51.813%,
                  15.291% 53.589%,
                  15.136% 55.292%,
                  14.92% 56.886%,
                  14.641% 58.334%,
                  6.636% 93.591%,
                  6.636% 93.591%,
                  6.347% 94.765%,
                  6.042% 95.829%,
                  5.722% 96.779%,
                  5.388% 97.612%,
                  5.042% 98.326%,
                  4.684% 98.917%,
                  4.318% 99.383%,
                  3.944% 99.72%,
                  3.564% 99.927%,
                  3.179% 100%,
                  88.524% 100%,
                  88.524% 100%,
                  88.91% 99.932%,
                  89.292% 99.728%,
                  89.667% 99.393%,
                  90.035% 98.929%,
                  90.394% 98.338%,
                  90.742% 97.624%,
                  91.077% 96.789%,
                  91.399% 95.837%,
                  91.705% 94.77%,
                  91.995% 93.591%,
                  100% 58.334%,
                  100% 58.334%,
                  100.279% 56.886%,
                  100.495% 55.292%,
                  100.65% 53.589%,
                  100.743% 51.813%,
                  100.774% 50%,
                  100.743% 48.187%,
                  100.65% 46.411%,
                  100.495% 44.708%,
                  100.279% 43.114%,
                  100% 41.666%,
                  91.995% 6.41%,
                  91.995% 6.41%,
                  91.705% 5.231%,
                  91.399% 4.163%,
                  91.077% 3.211%,
                  90.742% 2.376%,
                  90.394% 1.662%,
                  90.035% 1.072%,
                  89.667% 0.607%,
                  89.292% 0.272%,
                  88.91% 0.068%,
                  88.524% 0%,
                  0.326% 0%
                );
              "
            >
              {{ $t("translation.launchpad.launchpadDetail.startTrading") }}
            </button>
          </div>
          <div class="mt-6 sm:mt-8 flex flex-row gap-3">
            <template v-for="item in timeBlockData" :key="item.id">
              <div
                class="rounded-2xl border dark:border-navy-400 bg-gradient-to-b dark:from-navy-500 from-[#92cce4] dark:to-[#2d354d] to-white py-6 flex flex-col flex-1 items-center justify-center"
                :style="
                  currentTheme === 'dark'
                    ? 'box-shadow:0px 0px 0px 4px rgba(51,60,87,0.45),0px 0px 0px 2px rgba(14,17,27);'
                    : 'box-shadow:0px 0px 0px 4px rgba(57,163,208,0.45),0px 0px 0px 2px white;'
                "
              >
                <div
                  class="font-semibold text-[32px] dark:text-white text-navy-400"
                >
                  {{ item.digit }}
                </div>
                <div
                  class="font-normal text-lg dark:text-navy-100 text-primary-800"
                >
                  {{ item.label }}
                </div>
              </div>
            </template>
          </div>
          <div class="mt-6 sm:mt-8 flex flex-row dark:text-white text-navy-800">
            <div class="flex flex-col items-start mr-6">
              <div class="text-sm font-medium">
                {{ $t("translation.launchpad.launchpadDetail.participants") }}
              </div>
              <div class="text-xl font-semibold">28,210</div>
            </div>
            <div class="flex flex-col items-start">
              <div class="text-sm font-medium">
                {{ $t("translation.launchpad.launchpadDetail.progress") }}
              </div>
              <div class="text-xl font-semibold">42.8%</div>
            </div>
            <div class="grow" />
            <div class="flex flex-col items-end">
              <div class="text-sm font-medium">
                {{ $t("translation.launchpad.launchpadDetail.raised") }}
              </div>
              <div class="flex 2xl:flex-row flex-col items-end">
                <div class="text-xl font-semibold">56,108</div>
                <div class="text-base font-medium dark:text-navy-100">
                  /100,000 USDT
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-6 sm:mt-4 bg-primary-200 dark:bg-navy-500 rounded-full h-6 p-2"
          >
            <div
              class="bg-gradient-to-l dark:from-green-200 dark:to-[#90deff] from-[#81Fed1] to-[#2ed196] h-2 w-4/5 rounded-full"
              :style="[
                currentTheme === 'dark'
                  ? 'box-shadow:0px 0px 12px 0px #a7f3d0'
                  : '',
              ]"
            />
          </div>
          <div class="sm:mt-12 mt-8 w-full">
            <fill-button
              color="primary"
              width="full"
              size="lg"
              @click="handleCardButtonClick"
            >
              {{ cardButtonTitle[currentStepState] }}
            </fill-button>
          </div>
        </div>
      </div>
      <div class="mt-12 sm:mt-[120px]">
        <div
          class="font-semibold text-[32px] dark:text-white text-navy-800 sm:mb-16 mb-8"
        >
          {{ $t("translation.launchpad.launchpadDetail.timelineTitle") }}
        </div>
        <div
          class="flex sm:flex-row flex-col w-full sm:items-start justify-stretch"
        >
          <div class="flex sm:flex-col flex-row items-center flex-1">
            <div class="flex sm:flex-row flex-col sm:w-full items-center">
              <div class="sm:flex-1 max-sm:h-4" />
              <div
                class="w-10 h-10 rounded-full bg-primary-700 flex items-center justify-center"
              >
                <check-icon class="w-4 h-4 stroke-2 text-white" />
              </div>
              <div
                class="sm:flex-1 sm:border-t border-primary-700 max-sm:border-l max-sm:h-4"
              />
            </div>
            <div class="flex flex-col max-sm:ml-4 sm:items-center">
              <div
                class="text-base font-semibold dark:text-white text-navy-800 sm:mt-6"
              >
                {{ $t("translation.launchpad.launchpadDetail.steps.0") }}
              </div>
              <div
                class="text-sm font-medium dark:text-navy-50 text-navy-300 mt-1"
              >
                2023.11.04 07:00UTC+0
              </div>
            </div>
          </div>
          <div class="flex sm:flex-col flex-row items-center flex-1">
            <div class="flex sm:flex-row flex-col sm:w-full items-center">
              <div
                :class="[
                  'sm:flex-1 sm:border-t  max-sm:border-l max-sm:h-4',
                  currentStepState < 1
                    ? 'dark:border-navy-300 border-primary-300'
                    : 'border-primary-700',
                ]"
              />
              <div
                v-if="currentStepState < 2"
                class="w-10 h-10 rounded-full dark:bg-navy-200 bg-primary-300 flex items-center justify-center dark:text-white text-primary-700"
              >
                2
              </div>
              <div
                v-else
                class="w-10 h-10 rounded-full bg-primary-700 flex items-center justify-center"
              >
                <check-icon class="w-4 h-4 stroke-2 text-white" />
              </div>
              <div
                :class="[
                  'sm:flex-1 sm:border-t  max-sm:border-l max-sm:h-4',
                  currentStepState < 2
                    ? 'dark:border-navy-300 border-primary-300'
                    : 'border-primary-700',
                ]"
              />
            </div>
            <div class="flex flex-col max-sm:ml-4 sm:items-center">
              <div
                class="text-base font-semibold dark:text-white text-navy-800 sm:mt-6"
              >
                {{ $t("translation.launchpad.launchpadDetail.steps.1") }}
              </div>
              <div
                class="text-sm font-medium dark:text-navy-50 text-navy-300 mt-1"
              >
                2023.11.04 07:00UTC+0
              </div>
            </div>
          </div>
          <div class="flex sm:flex-col flex-row items-center flex-1">
            <div class="flex sm:flex-row flex-col sm:w-full items-center">
              <div
                :class="[
                  'sm:flex-1 sm:border-t  max-sm:border-l max-sm:h-4',
                  currentStepState < 3
                    ? 'dark:border-navy-300 border-primary-300'
                    : 'border-primary-700',
                ]"
              />
              <div
                v-if="currentStepState < 3"
                class="w-10 h-10 rounded-full dark:bg-navy-200 bg-primary-300 flex items-center justify-center dark:text-white text-primary-700"
              >
                3
              </div>
              <div
                v-else
                class="w-10 h-10 rounded-full bg-primary-700 flex items-center justify-center"
              >
                <check-icon class="w-4 h-4 stroke-2 text-white" />
              </div>
              <div
                :class="[
                  'sm:flex-1 sm:border-t  max-sm:border-l max-sm:h-4',
                  currentStepState < 3
                    ? 'dark:border-navy-300 border-primary-300'
                    : 'border-primary-700',
                ]"
              />
            </div>
            <div class="flex flex-col max-sm:ml-4 sm:items-center">
              <div
                class="text-base font-semibold dark:text-white text-navy-800 sm:mt-6"
              >
                {{ $t("translation.launchpad.launchpadDetail.steps.2") }}
              </div>
              <div
                class="text-sm font-medium dark:text-navy-50 text-navy-300 mt-1"
              >
                2023.11.04 07:00UTC+0
              </div>
            </div>
          </div>
          <div class="flex sm:flex-col flex-row items-center flex-1">
            <div class="flex sm:flex-row flex-col sm:w-full items-center">
              <div
                :class="[
                  'sm:flex-1 sm:border-t  max-sm:border-l max-sm:h-4',
                  currentStepState < 4
                    ? 'dark:border-navy-300 border-primary-300'
                    : 'border-primary-700',
                ]"
              />
              <div
                v-if="currentStepState < 4"
                class="w-10 h-10 rounded-full dark:bg-navy-200 bg-primary-300 flex items-center justify-center dark:text-white text-primary-700"
              >
                4
              </div>
              <div
                v-else
                class="w-10 h-10 rounded-full bg-primary-700 flex items-center justify-center"
              >
                <check-icon class="w-4 h-4 stroke-2 text-white" />
              </div>
              <div class="sm:flex-1 max-sm:h-4" />
            </div>
            <div class="flex flex-col max-sm:ml-4 sm:items-center">
              <div
                class="text-base font-semibold dark:text-white text-navy-800 sm:mt-6"
              >
                {{ $t("translation.launchpad.launchpadDetail.steps.3") }}
              </div>
              <div
                class="text-sm font-medium dark:text-navy-50 text-navy-300 mt-1"
              >
                2023.11.04 07:00UTC+0
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 sm:mt-40">
        <div v-html="projectDescription"></div>
      </div>
    </div>
    <dialog-view
      :title="`${$t(
        'translation.launchpad.launchpadDetail.dialog.title'
      )} ARKHAM`"
      v-if="showBuyDialog"
      :is-open="showBuyDialog"
      :contain-header="true"
      bg-color="gray"
      @change-is-open="handleChangeShowDialog"
    >
      <template #content>
        <div class="flex flex-col w-full">
          <div class="dark:text-white text-navy-800 font-semibold text-sm mb-2">
            {{ $t("translation.launchpad.launchpadDetail.dialog.asset") }}
          </div>
          <dropdown-select width="full" @select="handleCurrencySelect">
            <template #header="{ handleContentClick, isShowingMenu }">
              <div
                class="rounded-xl border dark:border-navy-300 p-4 flex flex-row items-center justify-stretch"
              >
                <img
                  :src="currentSelectedCurrency.icon"
                  class="size-5 rounded-full mr-2"
                />
                <div>{{ currentSelectedCurrency.currency.iso3 }}</div>
                <div class="grow" />
                <div @click="handleContentClick" class="cursor-pointer">
                  <chevron-up-icon
                    v-if="isShowingMenu"
                    class="w-4 h-4 stroke-2"
                  />
                  <chevron-down-icon v-else class="w-4 h-4 stroke-2" />
                </div>
              </div>
            </template>
            <template #menu="{ handleMenuItemClick }">
              <div
                class="flex flex-col w-full p-4 dark:bg-navy-600 rounded-lg gap-1 bg-navy-10"
              >
                <div
                  v-for="item in balanceList"
                  :key="item.currency.id"
                  class="py-2 px-3 cursor-pointer dark:hover:bg-navy-300 hover:bg-navy-50 rounded-md flex flex-row items-center"
                  @click="handleMenuItemClick(item)"
                >
                  <img :src="item.icon" class="size-5 rounded-full mr-2" />
                  <div>{{ item.currency.iso3 }}</div>
                </div>
              </div>
            </template>
          </dropdown-select>
          <div class="mt-6">
            <text-edit
              v-model="amountInput"
              :label="$t('translation.launchpad.launchpadDetail.dialog.amount')"
              type="text"
              size="lg"
              rounded="xl"
              :filled="true"
              borderColor="gray"
            >
              <template #suffix>
                <div
                  @click="amountInput = selectedBalance"
                  class="text-primary-700 cursor-pointer"
                >
                  {{ $t("translation.launchpad.launchpadDetail.dialog.max") }}
                </div>
              </template>
            </text-edit>
          </div>
          <div
            class="mt-4 flex flex-row w-full items-center text-base font-medium"
          >
            <div class="mr-2 dark:text-navy-200">
              {{
                $t("translation.launchpad.launchpadDetail.dialog.available")
              }}:
            </div>
            <div class="mr-4">1.872 USDT</div>
            <div class="mr-2 dark:text-navy-200">1 T2T2:</div>
            <div class="mr-4">0.0001626287 USDT</div>
            <div class="grow" />
            <div></div>
            <div class="text-primary-700">
              {{ $t("translation.launchpad.launchpadDetail.dialog.deposit") }}
            </div>
          </div>
          <div class="w-full mt-12">
            <fill-button
              size="lg"
              color="primary"
              width="full"
              @click="handleClickBuyButton"
            >
              {{ $t("translation.launchpad.launchpadDetail.dialog.buy") }}
              ARKHAM
            </fill-button>
          </div>
        </div>
      </template>
    </dialog-view>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { scrollToTop, isEmpty } from "@/utils/utils";
import TextBadge from "@/components/Badge/TextBadge.vue";
import BreadcrumbMenu from "@/components/Breadcrumb/BreadcrumbMenu.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  GlobeAltIcon,
} from "@heroicons/vue/24/outline";

const { t } = useI18n();
const store = useStore();
const projectDetail = computed(() => store.getters.launchpadProjectDetail);

const projectDescription = "About the Project";
const amountInput = ref(0);

const currentStepState = ref(0);
const showBuyDialog = ref(false);

const handleChangeShowDialog = () => {
  showBuyDialog.value = !showBuyDialog.value;
};

const handleClickBuyButton = () => {
  showBuyDialog.value = false;
};

const balancesVolume = computed(() => store.getters.balancesVolumeModified);
const balanceList = computed(() => {
  if (isEmpty(balancesVolume.value)) {
    return [];
  }
  return Object.values(balancesVolume.value).filter(
    (item) => item.balance_available != 0 && item.deposit_type == "spot"
  );
});

const currentSelectedCurrency = ref(balanceList.value[0]);

watch(
  () => balanceList.value,
  (newValue) => {
    if (!isEmpty(newValue)) {
      currentSelectedCurrency.value = newValue[0];
    }
  }
);

const selectedBalance = computed(() => {
  if (isEmpty(balanceList.value)) {
    return 0;
  }
  const item = balanceList.value.find(
    (item) => item.currency.id === currentSelectedCurrency.value.currency.id
  );
  return item.balance_available_f;
});

const handleCurrencySelect = (item) => {
  currentSelectedCurrency.value = item;
};

const cardButtonTitle = computed(() => [
  t("translation.launchpad.launchpadDetail.cardButtonTitles.0"),
  t("translation.launchpad.launchpadDetail.cardButtonTitles.1"),
  t("translation.launchpad.launchpadDetail.cardButtonTitles.2"),
  t("translation.launchpad.launchpadDetail.cardButtonTitles.3"),
  t("translation.launchpad.launchpadDetail.cardButtonTitles.4"),
]);

const handleCardButtonClick = () => {
  switch (currentStepState.value) {
    case 0:
      currentStepState.value++;
      break;
    case 1:
      currentStepState.value++;
      break;
    case 2:
      currentStepState.value++;
      break;
    case 3:
      currentStepState.value++;
      break;
    case 4:
      showBuyDialog.value = true;
      break;
  }
};

const breadCrumbMenuData = computed(() => [
  {
    id: 1,
    label: t("translation.launchpad.launchpadDetail.breadCrumb.0"),
    pathName: "launchpad",
  },
  {
    id: 2,
    label: `Arkham ${t("translation.launchpad.launchpadDetail.breadCrumb.1")}`,
    active: true,
  },
]);

const timeBlockData = [
  {
    id: 1,
    digit: "08",
    label: "Days",
  },
  {
    id: 2,
    digit: "14",
    label: "H",
  },
  {
    id: 3,
    digit: "20",
    label: "M",
  },
  {
    id: 4,
    digit: "17",
    label: "S ",
  },
];

onMounted(() => {
  scrollToTop();
  store.dispatch("fetchProjectDetailView");
});
</script>
