<template>
  <div>
    <div
      :class="[
        'flex flex-row py-2.5 px-3 cursor-pointer justify-between rounded-lg items-center hover:dark:bg-navy-500 hover:bg-primary-200 hover:text-primary-700 hover:dark:text-white',
        selected
          ? props.menuItemData.background ??
            'dark:bg-navy-500 bg-primary-200 text-primary-700 dark:text-white'
          : 'text-navy-800 dark:text-white',
      ]"
      @click="handleClick"
    >
      <div class="flex gap-3">
        <template v-if="props.menuItemData.icon">
          <component
            :is="props.menuItemData.icon"
            :class="['w-5 h-5', selected ? '' : 'text-navy-100']"
          />
        </template>
        <template v-else>
          <div class="w-5"></div>
        </template>
        <div class="text-sm font-medium">
          {{ $t(props.menuItemData.label) }}
        </div>
      </div>

      <chevron-down-icon
        v-if="menuItemData.hasSubItems && !isMenuOpen"
        class="w-3 h-3"
      />
      <chevron-up-icon
        v-if="menuItemData.hasSubItems && isMenuOpen"
        class="w-3 h-3"
      />
    </div>
    <div
      v-if="menuItemData.hasSubItems && isMenuOpen"
      class="flex flex-col gap-3 mt-3"
    >
      <sidebar-menu-item
        v-for="item in menuItemData.subItems"
        :key="item.id"
        :menu-item-data="item"
        :selected="item.name === route.name"
        @click="handleSelectMenuItem(item)"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  menuItemData: Object,
  selected: Boolean,
});

const emit = defineEmits(["click"]);
const router = useRouter();
const route = useRoute();

const isMenuOpen = ref(props.selected);

const handleClick = () => {
  if (props.menuItemData.hasSubItems) {
    isMenuOpen.value = !isMenuOpen.value;
  } else {
    emit("click");
  }
};
const handleSelectMenuItem = (item) => {
  router.push({ name: item.name });
};
</script>
