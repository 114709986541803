<template>
  <div
    class="dark:bg-navy-800 bg-navy-10 rounded-full w-fit p-1 flex flex-row items-center"
  >
    <button
      v-for="item in props.items"
      :key="item.id"
      :class="[
        'rounded-full dark:hover:bg-navy-600 hover:bg-navy-50 px-3 py-1',
        props.selectedTab.id === item.id ? 'dark:bg-navy-600 bg-navy-50' : '',
      ]"
      @click="handleSelectTab(item)"
    >
      <img class="w-4 h-4" :src="item.content" alt="Selector icon" />
    </button>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  selectedTab: Object,
  items: Object,
});

const emits = defineEmits(["selectTab"]);

const handleSelectTab = (item) => {
  emits("selectTab", item);
};
</script>
