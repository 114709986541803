<template>
  <dialog-view
    :contain-header="true"
    :is-open="model"
    :title="$t('translation.profile.addressBook.deleteConfirmDialog.title')"
    @change-is-open="handleChangeOpen"
    bg-color="gray"
  >
    <template #content>
      <div
        class="mt-4 text-sm font-medium dark:text-white text-navy-800 mb-8 w-full text-wrap"
      >
        {{ $t("translation.profile.addressBook.deleteConfirmDialog.label") }}
      </div>
      <div
        class="w-full flex sm:flex-row flex-col items-center justify-stretch gap-6"
      >
        <div class="w-full">
          <outline-button
            @click="handleCancelClick"
            color="primary"
            size="lg"
            width="full"
          >
            {{
              $t(
                "translation.profile.addressBook.deleteConfirmDialog.cancelButton"
              )
            }}
          </outline-button>
        </div>
        <div class="w-full">
          <fill-button
            @click="handleDeleteClick"
            color="red"
            size="lg"
            width="full"
          >
            {{
              $t(
                "translation.profile.addressBook.deleteConfirmDialog.confirmButton"
              )
            }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineEmits } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();
const emit = defineEmits(["confirm"]);

const handleCancelClick = () => {
  model.value = false;
};

const handleDeleteClick = () => {
  model.value = false;
  emit("confirm");
};

const handleChangeOpen = () => {
  model.value = !model.value;
};
</script>
