<template>
  <div>
    <alert-container :label="alert" />
    <div class="py-4"></div>
    <text-edit
      type="email"
      :filled="false"
      rounded="xl"
      :label="$t('translation.auth.forgetPassword.email.emailLabel')"
      :placeholder="
        $t('translation.auth.forgetPassword.email.emailPlaceholder')
      "
      v-model="email"
      :error="emailError"
    />
  </div>
</template>
<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { validateEmail } from "@/views/Auth/validation";
import AlertContainer from "@/views/Auth/ForgetPassword/RecoverWith/AlertContainer.vue";

const { t } = useI18n();
const email = ref("");
const emit = defineEmits(["changeEmail"]);
const alert = t("translation.auth.forgetPassword.email.alert");
const emailError = computed(() =>
  email.value && !validateEmail(email.value)
    ? t("translation.auth.forgetPassword.email.emailError")
    : ""
);
watch(email, (newEmail) => {
  emit("changeEmail", newEmail);
});
</script>
