<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    bg-color="gray"
    :contain-header="false"
    :show-only-content="true"
    :bg-close="true"
    @change-is-open="model = false"
  >
    <template #content>
      <div
        class="max-sm:w-[calc(100%-32px)] mx-auto my-auto shadow-xl p-2 grid sm:grid-cols-2 max-sm:grid-cols-1 gap-x-6 gap-y-2 rounded-2xl dark:bg-navy-600 dark:border-navy-400 border dark:shadow-black/[0.32] bg-white shadow-black/[0.16]"
      >
        <language-menu-item
          v-for="item in languageList"
          :label="item.label"
          :key="item.id"
          :locale="item.locale"
        />
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import { languageList } from "@/constants/constants";
import LanguageMenuItem from "@/layout/AppNavbar/item/LanguageMenuItem.vue";

const model = defineModel();
</script>
