<template>
  <div>
    <dropdown-select width="full" @select="handleSelectToken">
      <template #header="{ handleContentClick }">
        <text-edit
          placeholder="Enter or paste the address"
          width="full"
          rounded="xl"
          v-model="walletAddress"
          @focus="handleContentClick(true)"
          :error="walletAddressError"
        >
          <template #suffix>
            <person-contract class="w-6 h-6" />
          </template>
        </text-edit>
      </template>
      <template #menu="{ handleMenuItemClick }">
        <div
          class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-2 dark:shadow-none shadow-md"
        >
          <div class="max-h-60 overflow-auto custom-scrollbar">
            <div
              v-for="address in addresses"
              :key="address.iso3"
              class="px-3 py-2 font-semibold hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
              @click="handleMenuItemClick(address)"
            >
              <div class="flex gap-1">
                <p>{{ address.name }}</p>
                <text-badge
                  :label="address.network?.nice_name"
                  size="sm"
                  class="dark:bg-navy-400 bg-primary-300 dark:text-white text-primary-800 text-[10px] leading-3"
                />
              </div>
              <p class="text-xs dark:text-navy-100 text-navy-200">
                {{ address.address }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </dropdown-select>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineEmits, defineProps } from "vue";
import PersonContract from "@/assets/icons/common/PersonContract.vue";
import TextBadge from "@/components/Badge/TextBadge.vue";
import { isEmpty, isValidWalletAddress } from "@/utils/utils";

defineProps({
  addresses: {
    type: Array,
    default: () => [],
  },
  showHistory: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["select"]);
const selectedAddress = ref({});
const walletAddress = ref("");

const handleSelectToken = (address) => {
  selectedAddress.value = address;
  walletAddress.value = address.address;
};

const walletAddressError = computed(() =>
  isEmpty(walletAddress.value)
    ? ""
    : isValidWalletAddress(walletAddress.value)
    ? ""
    : "Input valid wallet address"
);

watch(walletAddress, () => {
  emits("select", walletAddress.value);
});
</script>
