<template>
  <div
    class="h-[40px] rounded-xl pl-4 flex items-center overflow-hidden bg-navy-10 dark:bg-navy-700"
  >
    <img src="@/assets/icons/bubble_dark.svg" alt="Bubble icon" />
    <div
      class="overflow-hidden w-full relative h-[16px]"
      @mouseenter="handleAnimationPause"
      @mouseleave="handleAnimationResume"
    >
      <div
        class="animate-moveLeft absolute top-0 left-0 w-fit flex"
        v-for="index in [0, 1]"
        :key="index"
        :style="{
          '--start': animationVariables[index].start,
          '--end': animationVariables[index].end,
          '--duration': animationVariables[index].duration,
          'animation-play-state': isPaused ? 'paused' : 'running',
        }"
        @animationiteration="(event) => handleAnimationIterated(event, index)"
      >
        <template v-for="index in [0, 1]" :key="index">
          <div
            class="text-xs ml-6 hover:cursor-pointer"
            v-for="pair in trendingPairs"
            :key="pair.id"
            @click="goToTrade(pair)"
          >
            <span>{{ pair.name }}</span>
            <span
              :class="[
                getPercentage(pair) < 0 ? 'text-red-500' : 'text-green-400',
                'ml-2',
              ]"
            >
              {{ getPercentage(pair) }}%
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { TIME_INTERVAL_PAIR_BRIEF } from "@/constants/constants";
import { isEmpty } from "@/utils/utils";

const store = useStore();
const router = useRouter();
const animationVariables = ref([
  {
    start: "0%",
    end: "-100%",
    duration: `${TIME_INTERVAL_PAIR_BRIEF}s`,
  },
  {
    start: "100%",
    end: "-100%",
    duration: `${TIME_INTERVAL_PAIR_BRIEF * 2}s`,
  },
]);
const isPaused = ref(false);

const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const trendingPairs = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item, index) => {
      return {
        id: index,
        img: activeCurrencies.value[item.main.id].icon,
        name: item.name,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
        volume: item.volume,
      };
    })
    .sort((valA, valB) => {
      return valA.volume - valB.volume;
    })
    .slice(0, 15);
});

const getPercentage = (pair) => {
  const resultPair = allPairs.value.find(
    (samplePair) => samplePair.name === pair.name
  );
  return resultPair ? resultPair.percent.toFixed(2) : "";
};

const handleAnimationIterated = (event, index) => {
  if (index == 0) {
    event.target.style.animation = "none";
    void event.target.offsetHeight;
    animationVariables.value[0].start = "100%";
    animationVariables.value[0].duration = `${TIME_INTERVAL_PAIR_BRIEF * 2}s`;
    event.target.style.animation = "";
  }
};

const handleAnimationPause = () => {
  isPaused.value = true;
};

const handleAnimationResume = () => {
  isPaused.value = false;
};

const goToTrade = (pair) => {
  router.push({ name: "trading", params: { pair: pair.name } });
};
</script>
