<template>
  <dialog-view
    :is-open="model"
    :contain-header="false"
    bg-color="gray"
    @change-is-open="handleChangeOpen"
  >
    <template #header>
      <div
        class="relative flex flex-col pt-3 pb-4 sm:py-4 sm:px-6 w-full px-4 rounded-t-3xl"
        :style="[
          currentTheme === 'light'
            ? `background-image: url('/assets/images/convert/confirm_convert_modal_light.svg');`
            : `background-image: url('/assets/images/convert/confirm_convert_modal_dark.svg');`,
        ]"
      >
        <div class="flex flex-row justify-center items-center w-full mb-4">
          <div class="text-xl font-semibold dark:text-white text-navy-800">
            {{ $t("translation.convert.previewDialog.title") }}
          </div>
          <div class="flex-grow"></div>
          <div
            class="dark:text-navy-100 text-navy-200 cursor-pointer"
            @click="handleCloseClick"
          >
            <x-mark-icon class="stroke-2 sm:w-6 sm:h-6 w-5 h-5" />
          </div>
        </div>
        <div class="relative flex flex-row justify-around max-sm:py-2 gap-12">
          <div class="flex flex-col items-center justify-center">
            <div class="">
              <token-circle-display :img-url="props.fromToken.icon" />
            </div>
            <div
              class="dark:text-navy-200 text-primary-800 text-sm font-medium text-center mt-4"
            >
              {{ $t("translation.convert.previewDialog.from") }}
            </div>
            <div
              class="dark:text-white text-navy-800 text-lg font-semibold text-center"
            >
              {{
                `${formatNumber(props.fromTokenAmount)} ${props.fromToken.iso3}`
              }}
            </div>
          </div>
          <div class="h-full flex-1 absolute sm:left-[240px] left-[137px]">
            <img
              src="/assets/images/convert/convert_arrow.svg"
              class="sm:mt-[60px] mt-12 mx-auto max-sm:hidden"
            />
            <img
              src="/assets/images/convert/convert_arrow_mobile.svg"
              class="sm:mt-[60px] mt-12 mx-auto sm:hidden"
            />
          </div>
          <div class="flex flex-col items-center justify-center">
            <div class="">
              <token-circle-display :img-url="props.toToken.icon" />
            </div>
            <div
              class="dark:text-navy-200 text-primary-800 text-sm font-medium text-center mt-4"
            >
              {{ $t("translation.convert.previewDialog.to") }}
            </div>
            <div
              class="dark:text-white text-navy-800 text-lg font-semibold text-center text-wrap"
            >
              {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col">
        <div class="flex flex-col gap-y-3 sm:mb-12 mb-9 mt-6">
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.convert.previewDialog.price") }}
            </div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{
                `1 ${props.fromToken.iso3} = ${formatNumber(1 / props.price)} ${
                  props.toToken.iso3
                }`
              }}
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.convert.previewDialog.payFrom") }}
            </div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{ $t("translation.convert.previewDialog.tradingWallet") }}
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.convert.previewDialog.inversePrice") }}
            </div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{
                `1 ${props.toToken.iso3} = ${formatNumber(props.price)} ${
                  props.fromToken.iso3
                }`
              }}
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">
              {{ $t("translation.convert.previewDialog.receiveLabel") }}
            </div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-center text-sm font-medium text-navy-100 mb-6"
        >
          {{ $t("translation.convert.previewDialog.quote") }}
        </div>
        <div class="flex flex-row w-full">
          <fill-button size="lg" width="full" @click="handleButtonClick">
            {{
              isRefresh
                ? $t("translation.convert.previewDialog.refresh")
                : `${$t("translation.convert.previewDialog.convert")}
            (${leftTime}s)`
            }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import {
  defineModel,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import { formatNumber } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenCircleDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const model = defineModel();
const leftTime = ref(8);
const isRefresh = ref(false);
const store = useStore();
const props = defineProps(["fromToken", "fromTokenAmount", "toToken", "price"]);
const emit = defineEmits(["convertClick"]);

const toTokenAmount = computed(() => {
  return props.fromTokenAmount * (1 / props.price);
});

const handleCloseClick = () => {
  model.value = false;
};

const handleButtonClick = () => {
  if (isRefresh.value === false) {
    emit("convertClick");
  } else {
    store.dispatch("fetchActiveCurrencies");
    store.dispatch("fetchPairData");
    store.dispatch("fetchBalancesVolume");
  }
};

const handleChangeOpen = () => {
  model.value = false;
};

onMounted(() => {
  setInterval(() => {
    if (leftTime.value === 0) {
      isRefresh.value = true;
    }
    leftTime.value--;
  }, 1000);
});
</script>
