<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    bg-color="gray"
    :contain-header="true"
    :title="$t('translation.profile.security.deletePasswordDialog.title')"
    @change-is-open="handleChangeOpen"
  >
    <template #content>
      <div class="w-full flex flex-col">
        <password-input
          :label="
            $t(
              'translation.profile.security.deletePasswordDialog.passwordLabel'
            )
          "
          :placeholder="
            $t(
              'translation.profile.security.deletePasswordDialog.passwordPlaceholder'
            )
          "
          v-model="password"
          :error="passwordError"
        />
        <div class="w-full mt-12">
          <fill-button
            color="red"
            width="full"
            @click="handleDeleteClick"
            :disabled="!canDelete"
          >
            {{
              $t(
                "translation.profile.security.deletePasswordDialog.deleteButton"
              )
            }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, ref, computed } from "vue";
import { validatePassword } from "@/views/Auth/validation";
import DialogView from "@/components/Dialog/DialogView.vue";
import PasswordInput from "@/components/TextEdit/PasswordInput.vue";

const model = defineModel();
const password = ref("");

const passwordError = computed(() => validatePassword(password.value));
const canDelete = computed(() => passwordError.value.every((item) => item));

const handleChangeOpen = () => {
  model.value = false;
};

const handleDeleteClick = () => {
  model.value = false;
};
</script>
