import store from "@/store/index";
import { getHttpRequest, postHttpRequest } from "@/api/api";

const state = {
  verification: {},
  questionnaireSettings: [],
};

const mutations = {
  SET_VERIFICATION(state, verification) {
    state.verification = verification;
  },
  SET_QUESTIONNAIRE_SETTINGS(state, settings) {
    state.questionnaireSettings = settings;
  },
};

const actions = {
  setVerification({ commit }, verification) {
    commit("SET_VERIFICATION", verification);
  },
  async fetchVerificationInfo({ commit }) {
    try {
      const result = await postHttpRequest(store)("/api/verification");
      if (result.status) {
        commit("SET_VERIFICATION", result.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchquestionnaireSettings({ commit }) {
    try {
      const result = await getHttpRequest(store)(
        "/api/verification/questionnaire-settings",
        false
      );
      if (result.status) {
        commit("SET_QUESTIONNAIRE_SETTINGS", result.data.settings);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  verificationInfo: (state) => state.verification,
  questionnaireSettings: (state) => state.questionnaireSettings,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
