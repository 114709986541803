import { computed, ref } from "vue";
import { useStore } from "vuex";
import { USER_SERVICE_API_URL } from "@/constants/constants";
import { useRouter } from "vue-router";
import axios from "@/api/axios";

export const useRecoveryPassword = () => {
  const store = useStore();
  const router = useRouter();
  const authInfo = computed(() => store.getters.authInfo);
  const password = ref("");
  const isLoading = ref(false);

  const handleSetPassword = async () => {
    isLoading.value = true;
    try {
      const params = {
        exziId: authInfo.value.exziId,
        token: authInfo.value.resetPasswordToken,
        password: password.value,
      };
      const res = await axios.post(
        `${USER_SERVICE_API_URL}/forget-password/change-password`,
        params
      );
      if (res.data.success) {
        router.push({ name: "signin" });
      }
    } catch (error) {
      store.dispatch("setToast", {
        type: "error",
        message: error.data.error,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    password,
    isLoading,
    handleSetPassword,
  };
};
