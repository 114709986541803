<template>
  <div class="w-full relative">
    <div class="absolute w-full h-fit -z-50 top-24">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/background-texture-dark.svg'
            : '/assets/images/help-center/background-texture-light.svg'
        "
        class="w-full max-sm:object-cover"
      />
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-50% from-white dark:to-transparent to-100% z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0"
      />
    </div>
    <div class="absolute -z-50 -top-12 w-full">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/ellipse-dark.svg'
            : '/assets/images/help-center/ellipse-light.svg'
        "
        class="w-full"
      />
    </div>
    <div class="w-full px-4 sm:px-16">
      <div
        class="mx-auto container w-full flex flex-col items-center max-w-[1088px]"
      >
        <div class="mt-12 sm:mt-16">
          <div
            class="font-bold text-5xl leading-[56px] -tracking-[1.2px] sm:text-[56px] sm:leading-[64px] sm:-tracking-[1.4px] text-center"
          >
            {{ $t("translation.beginnerGuide.title") }}
          </div>
          <div
            class="mt-6 font-medium text-lg -tracking-[0.4px] dark:text-navy-50 text-navy-300 max-w-[647px] md:text-center"
          >
            {{ $t("translation.beginnerGuide.subTitle") }}
          </div>
        </div>
        <div
          class="mt-[88px] sm:mt-32 w-full relative flex flex-col md:justify-around md:h-[2313px] max-md:items-center max-md:gap-14"
        >
          <div class="absolute w-full h-full max-md:hidden -z-10">
            <img
              src="/assets/images/beginner-guide/line.png"
              class="h-full w-full object-fill"
            />
          </div>
          <template v-for="item in guideData" :key="item.id">
            <div
              :class="[
                'w-full flex items-center gap-[104px] max-md:items-center max-md:flex-col',
                item.id % 2 == 1
                  ? 'md:pl-[72px] md:flex-row'
                  : 'md:pr-[72px] md:flex-row-reverse',
              ]"
            >
              <img :src="item.imgUrl" class="max-md:hidden" />
              <div
                :class="[
                  'flex flex-col items-center',
                  item.id % 2 === 1 ? 'md:items-start' : 'md:items-end',
                ]"
              >
                <div
                  class="size-10 shrink-0 rounded-full bg-navy-200 flex items-center justify-center font-medium text-base -tracking-[0.2px]"
                >
                  {{ item.id }}
                </div>
                <div
                  class="mt-6 font-semibold text-2xl leading-9 -tracking-[0.4px]"
                >
                  {{ item.title }}
                </div>
                <div
                  :class="[
                    'mt-2.5 font-normal text-base -tracking-[0.2px] max-w-[363px] text-navy-50 max-md:text-center',
                    item.id % 2 === 1 ? 'text-left' : 'text-right',
                  ]"
                >
                  {{ item.content }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          class="font-bold sm:text-5xl sm:leading-[64px] sm:-tracking-[1.2px] text-[32px] leading-10 -tracking-[0.8px] sm:mt-52 mt-[168px]"
        >
          {{ $t("translation.beginnerGuide.tipTitle") }}
        </div>
        <div
          class="flex sm:flex-row flex-col w-full justify-between sm:mt-16 mt-12 gap-[88px]"
        >
          <template v-for="item in tipData" :key="item.id">
            <div class="flex flex-col items-center gap-1 max-w-[500px]">
              <div class="font-semibold text-lg -tracking-[0.4px]">
                {{ item.title }}
              </div>
              <div
                class="font-medium text-base -tracking-[0.2px] text-navy-100 text-center"
              >
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
        <div
          class="font-bold sm:text-5xl sm:leading-[64px] sm:-tracking-[1.2px] text-[32px] leading-10 -tracking-[0.8px] sm:mt-52 mt-[168px]"
        >
          {{ $t("translation.beginnerGuide.helpTitle") }}
        </div>
        <div
          class="font-normal text-xl leading-8 -tracking-[0.4px] text-navy-50 max-w-[694px] text-center mt-4 sm:mb-52 mb-[168px]"
        >
          {{ $t("translation.beginnerGuide.helpText") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const guideData = computed(() => [
  {
    id: 1,
    title: t("translation.beginnerGuide.guides.1.title"),
    content: t("translation.beginnerGuide.guides.1.content"),
    imgUrl: "/assets/images/beginner-guide/logo-1.svg",
  },
  {
    id: 2,
    title: t("translation.beginnerGuide.guides.2.title"),
    content: t("translation.beginnerGuide.guides.2.content"),
    imgUrl: "/assets/images/beginner-guide/logo-2.svg",
  },
  {
    id: 3,
    title: t("translation.beginnerGuide.guides.3.title"),
    content: t("translation.beginnerGuide.guides.3.content"),
    imgUrl: "/assets/images/beginner-guide/logo-3.svg",
  },
  {
    id: 4,
    title: t("translation.beginnerGuide.guides.4.title"),
    content: t("translation.beginnerGuide.guides.4.content"),
    imgUrl: "/assets/images/beginner-guide/logo-4.svg",
  },
  {
    id: 5,
    title: t("translation.beginnerGuide.guides.5.title"),
    content: t("translation.beginnerGuide.guides.5.content"),
    imgUrl: "/assets/images/beginner-guide/logo-5.svg",
  },
  {
    id: 6,
    title: t("translation.beginnerGuide.guides.6.title"),
    content: t("translation.beginnerGuide.guides.6.content"),
    imgUrl: "/assets/images/beginner-guide/logo-6.svg",
  },
  {
    id: 7,
    title: t("translation.beginnerGuide.guides.7.title"),
    content: t("translation.beginnerGuide.guides.7.content"),
    imgUrl: "/assets/images/beginner-guide/logo-7.svg",
  },
]);

const tipData = computed(() => [
  {
    id: 1,
    title: t("translation.beginnerGuide.tips.1.title"),
    content: t("translation.beginnerGuide.tips.1.content"),
  },
  {
    id: 2,
    title: t("translation.beginnerGuide.tips.2.title"),
    content: t("translation.beginnerGuide.tips.2.content"),
  },
]);
</script>
