<template>
  <div class="w-full">
    <faq-mobile v-model="showFaqMobile" />
    <div class="flex gap-12">
      <div class="w-full">
        <div
          class="flex h-fit items-center justify-between mb-12 sm:flex-row flex-col gap-3"
        >
          <div class="flex flex-row items-center justify-between w-full">
            <p class="font-semibold text-2xl">
              {{ $t("translation.asset.depositCryptoPage.title") }}
            </p>
            <div class="sm:hidden">
              <link-button
                :label="$t('translation.asset.depositCryptoPage.faq')"
                @click="handleFAQClick"
              />
            </div>
          </div>
          <!-- <div class="sm:w-fit w-full shrink-0">
            <outline-button
              color="primary"
              size="sm"
              width="full"
              @click="goToDepositFiat"
            >
              <span>{{
                $t("translation.asset.depositCryptoPage.fiatButton")
              }}</span>
              <chevron-right-icon class="w-5 h-5" />
            </outline-button>
          </div> -->
        </div>
        <div>
          <vertical-circle-step
            :step-length="stepLength"
            :current-active-step="currentActiveStep"
          >
            <template #content-1>
              <div class="w-full">
                <p class="font-semibold text-xl mb-7 mt-1">
                  {{ $t("translation.asset.depositCryptoPage.selectCoin") }}
                </p>
                <select-currency-auto-complete
                  :currencies="activeCurrencies"
                  @select="handleSelectCoin"
                />
                <div class="h-10" />
              </div>
            </template>
            <template #content-2>
              <div class="w-full">
                <p class="font-semibold text-xl mb-7 mt-1">
                  {{ $t("translation.asset.depositCryptoPage.selectNetwork") }}
                </p>
                <select-network-dropdown
                  :networks="networks"
                  @select="handleSelectNetwork"
                />
                <div class="h-10" />
              </div>
            </template>
            <template #content-3>
              <div class="w-full">
                <p class="font-semibold text-xl mb-7 mt-1">
                  {{ $t("translation.asset.depositCryptoPage.depositDetails") }}
                </p>
                <deposit-crypto-detail
                  :address-info="addressInfo"
                  :network="network"
                  :token="coin"
                />
              </div>
            </template>
          </vertical-circle-step>
        </div>
      </div>
      <div class="w-[304px] sm:block hidden shrink-0">
        <faq-small />
      </div>
    </div>

    <div class="mt-24 mb-48">
      <div class="flex items-center gap-6">
        <p class="font-semibold text-2xl">
          {{ $t("translation.asset.depositCryptoPage.tableHeader") }}
        </p>
        <link-button
          color="primary"
          :label="$t('translation.asset.depositCryptoPage.moreButton')"
          :icon="ChevronRightIcon"
          @click="
            router.push({
              name: 'asset-transaction-history',
              params: {
                url: 'deposit',
              },
            })
          "
        />
      </div>
      <deposit-history />
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { isEmpty } from "@/utils/utils";
import { postHttpRequest } from "@/api/api";
import FaqSmall from "@/common/FAQ/FaqSmall.vue";
import SelectCurrencyAutoComplete from "@/common/SelectCurrencyDropdown/SelectCurrencyAutoComplete.vue";
import SelectNetworkDropdown from "@/common/SelectNetworkDropdown/SelectNetworkDropdown.vue";
import VerticalCircleStep from "@/components/VerticalCircleStep/VerticalCircleStep.vue";
import DepositCryptoDetail from "@/views/Asset/Deposit/DepositCryptoDetail.vue";
import DepositHistory from "@/common/TransactionHistory/DepositHistory.vue";
import FaqMobile from "@/common/FAQ/FaqMobile.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();

const coin = ref();
const network = ref();
const stepLength = ref(1);
const currentActiveStep = ref(1);
const addressInfo = ref(null);
const showFaqMobile = ref(false);

// const goToDepositFiat = () => {
//   router.push({ name: "asset-deposit-fiat" });
// };

const activeCurrencies = computed(() =>
  Object.values(store.getters.activeCurrencies ?? {})
);
const networks = computed(() => {
  if (!coin.value) return {};
  return Object.values(coin.value.networks);
});

const handleSelectCoin = (selectedCoin) => {
  coin.value = selectedCoin;
  if (!isEmpty(selectedCoin)) {
    currentActiveStep.value = 2;
    stepLength.value = 2;
  }
};

const handleSelectNetwork = async (selectedNetwork) => {
  network.value = selectedNetwork;

  if (!isEmpty(selectedNetwork)) {
    currentActiveStep.value = 3;
    stepLength.value = 3;

    try {
      addressInfo.value = null;
      const res = await postHttpRequest(store)("/api/deposit/get-address", {
        iso: coin.value.iso3,
        network_type: network.value.id,
      });
      if (res.status) {
        addressInfo.value = {
          success: true,
          ...res.data,
        };
      } else {
        addressInfo.value = {
          success: false,
        };
      }
    } catch (error) {
      console.error(error);
      addressInfo.value = {
        success: false,
      };
    }
  } else {
    currentActiveStep.value = 2;
    stepLength.value = 2;
  }
};

const handleFAQClick = () => {
  showFaqMobile.value = true;
};
</script>
