<template>
  <div class="relative">
    <div
      class="absolute left-0 h-full w-36 sm:w-80 bg-gradient-to-r to-transparent -z-40 dark:from-navy-800 from-white"
    />
    <div
      class="absolute right-0 h-full w-36 sm:w-80 bg-gradient-to-l to-transparent -z-40 dark:from-navy-800 from-white"
    />
    <div class="sm:block absolute w-full h-full -z-50">
      <img
        src="/assets/images/home/backgroud-primary_mobile_ellipase.svg"
        class="w-full h-full object-fill absolute top-0 bottom-0 left-0"
      />
    </div>
    <div class="absolute w-full -z-30 left-0 top-0 max-sm:hidden">
      <div class="mx-auto max-w-[1172px]">
        <animation-player path="/assets/animations/Coins.json" />
      </div>
    </div>
    <div class="absolute w-full -z-30 top-28 sm:hidden">
      <img
        src="/assets/images/home/coin-bg-small.svg"
        class="w-[308px] mx-auto h-auto"
      />
    </div>
    <div class="flex flex-col px-4 mb-96 mx-auto items-center mt-64 w-full">
      <div
        class="text-[40px] sm:leading-[64px] leading-[48px] mb-3 text-center sm:text-5xl font-bold dark:text-white text-navy-800 -tracking-[1.2px]"
      >
        {{ $t("translation.home.main.startJourneyTitle") }}
      </div>
      <div
        class="text-lg sm:text-xl font-normal text-center mb-10 dark:text-navy-50 text-navy-400 -tracking-[0.4px]"
      >
        {{ $t("translation.home.main.startJourneyLabel") }}
      </div>
      <div class="w-fit">
        <fill-button
          size="lg"
          @click="handleTradingClick"
          color="primary"
          width="full"
        >
          {{ $t("translation.home.main.startJourneyButton") }}
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import AnimationPlayer from "@/components/Animation/AnimationPlayer.vue";

const router = useRouter();

const handleTradingClick = () => {
  // router.push({ name: "trading" });
  router.push({ name: "signup" });
};
</script>
