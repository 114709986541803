<template>
  <div
    class="w-full cursor-pointer dark:bg-navy-600 bg-navy-10 rounded-2xl h-32"
    @click="handleClick"
  >
    <input type="file" class="hidden" ref="fileInput" />
    <div
      v-if="loadingStatus === 1"
      class="flex flex-row items-center justify-center dark:text-white text-navy-800 gap-2 py-6"
    >
      <img src="@/assets/icons/spinner.svg" class="w-6 h-6" />
      <div class="text-sm font-medium">Uploading...</div>
    </div>
    <div
      v-else-if="loadingStatus === 0"
      class="flex flex-col items-center justify-center w-full px-5 py-8"
    >
      <div
        class="flex flex-row items-center justify-center text-primary-700 gap-4"
      >
        <photo-icon class="w-10 h-10 stroke-2" />
        <div>Select file</div>
      </div>
      <div
        class="flex flex-row items-center justify-center text-sm font-medium mt-3 text-center"
      >
        (Front) file size must not exceed 10MB
      </div>
    </div>
    <div
      v-else-if="loadingStatus == 2"
      class="flex flex-row items-center justify-center shrink-0"
    >
      <div>
        <img :src="imageUrl" class="w-full h-32" />
      </div>
    </div>
    <button @click="handleClear">clear</button>
  </div>
</template>
<script setup>
import { onMounted, ref, defineEmits } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { PhotoIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const emit = defineEmits(["change"]);

const fileInput = ref();
const data = ref();
const loadingStatus = ref(0);

const imageUrl = ref("");

const handleClear = (e) => {
  data.value = null;
  fileInput.value.value = "";
  loadingStatus.value = 0;
  imageUrl.value = "";
  e.stopPropagation();
};

const handleClick = () => {
  if (loadingStatus.value === 0) {
    fileInput.value.click();
  }
};

onMounted(() => {
  fileInput.value.addEventListener("change", () => {
    if (isEmpty(fileInput.value.files)) {
      return;
    }
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      const reader1 = new FileReader();
      reader.onloadstart = () => {
        loadingStatus.value = 1;
      };
      reader.onload = (e) => {
        loadingStatus.value = 2;
        imageUrl.value = e.target.result;
      };
      reader1.onload = (e) => {
        emit("change", e.target.result);
      };
      reader.readAsDataURL(file);
      reader1.readAsArrayBuffer(file);
    } else {
      store.dispatch("error", "Please select a valid image file.");
    }
  });
});
</script>
