<template>
  <div class="flex flex-col w-full">
    <div class="dark:text-white text-navy-800 text-sm">
      {{ $t("translation.profile.idverify.passportDialog.subTitle") }}
    </div>
    <div class="dark:text-white text-navy-800 text-sm mt-5 mb-2">
      {{ $t("translation.profile.idverify.passportDialog.country") }}
    </div>
    <select-country-dropdown
      v-model="selectedCountry"
      current-location="LT"
      borderWidth="1px"
      borderColor="navy"
      bgColor="navy"
    />
  </div>
  <div class="gap-4 mt-6 flex flex-col">
    <radio-select
      v-for="(item, index) in verifyOptions"
      v-model="selectedVerifyOptions"
      :is-selected="selectedVerifyOptions === index"
      :key="item.id"
      :index="index"
    >
      <template #prefix>
        <div class="flex items-center">
          <img
            :src="currentTheme === 'dark' ? item.imageBlack : item.imageLight"
          />
          <div class="flex flex-center gap-2 items-center">
            <div
              class="ml-4 dark:text-white text-navy-800 font-semibold text-base"
            >
              {{ item.label }}
            </div>
            <div
              v-if="item.recommended"
              class="text-[10px] px-1.5 py-1 rounded-full bg-primary-700 text-white"
            >
              {{ $t("translation.profile.idverify.passportDialog.recommend") }}
            </div>
          </div>
        </div>
      </template>
    </radio-select>
  </div>
  <fill-button class="w-full mt-12" @click="handleClickNextButton">
    {{ $t("translation.profile.idverify.passportDialog.nextButton") }}
  </fill-button>
</template>
<script setup>
import { ref, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import RadioSelect from "@/components/RadioSelect/RadioSelect.vue";
import SelectCountryDropdown from "@/common/SelectCountryDropdown/SelectCountryDropdown.vue";

const { t } = useI18n();
const store = useStore();
const profileData = computed(() => store.getters.profile);
const selectedCountry = ref(profileData?.value?.profile?.country_of_residence);
const emits = defineEmits(["close", "next"]);
const selectedVerifyOptions = ref(0);

const verifyOptions = computed(() => [
  {
    label: t("translation.profile.idverify.passportDialog.options.0"),
    imageBlack: "/assets/images/verification/id-card-black.svg",
    imageLight: "/assets/images/verification/id-card-light.svg",
    id: "id-card",
    recommended: true,
  },
  {
    label: t("translation.profile.idverify.passportDialog.options.1"),
    imageBlack: "/assets/images/verification/passport-black.svg",
    imageLight: "/assets/images/verification/passport-light.svg",
    id: "passport",
    recommended: false,
  },
  {
    label: t("translation.profile.idverify.passportDialog.options.2"),
    imageBlack: "/assets/images/verification/driving-license-black.svg",
    imageLight: "/assets/images/verification/driving-license-light.svg",
    id: "driving-license",
    recommended: false,
  },
]);

const handleClickNextButton = () => {
  store.dispatch("setDocumentInfo", selectedVerifyOptions.value);
  emits("next");
};
</script>
