<template>
  <div class="flex flex-col">
    <div class="text-sm dark:text-white text-navy-800 font-medium">
      {{ $t("translation.profile.idverify.infoDialog.subTitle") }}
    </div>
    <div class="flex flex-col mt-8">
      <div class="flex justify-between gap-6 sm:flex-row flex-col">
        <div class="flex flex-col w-full">
          <div class="dark:text-white text-navy-800 text-sm font-semibold mb-2">
            {{ $t("translation.profile.idverify.infoDialog.firstNameLabel") }}
          </div>
          <text-edit
            type="text"
            rounded="xl"
            :placeholder="
              $t('translation.profile.idverify.infoDialog.firstNamePlaceholder')
            "
            fontSize="base"
            :filled="true"
            v-model="firstName"
            :error="firstNameError"
          >
            <!-- v-model="pairInput" -->
          </text-edit>
        </div>
        <div class="flex flex-col w-full">
          <div class="dark:text-white text-navy-800 text-sm font-semibold mb-2">
            {{ $t("translation.profile.idverify.infoDialog.middleNameLabel") }}
          </div>
          <text-edit
            type="text"
            rounded="xl"
            :placeholder="
              $t(
                'translation.profile.idverify.infoDialog.middleNamePlaceholder'
              )
            "
            fontSize="base"
            :filled="true"
            v-model="middleName"
            :error="middleNameError"
          >
          </text-edit>
        </div>
      </div>
      <div class="flex flex-col w-full mt-6">
        <div class="dark:text-white text-navy-800 text-sm font-semibold mb-2">
          {{ $t("translation.profile.idverify.infoDialog.lastNameLabel") }}
        </div>
        <text-edit
          type="text"
          rounded="xl"
          :placeholder="
            $t('translation.profile.idverify.infoDialog.lastNamePlaceholder')
          "
          fontSize="base"
          :filled="true"
          v-model="lastName"
          :error="lastNameError"
        >
        </text-edit>
      </div>
    </div>
    <fill-button class="w-full mt-12" @click="handleClickNextButton">
      {{ $t("translation.profile.idverify.infoDialog.nextButton") }}
    </fill-button>
  </div>
</template>
<script setup>
import { defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useStore();
const emits = defineEmits(["next", "close"]);
const profile = computed(() => store.getters.profile);
const user_id = computed(() => profile.value?.profile?.id ?? "");
const firstName = ref("");
const firstNameError = ref("");
const middleName = ref("");
const middleNameError = ref("");
const lastName = ref("");
const lastNameError = ref("");

const handleClickNextButton = () => {
  if (!firstName.value.length)
    firstNameError.value = t(
      "translation.profile.idverify.infoDialog.requireError"
    );
  if (!lastName.value.length)
    lastNameError.value = t(
      "translation.profile.idverify.infoDialog.requireError"
    );
  if (firstName.value.length && lastName.value.length) {
    store.dispatch("setUserId", user_id.value);
    store.dispatch("setFirstName", firstName.value);
    store.dispatch("setMiddleName", middleName.value);
    store.dispatch("setLastName", lastName.value);
    emits("next", {
      firstName: firstName.value,
      middleName: middleName.value,
      lastName: lastName.value,
    });
  }
};
</script>
