<template>
  <div
    class="cursor-pointer w-fit flex flex-row items-center gap-2"
    @click="handleClick"
  >
    <div
      :class="[
        'rounded-[5px]  flex items-center justify-center shrink-0 box-border',
        isChecked
          ? 'bg-primary-700 text-white '
          : 'bg-transparent border border-navy-100',
        sizeClass,
      ]"
    >
      <check-icon v-if="isChecked" class="w-4 h-4 stroke-2" />
    </div>
    <span class="font-medium dark:text-navy-100 text-navy-200">{{
      label
    }}</span>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["defaultValue", "label", "size"]);
const sizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "size-4";
    case "md":
    default:
      return "size-5";
  }
});
const emit = defineEmits(["change"]);
const isChecked = ref(props.defaultValue);

const handleClick = () => {
  isChecked.value = !isChecked.value;
  emit("change", isChecked.value);
};
</script>
Suply
