<template>
  <div class="w-full mb-24">
    <div
      class="flex justify-between sm:items-center sm:flex-row flex-col sm:mb-12 mb-6 gap-3"
    >
      <p class="text-2xl font-semibold">
        {{ $t("translation.asset.fundingPage.title") }}
      </p>
      <div class="flex flex-row flex-wrap">
        <div class="flex-1 mr-4">
          <fill-button width="full" size="sm" @click="goToDeposit">
            <deposit-icon />
            <span>{{ $t("translation.asset.fundingPage.depositButton") }}</span>
          </fill-button>
        </div>
        <div class="flex-1 mr-4">
          <outline-button
            width="full"
            size="sm"
            color="primary"
            @click="goToWithdraw"
          >
            {{ $t("translation.asset.fundingPage.withdrawButton") }}
          </outline-button>
        </div>
        <div>
          <dropdown-select alignment="end" @select="goToTransfer">
            <template #header="{ handleContentClick }">
              <div
                class="sm:hidden rounded-full border border-primary-700 text-primary-700 flex items-center justify-center w-10 h-10 shrink-0"
                @click="handleContentClick"
              >
                <ellipsis-vertical-icon class="w-6 h-6" />
              </div>
            </template>
            <template #menu="{ handleMenuItemClick }">
              <div
                class="w-[208px] dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10"
                :style="[
                  currentTheme === 'dark'
                    ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
                    : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
                ]"
              >
                <div
                  class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 text-sm font-medium"
                  @click="handleMenuItemClick(item)"
                >
                  {{ $t("translation.asset.fundingPage.transferButton") }}
                </div>
              </div>
            </template>
          </dropdown-select>
        </div>
        <outline-button
          class="max-sm:hidden"
          size="sm"
          color="primary"
          @click="goToTransfer"
        >
          {{ $t("translation.asset.fundingPage.transferButton") }}
        </outline-button>
      </div>
    </div>
    <div class="flex gap-6 sm:flex-row flex-col-reverse">
      <div class="flex-1">
        <asset-balance size="lg">
          <fill-button
            size="md"
            width="full"
            color="light"
            @click="handleClickTransactionHistory"
          >
            <roll-back-icon class="w-5 h-5" />
            <span>{{
              $t("translation.asset.fundingPage.transactionHistoryButton")
            }}</span>
          </fill-button>
        </asset-balance>
        <div class="h-14"></div>
      </div>
    </div>
    <div
      class="text-base font-semibold dark:text-white text-navy-800 mt-10 sm:mt-14"
    >
      {{ $t("translation.asset.fundingPage.tableTitle") }}
    </div>
    <div
      class="flex sm:flex-row flex-col sm:items-center items-start mt-6 gap-6"
    >
      <div class="sm:w-60 w-full">
        <text-edit
          type="text"
          size="sm"
          rounded="full"
          :placeholder="$t('translation.asset.fundingPage.searchPlaceholder')"
          :filled="false"
          v-model="searchText"
        >
          <template #prefix>
            <div class="mr-2">
              <magnifying-glass-icon
                :class="[
                  searchText.length > 0
                    ? 'dark:text-white text-navy-800'
                    : 'text-navy-200',
                  'w-6 h-6',
                ]"
              />
            </div>
          </template>
          <template #suffix v-if="searchText.length > 0">
            <div class="ml-2" @click="searchText = ''">
              <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
            </div>
          </template>
        </text-edit>
      </div>
      <check-box
        class="text-sm"
        :label="$t('translation.asset.fundingPage.hideButton')"
        @change="handleHideZeroChange"
      />
    </div>
    <table class="w-full mt-6">
      <thead>
        <tr class="h-10">
          <td
            v-for="item in tableHeaderItems"
            :key="item.id"
            :class="[
              'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
              `text-${item.align}`,
              item.hide === false ? '' : `max-${item.hide}:hidden`,
            ]"
          >
            {{ item.label }}
          </td>
        </tr>
      </thead>
      <tbody v-if="fundingBalances.length">
        <tr
          v-for="item in fundingBalances.slice(startIndex, endIndex + 1)"
          :key="item.currency.id"
          class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer"
        >
          <td class="px-3 rounded-l-xl">
            <div class="flex items-center">
              <img :src="item.icon" class="size-6 mr-2 rounded-full" />
              <div class="flex flex-col">
                <div
                  class="text-sm font-semibold dark:text-white text-navy-800"
                >
                  {{ item.currency.iso3 }}
                </div>
                <div class="text-xs font-semibold text-navy-100">
                  {{ item.currency.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-3">
            {{ addCommasToNumber(item.balance_f, -2) }}
          </td>
          <td class="px-3">
            {{ addCommasToNumber(item.balance_available_f, -2) }}
          </td>
          <td class="px-3 max-sm:hidden">
            {{ 0.0 }}
          </td>
          <td class="px-3 rounded-r-xl max-sm:hidden">
            <div class="flex flex-row items-center justify-end gap-3">
              <link-button
                :label="$t('translation.asset.fundingPage.tableActions.0')"
                @click="goToDeposit"
              />
              <span class="dark:text-navy-300 text-navy-50">|</span>
              <link-button
                :label="$t('translation.asset.fundingPage.tableActions.1')"
                @click="goToWithdraw"
              />
              <span class="dark:text-navy-300 text-navy-50">|</span>
              <link-button
                @click="goToTransferWithToken(item)"
                :label="$t('translation.asset.fundingPage.tableActions.2')"
              />
              <dropdown-select alignment="end" @select="handleSelect">
                <template #header="{ handleContentClick }">
                  <div
                    class="w-8 h-8 flex items-center justify-center rounded-full cursor-pointer dark:hover:bg-navy-300 hover:bg-navy-50"
                    @click="handleContentClick"
                  >
                    <ellipsis-vertical-icon class="w-6 h-6" />
                  </div>
                </template>
                <template #menu="{ handleMenuItemClick }">
                  <div
                    class="w-[208px] dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10"
                    :style="[
                      currentTheme === 'dark'
                        ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
                        : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
                    ]"
                  >
                    <div
                      class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 text-sm font-medium"
                      @click="handleMenuItemClick(item)"
                    >
                      {{ $t("translation.asset.fundingPage.tableActions.3") }}
                    </div>
                  </div>
                </template>
              </dropdown-select>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="fundingBalances.length === 0" class="w-full">
      <div class="h-40 flex flex-col items-center justify-center w-full gap-3">
        <div>
          <double-coin-icon class="w-8 h-8 dark:text-navy-400 text-navy-300" />
        </div>
        <div class="text-navy-100 font-semibold text-sm">
          {{ $t("translation.asset.fundingPage.noData") }}
        </div>
      </div>
    </div>
    <div class="w-full">
      <pagination-component
        class="mt-12"
        :total="Math.ceil(fundingBalances.length / 10)"
        :initial-page="1"
        :step-disabled="true"
        :step="10"
        @change="handlePageChange"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { addCommasToNumber } from "@/utils/NumberFormating";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";
import RollBackIcon from "@/assets/icons/common/RollBackIcon.vue";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const searchText = ref("");
const hideZero = ref(false);
const startIndex = ref(0);
const endIndex = ref(9);

const handleHideZeroChange = (value) => {
  hideZero.value = value;
};

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const fundingBalances = computed(() => {
  const balances = store.getters.balancesVolume?.list ?? [];
  const fundingBalance = balances.filter(
    (item) => item.deposit_type === "funding"
  );
  const search = searchText.value.toLowerCase();
  return fundingBalance
    .map((item) => ({
      ...item,
      balance_f: item.balance / Math.pow(10, item.currency.decimal),
      balance_available_f:
        item.balance_available / Math.pow(10, item.currency.decimal),
      icon: activeCurrencies.value[item.currency.id]?.icon ?? "",
    }))
    .filter((item) => {
      return (
        (hideZero.value ? item.balance > 0 : true) &&
        (item.currency.iso3.toLowerCase().includes(search) ||
          item.currency.name.toLowerCase().includes(search))
      );
    });
});

const handleClickTransactionHistory = () => {
  router.push({ name: "asset-transaction-history" });
};

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const goToWithdraw = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const goToTransfer = () => {
  store.dispatch("setTransferData", {});
};

const goToTransferWithToken = (token) => {
  if (activeCurrencies.value) {
    store.dispatch("setTransferData", {
      currency: activeCurrencies.value[token.currency.id],
    });
  }
};

const handleSelect = () => {
  router.push({ name: "buy-sell" });
};

const handlePageChange = ([currentPage, pageSize]) => {
  startIndex.value = (currentPage - 1) * pageSize;
  endIndex.value = currentPage * pageSize - 1;
};

const tableHeaderItems = computed(() => [
  {
    id: 1,
    label: t("translation.asset.fundingPage.tableHeaders.0"),
    align: "left",
    hide: false,
  },
  {
    id: 2,
    label: t("translation.asset.fundingPage.tableHeaders.1"),
    align: "left",
    hide: false,
  },
  {
    id: 3,
    label: t("translation.asset.fundingPage.tableHeaders.2"),
    align: "left",
    hide: false,
  },
  {
    id: 4,
    label: t("translation.asset.fundingPage.tableHeaders.3"),
    align: "left",
    hide: "lg",
  },
  {
    id: 5,
    label: t("translation.asset.fundingPage.tableHeaders.4"),
    align: "right",
    hide: "md",
  },
]);
</script>
