<template>
  <dropdown-select width="full" @select="handleSelect">
    <template #header="{ handleContentClick, isShowingMenu }">
      <div
        class="flex flex-row items-center justify-between border dark:border-navy-400 border-navy-50 rounded-xl w-full min-w-32 cursor-pointer"
        :class="`${sizeClass}`"
        @click="handleContentClick"
      >
        <div
          :class="[
            model ? 'dark:text-white text-navy-800' : 'text-navy-100',
            'mr-2',
            headerFontSizeClass,
          ]"
        >
          {{ model ? model.label : props.placeholder }}
        </div>
        <div class="dark:text-white text-navy-800 cursor-pointer">
          <chevron-up-icon v-if="isShowingMenu" class="w-4 h-4" />
          <chevron-down-icon v-else class="w-4 h-4" />
        </div>
      </div>
    </template>
    <template #menu="{ handleMenuItemClick }">
      <div
        class="w-full dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10 custom-scrollbar max-h-96"
        :class="menuFontSizeClass"
        :style="[
          currentTheme === 'dark'
            ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
            : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
        ]"
      >
        <div
          v-for="item in props.items"
          :key="item.id"
          class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 flex flex-row justify-between"
          :class="
            (model?.label === item.label && props.isSectedVisible) === true
              ? 'dark:bg-navy-500 rounded-lg bg-navy-10'
              : 'dark:hover:bg-navy-500 hover:bg-navy-10'
          "
          @click="handleMenuItemClick(item)"
        >
          <div class="flex items-center">
            <component :is="item.icon" class="w-5 h-5 mr-3" />
            <div class="text-sm font-medium">{{ item.label }}</div>
          </div>
          <span
            v-if="model?.label === item.label && props.isSectedVisible"
            class="text-primary-700 w-5 h-5"
          >
            <check-circle-icon />
          </span>
        </div>
      </div>
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps, defineModel, computed } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";

const props = defineProps([
  "placeholder",
  "items",
  "size",
  "headerFontSize",
  "MenuFontSize",
  "isSectedVisible",
]);

const model = defineModel();
const handleSelect = (value) => {
  model.value = value;
};

const sizeClass = computed(() => {
  switch (props.size) {
    case "lg":
      return "p-12";
    case "md":
      return "p-6";
    case "sm":
      return "p-4";
    case "xs":
      return "p-3";
    default:
      return "p-3";
  }
});

const menuFontSizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "text-sm font-medium";
    default:
      return "px-3";
  }
});

const headerFontSizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "text-base font-medium";
    default:
      return "text-sm font-medium";
  }
});
</script>
