<template>
  <div class="flex flex-col w-full mb-24">
    <div class="w-full flex flex-row items-center justify-between">
      <div class="text-2xl font-semibold dark:text-white text-navy-800">
        {{ $t("translation.profile.security.title") }}
      </div>
      <div class="flex flex-row items-center gap-3">
        <div class="dark:text-white text-xs font-medium text-navy-300">
          {{ $t("translation.profile.security.statusLabel") }}
        </div>
        <div
          :class="[
            'flex flex-row items-center px-2 py-1.5 bg-green-200 text-green-900 rounded-full gap-2',
            !is_risky
              ? 'bg-green-200 text-green-900'
              : 'bg-red-200 text-red-900',
          ]"
        >
          <shield-check-icon class="size-5 stroke-2" />
          <div class="text-sm font-semibold">
            {{ !is_risky ? "High" : "Low" }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full mt-8 sm:mt-12">
      <profile-list-item
        :icon="LoginPassword"
        :title="$t('translation.profile.security.passwordTitle')"
        :comment="$t('translation.profile.security.passwordLabel')"
        mode="vertical"
      >
        <outline-button
          size="sm"
          color="primary"
          width="full"
          @click="goToChangeLoginPassword"
        >
          {{ $t("translation.profile.security.changeButton") }}
        </outline-button>
      </profile-list-item>

      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />

      <profile-list-item
        :icon="DevicePhoneMobileIcon"
        :title="$t('translation.profile.security.phoneTitle')"
        :comment="$t('translation.profile.security.phoneLabel')"
        mode="vertical"
      >
        <template #default>
          <outline-button
            size="sm"
            color="primary"
            @click="handleChangePhoneClick"
            width="full"
          >
            {{ $t("translation.profile.security.changeButton") }}
          </outline-button>
        </template>
        <template #detail>
          <div
            class="flex flex-row items-center mr-6"
            v-if="!isEmpty(user_phone)"
          >
            <check-circle-icon class="text-green-500 size-4 mr-2" />
            <div class="text-sm font-semibold dark:text-white text-navy-300">
              {{ user_phone }}
            </div>
          </div>
        </template>
      </profile-list-item>

      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />

      <profile-list-item
        :icon="EnvelopeIcon"
        :title="$t('translation.profile.security.emailTitle')"
        :comment="$t('translation.profile.security.emailLabel')"
        mode="vertical"
      >
        <template #default>
          <outline-button
            size="sm"
            color="primary"
            @click="handleChangeEmail"
            width="full"
          >
            {{ $t("translation.profile.security.changeButton") }}
          </outline-button>
        </template>
        <template #detail>
          <div
            class="flex flex-row items-center mr-6"
            v-if="!isEmpty(user_email)"
          >
            <check-circle-icon class="text-green-500 size-4 mr-2" />
            <div class="text-sm font-semibold dark:text-white text-navy-300">
              {{ user_email }}
            </div>
          </div>
        </template>
      </profile-list-item>
    </div>

    <div class="text-2xl font-semibold dark:text-white text-navy-800 mt-16">
      {{ $t("translation.profile.security.advancedTitle") }}
    </div>

    <div class="flex flex-col w-full mt-8 sm:mt-12">
      <profile-list-item
        :icon="LoginPassword"
        :title="$t('translation.profile.security.tfaTitle')"
        :comment="$t('translation.profile.security.tfaLabel')"
        mode="vertical"
      >
        <template #default>
          <outline-button
            size="sm"
            color="primary"
            @click="handleChange2FA"
            width="full"
          >
            {{ $t("translation.profile.security.changeButton") }}
          </outline-button>
        </template>
        <template #detail>
          <div
            class="flex flex-row items-center mr-6"
            v-if="google_enabled === 1"
          >
            <check-circle-icon class="text-green-500 size-4 mr-2" />
            <div class="text-sm font-semibold dark:text-white text-navy-300">
              {{ $t("translation.profile.security.statusEnabled") }}
            </div>
          </div>
        </template>
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="DevicePhoneMobileIcon"
        :title="$t('translation.profile.security.accountTitle')"
        :comment="$t('translation.profile.security.accountLabel')"
        mode="vertical"
      >
        <outline-button size="sm" color="primary" width="full">
          {{ $t("translation.profile.security.changeButton") }}
        </outline-button>
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="EnvelopeIcon"
        :title="$t('translation.profile.security.protectTitle')"
        :comment="$t('translation.profile.security.protectLabel')"
        mode="vertical"
      >
        <outline-button size="sm" color="primary" width="full">
          {{ $t("translation.profile.security.changeButton") }}
        </outline-button>
      </profile-list-item>
    </div>

    <div class="text-2xl font-semibold dark:text-white text-navy-800 mt-16">
      {{ $t("translation.profile.security.marketTitle") }}
    </div>

    <div class="flex flex-col w-full mt-8 sm:mt-12">
      <profile-list-item
        :icon="TrashIcon"
        :title="$t('translation.profile.security.deleteTitle')"
        :comment="$t('translation.profile.security.deleteLabel')"
        mode="vertical"
      >
        <outline-button
          size="sm"
          color="red"
          @click="handleDeleteClick"
          width="full"
        >
          {{ $t("translation.profile.security.deleteButton") }}
        </outline-button>
      </profile-list-item>
    </div>
  </div>
  <security-two-factor-dialog
    v-if="show2FADialog"
    v-model="show2FADialog"
    :qr_code="qr_code"
    :secret="secret"
  />
  <security-delete-reason-dialog
    v-if="showDeleteReasonDialog"
    v-model="showDeleteReasonDialog"
    @confirm="handleDeleteReasonConfirm"
  />
  <security-delete-not-allowed-dialog
    v-if="showDeleteNotDialog"
    v-model="showDeleteNotDialog"
  />
  <security-delete-ask-dialog
    v-if="showDeleteAskDialog"
    v-model="showDeleteAskDialog"
    @confirm="handleDeleteConfirm"
  />
  <security-delete-password-dialog
    v-if="showDeletePasswordDialog"
    v-model="showDeletePasswordDialog"
  />
  <security-disable-two-factor-dialog
    v-if="showDisable2FADialog"
    v-model="showDisable2FADialog"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { postHttpRequest } from "@/api/api";
import ProfileListItem from "@/views/Profile/Items/ProfileListItem.vue";
import LoginPassword from "@/assets/icons/common/LoginPassword.vue";
import SecurityTwoFactorDialog from "@/views/Profile/Dialogs/SecurityTwoFactorDialog.vue";
import SecurityDeleteReasonDialog from "@/views/Profile/Dialogs/SecurityDeleteReasonDialog.vue";
import SecurityDeleteNotAllowedDialog from "@/views/Profile/Dialogs/SecurityDeleteNotAllowedDialog.vue";
import SecurityDeleteAskDialog from "@/views/Profile/Dialogs/SecurityDeleteAskDialog.vue";
import SecurityDeletePasswordDialog from "@/views/Profile/Dialogs/SecurityDeletePasswordDialog.vue";
import SecurityDisableTwoFactorDialog from "@/views/Profile/Dialogs/SecurityDisableTwoFactorDialog.vue";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  TrashIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline";
import { isEmpty } from "@/utils/utils";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const show2FADialog = ref(false);
const showDeleteReasonDialog = ref(false);
const showDeleteNotDialog = ref(false);
const showDeleteAskDialog = ref(false);
const showDeletePasswordDialog = ref(false);
const showDisable2FADialog = ref(false);
const qr_code = ref("");
const secret = ref("");

const goToChangeLoginPassword = () => {
  router.push({ name: "change-login-password" });
};
const profile = computed(() => store.getters.profile);
const is_risky = computed(() => profile.value?.profile?.isRisky ?? false);
const user_email = computed(() => profile.value?.profile?.email_masked);
const user_phone = computed(() => profile.value?.profile?.phone);
const google_enabled = computed(
  () => profile.value?.security?.google_code ?? 0
);

const requestQRCode = async () => {
  let status = 0;
  if (google_enabled.value === 1) {
    status = 0;
  } else {
    status = 1;
  }
  const res = await postHttpRequest(store)("/api/account/change-login-type", {
    status,
    send_to_email: 1,
    send_to_phone: 1,
    type: 2,
  });
  if (res.status) {
    if (google_enabled.value == 1) {
      return true;
    } else if (!isEmpty(res.response.google_2fa)) {
      qr_code.value = res.response.google_2fa.qr_code;
      secret.value = res.response.google_2fa.secret;
      return true;
    }
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: t("translation.profile.security.errorToast"),
    });
    return false;
  }
};

const handleDeleteClick = () => {
  showDeleteReasonDialog.value = true;
};

const handleDeleteReasonConfirm = () => {
  showDeleteAskDialog.value = true;
};

const handleChangePhoneClick = () => {
  router.push({ name: "change-phone" });
};

const handleDeleteConfirm = () => {
  showDeletePasswordDialog.value = true;
};

const handleChangeEmail = () => {};

const handleChange2FA = async () => {
  const result = await requestQRCode();
  if (result === true) {
    if (google_enabled.value == 0) {
      show2FADialog.value = true;
    } else {
      showDisable2FADialog.value = true;
    }
  }
};
</script>
