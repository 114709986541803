<template>
  <div v-if="isNotVerified && isLoggedIn" class="w-full">
    <alert-box
      type="danger"
      width="full"
      title="Complete KYC verification to have full access to all features of EXZi and unlock transaction limits"
      button-label="Verify Now"
      @click="handleClickVerify"
    />
    <dialog-view
      v-if="showDialog"
      title="Verify with EXZi App"
      :is-open="showDialog"
      bg-color="gray"
      :bg-close="true"
      @change-is-open="handleChangeIsOpen"
    >
      <template #content>
        <div class="dark:text-white text-navy-800 mt-6">
          <p class="text-sm font-medium">
            Scan the QR code to download the EXZi app for the verification.
          </p>
          <div
            v-if="!isEmpty(imageData)"
            class="mt-10 dark:bg-white p-5 w-fit rounded-3xl m-auto bg-navy-10"
          >
            <img :src="imageData" alt="QR code" />
          </div>
          <div class="flex items-center mt-10 w-full">
            <div class="flex-auto h-px dark:bg-navy-300 bg-navy-50"></div>
            <p class="p-4 text-navy-200 text-center text-xs">
              or continue with
            </p>
            <div class="flex-auto h-px dark:bg-navy-300 bg-navy-50"></div>
          </div>
          <p
            class="mt-6 text-primary-700 dark:text-white flex gap-2 items-center mx-auto w-fit cursor-pointer"
            @click="handleClickBrowser"
          >
            <span>Web Browser</span>
            <arrow-right-icon class="stroke-2 w-5 h-5" />
          </p>
        </div>
      </template>
    </dialog-view>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getHttpRequest } from "@/api/api";
import { isEmpty } from "@/utils/utils";
import session_manager from "@/utils/SessionManager";
import DialogView from "@/components/Dialog/DialogView.vue";
import AlertBox from "@/components/Alert/AlertBox.vue";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";

const showDialog = ref(false);
const imageData = ref("");
const router = useRouter();
const store = useStore();
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const isNotVerified = computed(
  () => store.getters.verificationInfo?.verification_state === "NOT_VERIFIED"
);

const handleClickVerify = async () => {
  const privateKey = session_manager.getPrivateKey();

  if (!isEmpty(privateKey)) {
    const url = encodeURIComponent(
      `"http://localhost:8080/verification?privateKey=${privateKey}&verificationStep=${isNotVerified.value}"`
    );
    const res = await getHttpRequest(store)(`/api/qr?address=${url}`);
    showDialog.value = true;
    imageData.value = res.data;
  }
};

const handleChangeIsOpen = () => {
  showDialog.value = !showDialog.value;
};

const handleClickBrowser = () => {
  showDialog.value = false;
  router.push({ path: "/profile/identity-verification" });
};
</script>
