<template>
  <div
    :class="[
      'rounded-lg flex flex-col sm:flex-row sm:justify-between dark:bg-navy-500 bg-primary-100 w-full',
      paddingClass,
    ]"
  >
    <div class="flex flex-col">
      <div class="flex flex-row items-center dark:text-navy-100 text-navy-300">
        <span :class="['mr-2 text-sm font-medium']">Total asset value</span>
        <div @click="showAssetProperty = !showAssetProperty">
          <eye-icon
            v-if="showAssetProperty"
            :class="[
              'w-4 h-4 cursor-pointer',
              props.size === 'lg' ? 'w-5 h-5' : 'w-4 h-4',
            ]"
          />
          <eye-slash-icon
            v-else
            :class="[
              'w-4 h-4 cursor-pointer',
              props.size === 'lg' ? 'w-5 h-5' : 'w-4 h-4',
            ]"
          />
        </div>
      </div>
      <div v-if="!isEmpty(balanceCurrencyList)">
        <div class="flex flex-row items-center dark:text-white text-navy-800">
          <span
            :class="[
              'mr-1 font-bold',
              props.size === 'lg' ? 'text-[32px]' : 'text-xl',
            ]"
            >{{ showAssetProperty ? selectedBalance : "*****" }}</span
          >
          <dropdown-select @select="handleCurrencySelect">
            <template
              #header="{ handleContentClick: handleIconClick, isShowingMenu }"
            >
              <div
                class="flex flex-row items-center dark:text-white text-navy-800 cursor-pointer"
                @click="handleIconClick"
              >
                <span
                  :class="[
                    'mr-1',
                    props.size === 'lg' ? 'text-base' : 'text-sm',
                  ]"
                  >{{ currentSelectedCurrency }}
                </span>
                <div>
                  <chevron-up-icon
                    v-if="isShowingMenu"
                    class="stroke-2 w-3 h-3 cursor-pointer"
                  />
                  <chevron-down-icon
                    v-else
                    class="stroke-2 w-3 h-3 cursor-pointer"
                  />
                </div>
              </div>
            </template>
            <template #menu="{ handleMenuItemClick }">
              <div
                class="flex flex-col w-fit dark:bg-navy-400 bg-primary-300 p-2 dark:text-white text-navy-800 text-sm font-medium gap-1 rounded-lg"
              >
                <div
                  v-for="item in balanceCurrencyList"
                  :key="item"
                  class="py-1 px-2 cursor-pointer dark:hover:bg-navy-300 hover:bg-primary-500 rounded-md"
                  @click="handleMenuItemClick(item)"
                >
                  {{ item }}
                </div>
              </div>
            </template>
          </dropdown-select>
        </div>
        <div
          :class="[
            'text-sm ',
            selectedPnl >= 0 ? 'text-trade-buy' : 'text-red-600',
          ]"
          v-if="showAssetProperty"
        >
          <span class="mr-1">{{ addPlus(selectedPnl) }}</span>
          <span>({{ addPlus(fixLengthNum(selectedPercent, 4)) }}%)</span>
        </div>
        <div v-else class="text-sm text-trade-buy">*****</div>
      </div>
    </div>
    <div class="sm:w-fit w-full max-sm:mt-4">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { addPlus, fixLengthNum } from "@/utils/NumberFormating";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import EyeSlashIcon from "@/assets/icons/header/EyeSlashIcon.vue";
import EyeIcon from "@/assets/icons/header/EyeIcon.vue";

const props = defineProps(["size"]);
const store = useStore();

const paddingClass = computed(() => {
  return props.size === "lg" ? "p-6" : "p-4";
});

const balanceList = computed(() => store.getters.balancesVolume?.total ?? {});
const pnlList = computed(() => store.getters.balancesVolume?.pnl ?? {});
const balanceCurrencyList = computed(() => Object.keys(balanceList.value));

const currentSelectedCurrency = ref(
  balanceCurrencyList.value ? balanceCurrencyList.value[0] : ""
);

watch(
  () => balanceCurrencyList.value,
  (newValue) => {
    if (!isEmpty(newValue)) {
      currentSelectedCurrency.value = newValue[0];
    }
  }
);

const selectedBalance = computed(() => {
  if (isEmpty(balanceList.value)) {
    return 0;
  }
  const item = balanceList.value[currentSelectedCurrency.value];
  return parseFloat(item) / 1e8;
});

const selectedPnl = computed(() => {
  if (isEmpty(balanceList.value)) {
    return 0;
  }
  const item = pnlList.value[currentSelectedCurrency.value];
  return parseFloat(item) / 1e8;
});

const selectedPercent = computed(() => {
  if (isEmpty(selectedBalance.value) || selectedBalance.value === 0) {
    return 0;
  }
  return (selectedPnl.value / selectedBalance.value) * 100;
});

const showAssetProperty = ref(true);

const handleCurrencySelect = (item) => {
  currentSelectedCurrency.value = item;
};
</script>
