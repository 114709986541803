<template>
  <div ref="custom-shape" :class="['rounded-[32px] h-[268px] relative']">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="elementWidth"
      :height="elementHeight"
      :viewBox="`0 0 ${elementWidth} ${elementHeight}`"
      fill="none"
      class="absolute z-10 pointer-events-none"
    >
      <path
        :d="elementPath"
        :stroke="currentTheme === 'dark' ? '#333C57' : '#D2EDF9'"
        stroke-dasharray="9 9"
      />
    </svg>
    <div
      :class="[
        'w-full flex flex-col px-12 py-6 h-full rounded-[32px]',
        bgColorClass,
      ]"
    >
      <div
        :class="[
          titleColorClass,
          'font-semibold text-xl leading-8 -tracking-[0.4px]',
        ]"
      >
        {{ title }}
      </div>
      <div
        :class="[labelColorClass, 'font-medium text-sm -tracking-[0.2px] mt-1']"
      >
        {{ label }}
      </div>
      <div class="grow" />
      <div class="flex flex-row items-center gap-2">
        <div
          :class="[
            pointColorClass,
            'font-semibold text-[32px] leading-10 -tracking-[0.8px]',
          ]"
        >
          +{{ point }}
        </div>
        <div
          :class="[
            pointLabelColorClass,
            'font-normal text-lg -tracking-[0.4px]',
          ]"
        >
          {{ $t("translation.rewardsHub.pointUnit") }}
        </div>
      </div>

      <div class="w-full mt-8">
        <fill-button color="primary" size="ml" width="full" :disabled="!active">
          {{ $t("translation.rewardsHub.claimButton") }}
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  computed,
  onMounted,
  useTemplateRef,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";

const props = defineProps({
  title: String,
  label: String,
  point: Number,
  active: Boolean,
});

const store = useStore();
const currentTheme = computed(() => store.getters.currentTheme);
const elementRef = useTemplateRef("custom-shape");
const elementHeight = ref(268);
const elementWidth = ref(528);
const elementPath = ref(
  "M 32 0 A32 32 -30 0 0 0 32 L0 102 A32 32 -30 0 1 0 166 L0 234 A32 32 -30 0 0 32 268 L496 268 A32 32 -30 0 0 528 234 L528 166 A32 32 102 0 1 528 102 L 528 32 A32 32 -30 0 0 496 0 L32 0 Z"
);

onMounted(() => {
  window.addEventListener("resize", calculateClipPath);
  calculateClipPath();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", calculateClipPath);
});

const calculateClipPath = () => {
  const height = elementRef.value.clientHeight;
  const width = elementRef.value.clientWidth;
  elementHeight.value = elementRef.value.clientHeight;
  elementWidth.value = elementRef.value.clientWidth;
  const startY = (height - 64) / 2;
  const endY = startY + 64;
  elementPath.value = `M 32 0 A32 32 -30 0 0 0 32 L0 ${startY} A32 32 -30 0 1 0 ${endY} L0 ${
    height - 32
  } A32 32 -30 0 0 32 ${height} L${
    width - 32
  } ${height} A32 32 -30 0 0 ${width} ${
    height - 32
  } L${width} ${endY} A32 32 ${startY} 0 1 ${width} ${startY} L${width} 32 A32 32 -30 0 0 ${
    width - 32
  } 0 L32 0 Z`;
  elementRef.value.style.clipPath = `path('M 0 0 L 0 ${startY} A 32 32 -30 0 1 0 ${endY} L 0 ${height} L ${width} ${height} L ${width} ${endY} A 32 32 ${startY} 0 1 ${width} ${startY} L ${width} 0 L 0 0 Z')`;
};

const bgColorClass = computed(() => {
  return currentTheme.value === "dark"
    ? "bg-navy-600 "
    : props.active === true
    ? "bg-primary-100"
    : "bg-navy-10";
});

const titleColorClass = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-white"
      : "text-navy-200"
    : props.active === true
    ? "text-navy-300"
    : "text-navy-100";
});

const labelColorClass = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-navy-100"
      : "text-navy-200"
    : props.active === true
    ? "text-navy-200"
    : "text-navy-100";
});

const pointColorClass = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-white"
      : "text-navy-200"
    : props.active === true
    ? "text-primary-800"
    : "text-navy-100";
});

const pointLabelColorClass = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-navy-100"
      : "text-navy-200"
    : props.active === true
    ? "text-primary-800"
    : "text-navy-100";
});
</script>
