const state = {
  showPuzzle: false,
};

const mutations = {
  SET_SHOW_PUZZLE(state, showPuzzle) {
    state.showPuzzle = showPuzzle;
  },
};

const actions = {
  setShowPuzzle({ commit }, showPuzzle) {
    commit("SET_SHOW_PUZZLE", showPuzzle);
  },
};

const getters = {
  showPuzzle: (state) => state.showPuzzle,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
