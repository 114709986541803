<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-navy-800 bg-opacity-[72%] flex items-center justify-center z-[200]"
    @click.self="handleOutsideClick"
  >
    <div
      :class="[
        'w-full max-h-[100vh] flex flex-col',
        maxSize ? maxSize : 'max-w-xl',
        bgColorClass,
        showOnlyContent
          ? ''
          : 'm-4 bg-white border dark:border-navy-400 rounded-3xl shadow-xl',
      ]"
    >
      <div
        v-if="containHeader"
        class="flex justify-between items-center px-6 py-4"
      >
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ title }}
        </h3>
        <button
          @click="closeDialog"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <x-mark-icon class="size-8" />
        </button>
      </div>
      <div v-else>
        <slot name="header"></slot>
      </div>
      <div
        :class="['custom-scrollbar h-full', showOnlyContent ? '' : 'px-6 pb-6']"
      >
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  watch,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  title: String,
  isOpen: Boolean,
  containHeader: {
    type: Boolean,
    default: true,
  },
  maxSize: String,
  bgColor: String,
  bgClose: {
    type: Boolean,
    default: true,
  },
  showOnlyContent: Boolean,
});

const bgColorClass = computed(() => {
  if (props.showOnlyContent === true) {
    return "bg-transparent";
  }
  switch (props.bgColor) {
    case "gray":
      return "dark:bg-navy-500";
    case "black":
    default:
      return "dark:bg-navy-700";
  }
});

const emits = defineEmits(["changeIsOpen"]);

const closeDialog = () => {
  emits("changeIsOpen");
};

const handleOutsideClick = () => {
  if (props.bgClose === true) {
    emits("changeIsOpen");
  }
};

onBeforeMount(() => {
  if (props.isOpen) {
    document.body.classList.add("no-scroll");
  }
});

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
});

watch(
  () => props.isOpen,
  (newVal) => {
    if (newVal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }
);
</script>
