<template>
  <div>
    <dropdown-select width="full" @select="handleSelectCountry">
      <template #header="{ handleContentClick, isShowingMenu }">
        <div
          :class="[
            isShowingMenu ? 'border-primary-700' : borderColorClass,
            borderWidthClass,
            bgColorClass,
            'p-4 flex justify-between cursor-pointer rounded-xl items-center',
          ]"
          @click="handleContentClick(true)"
        >
          <div class="flex">
            <img
              :src="getLocationFlagsFromAlpha2(selectedCountry)"
              class="w-9 h-6"
            />
            <p class="ml-4 dark:text-white text-navy-800">
              {{ getLocationNameFromAlpha2(selectedCountry) }}
            </p>
          </div>
          <chevron-down-icon v-if="!isShowingMenu" class="w-5 h-5" />
          <chevron-up-icon v-else class="w-5 h-5" />
        </div>
      </template>
      <template #menu="{ handleMenuItemClick }">
        <div
          class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-2 dark:shadow-none shadow-md h-[279px] overflow-hidden overflow-y-scroll custom-scrollbar"
        >
          <div class="flex flex-col">
            <div class="text-navy-200 text-sm">current location</div>
            <div
              class="flex pl-3 py-4 hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
            >
              <img
                :src="getLocationFlagsFromAlpha2(currentLocation)"
                class="w-9 h-6"
              />
              <p class="ml-4 dark:text-white text-navy-800">
                {{ getLocationNameFromAlpha2(currentLocation) }}
              </p>
            </div>
          </div>
          <div class="flex flex-col mt-6">
            <div class="text-navy-200 text-sm">countries</div>
            <div
              class="flex pl-3 py-4 hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
              v-for="item in locationNames"
              :key="item.key"
              @click="handleMenuItemClick(item.key)"
            >
              <img
                :src="getLocationFlagsFromAlpha2(item.key)"
                class="w-9 h-6"
              />
              <p class="ml-4 dark:text-white text-navy-800">
                {{ getLocationNameFromAlpha2(item.key) }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </dropdown-select>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";

const emits = defineEmits(["select"]);
const store = useStore();
const locationNames = computed(() => store.getters.locationNames);
const locationFlags = computed(() => store.getters.locationFlags);
const props = defineProps({
  borderWidth: String,
  borderColor: String,
  bgColor: String,
});
const profileData = computed(() => store.getters.profile);
const currentLocation = ref(profileData?.value?.profile?.country_of_residence);
const selectedCountry = ref(profileData?.value?.profile?.country_of_residence);
const getLocationNameFromAlpha2 = (value) => {
  var name;
  locationNames.value?.forEach((item) => {
    if (item.key === value) {
      name = item.label.EN;
    }
  });
  return name;
};

const getLocationFlagsFromAlpha2 = (value) => {
  return locationFlags.value[value]?.url;
};

const handleSelectCountry = (value) => {
  selectedCountry.value = value;
  emits("select", value);
};

const borderWidthClass = computed(() => {
  return `border-[${props.borderWidth}]`;
});

const borderColorClass = computed(() => {
  switch (props.borderColor) {
    case "navy":
      return "dark:border-navy-400 border-navy-50";
    default:
      return `border-${props.borderColor}`;
  }
});

const bgColorClass = computed(() => {
  switch (props.bgColor) {
    case "green":
      return "bg-green-400 text-white hover:bg-green-500";
    case "red":
      return "bg-red-400 text-white hover:bg-red-500";
    case "navy":
      return "dark:bg-navy-500 bg-white";
    case "light":
      return "dark:bg-navy-400 dark:hover:bg-navy-300 bg-primary-300 hover:bg-primary-200 dark:text-white text-primary-800";
    case "primary":
    default:
      return "bg-primary-700 text-white hover:bg-primary-800";
  }
});
</script>
