<template>
  <div class="w-full mb-24">
    <div
      class="flex justify-between sm:items-center sm:flex-row flex-col sm:mb-12 mb-6 gap-3"
    >
      <p class="text-2xl font-semibold">
        {{ $t("translation.asset.overviewPage.title") }}
      </p>
      <div class="flex flex-row">
        <div class="shrink-0 grow mr-4">
          <fill-button width="full" size="sm" @click="goToDeposit">
            <deposit-icon />
            <span>{{
              $t("translation.asset.overviewPage.depositButton")
            }}</span>
          </fill-button>
        </div>
        <div class="mr-4 shrink-0 grow">
          <outline-button
            width="full"
            size="sm"
            color="primary"
            @click="goToWithdraw"
          >
            {{ $t("translation.asset.overviewPage.withdrawButton") }}
          </outline-button>
        </div>
        <div class="mr-4 shrink-0 grow max-sm:hidden">
          <outline-button
            width="full"
            size="sm"
            color="primary"
            @click="goToBuySell"
          >
            {{ $t("translation.asset.overviewPage.buyCryptoButton") }}
          </outline-button>
        </div>
        <div class="mr-4 shrink-0 grow max-sm:hidden">
          <outline-button size="sm" color="primary" @click="goToTransfer">
            {{ $t("translation.asset.overviewPage.transferButton") }}
          </outline-button>
        </div>
        <div>
          <dropdown-select alignment="end" @select="goToTransfer">
            <template #header="{ handleContentClick }">
              <div
                class="sm:hidden rounded-full border border-primary-700 text-primary-700 flex items-center justify-center w-10 h-10 shrink-0"
                @click="handleContentClick"
              >
                <ellipsis-vertical-icon class="w-6 h-6" />
              </div>
            </template>
            <template #menu="{ handleMenuItemClick }">
              <div
                class="w-[208px] dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10"
                :style="[
                  currentTheme === 'dark'
                    ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
                    : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
                ]"
              >
                <div
                  class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 text-sm font-medium"
                  @click="goToBuySell"
                >
                  {{ $t("translation.asset.overviewPage.buyCryptoButton") }}
                </div>
                <div
                  class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 text-sm font-medium"
                  @click="handleMenuItemClick(item)"
                >
                  {{ $t("translation.asset.overviewPage.transferButton") }}
                </div>
              </div>
            </template>
          </dropdown-select>
        </div>
      </div>
      <asset-balance class="sm:hidden block" size="lg" />
    </div>
    <div class="flex gap-6 sm:flex-row flex-col-reverse">
      <div class="flex-1">
        <asset-balance size="lg" class="sm:block hidden" />
        <div class="h-14"></div>
        <underline-tab
          :items="tabItems"
          :default-selected="tabItems[0]"
          @change="handleChangeTab"
        />
        <template v-if="selectedTab.id == 1">
          <account-view class="mt-6 mb-14" />
          <recent-transactions />
        </template>
        <template v-else>
          <coin-view />
        </template>
      </div>
      <div
        class="flex sm:flex-col flex-row gap-10 overflow-auto custom-scrollbar"
      >
        <asset-movements />
        <balance-details />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";
import RecentTransactions from "@/views/Asset/Overview/RecentTransactions.vue";
import AccountView from "@/views/Asset/Overview/AccountView.vue";
import CoinView from "@/views/Asset/Overview/CoinView.vue";
import AssetMovements from "@/views/Asset/Overview/AssetMovements.vue";
import BalanceDetails from "@/views/Asset/Overview/BalanceDetails.vue";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";

const { t } = useI18n();

const tabItems = computed(() => [
  { id: 1, name: t("translation.asset.overviewPage.tabHeaders.0") },
  { id: 2, name: t("translation.asset.overviewPage.tabHeaders.1") },
]);

const router = useRouter();
const store = useStore();

const selectedTab = ref(tabItems.value[0]);

onMounted(() => {
  store.dispatch("fetchTransactions", 1);
});

const handleChangeTab = (item) => {
  selectedTab.value = item;
};

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const goToWithdraw = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const goToBuySell = () => {
  router.push({ name: "buy-sell" });
};

const goToTransfer = () => {
  store.dispatch("setTransferData", {});
};
</script>
