<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    :title="$t('translation.common.twoFactorVerifyDialog.title')"
    @change-is-open="handleClose"
    bg-color="gray"
  >
    <template #content>
      <div class="mt-6 flex flex-col gap-2">
        <!-- Email -->
        <text-edit
          v-if="requireEmail"
          type="number"
          rounded="xl"
          :label="$t('translation.common.twoFactorVerifyDialog.emailLabel')"
          :placeholder="
            $t('translation.common.twoFactorVerifyDialog.emailPlaceholder')
          "
          v-model="emailPin"
        />

        <!-- Phone -->
        <text-edit
          v-if="requirePhone"
          type="number"
          rounded="xl"
          :label="$t('translation.common.twoFactorVerifyDialog.phoneLabel')"
          :placeholder="
            $t('translation.common.twoFactorVerifyDialog.phonePlaceholder')
          "
          v-model="phonePin"
        />

        <!-- Google Authenticator -->
        <text-edit
          v-if="requireGoogle"
          type="number"
          rounded="xl"
          :label="$t('translation.common.twoFactorVerifyDialog.googleLabel')"
          :placeholder="
            $t('translation.common.twoFactorVerifyDialog.googlePlaceholder')
          "
          v-model="googlePin"
        />

        <fill-button
          class="mt-6 sm:mt-12"
          size="md"
          width="full"
          color="primary"
          :disabled="isEmpty(emailPin) && isEmpty(phonePin)"
          @click="handleClickConfirm"
        >
          {{ $t("translation.common.twoFactorVerifyDialog.confirmButton") }}
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { defineModel, defineEmits, ref, defineProps, computed } from "vue";
import { isEmpty } from "@/utils/utils.js";
import DialogView from "@/components/Dialog/DialogView.vue";

const props = defineProps({
  request: Object,
});
const model = defineModel();
const emits = defineEmits(["confirm"]);

const requireEmail = computed(() => {
  if (Object.values(props.request.required).includes(1)) {
    return true;
  }
  return false;
});

const requirePhone = computed(() => {
  if (Object.values(props.request.required).includes(3)) {
    return true;
  }
  return false;
});

const requireGoogle = computed(() => {
  if (Object.values(props.request.required).includes(2)) {
    return true;
  }
  return false;
});

const emailPin = ref();
const phonePin = ref();
const googlePin = ref();

const handleClose = () => {
  model.value = false;
};

const handleClickConfirm = () => {
  emits("confirm", {
    ...(requireEmail.value && { emailPin: emailPin.value }),
    ...(requirePhone.value && { phonePin: phonePin.value }),
    ...(requireGoogle.value && { googlePin: googlePin.value }),
  });
};
</script>
