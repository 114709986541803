<template>
  <div
    class="w-[482px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 rounded-2xl p-4 flex flex-col gap-6 dark:bg-navy-600 dark:border-navy-400 border dark:shadow-black/[0.32] bg-white shadow-[0px_4px_24px_0px_rgba(0,0,0,0.32)]"
  >
    <div class="dark:border-navy-400 border-navy-50 bg-white dark:bg-navy-600">
      <text-edit
        type="text"
        size="sm"
        rounded="full"
        :placeholder="
          $t('translation.header.navbar.searchDropdown.searchPlaceholder')
        "
        :no-border="true"
        :filled="true"
        v-model="searchText"
      >
        <template #prefix>
          <div class="w-6 h-6 mr-2">
            <magnifying-glass-icon
              :class="[
                searchText.length > 0
                  ? 'dark:text-white text-navy-800'
                  : 'text-navy-200',
              ]"
            />
          </div>
        </template>
        <template #suffix v-if="searchText.length > 0">
          <div
            class="w-6 h-6 ml-2"
            @click="
              () => {
                searchText = '';
              }
            "
          >
            <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
          </div>
        </template>
      </text-edit>
    </div>
    <div v-if="searchText.length === 0">
      <div class="flex flex-col gap-3">
        <div
          :class="[
            'flex flex-row justify-between text-navy-200 h-5 items-center',
          ]"
        >
          <span class="text-sm font-semibold">{{
            $t("translation.header.navbar.searchDropdown.history")
          }}</span>
          <span><trash-icon class="stroke-2 w-5 h-5" /></span>
        </div>
        <div class="flex flex-row gap-2 flex-wrap">
          <text-badge
            v-for="item in coinData"
            :label="item.label"
            size="md"
            :class="[
              'text-xs dark:text-navy-50 dark:bg-navy-500 bg-navy-10 text-navy-300',
            ]"
            :key="item.id"
            @click="handleSearch(item.label)"
          >
            <img :src="item.icon" />
          </text-badge>
        </div>
      </div>
      <div class="flex flex-col mt-6">
        <div
          class="flex flex-row h-7 justify-between items-center dark:text-white text-navy-800"
        >
          <span :class="['text-base font-semibold']">{{
            $t("translation.header.navbar.searchDropdown.topSearch")
          }}</span>
          <div class="flex-grow" />
          <span><chevron-left-icon class="stroke-2 w-5 h-5 mr-4" /> </span>
          <span><chevron-right-icon class="stroke-2 w-5 h-5" /> </span>
        </div>
        <div class="flex flex-row mt-4 flex-wrap gap-2 mb-2">
          <text-badge
            v-for="item in topSearchItemsData"
            :label="item.label"
            size="md"
            class="text-xs font-medium dark:text-white dark:bg-navy-400 bg-navy-10 text-navy-300"
            :key="item.id"
            @click="handleNavigateToTradePair(item.path)"
          />
        </div>
        <div class="flex flex-row items-center justify-center gap-3">
          <div class="w-1.5 h-1.5 rounded-full dark:bg-white bg-navy-300" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
        </div>
      </div>
    </div>
    <div v-if="searchText.length > 0" class="flex flex-col overflow-auto">
      <div class="font-medium text-sm">
        <underline-tab :items="searchTabData" :bottom-line="true" />
      </div>
      <div
        class="h-12 flex flex-row items-center justify-start dark:text-navy-200"
      >
        {{ $t("translation.header.navbar.searchDropdown.label") }}
      </div>
      <div class="w-full h-full custom-scrollbar">
        <table class="w-full table-fixed">
          <tbody>
            <tr
              v-for="item in searchSpotData"
              :key="item.name"
              class="cursor-pointer hover:bg-navy-10 dark:hover:bg-navy-500"
              @click="handleClickMenuItem(item.name)"
            >
              <td class="text-sm font-semibold rounded-l-lg">
                <div class="flex items-center space-x-3 pl-2 py-2">
                  <star-icon
                    :class="[
                      'size-4 shrink-0',
                      item.favorite
                        ? 'text-orange-400'
                        : 'text-navy-50 dark:text-navy-300',
                    ]"
                    @click="
                      (e) => {
                        e.stopPropagation();
                        if (isLoggedIn === false) {
                          return;
                        }
                        if (item.favorite) {
                          store.dispatch('removeFavoritePair', item.name);
                        } else {
                          store.dispatch('addFavoritePair', item.name);
                        }
                      }
                    "
                  />
                  <img
                    class="size-6 rounded-full"
                    :src="item?.icon"
                    alt="Token icon"
                  />
                  <p class="flex space-x-1">
                    <span>{{ item.tokenA }}</span>
                    <span class="text-navy-100">{{ item.tokenB }}</span>
                  </p>
                </div>
              </td>
              <td class="font-medium text-sm text-right">${{ item.price }}</td>
              <td
                :class="[
                  'font-medium text-sm text-right pr-2 rounded-r-lg',
                  item.ratio < 0 ? 'text-red-500' : 'text-green-500',
                ]"
              >
                {{ item.ratio.toFixed(2) }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import TextBadge from "@/components/Badge/TextBadge.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { XCircleIcon, StarIcon } from "@heroicons/vue/24/solid";
import { useRouter } from "vue-router";

const searchText = ref("");
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const allPairs = computed(() => store.getters.allPairsModified);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const coinData = [
  {
    id: 0,
    label: "USDT",
    icon: "/assets/icons/usdt_icon.svg",
  },
  {
    id: 1,
    label: "BTC",
    icon: "/assets/icons/btc_icon.svg",
  },
  {
    id: 2,
    label: "USDC",
    icon: "/assets/icons/usdc_icon.svg",
  },
  {
    id: 3,
    label: "SOL",
    icon: "/assets/icons/sol_icon.svg",
  },
  {
    id: 4,
    label: "XRP",
    icon: "/assets/icons/xrp_icon.svg",
  },
];

const topSearchItemsData = [
  {
    id: 0,
    label: "BTC/USDT",
    path: "BTCUSDT",
  },
  {
    id: 1,
    label: "ETH/USDT",
    path: "ETHUSDT",
  },
  {
    id: 2,
    label: "PEPE/USDT",
    path: "PEPEUSDT",
  },
  {
    id: 3,
    label: "BGB/USDT",
    path: "BGBUSDT",
  },
  {
    id: 4,
    label: "SOL/USDT",
    path: "SOLUSDT",
  },
  {
    id: 5,
    label: "XRP/USDT",
    path: "XRPUSDT",
  },
];

const searchTabData = computed(() => [
  { id: 1, name: t("translation.header.navbar.searchDropdown.tabs.0") },
  { id: 2, name: t("translation.header.navbar.searchDropdown.tabs.1") },
]);

const handleClickMenuItem = (name) => {
  router.push({
    name: "trading",
    params: {
      pair: name,
    },
  });
};

const searchSpotData = computed(() => {
  const filterText = searchText.value.toLowerCase();
  return allPairs.value
    .filter((item) => {
      return item.name.toLowerCase().includes(filterText);
    })
    .map((item, index) => {
      return {
        id: index,
        tokenA: item.main.iso3,
        tokenB: item.second.iso3,
        name: item.name,
        price: item.rate_f,
        ratio: item.percent,
        favorite: item.favorite,
        icon: activeCurrencies.value[item.main.id]?.icon ?? "",
      };
    });
});

const handleNavigateToTradePair = (path) => {
  router.push({ name: "trading", params: { pair: path } });
};

const handleSearch = (label) => {
  searchText.value = label;
};
</script>
