import DashboardIcon from "@/assets/icons/profile-sidebar/DashboardIcon.vue";
import IdentityVerificationIcon from "@/assets/icons/profile-sidebar/IdentityVerificationIcon.vue";
import SecurityIcon from "@/assets/icons/profile-sidebar/SecurityIcon.vue";
import SettingsIcon from "@/assets/icons/profile-sidebar/SettingsIcon.vue";
import AddressBookIcon from "@/assets/icons/profile-sidebar/AddressBookIcon.vue";
import LoginHistoryIcon from "@/assets/icons/profile-sidebar/LoginHistoryIcon.vue";

import ProfileDashboard from "@/views/Profile/ProfileDashboard.vue";
import ProfileIdentityVerification from "@/views/Profile/Verification/ProfileIdentityVerification.vue";
import ProfileLoginHistory from "@/views/Profile/ProfileLoginHistory.vue";
import ProfileSecurity from "@/views/Profile/ProfileSecurity.vue";
import ProfileSettings from "@/views/Profile/ProfileSettings.vue";
import ProfileAddressBook from "@/views/Profile/ProfileAddressBook.vue";
import ProfileSecurityChangeLogin from "@/views/Profile/Security/ProfileSeucirtyLoginPassword.vue";
import ProfileSecurityPhone from "@/views/Profile/Security/ProfileSecurityPhone.vue";
import MainLayout from "@/layout/MainLayout/MainLayout.vue";
import SidebarLayout from "@/layout/SidebarLayout/SidebarLayout.vue";

import { h } from "vue";
import { isEmpty } from "@/utils/utils";

export const profileSidebarItems = [
  {
    id: 1,
    name: "dashboard",
    path: "dashboard",
    label: "translation.profile.sidebarMenu.0",
    icon: DashboardIcon,
    content: ProfileDashboard,
  },
  {
    id: 2,
    name: "identity-verification",
    path: "identity-verification",
    label: "translation.profile.sidebarMenu.1",
    icon: IdentityVerificationIcon,
    content: ProfileIdentityVerification,
  },
  {
    id: 3,
    path: "security",
    label: "translation.profile.sidebarMenu.2",
    matchedItems: ["security", "change-login-password"],
    icon: SecurityIcon,
    children: [
      {
        id: 1,
        name: "security",
        path: "",
        label: "translation.profile.sidebarMenu.3",
        icon: SecurityIcon,
        content: ProfileSecurity,
      },
      {
        id: 2,
        name: "change-login-password",
        path: "change-login-password",
        label: "translation.profile.sidebarMenu.4",
        icon: SecurityIcon,
        content: ProfileSecurityChangeLogin,
      },
      {
        id: 3,
        name: "change-phone",
        path: "change-phone",
        label: "translation.profile.sidebarMenu.5",
        icon: SecurityIcon,
        content: ProfileSecurityPhone,
      },
    ],
  },
  {
    id: 4,
    name: "settings",
    path: "settings",
    label: "translation.profile.sidebarMenu.6",
    icon: SettingsIcon,
    content: ProfileSettings,
  },
  {
    id: 5,
    name: "address-book",
    path: "address-book",
    label: "translation.profile.sidebarMenu.7",
    icon: AddressBookIcon,
    content: ProfileAddressBook,
  },
  {
    id: 6,
    name: "login-history",
    path: "login-history",
    label: "translation.profile.sidebarMenu.8",
    icon: LoginHistoryIcon,
    content: ProfileLoginHistory,
  },
];

export const buildProfileSidebarRouter = (items) => {
  return items.map((item) => {
    const params = {
      path: item.path,
      name: item.name,
    };

    if (!isEmpty(item.content)) {
      params.component = {
        render() {
          return h(
            MainLayout,
            {},
            {
              default: () =>
                h(
                  SidebarLayout,
                  { menuData: profileSidebarItems, full: true },
                  {
                    top: () => <div class="h-10" />,
                    content: () => h(item.content),
                  }
                ),
            }
          );
        },
      };
    }
    if (!isEmpty(item.children)) {
      params.children = buildProfileSidebarRouter(item.children);
    }
    return params;
  });
};
