<template>
  <dialog-view
    v-if="dialogIsOpenModel"
    bg-color="gray"
    :contain-header="!isEmpty(verifyComponents[verifyStep].title)"
    :title="verifyComponents[verifyStep].title"
    :is-open="dialogIsOpenModel"
    @change-is-open="closeDialog"
  >
    <template #content>
      <component
        :is="verifyComponents[verifyStep].component"
        @next="nextStep"
        @close="closeDialog"
      />
    </template>
  </dialog-view>
</template>
<script setup>
import { ref, defineModel, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import SelectCountryPassportDialog from "@/views/Profile/Verification/VerificationDialogs/SelectCountryPassportDialog.vue";
import InformationDialog from "@/views/Profile/Verification/VerificationDialogs/InformationDialog.vue";
import KYC from "@/views/Profile/Verification/VerificationDialogs/KYC.vue";
import StartVerification from "@/views/Profile/Verification/VerificationDialogs/StartVerification.vue";
import IdCardUpload from "@/views/Profile/Verification/VerificationDialogs/IdCardUpload.vue";
import FaceInTheOval from "@/views/Profile/Verification/VerificationDialogs/FaceInTheOval.vue";
import VerifyDataSubmitted from "@/views/Profile/Verification/VerificationDialogs/VerifyDataSubmitted.vue";

const { t } = useI18n();
const store = useStore();

const verifyComponents = computed(() => [
  {
    id: 0,
    component: InformationDialog,
    title: t("translation.profile.idverify.infoDialog.title"),
  },
  {
    id: 1,
    component: KYC,
    title: t("translation.profile.idverify.kycDialog.title"),
  },
  {
    id: 2,
    component: SelectCountryPassportDialog,
    title: t("translation.profile.idverify.passportDialog.title"),
  },
  {
    id: 3,
    component: StartVerification,
  },
  {
    id: 4,
    component: IdCardUpload,
  },
  {
    id: 5,
    component: FaceInTheOval,
  },
  {
    id: 6,
    component: VerifyDataSubmitted,
  },
]);

store.dispatch("fetchquestionnaireSettings");

const dialogIsOpenModel = defineModel();
const verifyStep = ref(0);
const closeDialog = () => {
  dialogIsOpenModel.value = !dialogIsOpenModel.value;
};

const nextStep = (data) => {
  if (verifyStep.value === 0) {
    data;
  }
  if (verifyStep.value === 6) closeDialog();
  else verifyStep.value++;
};

watch(dialogIsOpenModel, () => {
  verifyStep.value = 0;
});
</script>
