<template>
  <div
    class="size-48 dark:bg-navy-600 bg-white dark:border dark:border-navy-400 p-4 rounded-2xl"
    :style="
      currentTheme === 'dark'
        ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
        : 'box-shadow:0px 4px 56px 0px rgba(0,0,0,0.16)'
    "
  >
    <div class="size-full dark:bg-navy-700 p-3 rounded-xl bg-white">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/home/qr_image.svg'
            : '/assets/images/home/qr_image_light.svg'
        "
      />
    </div>
  </div>
</template>
<script setup></script>
