import { postHttpRequest } from "@/api/api";
import store from "@/store/index";

const state = {
  userId: -1,
  firstName: "",
  middleName: "",
  lastName: "",
  statusOfIncome: -1,
  tradingExperience: -1,
  typeOfTheSow: -1,
  motivationForOpeningAnAccount: -1,
  expectedMonthlyTradeVol: -1,
  monthlyIncome: -1,
  gender: -1,
  countryOfCitizenship: "",
  turnoverGiven: "",
  geolocation: "",
  phone: "",
  pep: "",
  dob: "",
  documents: [],
  documentInfo: {},
  selfies: [],
};

const mutations = {
  SET_USER_ID(state, value) {
    state.userId = value;
  },
  SET_FIRST_NAME(state, value) {
    state.firstName = value;
  },
  SET_MIDDLE_NAME(state, value) {
    state.middleName = value;
  },
  SET_LAST_NAME(state, value) {
    state.lastName = value;
  },
  SET_STATUS_OF_INCOME(state, value) {
    state.statusOfIncome = value;
  },
  SET_TRADING_EXPERIENCE(state, value) {
    state.tradingExperience = value;
  },
  SET_TYPE_OF_THE_SOW(state, value) {
    state.typeOfTheSow = value;
  },
  SET_MOTIVATION_FOR_OPENING_AN_ACCOUNT(state, value) {
    state.motivationForOpeningAnAccount = value;
  },
  SET_EXPECTED_MONTHLY_TRADE_VOL(state, value) {
    state.expectedMonthlyTradeVol = value;
  },
  SET_MONTHLY_INCOME(state, value) {
    state.monthlyIncome = value;
  },
  SET_GENDER(state, value) {
    state.gender = value;
  },
  SET_COUNTRY_OF_CITIZENSHIP(state, value) {
    state.countryOfCitizenship = value;
  },
  SET_TURNOVER_GIVEN(state, value) {
    state.turnoverGiven = value;
  },
  SET_GEOLOCATION(state, value) {
    state.geolocation = value;
  },
  SET_PHONE(state, value) {
    state.phone = value;
  },
  SET_PEP(state, value) {
    state.pep = value;
  },
  SET_DOB(state, value) {
    state.dob = value;
  },
  SET_DOCUMENTS(state, value) {
    state.documents = value;
  },
  SET_DOCUMENT_INFO(state, value) {
    state.documentInfo = value;
  },
  SET_SELFIES(state, value) {
    state.selfies = value;
  },
};

const actions = {
  setUserId({ commit }, value) {
    commit("SET_USER_ID", value);
  },
  setFirstName({ commit }, value) {
    commit("SET_FIRST_NAME", value);
  },
  setMiddleName({ commit }, value) {
    commit("SET_MIDDLE_NAME", value);
  },
  setLastName({ commit }, value) {
    commit("SET_LAST_NAME", value);
  },
  setStatusOfIncome({ commit }, value) {
    commit("SET_STATUS_OF_INCOME", value);
  },
  setTradingExperience({ commit }, value) {
    commit("SET_TRADING_EXPERIENCE", value);
  },
  setTypeOfTheSow({ commit }, value) {
    commit("SET_TYPE_OF_THE_SOW", value);
  },
  setMotivationForOpeningAnAccount({ commit }, value) {
    commit("SET_MOTIVATION_FOR_OPENING_AN_ACCOUNT", value);
  },
  setExpectedMonthlyTradeVol({ commit }, value) {
    commit("SET_EXPECTED_MONTHLY_TRADE_VOL", value);
  },
  setMonthlyIncome({ commit }, value) {
    commit("SET_MONTHLY_INCOME", value);
  },
  setGender({ commit }, value) {
    commit("SET_GENDER", value);
  },
  setCountryOfCitizenship({ commit }, value) {
    commit("SET_COUNTRY_OF_CITIZENSHIP", value);
  },
  setTurnoverGiven({ commit }, value) {
    commit("SET_TURNOVER_GIVEN", value);
  },
  setGeolocation({ commit }, value) {
    commit("SET_GEOLOCATION", value);
  },
  setPhone({ commit }, value) {
    commit("SET_PHONE", value);
  },
  setPep({ commit }, value) {
    commit("SET_PEP", value);
  },
  setDob({ commit }, value) {
    commit("SET_DOB", value);
  },
  setDocuments({ commit }, value) {
    commit("SET_DOCUMENTS", value);
  },
  setDocumentInfo({ commit }, value) {
    commit("SET_DOCUMENT_INFO", value);
  },
  setSelfies({ commit }, value) {
    commit("SET_SELFIES", value);
  },
  async submitKYC() {
    console.log(state.documents);
    await postHttpRequest(store)("kyc", {
      registration: {
        user_id: state.userId,
        first_name: state.firstName,
        middle_name: state.middleName,
        last_name: state.lastName,
        status_of_income: state.statusOfIncome,
        trading_experience: state.tradingExperience,
        type_of_the_sow: state.typeOfTheSow,
        motivation_for_opening_an_account: state.motivationForOpeningAnAccount,
        expected_monthly_trade_vol: state.expectedMonthlyTradeVol,
        monthly_income: state.monthlyIncome,
        gender: state.gender,
        country_of_citizenship: state.countryOfCitizenship,
        turnover_given: state.turnoverGiven,
        geolocation: state.geolocation,
        phone: state.phone,
        pep: state.pep,
        dob: state.dob,
      },
      documents: state.documents,
      documentInfo: state.documentInfo,
      selfies: state.selfies,
    });
  },
};

const getters = {
  kycUserId: (state) => state.userId,
  kycFirstName: (state) => state.firstName,
  kycMiddleName: (state) => state.middleName,
  kycLastName: (state) => state.lastName,
  kycStatusOfIncome: (state) => state.lastName,
  kycTradingExperience: (state) => state.tradingExperience,
  kycTyeOfTheSow: (state) => state.typeOfTheSow,
  kycMotivationForOpeningAnAccount: (state) =>
    state.motivationForOpeningAnAccount,
  kycExpectedMonthlyTradeVol: (state) => state.expectedMonthlyTradeVol,
  kycMonthlyIncome: (state) => state.monthlyIncome,
  kycGender: (state) => state.gender,
  kycCountryOfCitizenship: (state) => state.countryOfCitizenship,
  kycTurnoverGiven: (state) => state.turnoverGiven,
  kycGeolocation: (state) => state.geolocation,
  kycPhone: (state) => state.phone,
  kycPep: (state) => state.pep,
  kycDob: (state) => state.dob,
  kycDocuments: (state) => state.documents,
  kycDocumentInfo: (state) => state.documentInfo,
  kycSelfies: (state) => state.selfies,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
