<template>
  <div class="flex flex-col">
    <div class="text-sm dark:text-white text-navy-800 font-medium mb-8">
      {{ $t("translation.profile.idverify.kycDialog.subTitle") }}
    </div>
    <div
      id="verify-data-input"
      class="max-h-[65vh] custom-scrollbar-light flex flex-row flex-wrap justify-between w-full pr-2"
    >
      <div
        v-for="(item, index) in verificationData"
        :key="item?.key"
        :class="[
          index > 2 && index < 9 ? 'sm:w-1/2 w-full' : 'w-full',
          index > 2 && index < 9 ? (index % 2 ? 'sm:pr-2' : 'sm:pl-2') : '',
          'mb-6',
        ]"
      >
        <div class="dark:text-white text-navy-800 text-sm font-semibold mb-2">
          {{ item?.label?.EN }}
        </div>
        <select-country-dropdown
          v-if="item?.key.search('country') != -1"
          borderWidth="1px"
          borderColor="navy"
          bgColor="navy"
          @select="(data) => handleSelectValue(`${item.key}`, data)"
        />
        <date-input
          v-else-if="item?.options_type === 'date'"
          size="md"
          fontSize="md"
          @change="(data) => handleSelectValue(`${item.key}`, data)"
        />
        <combo-box
          :placeholder="
            $t('translation.profile.idverify.kycDialog.selectPlaceholder')
          "
          :items="changeForm(item?.options)"
          size="sm"
          headerFontSize="sm"
          mediumFontSize="sm"
          :isSectedVisible="true"
          v-else
          @select="(data) => handleSelectValue(`${item.key}`, data)"
        />
      </div>
    </div>
  </div>
  <fill-button class="w-full mt-12" @click="handleClickNextButton">
    {{ $t("translation.profile.idverify.kycDialog.nextButton") }}
  </fill-button>
  <dialog-view
    v-if="dialogIsOpenModel"
    bg-color="gray"
    :contain-header="true"
    title="Exposed Person(PEP)"
    :is-open="dialogIsOpenModel"
    @change-is-open="closeDialog"
  >
    <template #content>
      <exposed-person @next="nextStep" @close="closeDialog" />
    </template>
  </dialog-view>
</template>
<script setup>
import { computed, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import DateInput from "@/components/DateInput/DateInput.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import SelectCountryDropdown from "@/common/SelectCountryDropdown/SelectCountryDropdown.vue";
import ExposedPerson from "@/views/Profile/Verification/VerificationDialogs/ExposedPerson.vue";

const emits = defineEmits(["close", "next"]);
const store = useStore();
const verificationData = computed(() => store.getters.questionnaireSettings);
const dialogIsOpenModel = ref(false);

const changeForm = (data) => {
  const items = data?.map((item) => {
    return {
      key: item.key,
      label: item.label.EN,
    };
  });
  return items;
};

const handleClickNextButton = () => {
  dialogIsOpenModel.value = true;
};
const closeDialog = () => {
  dialogIsOpenModel.value = !dialogIsOpenModel.value;
};

const nextStep = () => {
  emits("next");
};

const handleSelectValue = (key, data) => {
  switch (key) {
    case "status_of_income":
      store.dispatch("setStatusOfIncome", data.key);
      break;
    case "trading_experience":
      store.dispatch("setTradingExperience", 1 - data.key);
      break;
    case "motivation_for_opening_an_account":
      store.dispatch("setMotivationForOpeningAnAccount", data.key);
      break;
    case "expected_monthly_trade_vol":
      store.dispatch("setExpectedMonthlyTradeVol", Number.parseInt(data.key));
      break;
    case "monthly_income":
      store.dispatch("setMonthlyIncome", Number.parseInt(data.key));
      break;
    case "gender":
      store.dispatch("setGender", data.key);
      break;
    case "country_of_residence":
      store.dispatch("setCountryOfCitizenship", data);
      break;
    case "country_of_citizenship":
      store.dispatch("setCountryOfCitizenship", data);
      break;
    case "dob":
      store.dispatch("setDob", data);
      break;
    case "pep":
      store.dispatch("setPep", Number.parseInt(data.key));
      break;
  }
};
</script>
