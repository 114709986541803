<template>
  <div class="dark:text-navy-100 text-navy-200 flex gap-6">
    <button
      v-for="item in items"
      :key="item.id"
      :class="[
        'text-xs leading-[18px]  font-medium',
        selectedTab.id === item.id ? `dark:text-white text-navy-800` : '',
      ]"
      @click="handleClick(item)"
    >
      {{ item.label }}
    </button>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  items: {
    type: Array,
  },
});

const emit = defineEmits(["select"]);

const selectedTab = ref(props.items[0]);
const handleClick = (item) => {
  selectedTab.value = item;
  emit("select", item);
};
</script>
