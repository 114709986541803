<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    bg-color="gray"
    :contain-header="false"
    :show-only-content="true"
    :bg-close="true"
    @change-is-open="model = false"
  >
    <template #content>
      <div
        class="sm:w-[388px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 max-sm:my-auto rounded-2xl shadow-xl flex sm:flex-row flex-col p-4 gap-6 box-border dark:border-navy-400 dark:bg-navy-600 dark:shadow-black/[0.32] border bg-white shadow-black/[0.16]"
      >
        <div
          class="flex sm:flex-col flex-row gap-2 text-center sm:justify-between justify-around"
        >
          <img src="/assets/images/navbar/navbar_qr.svg" class="size-[136px]" />
          <div
            class="flex flex-col sm:justify-between sm:items-center items-start justify-center"
          >
            <div class="text-xs font-medium dark:text-navy-50 text-navy-100">
              {{ $t("translation.header.navbar.downloadDropdown.scanqr.0") }}
            </div>
            <div class="text-sm font-semibold dark:text-white text-navy-800">
              {{ $t("translation.header.navbar.downloadDropdown.scanqr.1") }}
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div
            class="flex flex-row px-4 py-3 gap-4 hover:rounded-xl cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10"
          >
            <apple-store-icon class="w-10 h-10 dark:text-white text-navy-800" />
            <div class="flex flex-col justify-between">
              <div class="text-xs font-medium dark:text-navy-50 text-navy-100">
                {{
                  $t(
                    "translation.header.navbar.downloadDropdown.downloadApple.0"
                  )
                }}
              </div>
              <div class="text-sm font-semibold dark:text-white text-navy-800">
                {{
                  $t(
                    "translation.header.navbar.downloadDropdown.downloadApple.1"
                  )
                }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-row px-4 py-3 gap-4 hover:rounded-xl cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10"
          >
            <google-play-icon class="w-10 h-10 dark:text-white text-navy-800" />
            <div class="flex flex-col justify-between">
              <div class="text-xs font-medium dark:text-navy-50 text-navy-100">
                {{
                  $t(
                    "translation.header.navbar.downloadDropdown.downloadGoogle.0"
                  )
                }}
              </div>
              <div class="text-sm font-semibold dark:text-white text-navy-800">
                {{
                  $t(
                    "translation.header.navbar.downloadDropdown.downloadGoogle.1"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel } from "vue";
import AppleStoreIcon from "@/assets/icons/common/AppleStoreIcon.vue";
import GooglePlayIcon from "@/assets/icons/common/GooglePlayIcon.vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();
</script>
