<template>
  <dialog-view
    v-if="model"
    bg-color="gray"
    :contain-header="true"
    :is-open="true"
    title="Share Now"
    @change-is-open="handleClose"
  >
    <template #content>
      <div class="flex flex-col w-full rounded-2xl overflow-hidden">
        <div
          class="bg-primary-700 h-[394px] relative rounded-b-2xl overflow-hidden"
        >
          <div class="absolute z-10 h-full">
            <img
              src="/assets/images/rewards-hub/dialog-bg-dark.svg"
              class="object-fill h-full"
            />
          </div>
          <div
            class="absolute sm:top-[87px] sm:left-10 top-8 left-6 font-bold text-[32px] leading-10 -tracking-[0.8px] z-20 text-white"
          >
            Rewards Hub
          </div>
          <div
            class="absolute z-20 sm:left-10 sm:top-[135px] top-20 left-6 max-w-[196px] font-medium text-base -tracking-[0.2px] text-white"
          >
            Complete tasks to win USDT rewards!
          </div>
          <div
            class="absolute bottom-0 p-3 dark:bg-navy-400 bg-primary-300 z-20 w-full flex flex-row sm:gap-4 gap-3 items-center rounded-b-2xl overflow-hidden"
          >
            <img
              src="/assets/images/rewards-hub/dialog-exzi-logo.svg"
              class="sm:size-16 size-10 shrink-0"
            />
            <div class="sm:max-w-[238px] flex flex-col items-start gap-1">
              <div
                class="dark:text-white text-navy-800 font-semibold text-xs leading-[18px]"
              >
                Register now! A 8200 USDT reward is waiting for you to claim....
              </div>
              <div
                class="font-medium text-xs leading-[18px] dark:text-navy-100 text-navy-200"
              >
                Referral Code: Q3727834672
              </div>
            </div>
            <div class="grow" />
            <img
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/rewards-hub/dialog-qr-logo-dark.svg'
                  : '/assets/images/rewards-hub/dialog-qr-logo-light.svg'
              "
              class="size-16 shrink-0"
            />
          </div>
        </div>
        <div
          class="sm:mt-8 mt-6 w-full sm:grid-cols-6 grid-cols-3 grid gap-6 mb-6"
        >
          <template v-for="item in iconData" :key="item.id">
            <div class="flex flex-col items-center gap-2">
              <img
                :src="
                  item.iconLight
                    ? currentTheme === 'dark'
                      ? item.icon
                      : item.iconLight
                    : item.icon
                "
              />
              <div
                class="font-medium text-xs leading-[18px] dark:text-navy-50 text-navy-800"
              >
                {{ item.label }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { computed, defineModel } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();

const handleClose = () => {
  model.value = false;
};

const iconData = computed(() => [
  {
    id: 1,
    icon: "/assets/images/rewards-hub/telegram-icon.svg",
    label: "Telegram",
  },
  {
    id: 2,
    icon: "/assets/images/rewards-hub/meta-icon.svg",
    label: "X (Twitter)",
  },
  {
    id: 1,
    icon: "/assets/images/rewards-hub/facebook-icon.svg",
    label: "Facebook",
  },
  {
    id: 1,
    icon: "/assets/images/rewards-hub/linkedin-icon.svg",
    label: "Linkedin",
  },
  {
    id: 1,
    icon: "/assets/images/rewards-hub/save-dark-icon.svg",
    iconLight: "/assets/images/rewards-hub/save-light-icon.svg",
    label: "Save",
  },
  {
    id: 1,
    icon: "/assets/images/rewards-hub/copy-dark-icon.svg",
    iconLight: "/assets/images/rewards-hub/copy-light-icon.svg",
    label: "Copy",
  },
]);
</script>
