<template>
  <div class="flex justify-between gap-6 lg:flex-row flex-col w-full mb-24">
    <div v-if="!isEmpty(profileData)" class="w-full basis-2/3">
      <p class="text-2xl font-semibold dark:text-white text-navy-800">
        {{ $t("translation.profile.idverify.title") }}
      </p>
      <div class="mt-8 flex items-center gap-3">
        <circle-user-icon />
        <label class="text-lg font-semibold ml-3">{{
          profileData.profile.email_masked
        }}</label>

        <!-- Should update the type according to the verification status -->
        <badge-with-icon
          :label="verificationStatus"
          :type="verificationStatus === 'Verified' ? 'success' : 'error'"
          :icon="
            verificationStatus === 'Verified' ? CheckBadgeIcon : UnVerifiedIcon
          "
        />
      </div>
      <div
        class="mt-8 bg-transparent border dark:border-navy-500 borer-navy-50 rounded-2xl w-full relative"
      >
        <div class="absolute h-full w-full -z-30">
          <img
            :src="
              currentTheme === 'dark'
                ? '/assets/images/verification/get_verified_background_ellipse.svg'
                : '/assets/images/verification/get_verified_background_ellipse_light.svg'
            "
            class="w-full h-full rounded-2xl"
          />
        </div>
        <div class="absolute bottom-0 right-0 -z-20">
          <img
            :src="
              currentTheme === 'dark'
                ? '/assets/images/verification/get_verified_background_image.svg'
                : '/assets/images/verification/get_verified_background_image_light.svg'
            "
            class="rounded-2xl"
          />
        </div>
        <div class="p-6">
          <p class="font-semibold">
            {{ $t("translation.profile.idverify.text0") }}
          </p>
          <p class="mt-3 dark:text-navy-100 mb-6">
            {{ $t("translation.profile.idverify.text1") }}
          </p>
          <fill-button size="md" width="fit" @click="handleVerifiedButtonClick">
            <p>{{ $t("translation.profile.idverify.verifyButton") }}</p>
            <arrow-right-icon class="w-5 h-5" />
          </fill-button>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10 min-w-[200px] w-full basis-1/3 text-base">
      <collapse-view
        @header-click="(val) => handlePersonalInformationHeaderClick(val)"
        :isShow="collapseState"
      >
        <template #header="{ handleContentClick: handleButtonClick }">
          <div
            class="flex flex-row items-center justify-between py-3.5 cursor-pointer"
            @click="handleButtonClick"
          >
            <div class="dark:text-white text-navy-800 text-base">
              {{ $t("translation.profile.idverify.infoTitle") }}
            </div>
            <div class="w-6 h-6 dark:text-white text-navy-800">
              <chevron-down-icon
                v-if="collapseState === true"
                class="w-6 h-6 stroke-2"
              />
              <chevron-up-icon v-else class="w-6 h-6 stroke-2" />
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex gap-4 flex-col">
            <div class="flex justify-between">
              <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
                {{ $t("translation.profile.idverify.name") }}
              </div>
              <div class="dark:text-white text-navy-800 text-sm font-medium">
                __
              </div>
            </div>
            <div class="flex justify-between">
              <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
                {{ $t("translation.profile.idverify.country") }}
              </div>
              <div class="dark:text-white text-navy-800 text-sm font-medium">
                {{ profileData?.profile?.country_of_residence }}
              </div>
            </div>
            <div class="flex justify-between">
              <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
                {{ $t("translation.profile.idverify.email") }}
              </div>
              <div class="dark:text-white text-navy-800 text-sm font-medium">
                {{ profileData?.profile?.email_masked }}
              </div>
            </div>
            <div class="flex justify-between">
              <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
                {{ $t("translation.profile.idverify.dob") }}
              </div>
              <div class="dark:text-white text-navy-800 text-sm font-medium">
                __
              </div>
            </div>
          </div>
        </template>
      </collapse-view>
      <div class="flex flex-col gap-6 font-semibold">
        <div>{{ $t("translation.profile.idverify.faq") }}</div>
        <faq-small />
      </div>
    </div>
  </div>
  <verification-modal v-model="isShowVerifyModal" />
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import FaqSmall from "@/common/FAQ/FaqSmall.vue";
import VerificationModal from "@/views/Profile/Verification/VerificationModal.vue";
import CircleUserIcon from "@/assets/icons/user/CircleUserIcon.vue";
import UnVerifiedIcon from "@/assets/icons/verification/UnVerifiedIcon.vue";
import BadgeWithIcon from "@/components/Badge/BadgeWithIcon";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/vue/24/outline";

const store = useStore();
const isShowVerifyModal = ref(false);
const profileData = computed(() => store.getters.profile);
const verificationStatus = computed(
  () => store.getters.verificationInfo.verification_state
);

const collapseState = ref(true);

const handlePersonalInformationHeaderClick = (val) => {
  collapseState.value = val;
};

const handleVerifiedButtonClick = async () => {
  isShowVerifyModal.value = true;
};
</script>
