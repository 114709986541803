import { ref } from "vue";
import axios from "@/api/axios";
import {
  USER_SERVICE_API_URL,
  VERIFICATION_TIME_LEFT,
} from "@/constants/constants";
import { useStore } from "vuex";
import session_manager from "@/utils/SessionManager";
import { useRouter } from "vue-router";

export const useForgetPassword = () => {
  const store = useStore();
  const router = useRouter();
  const isLoading = ref(false);
  const email = ref("");
  const phone = ref("");

  const requestForgetPassword = async () => {
    try {
      isLoading.value = true;
      const res = await axios.post(`${USER_SERVICE_API_URL}/forget-password`, {
        email: email.value,
      });
      if (res.data.success) {
        store.dispatch("setToast", {
          type: "success",
          message: res.data.message,
        });
        const codeInfo = {
          exziId: res.data.exziId,
          required: true,
          requiredType: true,
          codeSentTo: "email",
          timeBeforeNewCode: VERIFICATION_TIME_LEFT,
          token: res.data.token,
          email: email.value,
          phone: phone.value,
          context: "recovery",
          timeSentVerificationCode: new Date(),
        };
        session_manager.set2FactorCodeInfo(codeInfo);
        store.dispatch("setCode", codeInfo);
        router.push({ name: "verification-code", query: { back: "home" } });
      }
    } catch (error) {
      store.dispatch("setToast", {
        type: "error",
        message: error.data?.message || error,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    email,
    phone,
    requestForgetPassword,
  };
};
