const state = {
  top_topics: [],
  latest_articles: [],
  crypto_insights: [],
};

const mutations = {
  SET_TOP_TOPICS(state, value) {
    state.top_topics = value;
  },
  SET_LATEST_ARTICLES(state, value) {
    state.latest_articles = value;
  },
  SET_CRYPTO_INSIGHTS(state, value) {
    state.crypto_insights = value;
  },
};

const actions = {
  async getTopTopics({ commit }) {
    try {
      const response = await fetch("/mock/blog/top_topics.json");
      const data = await response.json();
      commit("SET_TOP_TOPICS", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getLatestArticles({ commit }) {
    try {
      const response = await fetch("/mock/blog/blog_list.json");
      const data = await response.json();
      commit("SET_LATEST_ARTICLES", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getCryptoInsights({ commit }) {
    try {
      const response = await fetch("/mock/blog/crypto_insights.json");
      const data = await response.json();
      commit("SET_CRYPTO_INSIGHTS", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  topTopics: (state) => state.top_topics,
  latestArticles: (state) => state.latest_articles,
  cryptoInsights: (state) => state.crypto_insights,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
