<template>
  <div
    :class="['relative w-full', props.padding === true ? 'px-4' : '']"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      :class="[
        'rounded-lg flex flex-row sm:px-3 gap-3 py-2.5 w-full items-center',
        disabled
          ? 'cursor-not-allowed'
          : 'dark:hover:bg-navy-500 hover:bg-primary-100 cursor-pointer',
      ]"
      @click="handleClick"
    >
      <div
        class="relative overflow-hidden size-10 rounded-full p-2 bg-primary-700 bg-opacity-[18%] dark:text-primary-500 text-primary-700"
      >
        <component :is="props.icon" class="w-6 h-6" />

        <div
          v-if="disabled"
          class="cursor-not-allowed absolute dark:bg-navy-600 bg-white dark:bg-opacity-40 bg-opacity-50 z-10 w-[1000px] h-[1000px] top-0 left-0"
        />
      </div>
      <div class="flex flex-col gap-1 relative overflow-hidden">
        <div class="text-base font-medium dark:text-white text-navy-800">
          {{ props.title }}
        </div>
        <div
          class="sm:text-sm text-xs font-medium dark:text-navy-100 text-navy-200"
        >
          {{ props.label }}
        </div>

        <div
          v-if="disabled"
          class="cursor-not-allowed absolute dark:bg-navy-600 bg-white dark:bg-opacity-40 bg-opacity-50 z-10 w-[1000px] h-[1000px] top-0 left-0"
        />
      </div>
    </div>
    <div v-if="showExtraContent" class="absolute left-full ml-0 -top-[17px]">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";

const router = useRouter();
const showSlot = ref(false);

const props = defineProps([
  "title",
  "label",
  "padding",
  "icon",
  "pathName",
  "showExtraContent",
  "disabled",
]);
const emit = defineEmits(["click", "mouseEnter", "mouseLeave"]);

const handleClick = () => {
  if (!props.disabled) {
    if (!isEmpty(props.pathName)) router.push({ name: props.pathName });
    emit("click");
  }
};

const handleMouseEnter = () => {
  if (!props.disabled) {
    emit("mouseEnter");
    showSlot.value = true;
  }
};

const handleMouseLeave = () => {
  if (!props.disabled) {
    emit("mouseLeave");
    showSlot.value = false;
  }
};
</script>
