<template>
  <div
    :class="[
      'rounded-[32px] flex flex-col w-[341px] shrink-0 relative overflow-hidden',
      bgColorName,
    ]"
  >
    <div class="absolute w-full h-full z-10">
      <img :src="bgImgUrl" class="object-fill" />
    </div>
    <div class="w-full p-6 relative z-20">
      <div class="flex flex-row gap-2 items-center">
        <div
          :class="[
            'font-semibold text-[32px] leading-10 -tracking-[0.8px] ',
            pointColorName,
          ]"
        >
          +{{ points }}
        </div>
        <div
          :class="[
            'font-normal text-lg leading-7 -tracking-[0.4px] ',
            pointLabelColorName,
          ]"
        >
          {{ $t("translation.rewardsHub.pointUnit") }}
        </div>
      </div>
      <div
        :class="[
          'mt-6 font-semibold text-xl leading-8 -tracking-[0.4px]',
          signUpLabelColorName,
        ]"
      >
        {{ title }}
      </div>
      <div
        :class="[
          'mt-1 font-medium text-sm leading-5 -tracking-[0.2px] ',
          labelColorName,
        ]"
      >
        {{ content }}
      </div>
      <div class="w-full mt-5">
        <fill-button color="primary" size="md" width="full" :disabled="!active">
          {{ $t("translation.rewardsHub.claimButton") }}
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  points: Number,
  active: Boolean,
  title: String,
  content: String,
});

const store = useStore();
const currentTheme = computed(() => store.getters.currentTheme);

const bgColorName = computed(() => {
  return currentTheme.value === "dark"
    ? "bg-navy-600"
    : props.active === true
    ? "bg-primary-100"
    : "bg-navy-10";
});

const pointColorName = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-white"
      : "text-navy-200"
    : props.active === true
    ? "text-primary-800"
    : "text-navy-200";
});

const pointLabelColorName = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-navy-100"
      : "text-navy-200"
    : props.active === true
    ? "text-primary-800"
    : "text-navy-100";
});

const signUpLabelColorName = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-white"
      : "text-navy-200"
    : props.active === true
    ? "text-navy-300"
    : "text-navy-100";
});

const labelColorName = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "text-navy-100"
      : "text-navy-200"
    : props.active === true
    ? "text-navy-200"
    : "text-navy-100";
});

const bgImgUrl = computed(() => {
  return currentTheme.value === "dark"
    ? props.active === true
      ? "/assets/images/rewards-hub/daily-bg-active-dark.svg"
      : "/assets/images/rewards-hub/daily-bg-normal-dark.svg"
    : props.active === true
    ? "/assets/images/rewards-hub/daily-bg-active-light.svg"
    : "/assets/images/rewards-hub/daily-bg-normal-light.svg";
});
</script>
