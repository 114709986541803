<template>
  <div
    :class="[
      'w-full flex sm:flex-row items-center justify-between gap-3',
      props.mode === 'vertical' ? 'flex-col' : 'flex-row',
    ]"
  >
    <div class="flex flex-row items-center justify-between w-full">
      <div class="flex flex-row items-center">
        <div
          class="size-14 rounded-full dark:bg-navy-500 bg-primary-300 text-primary-700 dark:text-white mr-4 flex items-center justify-center shrink-0"
        >
          <component class="w-6 h-6" :is="props.icon" />
        </div>
        <div class="flex flex-col items-start gap-1">
          <div class="dark:text-white text-navy-800 text-base font-semibold">
            {{ props.title }}
          </div>
          <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
            {{ props.comment }}
          </div>
        </div>
      </div>

      <slot name="detail" />
    </div>
    <div :class="[props.mode === 'vertical' ? 'max-sm:w-full' : '']">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["icon", "title", "comment", "mode"]);
</script>
