<template>
  <div class="relative min-h-[759px]">
    <!-- <Carousel v-bind="config" v-model="currentSlider">
      <Slide v-for="slide in carouselLandingSectionInfo" :key="slide.id">
        <carousel-landing-section v-bind="slide" />
      </Slide>
    </Carousel> -->
    <carousel-landing-section v-bind="{ ...carouselLandingSectionInfo[0] }" />

    <!-- Background with patter and gradients [desktop] -->
    <img
      class="absolute -top-12 -z-10 max-sm:hidden"
      :src="
        currentTheme === 'dark'
          ? '/assets/images/home/carousel/carousel_bg_dark.svg'
          : '/assets/images/home/carousel/carousel_bg_light.svg'
      "
    />
    <!-- Background with patter and gradients [mobile] -->
    <img
      class="absolute -top-12 -z-10 sm:hidden"
      :src="
        currentTheme === 'dark'
          ? '/assets/images/home/carousel/carousel_bg_mobile_dark.svg'
          : '/assets/images/home/carousel/carousel_bg_mobile_light.svg'
      "
    />
    <!-- <div class="absolute bottom-[150px] sm:px-16 max-sm:hidden">
      <carousel-pagination
        :len="5"
        :active="currentSlider"
        @change="handleChangePagination"
      />
    </div> -->
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
// import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import CarouselLandingSection from "./LandingSection/CarouselLandingSection.vue";
// import CarouselPagination from "./LandingSection/CarouselPagination.vue";

const { t } = useI18n();

const store = useStore();
const currentTheme = computed(() => store.getters.currentTheme);

// const config = {
//   itemsToShow: 1,
// };

const carouselLandingSectionInfo = computed(() => [
  {
    id: 1,
    title: t("translation.home.main.carousel.0.title"),
    coloredTitle: t("translation.home.main.carousel.0.colorTitle"),
    subTitle: t("translation.home.main.carousel.0.subTitle"),
    buttonTitle: t("translation.home.main.carousel.0.buttonTitle"),
    disabled: true,
    image:
      currentTheme.value === "dark"
        ? "/assets/images/home/carousel-sections/app_logo.svg"
        : "/assets/images/home/carousel-sections/app_logo_light.svg",
  },
  // {
  //   id: 2,
  //   title: t("translation.home.main.carousel.1.title"),
  //   coloredTitle: t("translation.home.main.carousel.1.colorTitle"),
  //   subTitle: t("translation.home.main.carousel.1.subTitle"),
  //   buttonTitle: t("translation.home.main.carousel.1.buttonTitle"),
  //   image:
  //     currentTheme.value === "dark"
  //       ? "/assets/images/home/carousel-sections/app_logo_1.svg"
  //       : "/assets/images/home/carousel-sections/app_logo_1_light.svg",
  // },
]);

// const currentSlider = ref(0);
// const handleChangePagination = (value) => {
//   currentSlider.value = value;
// };
</script>
<style>
.carousel__track {
  align-items: start;
}
</style>
