<template>
  <div
    class="relative w-full rounded-[32px] dark:bg-navy-600 bg-primary-100 overflow-hidden sm:h-full justify-between self-stretch h-[458px]"
  >
    <div class="flex flex-col px-6 mt-6 z-10">
      <component :is="props.icon" class="size-12 text-primary-600" />
      <div class="dark:text-white text-navy-800 text-2xl font-semibold mt-6">
        {{ props.title }}
      </div>
      <div
        class="dark:text-white text-navy-800 text-base font-normal mt-3 -tracking-[0.2px]"
      >
        {{ props.label }}
      </div>
    </div>
    <div
      class="absolute right-0 top-[240px] bottom-0 w-full flex justify-end z-0"
    >
      <img :src="currentTheme === 'dark' ? props.imgUrl : props.imgLightUrl" />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";

const props = defineProps(["title", "label", "imgUrl", "imgLightUrl", "icon"]);
</script>
