<template>
  <div class="overflow-auto custom-scrollbar">
    <table class="sm:w-full w-[700px] text-sm">
      <thead>
        <tr class="font-medium dark:text-navy-100 text-navy-200">
          <td
            v-for="item in headerItems"
            :key="item.id"
            :class="[item.align, 'p-3']"
          >
            {{ item.label }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="balance in balances" :key="balance.currency.iso3">
          <td class="px-3 py-4">
            <div class="flex items-center gap-2">
              <img :src="balance.icon" class="size-6 rounded-full" />
              <div>
                <p>{{ balance.currency.iso3 }}</p>
                <p
                  class="font-semibold text-xs dark:text-navy-100 text-navy-200"
                >
                  {{ balance.currency.name }}
                </p>
              </div>
            </div>
          </td>
          <td class="px-3 py-4">
            {{ balance.balance / Math.pow(10, balance.currency.decimal) }}
          </td>
          <td class="px-3 py-4">
            {{ balance.balance_available_f }}
          </td>
          <td class="text-right px-3 py-4">
            <div class="flex flex-row items-center justify-end">
              <link-button
                :label="
                  $t('translation.asset.overviewPage.coinPanel.actionDeposit')
                "
                @click="goToDeposit"
              />
              <span class="dark:text-navy-300 text-navy-50 mx-3">|</span>
              <link-button
                :label="
                  $t('translation.asset.overviewPage.coinPanel.actionWithdraw')
                "
                @click="goToWithdraw"
              />
              <span class="dark:text-navy-300 text-navy-50 mx-3">|</span>
              <link-button
                :label="
                  $t(
                    'translation.asset.overviewPage.accountPanel.actionTransfer'
                  )
                "
                @click="goToTransfer(balance)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();

const headerItems = computed(() => [
  {
    id: 1,
    label: t("translation.asset.overviewPage.coinPanel.headerItems.0"),
    align: "text-left",
  },
  {
    id: 2,
    label: t("translation.asset.overviewPage.coinPanel.headerItems.1"),
    align: "text-left",
  },
  {
    id: 3,
    label: t("translation.asset.overviewPage.coinPanel.headerItems.2"),
    align: "text-left",
  },
  {
    id: 4,
    label: t("translation.asset.overviewPage.coinPanel.headerItems.3"),
    align: "text-right",
  },
]);

const store = useStore();
const router = useRouter();

const balances = computed(() =>
  Object.values(store.getters.balancesVolumeModified).filter(
    (item) => item.balance != 0
  )
);

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const goToWithdraw = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const goToTransfer = (balance) => {
  if (activeCurrencies.value) {
    store.dispatch("setTransferData", {
      currency: activeCurrencies.value[balance.currency.id],
    });
  }
};
</script>
