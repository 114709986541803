<template>
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.37 4.86674C35.37 2.17891 33.1911 0 30.5033 0H6.48899C2.90522 0 0 2.90522 0 6.48898V30.5034C0 33.1912 2.17891 35.3702 4.86674 35.3702H30.5033C33.1911 35.3702 35.37 33.1912 35.37 30.5034V4.86674ZM29.4751 24.6082C29.4751 27.2961 27.2961 29.475 24.6083 29.475H10.7617C8.0739 29.475 5.89499 27.2961 5.89499 24.6082V10.7617C5.89499 8.07391 8.07391 5.895 10.7617 5.895H24.6083C27.2961 5.895 29.4751 8.07391 29.4751 10.7617V24.6082Z"
      fill="currentColor"
    />
    <path
      d="M64.8455 109.053C64.8455 110.681 66.1651 112 67.793 112C69.4208 112 70.7405 110.681 70.7405 109.053C70.7405 107.425 69.4208 106.105 67.793 106.105C66.1651 106.105 64.8455 107.425 64.8455 109.053Z"
      fill="currentColor"
    />
    <path
      d="M32.4204 100.2C34.0483 100.2 35.368 101.519 35.368 103.147V109.042C35.368 110.67 36.6876 111.99 38.3155 111.99C39.9433 111.99 41.263 110.67 41.263 109.042V99.1714C41.263 96.4836 39.084 94.3047 36.3962 94.3047H32.4204C30.7925 94.3047 29.4729 95.6243 29.4729 97.2522C29.4729 98.88 30.7926 100.2 32.4204 100.2Z"
      fill="currentColor"
    />
    <path
      d="M5.89331 109.053C5.89331 110.681 7.21295 112 8.8408 112C10.4686 112 11.7883 110.681 11.7883 109.053C11.7883 107.425 10.4686 106.105 8.8408 106.105C7.21295 106.105 5.89331 107.425 5.89331 109.053Z"
      fill="currentColor"
    />
    <path
      d="M76.6385 107.132C76.6385 109.82 78.8174 111.999 81.5052 111.999H105.513C109.097 111.999 112.002 109.094 112.002 105.51V81.4958C112.002 78.808 109.823 76.6291 107.136 76.6291H81.5052C78.8174 76.6291 76.6385 78.808 76.6385 81.4958V107.132ZM82.5334 87.3906C82.5334 84.7028 84.7123 82.5239 87.4001 82.5239H101.241C103.928 82.5239 106.107 84.7028 106.107 87.3906V101.237C106.107 103.925 103.928 106.104 101.241 106.104H87.4001C84.7123 106.104 82.5334 103.925 82.5334 101.237V87.3906ZM81.5052 0C78.8174 0 76.6385 2.17891 76.6385 4.86674V30.5034C76.6385 33.1912 78.8174 35.3702 81.5052 35.3702H107.136C109.823 35.3702 112.002 33.1912 112.002 30.5034V6.48899C112.002 2.90522 109.097 0 105.513 0H81.5052ZM106.107 24.6082C106.107 27.2961 103.928 29.475 101.241 29.475H87.4001C84.7123 29.475 82.5334 27.2961 82.5334 24.6082V10.7617C82.5334 8.07391 84.7123 5.895 87.4001 5.895H101.241C103.928 5.895 106.107 8.07391 106.107 10.7617V24.6082Z"
      fill="currentColor"
    />
    <path
      d="M32.4208 47.1606C30.793 47.1606 29.4733 48.4803 29.4733 50.1082C29.4733 51.7361 28.1536 53.0558 26.5257 53.0558H20.631C19.003 53.0558 17.6833 51.7361 17.6833 50.1082C17.6833 48.4803 19.003 47.1606 20.6308 47.1606C22.2587 47.1606 23.5783 45.841 23.5783 44.2131C23.5783 42.5853 22.2587 41.2656 20.6308 41.2656H8.8408C7.21295 41.2656 5.89331 42.5853 5.89331 44.2131C5.89331 45.841 7.21295 47.1606 8.8408 47.1606C10.4686 47.1606 11.7883 48.4802 11.7883 50.1081V56.0031C11.7883 57.631 13.1079 58.9506 14.7358 58.9506C16.3637 58.9506 17.6833 60.2704 17.6833 61.8982C17.6833 63.5261 19.003 64.8458 20.6308 64.8458C22.2587 64.8458 23.5783 63.5261 23.5783 61.8983C23.5783 60.2704 24.898 58.9506 26.5259 58.9506H30.5016C33.1894 58.9506 35.3684 56.7717 35.3684 54.0839V50.1081C35.3684 48.4803 34.0487 47.1606 32.4208 47.1606Z"
      fill="currentColor"
    />
    <path
      d="M5.89331 61.8929C5.89331 63.5207 7.21294 64.8405 8.8408 64.8405C10.4686 64.8405 11.7883 63.5207 11.7883 61.8929C11.7883 60.265 10.4686 58.9453 8.8408 58.9453C7.21294 58.9453 5.89331 60.265 5.89331 61.8929Z"
      fill="currentColor"
    />
    <path
      d="M29.4729 67.799C29.4729 69.4269 30.7926 70.7465 32.4204 70.7465C34.0483 70.7465 35.3679 69.4269 35.3679 67.799C35.3679 66.1712 34.0483 64.8516 32.4204 64.8516C30.7926 64.8516 29.4729 66.1712 29.4729 67.799Z"
      fill="currentColor"
    />
    <path
      d="M44.2117 70.7461H38.3107C36.6845 70.7461 35.3662 72.0644 35.3662 73.6906C35.3662 75.3168 36.6845 76.6351 38.3107 76.6351H44.2117C45.8379 76.6351 47.1562 75.3168 47.1562 73.6906C47.1562 72.0644 45.8379 70.7461 44.2117 70.7461Z"
      fill="currentColor"
    />
    <path
      d="M65.8755 94.31C68.5633 94.31 70.7422 92.1311 70.7422 89.4433V79.5725C70.7422 77.9446 69.4226 76.625 67.7947 76.625C66.1669 76.625 64.8472 77.9446 64.8472 79.5725V85.4674C64.8472 87.0953 63.5276 88.4149 61.8997 88.4149C60.2718 88.4149 58.9522 89.7346 58.9522 91.3625C58.9522 92.9904 60.2718 94.31 61.8997 94.31H65.8755Z"
      fill="currentColor"
    />
    <path
      d="M64.8455 26.5217C64.8455 28.1496 66.1651 29.4692 67.793 29.4692C69.4208 29.4692 70.7405 28.1496 70.7405 26.5217C70.7405 24.8939 69.4208 23.5742 67.793 23.5742C66.1651 23.5742 64.8455 24.8939 64.8455 26.5217Z"
      fill="currentColor"
    />
    <path
      d="M95.3484 88.4141H93.2918C90.604 88.4141 88.4251 90.593 88.4251 93.2808V95.3373C88.4251 98.0251 90.604 100.204 93.2918 100.204H95.3484C98.0362 100.204 100.215 98.0251 100.215 95.3373V93.2808C100.215 90.593 98.0362 88.4141 95.3484 88.4141Z"
      fill="currentColor"
    />
    <path
      d="M95.3484 11.7852H93.2918C90.604 11.7852 88.4251 13.9641 88.4251 16.6519V18.7084C88.4251 21.3962 90.604 23.5751 93.2918 23.5751H95.3484C98.0362 23.5751 100.215 21.3962 100.215 18.7084V16.6519C100.215 13.9641 98.0362 11.7852 95.3484 11.7852Z"
      fill="currentColor"
    />
    <path
      d="M100.218 44.2131C100.218 45.8393 101.536 47.1606 103.162 47.1606C104.789 47.1606 106.107 45.8393 106.107 44.2131C106.107 42.587 104.789 41.2656 103.162 41.2656C101.536 41.2656 100.218 42.587 100.218 44.2131Z"
      fill="currentColor"
    />
    <path
      d="M100.218 67.799C100.218 69.4252 101.536 70.7465 103.162 70.7465C104.789 70.7465 106.107 69.4252 106.107 67.799C106.107 66.1729 104.789 64.8516 103.162 64.8516C101.536 64.8516 100.218 66.1729 100.218 67.799Z"
      fill="currentColor"
    />
    <path
      d="M26.5306 100.211H14.7405C13.1126 100.211 11.793 101.531 11.793 103.158C11.793 104.786 13.1126 106.106 14.7405 106.106H26.5306C28.1584 106.106 29.4781 104.786 29.4781 103.158C29.4781 101.531 28.1584 100.211 26.5306 100.211Z"
      fill="currentColor"
    />
    <path
      d="M56.0018 100.2C54.3739 100.2 53.0543 98.88 53.0543 97.2522C53.0543 95.6243 51.7346 94.3047 50.1068 94.3047C48.4789 94.3047 47.1592 95.6243 47.1592 97.2522V101.228C47.1592 103.916 49.3381 106.095 52.0259 106.095H61.8968C63.5247 106.095 64.8443 104.775 64.8443 103.147C64.8443 101.519 63.5247 100.2 61.8968 100.2H56.0018Z"
      fill="currentColor"
    />
    <path
      d="M5.89331 89.4534C5.89331 92.1412 8.07222 94.3201 10.76 94.3201H26.5258C28.1536 94.3201 29.4733 93.0005 29.4733 91.3726C29.4733 89.7447 28.1536 88.425 26.5258 88.425H16.655C13.9672 88.425 11.7883 86.2461 11.7883 83.5583V79.5826C11.7883 77.9548 13.108 76.6351 14.7359 76.6351C16.3637 76.6351 17.6833 77.9547 17.6833 79.5825C17.6833 81.2103 19.0029 82.5299 20.6307 82.5299H24.6066C27.2944 82.5299 29.4733 80.351 29.4733 77.6632V75.6128C29.4733 72.925 27.2944 70.7461 24.6066 70.7461H10.7601C8.07223 70.7461 5.89331 72.925 5.89331 75.6128V89.4534Z"
      fill="currentColor"
    />
    <path
      d="M47.1562 79.5724C47.1562 81.2002 45.8366 82.5198 44.2088 82.5198H38.3138C36.6859 82.5198 35.3662 83.8395 35.3662 85.4674C35.3662 87.0953 36.6859 88.4149 38.3138 88.4149H55.9986C57.6265 88.4149 58.9462 87.0952 58.9462 85.4673C58.9462 83.8395 57.6265 82.5198 55.9987 82.5198C54.3709 82.5198 53.0512 81.2002 53.0512 79.5724C53.0512 77.9446 51.7315 76.625 50.1037 76.625C48.4759 76.625 47.1562 77.9446 47.1562 79.5724Z"
      fill="currentColor"
    />
    <path
      d="M53.0525 73.6906C53.0525 75.3168 54.3738 76.6351 56 76.6351C57.6262 76.6351 58.9474 75.3168 58.9474 73.6906C58.9474 72.0644 57.6262 70.7461 56 70.7461C54.3738 70.7461 53.0525 72.0644 53.0525 73.6906Z"
      fill="currentColor"
    />
    <path
      d="M82.5271 61.8929C82.5271 60.265 81.2075 58.9453 79.5796 58.9453C77.9518 58.9453 76.6321 60.2649 76.6321 61.8928V65.8687C76.6321 68.5565 78.811 70.7354 81.4989 70.7354H85.4747C87.1025 70.7354 88.4222 69.4158 88.4222 67.7879C88.4222 66.1601 87.1025 64.8405 85.4747 64.8405C83.8468 64.8405 82.5271 63.5207 82.5271 61.8929Z"
      fill="currentColor"
    />
    <path
      d="M82.5271 50.1082C82.5271 51.7361 83.8467 53.0558 85.4746 53.0558C87.1025 53.0558 88.4222 54.3755 88.4222 56.0034V59.979C88.4222 62.6669 90.6011 64.8458 93.2889 64.8458H95.3455C98.0333 64.8458 100.212 62.6669 100.212 59.979V56.0034C100.212 54.3755 98.8925 53.0558 97.2647 53.0558C95.6368 53.0558 94.3171 51.7361 94.3171 50.1082C94.3171 48.4803 92.9975 47.1606 91.3696 47.1606C89.7418 47.1606 88.4222 45.841 88.4222 44.2131C88.4222 42.5853 87.1025 41.2656 85.4747 41.2656H79.5796C77.9518 41.2656 76.6321 42.5853 76.6321 44.2131C76.6321 45.841 77.9518 47.1606 79.5796 47.1606C81.2075 47.1606 82.5271 48.4803 82.5271 50.1082Z"
      fill="currentColor"
    />
    <path
      d="M53.0525 50.1077C53.0525 51.7356 54.3721 53.0553 56 53.0553C57.6278 53.0553 58.9474 51.7356 58.9474 50.1077C58.9474 48.4799 57.6278 47.1602 56 47.1602C54.3721 47.1602 53.0525 48.4799 53.0525 50.1077Z"
      fill="currentColor"
    />
    <path
      d="M76.6373 50.1077C76.6373 48.4798 75.3176 47.1602 73.6897 47.1602C72.0619 47.1602 70.7422 48.4799 70.7422 50.1077C70.7422 51.7357 69.4225 53.0554 67.7946 53.0554H63.8189C61.1311 53.0554 58.9522 55.2343 58.9522 57.9221V65.8736C58.9522 68.5614 61.1311 70.7403 63.8189 70.7403H65.8755C68.5633 70.7403 70.7422 68.5614 70.7422 65.8736V61.8977C70.7422 60.2698 72.0619 58.9502 73.6897 58.9502C75.3176 58.9502 76.6373 57.6305 76.6373 56.0027V50.1077Z"
      fill="currentColor"
    />
    <path
      d="M47.1562 61.8982C47.1562 60.2703 48.4759 58.9506 50.1038 58.9506C51.7316 58.9506 53.0512 57.631 53.0512 56.0032C53.0512 54.3754 51.7316 53.0558 50.1038 53.0558C48.4759 53.0558 47.1562 51.7362 47.1562 50.1083V46.1324C47.1562 43.4445 44.9773 41.2656 42.2894 41.2656H38.3137C36.6859 41.2656 35.3662 42.5853 35.3662 44.2131C35.3662 45.841 36.6859 47.1606 38.3137 47.1606C39.9416 47.1606 41.2612 48.4802 41.2612 50.1081V56.0031C41.2612 57.631 39.9416 58.9506 38.3137 58.9506C36.6859 58.9506 35.3662 60.2703 35.3662 61.8982C35.3662 63.5261 36.6859 64.8458 38.3138 64.8458H44.2086C45.8365 64.8458 47.1562 63.5261 47.1562 61.8982Z"
      fill="currentColor"
    />
    <path
      d="M18.7163 11.7852H16.6597C13.9719 11.7852 11.793 13.9641 11.793 16.6519V18.7084C11.793 21.3962 13.9719 23.5751 16.6597 23.5751H18.7163C21.4041 23.5751 23.583 21.3962 23.583 18.7084V16.6519C23.583 13.9641 21.4041 11.7852 18.7163 11.7852Z"
      fill="currentColor"
    />
    <path
      d="M64.8443 20.6437C64.8443 19.0158 63.5246 17.6962 61.8968 17.6962C60.2689 17.6962 58.9493 16.3766 58.9493 14.7487C58.9493 13.1209 60.2689 11.8012 61.8967 11.8012H67.7918C69.4197 11.8012 70.7393 10.4816 70.7393 8.85374C70.7393 7.22588 69.4197 5.90625 67.7918 5.90625H50.1067C48.4788 5.90625 47.1592 7.22588 47.1592 8.85374C47.1592 10.4816 48.4789 11.8012 50.1067 11.8012C51.7346 11.8012 53.0543 13.1209 53.0543 14.7488V18.7245C53.0543 21.4123 55.2332 23.5913 57.921 23.5913H61.8968C63.5247 23.5913 64.8443 22.2716 64.8443 20.6437Z"
      fill="currentColor"
    />
    <path
      d="M41.2659 14.7326C41.2659 16.3605 42.5855 17.6801 44.2134 17.6801C45.8412 17.6801 47.1609 16.3605 47.1609 14.7326C47.1609 13.1048 45.8412 11.7852 44.2134 11.7852C42.5855 11.7852 41.2659 13.1048 41.2659 14.7326Z"
      fill="currentColor"
    />
    <path
      d="M70.7409 38.3117C70.7409 36.6839 69.4213 35.3643 67.7935 35.3643C66.1656 35.3643 64.846 34.0446 64.846 32.4168C64.846 30.7889 63.5263 29.4692 61.8984 29.4692C60.2706 29.4692 58.9509 30.7889 58.9509 32.4168C58.9509 34.0446 57.6313 35.3643 56.0034 35.3643C54.3756 35.3643 53.0559 34.0447 53.0559 32.4169V26.5217C53.0559 24.8939 51.7362 23.5742 50.1084 23.5742C48.4805 23.5742 47.1609 24.8939 47.1609 26.5217C47.1609 28.1496 45.8412 29.4692 44.2134 29.4692C42.5855 29.4692 41.2659 30.7889 41.2659 32.4168C41.2659 34.0446 42.5856 35.3643 44.2134 35.3643C45.8412 35.3643 47.1609 36.6839 47.1609 38.3117C47.1609 39.9395 48.4805 41.2591 50.1083 41.2591H67.7935C69.4213 41.2591 70.7409 39.9395 70.7409 38.3117Z"
      fill="currentColor"
    />
  </svg>
</template>
