<template>
  <dialog-view
    :contain-header="true"
    v-if="model"
    :title="$t('translation.profile.security.security2FADialog.title')"
    :is-open="model"
    @change-is-open="handleChangeOpen"
    bg-color="gray"
  >
    <template #content>
      <div class="w-full flex flex-col">
        <vertical-circle-step
          :step-length="3"
          :current-active-step="1"
          :only-show="true"
        >
          <template #content-1>
            <collapse-view>
              <template #header="{ handleContentClick, isShowingContent }">
                <div
                  class="w-full flex flex-row justify-between h-10 items-center cursor-pointer mb-4"
                  @click="handleContentClick"
                >
                  <div
                    class="text-base font-semibold dark:text-white text-navy-800"
                  >
                    {{
                      $t(
                        "translation.profile.security.security2FADialog.step.0.title"
                      )
                    }}
                  </div>
                  <div>
                    <chevron-up-icon
                      v-if="isShowingContent"
                      class="size-4 stroke-2"
                    />
                    <chevron-down-icon v-else class="size-4 stroke-2" />
                  </div>
                </div>
              </template>
              <template #content>
                <div class="w-full flex flex-col items-start mt-6">
                  <div
                    class="w-full text-sm font-normal dark:text-navy-50 text-navy-300"
                  >
                    {{
                      $t(
                        "translation.profile.security.security2FADialog.step.0.body"
                      )
                    }}
                  </div>
                  <div
                    class="flex flex-row items-start justify-start w-full mt-4 sm:mt-8 sm:gap-10 gap-4"
                  >
                    <div class="flex flex-col gap-3">
                      <div
                        class="size-36 rounded-xl dark:bg-white bg-navy-10 flex items-center justify-center"
                      >
                        <QrImage class="size-[120px] text-black" />
                      </div>
                      <div
                        class="text-sm font-semibold dark:text-white text-navy-800 w-full text-center"
                      >
                        iOS
                      </div>
                    </div>
                    <div class="flex flex-col gap-3">
                      <div
                        class="size-36 rounded-xl dark:bg-white bg-navy-10 flex items-center justify-center"
                      >
                        <QrImage class="size-[120px] text-black" />
                      </div>
                      <div
                        class="text-sm font-semibold dark:text-white text-navy-800 w-full text-center"
                      >
                        Android
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </collapse-view>
          </template>
          <template #content-2>
            <collapse-view>
              <template #header="{ handleContentClick, isShowingContent }">
                <div
                  class="w-full flex flex-row justify-between h-10 items-center cursor-pointer mb-4"
                  @click="handleContentClick"
                >
                  <div
                    class="text-base font-semibold dark:text-white text-navy-800"
                  >
                    {{
                      $t(
                        "translation.profile.security.security2FADialog.step.1.title"
                      )
                    }}
                  </div>
                  <div>
                    <chevron-up-icon
                      v-if="isShowingContent"
                      class="size-4 stroke-2"
                    />
                    <chevron-down-icon v-else class="size-4 stroke-2" />
                  </div>
                </div>
              </template>
              <template #content>
                <div class="w-full flex flex-col items-start mt-6">
                  <div
                    class="w-full text-sm font-normal dark:text-navy-50 text-navy-300"
                  >
                    {{
                      $t(
                        "translation.profile.security.security2FADialog.step.1.body"
                      )
                    }}
                  </div>
                  <div
                    class="flex sm:flex-row flex-col sm:items-center items-start justify-start w-full mt-4 sm:mt-6 rounded-2xl border dark:border-navy-400 p-4 gap-6"
                  >
                    <div class="p-3 rounded-xl dark:bg-navy-400 bg-navy-10">
                      <div class="size-28">
                        <img class="object-fill" :src="qr_code" />
                      </div>
                    </div>
                    <div class="flex flex-col items-start w-full">
                      <div
                        class="mb-1 text-sm font-medium dark:text-navy-100 text-primary-700"
                      >
                        {{
                          $t(
                            "translation.profile.security.security2FADialog.step.1.setup"
                          )
                        }}
                      </div>
                      <div
                        class="text-base font-semibold dark:text-white text-navy-800 mb-4"
                      >
                        {{ secret }}
                      </div>
                      <div class="sm:w-fit w-full">
                        <fill-button
                          color="primary"
                          width="full"
                          size="md"
                          @click="copyClick"
                        >
                          <CopyIcon class="size-5" />
                          <div class="text-sm font-semibold">
                            {{
                              $t(
                                "translation.profile.security.security2FADialog.step.1.copy"
                              )
                            }}
                          </div>
                        </fill-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </collapse-view>
          </template>
          <template #content-3>
            <collapse-view>
              <template #header="{ handleContentClick, isShowingContent }">
                <div
                  class="w-full flex flex-row justify-between h-10 items-center cursor-pointer mb-4"
                  @click="handleContentClick"
                >
                  <div
                    class="text-base font-semibold dark:text-white text-navy-800"
                  >
                    {{
                      $t(
                        "translation.profile.security.security2FADialog.step.2.title"
                      )
                    }}
                  </div>
                  <div>
                    <chevron-up-icon
                      v-if="isShowingContent"
                      class="size-4 stroke-2"
                    />
                    <chevron-down-icon v-else class="size-4 stroke-2" />
                  </div>
                </div>
              </template>
              <template #content>
                <div class="w-full flex flex-col items-start mt-6">
                  <div class="w-full">
                    <text-edit
                      :label="
                        $t(
                          'translation.profile.security.security2FADialog.step.2.textLabel'
                        )
                      "
                      :placeholder="
                        $t(
                          'translation.profile.security.security2FADialog.step.2.textPlaceholder'
                        )
                      "
                      :fill="false"
                      rounded="xl"
                      v-model="email_code"
                    />
                  </div>
                  <div class="w-full mt-6">
                    <text-edit
                      :label="
                        $t(
                          'translation.profile.security.security2FADialog.step.2.codeLabel'
                        )
                      "
                      :placeholder="
                        $t(
                          'translation.profile.security.security2FADialog.step.2.codePlaceholder'
                        )
                      "
                      :fill="false"
                      rounded="xl"
                      v-model="google_code"
                    >
                    </text-edit>
                  </div>
                </div>
              </template>
            </collapse-view>
          </template>
        </vertical-circle-step>
        <div class="mt-12 w-full">
          <fill-button
            color="primary"
            size="md"
            width="full"
            @click="handleConfirmClick"
          >
            {{ $t("translation.profile.security.security2FADialog.confirm") }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineProps, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { copyToClipboard } from "@/utils/utils";
import { postHttpRequest } from "@/api/api";
import DialogView from "@/components/Dialog/DialogView.vue";
import VerticalCircleStep from "@/components/VerticalCircleStep/VerticalCircleStep.vue";
import CollapseView from "@/components/Collapse/CollapseView.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import QrImage from "@/assets/icons/common/QrImage.vue";
import CopyIcon from "@/assets/icons/common/CopyIcon.vue";

const { t } = useI18n();
const model = defineModel();
const email_code = ref("");
const google_code = ref("");
// eslint-disable-next-line vue/no-setup-props-destructure
const { secret, qr_code } = defineProps({
  secret: String,
  qr_code: String,
});

const store = useStore();

const handleChangeOpen = () => {
  model.value = false;
};

const copyClick = () => {
  copyToClipboard(secret);
  store.dispatch("setToast", {
    type: "success",
    message: t(
      "translation.profile.security.security2FADialog.copySuccessToast"
    ),
  });
};

const handleConfirmClick = async () => {
  const res = await postHttpRequest(store)("/api/account/change-login-type", {
    send_to_email: 0,
    send_to_phone: 0,
    status: 1,
    type: 2,
    email_code: email_code.value,
    google_code: google_code.value,
  });
  if (res.status) {
    store.dispatch("fetchAccountInfo");
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.security2FADialog.changeSuccessToast"
      ),
    });
    model.value = false;
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: t(
        "translation.profile.security.security2FADialog.changeErrorToast"
      ),
    });
  }
};
</script>
