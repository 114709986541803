import session_manager from "@/utils/SessionManager";
import Tr from "@/i18n/translation";
import router from "@/router";

const state = {
  language: session_manager.getLanguage(),
  locale: session_manager.getLocale(),
};

const mutations = {
  SET_LANGUAGE(state, {language, locale}) {
    state.language = language;
    state.locale = locale;
    session_manager.setLanguage(language);
  },
};

const actions = {
  async setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", {
      language: language.label,
      locale: language.locale
    });
    Tr.switchLanguage(language.locale);
    try {
      await router.replace({ params: { locale: language.locale } });
    } catch (e) {
      console.error(e);
      router.push("/");
    }
  },
};

const getters = {
  currentLanguage: (state) => state.language,
  localeGetter: (state) => state.locale
};

export default {
  state,
  mutations,
  actions,
  getters,
};
