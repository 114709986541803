<template>
  <div class="relative">
    <div class="absolute -z-50 w-full -top-12">
      <img
        src="/assets/images/proof-funds/bg-ellipse.svg"
        class="w-full max-sm:hidden"
      />
      <img
        src="/assets/images/proof-funds/bg-ellipse-mobile.svg"
        class="w-full sm:hidden"
      />
    </div>
    <div class="w-full px-2 sm:px-16">
      <div class="mx-auto w-full container flex flex-col items-center">
        <div
          class="flex lg:flex-row flex-col w-full items-center justify-center gap-8 mt-[88px]"
        >
          <div class="flex flex-col w-full max-w-[646px] gap-6">
            <div
              class="font-bold sm:text-5xl leading-[64px] sm:-tracking-[1.2px] text-[48px] -tracking-[1.2px] text-center sm:text-start w-full"
            >
              {{ $t("translation.proofFunds.title") }}
            </div>
            <div
              class="font-medium text-lg -tracking-[0.4px] text-navy-50 sm:text-xl sm:leading-8 sm:text-start text-center"
            >
              {{ $t("translation.proofFunds.label") }}
            </div>
          </div>
          <div class="max-w-[468px] max-sm:hidden">
            <img
              src="/assets/images/proof-funds/lock.png"
              class="object-cover w-full"
            />
          </div>
        </div>
        <div
          class="mt-[72px] sm:mt-[120px] rounded-[32px] dark:bg-navy-700 w-full relative overflow-hidden"
        >
          <div class="absolute w-full h-full pointer-events-none">
            <img
              src="/assets/images/proof-funds/card-bg-ellipse.svg"
              class="w-full h-full max-sm:hidden"
            />
            <img
              src="/assets/images/proof-funds/card-bg-ellipse-mobile.svg"
              class="w-full h-full sm:hidden"
            />
          </div>
          <div
            class="w-full h-full sm:py-4 pt-8 px-4 sm:pr-[51px] sm:pl-28 flex sm:flex-row flex-col justify-between items-center"
          >
            <div
              class="flex flex-col sm:items-start items-center max-w-[536px]"
            >
              <div
                class="font-bold text-[40px] leading-[48px] -tracking-[1.2px] sm:text-5xl sm:leading-[64px] text-center"
              >
                {{ $t("translation.proofFunds.treeTitle") }}
              </div>
              <div
                class="mt-8 sm:mt-[72px] font-bold text-2xl leading-9 -tracking-[0.4px] sm:text-[32px] sm:leading-10 sm:-tracking-[0.8px]"
              >
                {{ $t("translation.proofFunds.treeSubTitle") }}
              </div>
              <div
                class="mt-4 font-normal text-base -tracking-[0.2px] text-center sm:text-start dark:text-navy-50"
              >
                {{ $t("translation.proofFunds.treeLabel.1") }}<br /><br />
                {{ $t("translation.proofFunds.treeLabel.2") }}<br /><br />
                {{ $t("translation.proofFunds.treeLabel.3") }}
              </div>
            </div>
            <div class="sm:max-w-[459px] max-w-[291px]">
              <img
                src="/assets/images/proof-funds/merkle-tree.png"
                class="max-sm:hidden"
              />
              <img
                src="/assets/images/proof-funds/merkle-tree-small.png"
                class="sm:hidden"
              />
            </div>
          </div>
        </div>
        <div
          class="mt-[168px] sm:mt-[257px] text-center font-bold text-[40px] leading-[48px] -tracking-[1.2px] sm:text-5xl sm:leading-[64px]"
        >
          {{ $t("translation.proofFunds.auditTitle") }}
        </div>
        <div
          class="mt-4 text-center font-medium text-lg -tracking-[0.4px] text-navy-100 sm:text-xl sm:leading-8 max-w-[831px] w-full"
        >
          {{ $t("translation.proofFunds.auditSubtitle") }}
        </div>
        <div
          class="mt-12 sm:mt-16 flex sm:flex-row flex-col items-start sm:gap-[88px] gap-12"
        >
          <div class="flex flex-col items-center sm:max-w-[308px]">
            <img src="/assets/images/proof-funds/report-icon.svg" />
            <div class="mt-4 font-semibold text-lg -tracking-[0.4px]">
              {{ $t("translation.proofFunds.auditContent.1.title") }}
            </div>
            <div
              class="mt-1 font-medium text-base -tracking-[0.2px] text-navy-100 text-center"
            >
              {{ $t("translation.proofFunds.auditContent.1.content") }}
            </div>
          </div>
          <div class="flex flex-col items-center sm:max-w-[308px]">
            <img src="/assets/images/proof-funds/monitoring-icon.svg" />
            <div class="mt-4 font-semibold text-lg -tracking-[0.4px]">
              {{ $t("translation.proofFunds.auditContent.2.title") }}
            </div>
            <div
              class="mt-1 font-medium text-base -tracking-[0.2px] text-navy-100 text-center"
            >
              {{ $t("translation.proofFunds.auditContent.2.content") }}
            </div>
          </div>
        </div>
        <div
          class="mt-8 sm:mt-16 font-medium text-lg -tracking-[0.4px] text-navy-100 sm:max-w-[912px] text-center sm:text-xl sm:leading-8"
        >
          {{ $t("translation.proofFunds.auditLabel") }}
        </div>
        <div
          class="mt-[168px] sm:mt-52 font-bold text-[40px] leading-[48px] -tracking-[1.2px] text-center sm:text-5xl sm:leading-[64px] max-w-[884px]"
        >
          {{ $t("translation.proofFunds.reasonTitle") }}
        </div>
        <div
          class="mt-12 sm:mt-16 w-full grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8"
        >
          <info-card
            v-for="item in reasonData"
            :key="item.id"
            :icon="item.icon"
            :title="item.title"
            :content="item.content"
          />
        </div>
        <div
          class="mt-[168px] sm:mt-52 font-bold text-[40px] leading-[48px] -tracking-[1.2px] text-center sm:text-5xl sm:leading-[64px] max-w-[884px]"
        >
          {{ $t("translation.proofFunds.proofTitle") }}
        </div>
        <div
          class="mt-12 sm:mt-16 w-full grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8"
        >
          <info-card
            v-for="item in proofData"
            :key="item.id"
            :icon="item.icon"
            :title="item.title"
            :content="item.content"
          />
        </div>
        <div
          class="mt-[168px] sm:mt-52 font-bold text-[40px] leading-[48px] -tracking-[1.2px] text-center sm:text-5xl sm:leading-[64px] max-w-[884px]"
        >
          {{ $t("translation.proofFunds.userTitle") }}
        </div>
        <div
          class="mt-12 sm:mt-16 w-full grid sm:grid-cols-2 grid-cols-1 gap-8 mb-[168px] sm:mb-[208px]"
        >
          <info-card
            v-for="item in userData"
            :key="item.id"
            :icon="item.icon"
            :title="item.title"
            :content="item.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import InfoCard from "@/views/ProofFunds/Items/InfoCard.vue";

const { t } = useI18n();

const reasonData = computed(() => [
  {
    id: 1,
    icon: "/assets/images/proof-funds/shield-icon.svg",
    title: t("translation.proofFunds.reasonContent.1.title"),
    content: t("translation.proofFunds.reasonContent.1.content"),
  },
  {
    id: 2,
    icon: "/assets/images/proof-funds/message-icon.svg",
    title: t("translation.proofFunds.reasonContent.2.title"),
    content: t("translation.proofFunds.reasonContent.2.content"),
  },
  {
    id: 3,
    icon: "/assets/images/proof-funds/message-icon.svg",
    title: t("translation.proofFunds.reasonContent.3.title"),
    content: t("translation.proofFunds.reasonContent.3.content"),
  },
]);

const proofData = computed(() => [
  {
    id: 1,
    icon: "/assets/images/proof-funds/shield-icon.svg",
    title: t("translation.proofFunds.proofContent.1.title"),
    content: t("translation.proofFunds.proofContent.1.content"),
  },
  {
    id: 2,
    icon: "/assets/images/proof-funds/message-icon.svg",
    title: t("translation.proofFunds.proofContent.2.title"),
    content: t("translation.proofFunds.proofContent.2.content"),
  },
  {
    id: 3,
    icon: "/assets/images/proof-funds/message-icon.svg",
    title: t("translation.proofFunds.proofContent.3.title"),
    content: t("translation.proofFunds.proofContent.3.content"),
  },
]);

const userData = computed(() => [
  {
    id: 1,
    icon: "/assets/images/proof-funds/shield-icon.svg",
    title: t("translation.proofFunds.userContent.1.title"),
    content: t("translation.proofFunds.userContent.1.content"),
  },
  {
    id: 2,
    icon: "/assets/images/proof-funds/message-icon.svg",
    title: t("translation.proofFunds.userContent.2.title"),
    content: t("translation.proofFunds.userContent.2.content"),
  },
]);
</script>
