<template>
  <div class="mt-6 flex flex-col items-center justify-center">
    <img
      :src="[
        currentTheme === 'dark'
          ? '/assets/images/market/market_logo_dark.svg'
          : '/assets/images/market/market_logo_light.svg',
      ]"
    />
    <div
      class="mt-6 sm:mt-8 text-lg font-semibold dark:text-white text-navy-800 mx-2"
    >
      {{ $t("translation.market.main.favoriteTab.loginTitle") }}
    </div>
    <div
      class="mt-3 font-normal text-base dark:text-navy-100 text-navy-300 mx-2"
    >
      {{ $t("translation.market.main.favoriteTab.loginSubtitle") }}
    </div>
    <div class="w-[303px] mt-8 sm:mt-10">
      <fill-button size="lg" width="full" @click="handleClick">
        {{ $t("translation.market.main.favoriteTab.loginButton") }}
      </fill-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const handleClick = () => {
  router.push({ name: "signup" });
};
</script>
