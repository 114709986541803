<template>
  <div class="relative">
    <div
      class="sm:mt-28 mt-12 flex justify-between flex-grow w-full md:flex-row flex-col gap-20"
    >
      <div class="flex flex-col justify-start">
        <div
          class="sm:text-[56px] sm:leading-[64px] text-5xl leading-[56px] dark:text-white text-navy-800 font-bold"
        >
          {{ $t("translation.referral.title") }}
        </div>
        <div
          class="sm:text-xl text-lgs dark:text-navy-50 text-navy-800 font-normal mt-6"
        >
          {{ $t("translation.referral.subTitle") }}
        </div>
      </div>
      <div class="sm:max-w-[526px] shrink">
        <div
          class="dark:bg-[#151823] bg-white rounded-[32px] flex w-full p-6 flex-col"
          :style="
            currentTheme === 'dark'
              ? 'box-shadow: 0px 0px 40px 0px #424D70A3'
              : 'box-shadow: 2px 4px 16px 0px #00000014'
          "
        >
          <div
            class="relative w-full h-[88px] bg-referral-card bg-[#F39D0E] rounded-2xl overflow-hidden"
          >
            <div class="absolute p-4 z-10 flex justify-between w-full h-full">
              <div class="flex flex-col">
                <div class="text-[#1B1F2D] text-xs font-semibold">
                  {{
                    $t("translation.referral.referPanel.commissionRateLabel")
                  }}:
                </div>
                <div class="text-[#1B1F2D] text-3xl font-bold">-%</div>
              </div>
            </div>
            <div class="relative w-full h-[88px] mt-2">
              <img
                src="/assets/images/referral/card.svg"
                class="object-cover rounded-2xl h-[88px] absolute bottom-0 right-0"
              />
            </div>
            <div class="w-full h-[88px] -mt-24">
              <img src="/assets/images/referral/ellipse.svg" class="h-[88px]" />
            </div>
          </div>
          <div class="sm:mt-6 mt-4">
            <text-view
              :value="referralLink"
              width="full"
              rounded="2xl"
              size="lg"
            >
              <template #prefix>
                <div
                  class="sm:text-lg text-sm text-navy-200 shrink-0 self-center mr-3 font-semibold"
                >
                  {{ $t("translation.referral.referPanel.linkLabel") }}
                </div>
              </template>
              <template #suffix>
                <span
                  class="font-semibold sm:text-lg text-sm text-right truncate block whitespace-nowrap overflow-hidden shrink w-full text-navy-200"
                >
                  Coming Soon
                </span>
              </template>
            </text-view>
          </div>
          <div class="sm:mt-6 mt-4">
            <text-view
              :value="referralCode"
              width="full"
              rounded="2xl"
              size="lg"
            >
              <template #prefix>
                <div
                  class="sm:text-lg text-sm text-navy-200 shrink-0 self-center mr-3 font-semibold"
                >
                  {{ $t("translation.referral.referPanel.codeLabel") }}
                </div>
              </template>
              <template #suffix>
                <span
                  class="font-semibold sm:text-lg text-sm text-right truncate block whitespace-nowrap overflow-hidden shrink w-full text-navy-200"
                >
                  Coming Soon
                </span>
              </template>
            </text-view>
          </div>
          <div class="w-full flex justify-between mt-8 gap-6">
            <div class="shrink-0 grow">
              <fill-button
                color="primary"
                width="full"
                :disabled="!isLoggedIn"
                v-if="!isLoggedIn"
              >
                {{
                  isLoggedIn
                    ? $t("translation.referral.referPanel.loginButton")
                    : "Coming Soon"
                }}
              </fill-button>
              <outline-button
                color="primary"
                width="full"
                v-else
                @click="handleNotifyButtonClick"
              >
                Notify me
              </outline-button>
            </div>
            <div
              className="h-14 w-14 rounded-full flex justify-center items-center border-primary-700 border-[1px] shrink-0"
            >
              <qr-code-icon class="text-primary-700 h-6 w-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <notify-success-dialog v-model="showNotifyDialog" />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { QrCodeIcon } from "@heroicons/vue/24/outline";
import TextView from "@/components/TextEdit/TextView.vue";
import NotifySuccessDialog from "@/views/Referral/NotifySuccessDialog.vue";

const store = useStore();
const showNotifyDialog = ref(false);
const referralLink = computed(() => store.getters.referralLink);
const referralCode = computed(() => store.getters.referralCode);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const handleNotifyButtonClick = () => {
  showNotifyDialog.value = true;
};
</script>
