<template>
  <div>
    <phone-input
      :label="$t('translation.auth.signin.phoneLogin.phoneLabel')"
      :placeholder="$t('translation.auth.signin.phoneLogin.phonePlaceholder')"
      v-model="phone"
    />
    <div class="h-6" />
    <password-input
      :label="$t('translation.auth.signin.emailLogin.passwordLabel')"
      :placeholder="
        $t('translation.auth.signin.emailLogin.passwordPlaceholder')
      "
      v-model="password"
      :error="passwordError"
      :hideValidator="true"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { validatePassword } from "@/views/Auth/validation";
import PhoneInput from "@/components/PhoneInput/PhoneInput.vue";

const phone = ref("");
const password = ref("");
const emit = defineEmits(["changePhone", "changePassword"]);

const passwordError = computed(() => validatePassword(password.value));

watch(phone, (newPhone) => {
  emit("changePhone", newPhone);
});

watch(password, (newPassword) => {
  emit("changePassword", newPassword);
});
</script>
