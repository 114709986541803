<template>
  <dialog-view
    v-if="model"
    :is-open="model"
    :contain-header="false"
    bg-color="gray"
    @change-is-open="handleChangeSuccessDialog"
  >
    <template #header>
      <div class="flex flex-col">
        <div class="relative w-full">
          <div class="absolute top-4 right-4">
            <x-mark-icon
              class="w-8 h-8 dark:text-navy-100 cursor-pointer"
              @click="handleChangeSuccessDialog"
            />
          </div>
          <div class="top-0 -z-50 overflow-x-hidden h-[272px] rounded-t-3xl">
            <img
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/career/career_apply_success_dark.svg'
                  : '/assets/images/career/career_apply_success_light.svg'
              "
              class="h-full w-auto object-none"
            />
          </div>
          <div class="absolute top-20 w-full">
            <img
              class="mx-auto"
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/career/career_apply_check_dark.svg'
                  : '/assets/images/career/career_apply_check_light.svg'
              "
            />
            <div
              class="text-xl font-semibold text-white mt-4 mx-auto text-center"
            >
              Thanks! We'll notify you.
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="flex flex-col items-center justify-center p-6 sm:gap-12 gap-6 w-full"
      >
        <div class="w-full">
          <fill-button
            size="lg"
            color="primary"
            width="full"
            @click="handleButtonClick"
          >
            Got it
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const model = defineModel();

const handleChangeSuccessDialog = () => {
  model.value = false;
};

const handleButtonClick = () => {
  model.value = false;
};
</script>
