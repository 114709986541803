import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import { DEFAULT_LOCALE } from "@/constants/constants";

export default createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: { en },
  runtimeOnly: false,
});
