<template>
  <div class="w-full sm:mb-[200px] mb-[100px]">
    <breadcrumb-menu :items="breadcrumbItems" />
    <p class="flex gap-6 mt-6 mb-12 w-fit">
      <arrow-left-icon class="w-8 h-8 cursor-pointer" @click="goToBack" />
      <span class="text-2xl font-semibold">{{
        $t("translation.profile.security.profileSecurityPhonePage.title")
      }}</span>
    </p>

    <div class="max-w-[480px]">
      <div class="flex flex-col mb-10">
        <phone-input
          :label="
            $t(
              'translation.profile.security.profileSecurityPhonePage.phoneLabel'
            )
          "
          :placeholder="
            $t(
              'translation.profile.security.profileSecurityPhonePage.phonePlaceholder'
            )
          "
          v-model="phone"
        >
          <div class="shrink-0">
            <link-button
              :label="
                $t(
                  'translation.profile.security.profileSecurityPhonePage.sendCodeButton'
                )
              "
              @click="handleClickSendCode"
              :disabled="!canSend"
            />
          </div>
        </phone-input>
        <div class="mt-8" />
        <digit-code :error="error" @on-complete="handleChangeCode" />
        <div class="mt-6 mb-10" />
        <p class="text-navy-200 underline cursor-pointer" @click="handleResend">
          {{
            $t(
              "translation.profile.security.profileSecurityPhonePage.cantReceiveCode"
            )
          }}
        </p>
        <fill-button
          class="mt-12"
          size="lg"
          width="full"
          color="primary"
          :disabled="!canChangeNumber"
          @click="handleChangeNumberClick"
        >
          {{
            $t(
              "translation.profile.security.profileSecurityPhonePage.confirmButton"
            )
          }}
        </fill-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
import BreadcrumbMenu from "@/components/Breadcrumb/BreadcrumbMenu.vue";
import DigitCode from "@/components/DigitCode/DigitCode.vue";
import PhoneInput from "@/components/PhoneInput/PhoneInput.vue";
import { postHttpRequest } from "@/api/api";
import { validatePhoneNumber } from "@/views/Auth/validation";

const { t } = useI18n();
const router = useRouter();
const phone = ref("");
const store = useStore();
const error = ref(false);
const verificationCode = ref("");
const canChangeNumber = ref(false);
const canSend = computed(() => {
  return validatePhoneNumber(phone.value);
});

const breadcrumbItems = computed(() => [
  {
    id: 1,
    label: t(
      "translation.profile.security.profileSecurityPhonePage.breadcrumb.0"
    ),
    pathName: "security",
  },
  {
    id: 1,
    label: t(
      "translation.profile.security.profileSecurityPhonePage.breadcrumb.1"
    ),
    pathName: "change-phone",
    active: true,
  },
]);

const goToBack = () => {
  router.push({ name: "security" });
};

const handleClickSendCode = async () => {
  const res = await postHttpRequest(store)("/api/account/change-phone-number", {
    send_to_email: 1,
    send_to_phone: 1,
    new_user_phone: phone.value,
  });
  if (res.status) {
    canChangeNumber.value = true;
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.profileSecurityPhonePage.sendSuccessToast"
      ),
    });
  } else {
    canChangeNumber.value = false;
    store.dispatch("setToast", {
      type: "error",
      message: t(
        "translation.profile.security.profileSecurityPhonePage.sendFailToast"
      ),
    });
  }
};

const handleChangeCode = async (value) => {
  verificationCode.value = value;
};

const handleResend = async () => {};

const handleChangeNumberClick = async () => {
  const res = await postHttpRequest(store)("/api/account/change-phone-number", {
    send_to_email: 0,
    send_to_phone: 0,
    new_user_phone: phone.value,
    email_code: verificationCode.value,
  });
  if (res.status) {
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.profileSecurityPhonePage.changeSuccessToast"
      ),
    });
    goToBack();
  }
};
</script>
