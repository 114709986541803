<template>
  <div ref="animationController" class="lottie-container" />
</template>
<script setup>
import { defineProps, onMounted, ref } from "vue";
import Lottie from "lottie-web";

const props = defineProps({
  path: {
    type: String,
    required: true,
  },
});

const animationController = ref(null);

onMounted(() => {
  Lottie.loadAnimation({
    container: animationController.value,
    path: props.path,
    renderer: "svg",
    loop: true,
    autoplay: true,
  });
});
</script>
<style>
.lottie-container {
  width: 100%;
  height: 100%;
}
</style>
