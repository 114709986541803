<template>
  <div class="bg-navy-10 dark:bg-transparent pb-24">
    <div class="px-2">
      <pair-brief />
      <div class="h-2" />
      <div
        :class="[
          'flex flex-col gap-2',
          layout === 'right' ? 'sm:flex-row' : 'sm:flex-row-reverse',
        ]"
      >
        <div class="flex-1">
          <pair-detail :all-pair-items="allPairItems" />
          <div class="h-2" />
          <div class="flex sm:flex-row flex-col gap-2">
            <div class="flex-1 sm:min-w-[800px]">
              <container-view
                :header-items="chatHeaderItems"
                :default-seleted-item-index="
                  route.params.overview === 'overview' ? 1 : 0
                "
                @select-tab="handleSelectChartTab"
              >
                <template #content-1>
                  <advanced-chart />
                </template>
                <template #content-2>
                  <overview-detail />
                </template>
                <template #content-3>
                  <div class="sm:hidden block">
                    <order-book-list />
                  </div>
                </template>
                <template #content-4>
                  <div class="sm:hidden block">
                    <market-trades />
                  </div>
                </template>
              </container-view>
            </div>
            <div class="min-w-[288px] sm:block hidden">
              <container-view :header-items="orderBookHeaderItems">
                <template #content-1>
                  <order-book-list />
                </template>
                <template #content-2>
                  <market-trades />
                </template>
              </container-view>
            </div>
          </div>
          <div class="mt-2 sm:block hidden">
            <container-view :header-items="orderHistoryHeaderItems">
              <template #content-1><open-orders /></template>
              <template #content-2>
                <order-history :hide-cancelled="hideCancelled" />
              </template>
              <template #content-3><funds-view /></template>

              <template #extra-header>
                <div
                  class="flex text-xs gap-2 items-center dark:text-navy-100 text-navy-200"
                >
                  <div class="w-4 h-4">
                    <check-box @change="handleChangeHide" size="sm" />
                  </div>
                  <span>{{ $t("translation.trading.hideAllCancel") }}</span>
                </div>
              </template>
            </container-view>
          </div>
        </div>
        <div class="min-w-[288px] sm:block hidden">
          <container-view :header-items="actionHeaderItems">
            <template #content-1>
              <buy-sell />
            </template>
          </container-view>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <mobile-footer class="sm:hidden block" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed, watch, watchEffect, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { DEFAULT_PAIR_TO_SHOW } from "@/constants/constants";
import { isEmpty, allPairItemsFromData } from "@/utils/utils";
import ContainerView from "@/views/Trading/ContainerView.vue";
import PairBrief from "@/views/Trading/PairBrief.vue";
import PairDetail from "@/views/Trading/PairDetail.vue";
import OrderBookList from "@/views/Trading/OrderBook/OrderBookList/OrderBookList.vue";
import MarketTrades from "@/views/Trading/OrderBook/MarketTrades/MarketTrades.vue";
import AdvancedChart from "@/views/Trading/Chart/AdvancedChart.vue";
import OverviewDetail from "@/views/Trading/OverviewDetail.vue";
import BuySell from "@/views/Trading/BuySell/BuySell.vue";
import OpenOrders from "@/views/Trading/OrderHistory/OpenOrders.vue";
import OrderHistory from "@/views/Trading/OrderHistory/OrderHistory.vue";
import FundsView from "@/views/Trading/OrderHistory/FundsView.vue";
import MobileFooter from "@/views/Trading/MobileFooter.vue";

const { t } = useI18n();

const chatHeaderItems = computed(() => [
  {
    id: 1,
    name: t("translation.trading.panelHeaders.chart"),
    value: "chart",
    shouldHide: false,
  },
  {
    id: 2,
    name: t("translation.trading.panelHeaders.overview"),
    value: "overview",
    shouldHide: false,
  },
  {
    id: 3,
    name: t("translation.trading.panelHeaders.orderbook"),
    value: "orderbook",
    shouldHide: true,
  },
  {
    id: 4,
    name: t("translation.trading.panelHeaders.recentTrades"),
    value: "marketTrades",
    shouldHide: true,
  },
]);

const orderBookHeaderItems = computed(() => [
  {
    id: 1,
    name: t("translation.trading.panelHeaders.orderbook"),
    value: "orderbook",
  },
  {
    id: 2,
    name: t("translation.trading.panelHeaders.recentTrades"),
    value: "marketTrades",
  },
]);

const orderHistoryHeaderItems = computed(() => [
  {
    id: 1,
    name: `${t("translation.trading.panelHeaders.openOrders")} (${
      openOrderHistoryLength.value
    })`,
    value: "openOrders",
  },
  {
    id: 2,
    name: t("translation.trading.panelHeaders.orderHistory"),
    value: "orderHistory",
  },
  { id: 3, name: t("translation.trading.panelHeaders.funds"), value: "funds" },
]);

const actionHeaderItems = computed(() => [
  { id: 1, name: t("translation.trading.panelHeaders.spot"), value: "spot" },
]);

const store = useStore();
const route = useRoute();
const router = useRouter();
const selectedPair = computed(() => store.getters.selectedPair?.main.iso3);
const pair = computed(() =>
  isEmpty(route.params.pair) ? DEFAULT_PAIR_TO_SHOW : route.params.pair
);

const layout = computed(() => store.getters.chartTradingPanel);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const allPairs = computed(() => store.getters.allPairsModified);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const openOrderHistoryLength = computed(
  () => store.getters.openOrderHistory?.list?.length ?? 0
);
const allPairItems = computed(() =>
  allPairItemsFromData(allPairs.value, activeCurrencies.value)
);

const intervalId = ref();
const hideCancelled = ref(false);

watchEffect(() => {
  const pairInfo = allPairItems.value.find((item) => item.name === pair.value);
  if (!pairInfo) {
    const pairInfo = allPairItems.value.find(
      (item) => item.name === DEFAULT_PAIR_TO_SHOW
    );
    store.dispatch("setSelectedPair", pairInfo);
  } else {
    store.dispatch("setSelectedPair", pairInfo);
  }
});

watch(selectedPair, () => {
  // if (selectedPair.value) {
  //   store.dispatch("fetchPairDetail", selectedPair.value);
  // }
});

onMounted(() => {
  if (isLoggedIn.value) {
    store.dispatch("fetchOrderHistory");
    store.dispatch("fetchOpenOrderHistory");
  }
  intervalId.value = setInterval(() => {
    store.dispatch("fetchPairDataNonBlocking");
  }, 2000);
});

const handleChangeHide = (changed) => {
  hideCancelled.value = changed;
};

const handleSelectChartTab = (tab) => {
  switch (tab.value) {
    case "chart":
      router.push({ name: "trading", params: { pair: pair.value } });
      break;
    case "overview":
      router.push({
        name: "trading",
        params: { pair: pair.value, overview: "overview" },
      });
      break;
  }
};

onUnmounted(() => {
  clearInterval(intervalId.value);
});
</script>
