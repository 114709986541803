<template>
  <div v-if="!isEmpty(selectedPair)" class="p-4">
    <circle-tab :items="actionItems" @select="handleSelectActionTab" />
    <div class="h-4" />
    <text-tab :items="typeItems" @select="handleSelectTextTab" />
    <div class="h-4" />

    <limit-view v-model="inputData" />

    <!-- Total: disabled -->
    <div class="h-6" />
    <text-edit
      v-model="total"
      size="sm"
      rounded="xl"
      :topPlaceholder="true"
      type="number"
    >
      <template #prefix>
        <div class="w-2" />
      </template>
      <template #suffix>
        <p class="text-sm font-semibold m-auto text-navy-100">
          {{ selectedPair?.second.iso3 }}
        </p>
      </template>
      <template #floatingLabel>
        <div class="font-medium text-navy-200">
          {{ $t("translation.trading.buySell.totalPlaceholder") }}
        </div>
      </template>
    </text-edit>

    <div class="text-xs font-medium mb-6">
      <div class="mt-3 flex justify-between">
        <p class="dark:text-navy-100 text-navy-200">
          {{ $t("translation.trading.buySell.availableBalance") }}
        </p>
        <p class="dark:text-white text-navy-800">
          {{ availableBalance.value }} {{ availableBalance.unit }}
        </p>
      </div>
      <div class="mt-3 flex justify-between">
        <p class="dark:text-navy-100 text-navy-200">
          {{ $t("translation.trading.buySell.maxValue") }}. {{ actionTab.name }}
        </p>
        <p class="dark:text-white text-navy-800">
          <!-- Need to update with real data -->
          {{ max.value }} {{ max.unit }}
        </p>
      </div>
    </div>

    <template v-if="!isLoggedIn">
      <fill-button
        width="full"
        size="sm"
        :color="actionTab.name == 'Buy' ? 'buy' : 'red2'"
        @click="handleLoginOrSignUp"
      >
        <div class="text-base font-semibold">
          {{ $t("translation.trading.buySell.loginOrSignup") }}
        </div>
      </fill-button>
    </template>
    <template v-else>
      <fill-button
        width="full"
        size="sm"
        :color="actionTab.color"
        @click="handleBuySell"
      >
        <div class="text-base font-semibold">{{ actionTab.name }}</div>
      </fill-button>

      <div class="my-8 h-[1px] dark:bg-navy-500 bg-navy-10" />

      <div>
        <p class="dark:text-white text-navy-800 text-sm font-medium mb-4">
          {{ $t("translation.trading.buySell.assetOverview") }}
        </p>
        <div class="my-3 flex justify-between items-center">
          <div class="flex items-center gap-3">
            <img
              class="size-6 rounded-full"
              :src="selectedPair.main.icon"
              alt="Main Token Icon"
            />
            <div>
              <p class="text-sm font-semibold">{{ selectedPair.main.iso3 }}</p>
              <p class="text-xs font-medium">{{ selectedPair.main.name }}</p>
            </div>
          </div>
          <div class="text-xs text-right">
            <p>{{ mainTokenInfo[0] }}</p>
            <p class="mt-1 text-navy-100">${{ mainTokenInfo[1] }}</p>
          </div>
        </div>
        <div class="mb-6 flex justify-between items-center">
          <div class="flex items-center gap-3">
            <img
              class="size-6 rounded-full"
              :src="selectedPair.second.icon"
              alt="Second Token Icon"
            />
            <div>
              <p class="text-sm font-semibold">
                {{ selectedPair.second.iso3 }}
              </p>
              <p class="text-xs font-medium">{{ selectedPair.second.name }}</p>
            </div>
          </div>
          <div class="text-xs text-right">
            <p>{{ secondTokenInfo[0] }}</p>
            <p class="mt-1 text-navy-100">${{ secondTokenInfo[1] }}</p>
          </div>
        </div>
        <div class="flex gap-2">
          <outline-button
            width="full"
            size="xs"
            @click="
              () => {
                router.push({ name: 'asset-deposit-crypto' });
              }
            "
          >
            {{ $t("translation.trading.buySell.actionTypeDeposit") }}
          </outline-button>
          <outline-button
            width="full"
            size="xs"
            @click="
              () => {
                store.dispatch('setTransferData', {});
              }
            "
          >
            {{ $t("translation.trading.buySell.actionTypeTransfer") }}
          </outline-button>
        </div>
      </div>
    </template>
    <dialog-view
      v-if="confirmTransfer"
      :is-open="confirmTransfer"
      :title="`${tab.name} Order`"
      @change-is-open="handleClose"
    >
      <template #content>
        <div class="mt-6">
          <p class="dark:text-white text-navy-800 font-semibold text-2xl">
            {{ selectedPair.main.iso3 }}/{{ selectedPair.second.iso3 }}
          </p>
          <p class="mt-2 font-semibold">
            <span
              :class="[inputData.type == 0 ? 'text-trade-buy' : 'text-red-500']"
              >{{
                inputData.type == 0
                  ? $t("translation.trading.buySell.actionTypeBuy")
                  : $t("translation.trading.buySell.actionTypeSell")
              }}
            </span>
            <span> • {{ TRADE_TYPES[inputData.type_trade] }}</span>
          </p>
          <div class="mt-8 text-sm flex flex-col gap-3">
            <p
              v-for="item in confirmItems"
              :key="item.id"
              class="flex justify-between"
            >
              <span class="dark:text-navy-100 text-navy-200">
                {{ item.name }}
              </span>
              <span class="dark:text-white text-navy-800">
                {{ item.value }}
              </span>
            </p>
          </div>
          <div class="mt-8 flex gap-2">
            <div class="w-5 h-5">
              <check-box />
            </div>
            <p class="text-sm dark:text-navy-100 text-navy-200">
              Don't remind me again
            </p>
          </div>
          <div class="mt-12 flex gap-6">
            <outline-button
              class="basis-1/2"
              size="md"
              width="full"
              color="primary"
              @click="handleClose"
            >
              Cancel
            </outline-button>
            <fill-button
              size="md"
              width="full"
              color="primary"
              @click="handleConfirm"
            >
              Confirm
            </fill-button>
          </div>
        </div>
      </template>
    </dialog-view>
  </div>
</template>
<script setup>
import { ref, watch, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { postHttpRequest } from "@/api/api";
import { isEmpty } from "@/utils/utils";
import { numberAddCommas } from "@/utils/NumberFormating";
import CircleTab from "@/components/Tab/CircleTab.vue";
import TextTab from "@/components/Tab/TextTab.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import LimitView from "@/views/Trading/BuySell/LimitView.vue";
import { TRADE_TYPES } from "@/constants/constants";

const { t } = useI18n();

const actionItems = computed(() => [
  {
    id: 0,
    name: "Buy",
    label: t("translation.trading.buySell.actionTypeBuy"),
    value: "buy",
    bgColor: "bg-green-400 hover:bg-green-500",
    textColor: "text-green-900",
    color: "buy",
  },
  {
    id: 1,
    name: "Sell",
    label: t("translation.trading.buySell.actionTypeSell"),
    value: "sell",
    bgColor: "bg-red-500 hover:bg-red-600",
    textColor: "text-white",
    color: "red2",
  },
]);

const typeItems = computed(() => [
  { id: 0, name: "Limit", label: t("translation.trading.buySell.typeLimit") },
  { id: 1, name: "Market", label: t("translation.trading.buySell.typeMarket") },
  {
    id: 2,
    name: "Stop Limit",
    label: t("translation.trading.buySell.typeStopLimit"),
  },
]);

const confirmItems = computed(() => [
  {
    id: 1,
    name: t("translation.trading.buySell.transferDialogPrice"),
    value: `${inputData.value.rate} ${selectedPair.value.second.iso3}`,
  },
  {
    id: 2,
    name: t("translation.trading.buySell.transferDialogQuantity"),
    value: `${inputData.value.volume} ${selectedPair.value.main.iso3}`,
  },
  {
    id: 3,
    name: t("translation.trading.buySell.transferDialogTotal"),
    value: `${inputData.value.total} ${selectedPair.value.second.iso3}`,
  },
  // need to change for time
  {
    id: 4,
    name: t("translation.trading.buySell.transferDialogTimeInForce"),
    value: inputData.value.rate,
  },
]);

const router = useRouter();
const store = useStore();
const balances = computed(() => store.getters.balancesVolumeModified);
const selectedPair = computed(() => store.getters.selectedPair);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const mainTokenInfo = computed(() => {
  if (!selectedPair.value || !balances.value) return [0, 0];
  const balanceInfo = balances.value[selectedPair.value.main.id];
  if (!balanceInfo) {
    return [0, 0];
  } else {
    return [
      numberAddCommas(
        balanceInfo.balance_available / Math.pow(10, balanceInfo.decimal),
        balanceInfo.decimal
      ),
      numberAddCommas(
        balanceInfo.total / Math.pow(10, balanceInfo.decimal),
        balanceInfo.decimal
      ),
    ];
  }
});

const secondTokenInfo = computed(() => {
  if (!selectedPair.value || !balances.value) return [0, 0];
  const balanceInfo = balances.value[selectedPair.value.second.id];
  if (!balanceInfo) {
    return [0, 0];
  } else {
    return [
      numberAddCommas(
        balanceInfo.balance_available / Math.pow(10, balanceInfo.decimal),
        balanceInfo.decimal
      ),
      numberAddCommas(
        balanceInfo.total / Math.pow(10, balanceInfo.decimal),
        balanceInfo.decimal
      ),
    ];
  }
});

const actionTab = ref(actionItems.value[0]);
const tab = ref(typeItems.value[0]);
const availableBalance = computed(() => {
  if (!selectedPair.value) return [0, 0];
  return actionTab.value.id === 0
    ? {
        value: secondTokenInfo.value[0],
        unit: selectedPair.value.second.iso3,
      }
    : {
        value: mainTokenInfo.value[0],
        unit: selectedPair.value.main.iso3,
      };
});
const max = computed(() => {
  if (!selectedPair.value)
    return {
      value: 0,
      unit: "",
    };
  return actionTab.value.id === 0
    ? {
        value: !selectedPair.value.rate_f
          ? 0
          : numberAddCommas(
              secondTokenInfo.value[0] / selectedPair.value.rate_f,
              selectedPair.value.main.decimal
            ),
        unit: selectedPair.value.main.iso3,
      }
    : {
        value: !selectedPair.value.rate_f
          ? 0
          : numberAddCommas(
              mainTokenInfo.value[0] * selectedPair.value.rate_f,
              selectedPair.value.second.decimal
            ),
        unit: selectedPair.value.second.iso3,
      };
});

const inputData = ref({
  type_trade: 0, // Limit, Market, Stop Limit
  type: 0, // Buy or Sell
  currency_pair_id: "",
  rate: null,
  stop_rate: null,
  volume: null,
  total: null,
  cost: 0,
  max: 0,
});

const confirmTransfer = ref(false);

const total = ref(null);

watch(
  () => inputData.value.total,
  (cur) => {
    total.value = cur;
  }
);

watch(
  () => total.value,
  (nextValue) => {
    if (
      inputData.value.rate === 0 ||
      nextValue < 0 ||
      inputData.value.rate === null
    ) {
      return;
    }
    inputData.value.volume = nextValue / inputData.value.rate;
  }
);

watch(selectedPair, () => {
  inputData.value.currency_pair_id = selectedPair.value.id;
});

watch(max, () => {
  inputData.value.max =
    actionTab.value.id === 0 ? max.value.value : availableBalance.value.value;
});

watchEffect(() => {
  if (inputData.value.volume === null || inputData.value.rate === null) {
    return;
  }
  if (inputData.value.type == 0) {
    inputData.value.total = inputData.value.volume * inputData.value.rate;
  } else {
    inputData.value.total = inputData.value.volume * inputData.value.rate;
  }
});

// Buy or Sell
const handleSelectActionTab = (item) => {
  actionTab.value = item;
  inputData.value.volume = null;
  inputData.value.type = item.id;
};

// Limit, Market, or Stop Limit
const handleSelectTextTab = (item) => {
  tab.value = item;
  inputData.value.volume = null;
  inputData.value.type_trade = item.id;
  if (item.id === typeItems.value[1].id) {
    inputData.value.rate = selectedPair.value.rate_f;
  } else {
    inputData.value.rate = null;
  }
};

const handleLoginOrSignUp = () => {
  router.push({ name: "signup" });
};

const handleBuySell = () => {
  if (inputData.value.total === null) {
    return;
  }
  confirmTransfer.value = true;
};

const handleClose = () => {
  confirmTransfer.value = false;
};

const handleConfirm = async () => {
  confirmTransfer.value = false;
  inputData.value.cost = inputData.value.volume;
  await postHttpRequest(store)("/api/p2p/create", {
    ...inputData.value,
    rate: `${Number(inputData.value.rate).toFixed(8)}`,
    stop_rate: `${Number(inputData.value.stop_rate).toFixed(8)}`,
    volume: `${Number(inputData.value.volume).toFixed(4)}0000`,
    total: `${Number(inputData.value.total).toFixed(8)}`,
    cost: `${Number(inputData.value.cost).toFixed(8)}`,
    max: `${Number(max.value.value).toFixed(8)}`,
  });
};
</script>
