<template>
  <div class="relative w-full">
    <div class="absolute overflow-y-visible -z-50 w-full top-36">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_background_dark.svg'
            : '/assets/images/launchpad/launchpad_background_light.svg',
        ]"
        class="w-full"
      />
    </div>
    <div class="absolute -z-40 w-full">
      <img
        class="mx-auto"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_ellipse_dark.svg'
            : '/assets/images/launchpad/launchpad_ellipse_light.svg',
        ]"
      />
    </div>
    <div
      class="absolute -z-50 h-full right-0 top-0 w-1/2 bg-gradient-to-r from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-full left-0 top-0 w-1/2 bg-gradient-to-l from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-screen left-0 top-0 w-full bg-gradient-to-b from-transparent dark:to-navy-800 to-white"
    />
    <div class="w-full container px-4 sm:px-16 mx-auto">
      <div
        class="max-w-[1088px] mx-auto flex flex-col items-center justify-center"
      >
        <div
          class="w-full flex sm:flex-row flex-col items-start justify-between gap-4 mt-12 sm:mt-[104px]"
        >
          <div
            class="flex flex-col items-start justify-center gap-6 max-w-[660px]"
          >
            <div
              class="dark:text-white text-navy-800 font-bold text-5xl sm:text-[56px]"
            >
              {{ $t("translation.launchpad.home.title") }}
            </div>
            <div
              class="text-lg font-medium sm:text-xl sm:font-normal dark:text-navy-50 text-navy-300"
            >
              {{ $t("translation.launchpad.home.subTitle") }}
            </div>
          </div>
          <div class="shrink-0 w-full sm:w-fit">
            <fill-button
              width="full"
              size="md"
              color="navy"
              @click="handleClickSubscriptionHistory"
            >
              <roll-back-icon />
              <div>
                {{ $t("translation.launchpad.home.subscriptionHistory") }}
              </div>
            </fill-button>
          </div>
        </div>
        <div
          class="w-full flex md:flex-row flex-col items-center justify-between gap-8 sm:mt-10 mt-8"
        >
          <div class="shrink-0 w-full md:w-fit">
            <fill-button width="full" size="lg" color="primary">
              {{ $t("translation.launchpad.home.shareFriend") }}
            </fill-button>
          </div>
          <div
            class="flex flex-row items-center justify-between md:flex-grow md:max-w-[566px] w-full"
            v-if="!isEmpty(launchpadProjects)"
          >
            <div class="flex flex-col gap-2">
              <div
                class="dark:text-white text-navy-800 md:text-[32px] font-semibold text-2xl"
              >
                $1,593,554
              </div>
              <div class="text-lg font-normal dark:text-white text-navy-800">
                {{ $t("translation.launchpad.home.overview.0") }}
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div
                class="dark:text-white text-navy-800 md:text-[32px] font-semibold text-2xl"
              >
                326,792
              </div>
              <div class="text-lg font-normal dark:text-white text-navy-800">
                {{ $t("translation.launchpad.home.overview.1") }}
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div
                class="dark:text-white text-navy-800 md:text-[32px] font-semibold text-2xl"
              >
                13
              </div>
              <div class="text-lg font-normal dark:text-white text-navy-800">
                {{ $t("translation.launchpad.home.overview.2") }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full" v-if="!isEmpty(launchpadProjects)">
          <div
            class="flex flex-row items-center mt-28 sm:mt-[152px] mb-8 sm:mb-12 w-full gap-4"
          >
            <fire-icon class="text-red-500 w-6 h-6 stroke-2" />
            <div class="dark:text-white text-navy-800 text-2xl font-semibold">
              {{ $t("translation.launchpad.home.liveProject") }}
            </div>
          </div>
          <div class="flex flex-col items-center justify-center w-full gap-12">
            <live-project-overview-item
              v-for="(item, index) in showLaunchpadProject"
              :key="index"
              :icon="item.image"
              :full-name="item.name"
              :short-name="item.main_iso"
              :id="item.id"
              left-time="2 Days 18:09:57"
            />
            <div class="sm:w-fit w-full">
              <outline-button
                size="lg"
                color="primary"
                width="full"
                @click="handleViewMoreLiveProjects"
              >
                {{ $t("translation.launchpad.home.viewMore") }}
              </outline-button>
            </div>
          </div>
          <div
            class="flex flex-col w-full sm:gap-12 gap-6 sm:mt-52 mt-[104px] mb-52"
          >
            <div class="flex flex-row items-center self-start gap-4">
              <div class="dark:text-white text-navy-800 text-2xl font-semibold">
                {{ $t("translation.launchpad.home.previousProject") }}
              </div>
              <div class="text-navy-200 text-2xl font-semibold">(19)</div>
            </div>
            <div class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
              <previous-projects-overview-item
                v-for="(item, index) in showPreviousProject"
                :key="index"
                :icon="item.image"
                :full-name="item.name"
                :short-name="item.main_iso"
              />
            </div>
            <div class="mt-8 sm:mt-12 sm:w-fit w-full mx-auto">
              <outline-button
                size="lg"
                color="primary"
                width="full"
                @click="handleViewMorePrivousProjects"
              >
                {{ $t("translation.launchpad.home.viewMore") }}
              </outline-button>
            </div>
          </div>
        </div>
        <div class="relative w-full mt-28 mb-32" v-else>
          <div class="relative overflow-hidden rounded-3xl select-none">
            <live-project-overview-item
              :icon="fakeDataProject.image"
              :full-name="fakeDataProject.name"
              :short-name="fakeDataProject.main_iso"
              left-time="2 Days 18:09:57"
              class="blur-[4px]"
            />
            <div
              class="dark:bg-gradient-to-t dark:from-navy-800/[0.75] dark:to-navy-800/[0.55] z-10 absolute w-full h-full left-0 top-0 rounded-3xl"
            />
          </div>
          <div
            class="font-semibold text-[32px] dark:text-[#6e747d] text-navy-800 left-0 top-0 w-full h-full flex items-center justify-center text-center absolute z-20"
          >
            {{ $t("translation.launchpad.home.noProject") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";
import LiveProjectOverviewItem from "@/views/Launchpad/LiveProjectOverviewItem.vue";
import PreviousProjectsOverviewItem from "@/views/Launchpad/PreviousProjectsOverviewItem.vue";
import RollBackIcon from "@/assets/icons/common/RollBackIcon.vue";
import { FireIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();
const launchpadProjects = computed(() => []);
const fakeDataProject = computed(() => store.getters.fakeData);
const numberShowLiveProjects = ref(2);
const numberPreviousProjects = ref(6);

const showLaunchpadProject = computed(() => {
  if (isEmpty(launchpadProjects.value)) {
    return;
  }
  const length = launchpadProjects.value.length;
  return launchpadProjects.value.slice(
    0,
    Math.min(numberShowLiveProjects.value, length)
  );
});

const showPreviousProject = computed(() => {
  if (isEmpty(launchpadProjects.value)) {
    return;
  }
  const length = launchpadProjects.value.length;
  return launchpadProjects.value.slice(
    0,
    Math.min(numberPreviousProjects.value, length)
  );
});

const handleViewMorePrivousProjects = () => {
  numberPreviousProjects.value = Math.min(
    numberPreviousProjects.value + 3,
    launchpadProjects.value.length
  );
};

const handleViewMoreLiveProjects = () => {
  numberShowLiveProjects.value = Math.min(
    numberShowLiveProjects.value + 3,
    launchpadProjects.value.length
  );
};

const handleClickSubscriptionHistory = () => {
  router.push({ name: "launchpad-subscription-history" });
};

onMounted(() => {
  store.dispatch("fetchLaunchpads");
  store.dispatch("fetchFakeData");
});
</script>
