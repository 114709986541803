<template>
  <div class="w-full relative">
    <div class="absolute w-full -z-50 left-0 -top-12">
      <img
        alt=""
        class="w-full"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/announcement/announcement_background_dark.svg'
            : '/assets/images/announcement/announcement_background_light.svg',
        ]"
      />
    </div>
    <div class="px-4 sm:px-16 w-full">
      <div class="flex flex-col sm:max-w-[1088px] w-full mx-auto">
        <div
          class="flex flex-col md:flex-row gap-8 w-full justify-between mb-6 sm:mb-24 mt-10 sm:mt-16"
        >
          <div class="flex flex-col max-w-[769px]">
            <div
              class="dark:text-white text-navy-800 text-[40px] font-bold sm:text-[56px] max-sm:text-center"
            >
              {{ $t("translation.announcement.title") }}
            </div>
            <div
              class="mt-3 sm:mt-6 text-lg font-medium sm:text-xl sm:font-normal dark:text-navy-50 text-navy-400 max-sm:text-center"
            >
              {{ $t("translation.announcement.subTitle") }}
            </div>
          </div>
          <div class="sm:w-72 w-full shrink-0">
            <text-edit
              type="text"
              size="sm"
              rounded="full"
              :placeholder="$t('translation.announcement.searchPlaceholder')"
              :filled="true"
              v-model="searchText"
            >
              <template #prefix>
                <div class="mr-2">
                  <magnifying-glass-icon
                    :class="[
                      searchText.length > 0
                        ? 'dark:text-white text-navy-800'
                        : 'text-navy-200',
                      'w-6 h-6',
                    ]"
                  />
                </div>
              </template>
              <template #suffix v-if="searchText.length > 0">
                <div
                  class="ml-2"
                  @click="
                    () => {
                      searchText = '';
                    }
                  "
                >
                  <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
                </div>
              </template>
            </text-edit>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps } from "vue";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";

const searchText = ref("");
const props = defineProps(["handleChange"]);
watch(
  () => searchText.value,
  (newValue) => {
    props.handleChange(newValue);
  }
);
</script>
