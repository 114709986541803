<template>
  <div
    v-if="!isEmpty(allPairItems)"
    :class="[
      'max-h-[576px] p-4 flex flex-col bg-white dark:bg-navy-600',
      displayOnlyPairs ? 'w-[304px]' : 'w-[410px]',
    ]"
  >
    <!-- Search Input -->
    <text-edit
      type="text"
      size="sm"
      rounded="full"
      :placeholder="$t('translation.common.pairDropdown.searchPlaceholder')"
      :filled="true"
      v-model="pairInput"
    >
      <template #prefix>
        <magnifying-glass-icon class="w-6 h-6 mr-2 text-navy-100" />
      </template>
    </text-edit>
    <div class="my-2" />

    <underline-tab
      :default-selected="isLoggedIn ? searchItems[1] : searchItems[0]"
      :items="searchItems"
      :bottom-line="true"
      :show-arrows="true"
      @change="handleChangeSelectedTab"
    />
    <div class="border dark:border-navy-500 border-navy-10" />
    <div class="my-2" />

    <div class="w-full h-full custom-scrollbar">
      <table class="w-full">
        <thead>
          <tr>
            <th
              v-for="item in props.displayOnlyPairs === true
                ? searchTableHeaderDisplayOnlyPairItems
                : searchTableHeaderItems"
              :key="item.id"
              class="text-xs font-medium text-navy-100 py-2 px-1"
            >
              <div
                :class="[
                  'flex space-x-1 cursor-pointer',
                  item.align === 'start' ? 'justify-start' : 'justify-end',
                ]"
                @click="handleSortWith(item.name)"
              >
                <label>{{ item.name }}</label>
                <img src="@/assets/icons/vector.svg" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in filteredPairItems"
            :key="item.name"
            class="cursor-pointer hover:bg-navy-10 dark:hover:bg-navy-500"
            @click="handleClickMenuItem(item.name)"
          >
            <td :class="displayOnlyPairs ? 'rounded-lg' : 'rounded-l-lg'">
              <div
                class="px-1 py-2 text-sm font-semibold flex items-center space-x-3"
              >
                <star-icon
                  :class="[
                    'w-4 h-4',
                    item.favorite
                      ? 'text-orange-400'
                      : 'text-navy-50 dark:text-navy-300',
                  ]"
                  @click="
                    (e) => {
                      e.stopPropagation();
                      if (loggedIn === false) {
                        return;
                      }
                      if (item.favorite) {
                        store.dispatch('removeFavoritePair', item.name);
                      } else {
                        store.dispatch('addFavoritePair', item.name);
                      }
                    }
                  "
                />
                <img
                  class="w-6 h-6 rounded-full"
                  :src="item.main?.icon"
                  alt="Token icon"
                />
                <p class="flex space-x-1">
                  <span>{{ item.main?.iso3 }}</span>
                  <span class="text-navy-100">{{ item.second?.iso3 }}</span>
                </p>
              </div>
            </td>
            <td v-if="!displayOnlyPairs" class="font-medium text-sm text-right">
              ${{ item.price }}
            </td>
            <td
              v-if="!displayOnlyPairs"
              :class="[
                'font-medium text-sm text-right rounded-r-lg',
                item.isMinus ? 'text-red-500' : 'text-green-500',
              ]"
            >
              {{ item.percent }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { isEmpty } from "@/utils/utils";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import { StarIcon } from "@heroicons/vue/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

const { t } = useI18n();
const store = useStore();
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const searchItems = computed(() =>
  isLoggedIn.value
    ? [
        { id: 0, name: t("translation.common.pairDropdown.searchTabs.0") },
        { id: 1, name: t("translation.common.pairDropdown.searchTabs.1") },
        { id: 2, name: t("translation.common.pairDropdown.searchTabs.2") },
      ]
    : [
        { id: 1, name: t("translation.common.pairDropdown.searchTabs.1") },
        { id: 2, name: t("translation.common.pairDropdown.searchTabs.2") },
      ]
);

const searchTableHeaderItems = computed(() => [
  {
    id: 1,
    name: t("translation.common.pairDropdown.tableHeaders.0"),
    align: "start",
  },
  {
    id: 2,
    name: t("translation.common.pairDropdown.tableHeaders.1"),
    align: "end",
  },
  {
    id: 3,
    name: t("translation.common.pairDropdown.tableHeaders.2"),
    align: "end",
  },
]);

const searchTableHeaderDisplayOnlyPairItems = computed(() => [
  {
    id: 1,
    name: t("translation.common.pairDropdown.tableHeaders.0"),
    align: "start",
  },
]);

const pairInput = ref("");
const sortInput = ref({
  name: "Price",
  desc: true,
});
const groupInput = ref(1);
const props = defineProps(["allPairItems", "displayOnlyPairs", "class"]);
const emit = defineEmits(["clickItem"]);

const loggedIn = computed(() => store.getters.isLoggedIn);

const filteredPairItems = computed(() => {
  let filteredItems = props.allPairItems;
  switch (groupInput.value) {
    case 0:
      filteredItems = filteredItems.filter((item) => item.favorite);
      break;
    case 1:
      break;
    default:
      filteredItems = filteredItems.filter(
        (item) =>
          item.second.iso3 ===
          searchItems.value.find((item) => item.id === groupInput.value).name
      );
      break;
  }
  return filteredItems
    ?.filter(
      (item) =>
        item.main.iso3.includes(pairInput.value?.toUpperCase()) ||
        item.second.iso3.includes(pairInput.value?.toUpperCase())
    )
    .sort((itemA, itemB) => {
      switch (sortInput.value.name) {
        case t("translation.common.pairDropdown.tableHeaders.0"):
          return sortInput.value.desc
            ? itemA.main.iso3.localeCompare(itemB.main.iso3)
            : itemB.main.iso3.localeCompare(itemA.main.iso3);
        case t("translation.common.pairDropdown.tableHeaders.1"):
          return sortInput.value.desc
            ? itemA.actualPrice - itemB.actualPrice
            : itemB.actualPrice - itemA.actualPrice;
        case t("translation.common.pairDropdown.tableHeaders.2"):
          return sortInput.value.desc
            ? itemA.actualPercent - itemB.actualPercent
            : itemB.actualPercent - itemA.actualPercent;
      }
    });
});

const handleChangeSelectedTab = (item) => {
  groupInput.value = item.id;
};
const handleSortWith = (name) => {
  if (name === sortInput.value.name) {
    sortInput.value.desc = !sortInput.value.desc;
  } else {
    sortInput.value = {
      name,
      desc: false,
    };
  }
};
const handleClickMenuItem = (name) => {
  emit("clickItem", name);
};
</script>
