<template>
  <div>
    <div
      class="bg-gradient-to-t from-primary-800 to-navy-400 rounded-tl-3xl rounded-tr-3xl -m-6"
    >
      <div class="flex justify-between items-center px-6 py-4 mt-6">
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ $t("translation.profile.idverify.uploadDialog.title") }}
        </h3>
        <button
          @click="handleClickCloseButton"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <x-mark-icon class="size-8" />
        </button>
      </div>
      <div class="p-6">
        <horizontal-circle-step :step-length="3" :current-active-step="1" />
        <div class="text-white text-base mt-6">
          {{ $t("translation.profile.idverify.uploadDialog.subTitle") }}
        </div>
        <div class="text-navy-50 text-sm mt-1">
          {{ $t("translation.profile.idverify.uploadDialog.label") }}
        </div>
      </div>
    </div>
    <div class="mt-12">
      <image-select @change="handleImageChange" />
    </div>
    <div class="mt-6">
      <div class="flex justify-between items-center">
        <div
          class="border-[1px] dark:border-navy-300 border-navy-50 rounded-sm flex-grow h-[1px] mr-4"
        ></div>
        <div class="dark:text-navy-200 text-navy-300 text-xs">
          {{ $t("translation.profile.idverify.uploadDialog.continueWith") }}
        </div>
        <div
          class="border-[1px] dark:border-navy-300 border-navy-50 rounded-sm flex-grow h-[1px] ml-4"
        ></div>
      </div>
    </div>
    <div class="mt-4">
      <take-photo />
    </div>
  </div>
  <div class="flex gap-6 mt-12">
    <outline-button color="primary" @click="handleClickCloseButton">
      {{ $t("translation.profile.idverify.uploadDialog.cancelButton") }}
    </outline-button>
    <fill-button class="w-full" @click="handleClickNextButton">
      {{ $t("translation.profile.idverify.uploadDialog.nextButton") }}
    </fill-button>
  </div>
</template>
<script setup>
import { defineEmits, ref } from "vue";
import { useStore } from "vuex";
import ImageSelect from "@/components/FileSelect/ImageSelect.vue";
import HorizontalCircleStep from "@/components/HorizontalCircleStep/HorizontalCircleStep.vue";
import TakePhoto from "@/components/TakePhoto/TakePhoto.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { arrayBufferToBase64 } from "@/utils/utils";

const imageValue = ref();
const emits = defineEmits(["close", "next"]);
const store = useStore();

const handleClickCloseButton = () => {
  emits("close");
};

const handleClickNextButton = () => {
  store.dispatch("setDocuments", [arrayBufferToBase64(imageValue.value)]);
  emits("next");
};

const handleImageChange = (value) => {
  imageValue.value = value;
};
</script>
