<template>
  <div
    class="h-24 group rounded-2xl dark:bg-navy-600 bg-navy-10 hover:dark:bg-navy-300 hover:bg-primary-700 hover:text-white px-6 flex flex-row items-center justify-between cursor-pointer dark:text-white text-navy-800 dark:hover:shadow-[0px_0px_24px_0px_rgba(66,77,112,0.56)] hover:shadow-[2px_4px_16px_0px_rgba(0,0,0,0.08)]"
    @click="handleClick"
  >
    <div class="text-lg font-semibold">
      {{ props.label }}
    </div>
    <div
      class="w-12 h-12 dark:bg-navy-500 group-hover:dark:bg-primary-700 rounded-full flex items-center justify-center bg-navy-50 group-hover:bg-white group-hover:text-primary-700 group-hover:dark:text-white flex-shrink-0"
    >
      <chevron-right-icon class="stroke-2 w-6 h-6" />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["label", "path"]);
const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>
