<template>
  <div>
    <div class="dark:bg-navy-700 bg-white p-4 rounded-xl mx-2">
      <p
        class="dark:text-white text-navy-800 pb-2 font-bold text-lg max-w-[257px]"
      >
        {{ $t("translation.trading.tradeFearlessly.title") }}
      </p>
      <div
        v-for="item in tradeFearItems"
        :key="item.id"
        class="mt-4 flex gap-2 items-center"
      >
        <div class="w-16 h-16">
          <component :is="item.icon" />
        </div>
        <div>
          <p class="font-semibold dark:text-white text-navy-800">
            {{ item.title }}
          </p>
          <p
            class="font-medium text-xs dark:text-navy-100 text-navy-200"
            v-html="item.content"
          />
        </div>
      </div>
    </div>
    <div class="mt-6 p-4 flex gap-2 dark:bg-navy-600 bg-white">
      <fill-button color="buy" width="full" @click="handleClick">
        {{ $t("translation.trading.buySell.actionTypeBuy") }}
      </fill-button>
      <fill-button color="red2" width="full" @click="handleClick">
        {{ $t("translation.buysell.actionTypeSell") }}
      </fill-button>
    </div>
    <dialog-view
      v-if="showingAndroidDialog || showingIosDialog"
      title="Download EXZi App"
      :is-open="showingAndroidDialog || showingIosDialog"
      @change-is-open="handleChangeIsOpen"
    >
      <template #content>
        <div class="bg-primary-800 pt-6 rounded-2xl">
          <p
            class="text-white text-center font-semibold max-w-[300px] mx-auto mb-4"
          >
            Use our mobile app for a better experience.
          </p>
          <div
            class="h-[220px] bg-[url(@/assets/icons/svg/download.svg)] bg-no-repeat bg-[top_center]"
          />
          <div
            class="p-3 dark:bg-navy-400 bg-primary-300 rounded-bl-2xl rounded-br-2xl"
          >
            <download-apple
              v-if="showingAndroidDialog"
              type="android"
              mode="fill"
            />
            <download-apple v-if="showingIosDialog" type="apple" mode="fill" />
          </div>
        </div>
      </template>
    </dialog-view>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { getMobileOperatingSystem } from "@/utils/utils";
import TradePro from "@/assets/icons/common/TradePro.vue";
import RobustSecurity from "@/assets/icons/common/RobustSecurity.vue";
import SupportView from "@/assets/icons/common/SupportView.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import DownloadApple from "@/components/Button/DownloadApple.vue";

const showingAndroidDialog = ref(false);
const showingIosDialog = ref(false);

const { t } = useI18n();

const tradeFearItems = [
  {
    id: 1,
    icon: TradePro,
    title: t("translation.trading.tradeFearlessly.content.0.title"),
    content: t("translation.trading.tradeFearlessly.content.0.content"),
  },
  {
    id: 1,
    icon: RobustSecurity,
    title: t("translation.trading.tradeFearlessly.content.1.title"),
    content: t("translation.trading.tradeFearlessly.content.1.content"),
  },
  {
    id: 1,
    icon: SupportView,
    title: t("translation.trading.tradeFearlessly.content.2.title"),
    content: t("translation.trading.tradeFearlessly.content.2.content"),
  },
];

const handleClick = () => {
  switch (getMobileOperatingSystem()) {
    case 0:
    case 1:
      showingIosDialog.value = true;
      break;
    case 2:
      showingAndroidDialog.value = true;
      break;
  }
};

const handleChangeIsOpen = () => {
  showingAndroidDialog.value = false;
  showingIosDialog.value = false;
};
</script>
