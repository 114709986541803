<template>
  <div class="flex justify-end items-center py-6">
    <button
      @click="handleClickCloseButton"
      class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
    >
      <x-mark-icon class="size-8" />
    </button>
  </div>
  <div class="flex mt-10 flex-col items-center">
    <img
      src="/assets/images/verification/start-verification/submitted.svg"
      class="w-[177px] h-[188px]"
    />
    <div class="text-xl dark:text-white text-navy-800 font-semibold mt-10">
      {{ $t("translation.profile.idverify.submittedDialog.title") }}
    </div>
    <div
      class="text-base dark:text-white text-navy-800 font-medium text-center mt-2"
    >
      {{ $t("translation.profile.idverify.submittedDialog.subTitle") }}
    </div>
    <fill-button class="w-full mt-12" @click="handleClickNextButton">
      {{ $t("translation.profile.idverify.submittedDialog.button") }}
    </fill-button>
  </div>
</template>
<script setup>
import { defineEmits } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const emits = defineEmits(["next", "close"]);
const handleClickCloseButton = () => {
  emits("close");
};
const handleClickNextButton = () => {
  emits("next");
};
</script>
