<template>
  <div class="mt-4 sm:mt-8">
    <new-listing-table
      :display-data="displayData"
      @sort-click="handleSortClicked"
    />
    <div v-if="displayData.length === 0" class="w-full">
      <div class="h-40 flex flex-col items-center justify-center w-full gap-3">
        <div>
          <double-coin-icon class="w-8 h-8 dark:text-navy-400 text-navy-300" />
        </div>
        <div class="text-navy-100 font-semibold text-sm">No Data</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import NewListingTable from "@/common/PairTable/NewListingTable.vue";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";

const props = defineProps(["filter"]);
const store = useStore();
const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const favoritePairs = computed(() => store.getters.favoritePairs);
const newListing = computed(() => store.getters.newListing);

const sortState = ref({
  id: 2,
  asc: true,
});

const displayData = computed(() => {
  if (
    isEmpty(allPairs.value) ||
    isEmpty(activeCurrencies.value) ||
    isEmpty(favoritePairs.value) ||
    isEmpty(newListing.value)
  ) {
    return [];
  }
  let data = newListing.value
    .map((item) => {
      const pairData = allPairs.value.find((pair) => pair.name === item.iso3);
      return {
        id: item.iso3,
        token1: pairData.main.iso3,
        token2: pairData.second.iso3,
        imgUrl: item.image,
        timeStart: item.time_start,
        rate: item.rate_usd,
        percent: item.ticker.percent_f,
        volume24h: pairData.volume_f,
        marketCap: pairData.volume,
      };
    })
    .filter((item) => {
      return (
        item.token1.toLowerCase().includes(props.filter.toLowerCase()) ||
        item.token2.toLowerCase().includes(props.filter.toLowerCase())
      );
    })
    .sort((valA, valB) => {
      switch (sortState.value.id) {
        case 2:
          return valA.rate - valB.rate;
        case 3:
          return valA.percent - valB.percent;
        case 4:
          return valA.volume24h - valB.volume24h;
      }
    });
  if (sortState.value.asc === true) {
    return data.reverse();
  }
  return data;
});

const handleSortClicked = (id) => {
  if (id === sortState.value.id) {
    sortState.value.asc = !sortState.value.asc;
    return;
  }
  sortState.value = {
    id: id,
    asc: false,
  };
};
</script>
