<template>
  <div class="min:w-96 overflow-auto custom-scrollbar">
    <table class="sm:w-full w-[560px]">
      <tbody>
        <tr
          v-for="info in tokenInfo"
          :key="info.id"
          class="hover:dark:bg-navy-700 hover:bg-navy-10 cursor-pointer text-sm rounded-md"
        >
          <td class="px-3 py-4 rounded-l-xl">
            <div class="flex items-center gap-2">
              <div :class="`w-2 h-2 rounded-full ${info.bgColor}`" />
              {{ info.label }}
            </div>
          </td>
          <td class="px-3 py-4">
            <p class="font-semibold">${{ info.USDT }}</p>
            <p class="dark:text-navy-100 text-navy-200 text-xs">
              = {{ info.BTC }} BTC
            </p>
          </td>
          <td
            :class="[
              info.percent >= 0 ? 'text-green-400' : 'text-red-500',
              'text-right px-3 py-4',
            ]"
          >
            ({{ info.percent >= 0 ? "+" + info.percent : info.percent }}%)
          </td>
          <td class="text-right px-3 py-4 rounded-r-xl">
            <div class="flex flex-row items-center justify-end">
              <link-button
                :label="
                  $t(
                    'translation.asset.overviewPage.accountPanel.actionTransfer'
                  )
                "
                @click="goToTransfer(info.value)"
              />
              <span class="dark:text-navy-300 text-navy-50 mx-3">|</span>
              <link-button
                @click="handleTradeClick"
                :label="
                  $t('translation.asset.overviewPage.accountPanel.actionTrade')
                "
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { walletTypes } from "@/constants/constants.js";

const { t } = useI18n();
const store = useStore();
const balances = computed(() => store.getters.balancesVolume);
const router = useRouter();

const handleTradeClick = () => {
  router.push({ name: "trading" });
};

const tokenInfo = computed(() => {
  let funding, trading;
  if (balances.value.total_by_wallet_type?.funding) {
    funding = {
      id: "1",
      label: t("translation.asset.overviewPage.accountPanel.typeFunding"),
      value: "funding",
      bgColor: "bg-orange-400",
      USDT: balances.value.total_by_wallet_type.funding.USDT / 1e8,
      BTC: balances.value.total_by_wallet_type.funding.BTC / 1e8,
      percent: 0,
    };
  } else {
    funding = {
      id: "1",
      label: t("translation.asset.overviewPage.accountPanel.typeFunding"),
      value: "funding",
      bgColor: "bg-orange-400",
      USDT: 0,
      BTC: 0,
      percent: 0,
    };
  }

  if (balances.value.total_by_wallet_type?.spot) {
    trading = {
      id: "2",
      label: t("translation.asset.overviewPage.accountPanel.typeTrading"),
      value: "spot",
      bgColor: "bg-blue-500",
      USDT: balances.value.total_by_wallet_type.spot.USDT / 1e8,
      BTC: balances.value.total_by_wallet_type.spot.BTC / 1e8,
      percent: 0,
    };
  } else {
    trading = {
      id: "2",
      label: t("translation.asset.overviewPage.accountPanel.typeTrading"),
      value: "spot",
      bgColor: "bg-blue-500",
      USDT: 0,
      BTC: 0,
      percent: 0,
    };
  }

  return [funding, trading];
});

const goToTransfer = (transferWalletType) => {
  store.dispatch("setTransferData", {
    wallet: walletTypes.find((type) => type.value === transferWalletType),
  });
};
</script>
