<template>
  <div class="flex flex-col w-full items-center">
    <div class="w-full overflow-hidden">
      <div
        ref="slotContainer"
        class="grid grid-rows-1 grid-flow-col gap-8 items-stretch auto-cols-[100%] overflow-visible transition-all duration-500 ease-in-out"
        :style="{
          transform: `translateX(calc(-${activeIndex * 100}% - ${
            32 * activeIndex
          }px))`,
        }"
      >
        <slot />
      </div>
      <div class="mt-8 flex flex-row items-center justify-center gap-3">
        <div
          @click="activeIndex = n - 1"
          v-for="n in childCount"
          :key="n"
          :class="[
            'size-1.5 rounded-full shrink-0 cursor-pointer',
            n - 1 === activeIndex
              ? 'dark:bg-white bg-navy-800'
              : 'dark:bg-navy-300 bg-navy-100',
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

const slotContainer = ref(null);
const activeIndex = ref(0);
const childCount = ref(0);
let timerId = null;
let observer = null;

const countChildElement = () => {
  if (slotContainer.value) {
    childCount.value = slotContainer.value.childElementCount;
  }
};

onMounted(() => {
  observer = new MutationObserver(() => {
    countChildElement();
  });
  if (slotContainer.value) {
    observer.observe(slotContainer.value, { childList: true, subtree: true });
  }
  countChildElement();
  timerId = setInterval(() => {
    if (activeIndex.value === childCount.value - 1) {
      activeIndex.value = 0;
      return;
    }
    activeIndex.value++;
  }, 5000);
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
  clearInterval(timerId);
});
</script>
