<template>
  <dialog-view
    :contain-header="true"
    :title="$t('translation.profile.security.deleteReasonDialog.title')"
    bg-color="gray"
    :is-open="model"
    v-if="model"
    @change-is-open="handleChangeOpen"
  >
    <template #content>
      <div class="w-full flex flex-col">
        <div class="flex flex-col w-full gap-4">
          <radio-select
            v-for="item in deleteReasonList"
            :key="item.id"
            v-model="deleteReasonOption"
            :is-selected="deleteReasonOption === item.id"
            :index="item.id"
          >
            <template #prefix>
              <div class="py-2">{{ item.label }}</div>
            </template>
          </radio-select>
        </div>
        <div class="w-full mt-12">
          <fill-button
            color="primary"
            width="full"
            @click="handleClickContinue"
          >
            {{ $t("translation.profile.security.deleteReasonDialog.button") }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { ref, defineModel, defineEmits, computed } from "vue";
import { useI18n } from "vue-i18n";
import DialogView from "@/components/Dialog/DialogView.vue";
import RadioSelect from "@/components/RadioSelect/RadioSelect.vue";

const { t } = useI18n();
const model = defineModel();
const emit = defineEmits(["confirm"]);
const deleteReasonOption = ref(1);

const handleChangeOpen = () => {
  model.value = false;
};

const handleClickContinue = () => {
  model.value = false;
  emit("confirm");
};

const deleteReasonList = computed(() => [
  {
    id: 1,
    label: t("translation.profile.security.deleteReasonDialog.reason.0"),
  },
  {
    id: 2,
    label: t("translation.profile.security.deleteReasonDialog.reason.1"),
  },
  {
    id: 3,
    label: t("translation.profile.security.deleteReasonDialog.reason.2"),
  },
]);
</script>
