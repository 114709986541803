<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- <path d="M24 0L22.9551 3.24028L24.563 3.76669L24 0Z" fill="#68BBDE" />
    <path d="M24 0L25.0449 3.24028L23.437 3.76669L24 0Z" fill="#68BBDE" />
    <path
      d="M27.2157 2.35449L23.8345 2.35449L23.8345 4.05801L27.2157 2.35449Z"
      fill="#68BBDE"
    />
    <path
      d="M27.2157 2.35449L24.4802 4.35709L23.4865 2.97892L27.2157 2.35449Z"
      fill="#68BBDE"
    />
    <path
      d="M25.9874 6.16309L24.9426 2.92281L23.3347 3.44922L25.9874 6.16309Z"
      fill="#68BBDE"
    />
    <path
      d="M25.9874 6.16309L23.252 4.16048L24.2457 2.78231L25.9874 6.16309Z"
      fill="#68BBDE"
    />
    <path
      d="M20.7842 2.35449L24.1654 2.35449L24.1654 4.05801L20.7842 2.35449Z"
      fill="#68BBDE"
    />
    <path
      d="M20.7842 2.35449L23.5197 4.35709L24.5134 2.97892L20.7842 2.35449Z"
      fill="#68BBDE"
    />
    <path
      d="M22.0125 6.16309L23.0573 2.92281L24.6652 3.44922L22.0125 6.16309Z"
      fill="#68BBDE"
    />
    <path
      d="M22.0125 6.16309L24.7479 4.16048L23.7542 2.78231L22.0125 6.16309Z"
      fill="#68BBDE"
    />
    <path
      d="M24 40.8848L22.9551 44.125L24.563 44.6515L24 40.8848Z"
      fill="#68BBDE"
    />
    <path
      d="M24 40.8848L25.0449 44.125L23.437 44.6515L24 40.8848Z"
      fill="#68BBDE"
    />
    <path
      d="M27.2156 43.2393L23.8343 43.2393L23.8343 44.9428L27.2156 43.2393Z"
      fill="#68BBDE"
    />
    <path
      d="M27.2156 43.2393L24.4801 45.2419L23.4864 43.8637L27.2156 43.2393Z"
      fill="#68BBDE"
    />
    <path
      d="M25.9873 47.0488L24.9424 43.8086L23.3346 44.335L25.9873 47.0488Z"
      fill="#68BBDE"
    />
    <path
      d="M25.9873 47.0488L23.2518 45.0462L24.2455 43.6681L25.9873 47.0488Z"
      fill="#68BBDE"
    />
    <path
      d="M20.7843 43.2393L24.1655 43.2393L24.1655 44.9428L20.7843 43.2393Z"
      fill="#68BBDE"
    />
    <path
      d="M20.7843 43.2393L23.5198 45.2419L24.5135 43.8637L20.7843 43.2393Z"
      fill="#68BBDE"
    />
    <path
      d="M22.0125 47.0488L23.0573 43.8086L24.6652 44.335L22.0125 47.0488Z"
      fill="#68BBDE"
    />
    <path
      d="M22.0125 47.0488L24.7479 45.0462L23.7542 43.6681L22.0125 47.0488Z"
      fill="#68BBDE"
    />
    <path
      d="M3.71277 20.4424L2.66791 23.6827L4.27578 24.2091L3.71277 20.4424Z"
      fill="#68BBDE"
    />
    <path
      d="M3.71277 20.4424L4.75763 23.6827L3.14976 24.2091L3.71277 20.4424Z"
      fill="#68BBDE"
    />
    <path
      d="M6.92834 22.7969L3.54711 22.7969L3.54711 24.5004L6.92834 22.7969Z"
      fill="#68BBDE"
    />
    <path
      d="M6.92834 22.7969L4.19287 24.7995L3.19915 23.4213L6.92834 22.7969Z"
      fill="#68BBDE"
    />
    <path
      d="M5.7002 26.6064L4.65534 23.3662L3.04747 23.8926L5.7002 26.6064Z"
      fill="#68BBDE"
    />
    <path
      d="M5.7002 26.6064L2.96472 24.6038L3.95844 23.2257L5.7002 26.6064Z"
      fill="#68BBDE"
    />
    <path
      d="M0.496948 22.7969L3.87818 22.7969L3.87818 24.5004L0.496948 22.7969Z"
      fill="#68BBDE"
    />
    <path
      d="M0.496948 22.7969L3.23242 24.7995L4.22614 23.4213L0.496948 22.7969Z"
      fill="#68BBDE"
    />
    <path
      d="M1.72522 26.6064L2.77008 23.3662L4.37795 23.8926L1.72522 26.6064Z"
      fill="#68BBDE"
    />
    <path
      d="M1.72522 26.6064L4.46069 24.6038L3.46698 23.2257L1.72522 26.6064Z"
      fill="#68BBDE"
    />
    <path
      d="M11.8661 8.90234L14.6016 6.89974L13.6078 5.52157L11.8661 8.90234Z"
      fill="#68BBDE"
    />
    <path
      d="M11.8661 8.90234L12.9109 5.66207L14.5188 6.18848L11.8661 8.90234Z"
      fill="#68BBDE"
    />
    <path
      d="M10.6377 5.09375L13.3732 7.09635L14.3669 5.71818L10.6377 5.09375Z"
      fill="#68BBDE"
    />
    <path
      d="M10.6377 5.09375L14.0189 5.09375L14.0189 6.79726L10.6377 5.09375Z"
      fill="#68BBDE"
    />
    <path
      d="M13.8535 2.73926L12.8087 5.97953L14.4165 6.50595L13.8535 2.73926Z"
      fill="#68BBDE"
    />
    <path
      d="M13.8535 2.73926L14.8984 5.97953L13.2905 6.50595L13.8535 2.73926Z"
      fill="#68BBDE"
    />
    <path
      d="M15.8409 8.90234L13.1055 6.89974L14.0992 5.52157L15.8409 8.90234Z"
      fill="#68BBDE"
    />
    <path
      d="M15.8409 8.90234L14.7961 5.66207L13.1882 6.18848L15.8409 8.90234Z"
      fill="#68BBDE"
    />
    <path
      d="M17.0693 5.09277L14.3339 7.09537L13.3401 5.7172L17.0693 5.09277Z"
      fill="#68BBDE"
    />
    <path
      d="M17.0693 5.09277L13.6881 5.09277L13.6881 6.79629L17.0693 5.09277Z"
      fill="#68BBDE"
    />
    <path
      d="M8.41577 16.3848L7.37091 13.1445L5.76304 13.6709L8.41577 16.3848Z"
      fill="#68BBDE"
    />
    <path
      d="M8.41577 16.3848L5.6803 14.3822L6.67402 13.004L8.41577 16.3848Z"
      fill="#68BBDE"
    />
    <path
      d="M4.4408 16.3857L7.17627 14.3831L6.18255 13.005L4.4408 16.3857Z"
      fill="#68BBDE"
    />
    <path
      d="M4.4408 16.3857L5.48565 13.1455L7.09353 13.6719L4.4408 16.3857Z"
      fill="#68BBDE"
    />
    <path
      d="M3.21228 12.5762L5.94776 14.5788L6.94148 13.2006L3.21228 12.5762Z"
      fill="#68BBDE"
    />
    <path
      d="M3.21228 12.5762L6.59351 12.5762L6.59351 14.2797L3.21228 12.5762Z"
      fill="#68BBDE"
    />
    <path
      d="M9.64404 12.5762L6.90857 14.5788L5.91485 13.2006L9.64404 12.5762Z"
      fill="#68BBDE"
    />
    <path
      d="M9.64404 12.5762L6.26281 12.5762L6.26281 14.2797L9.64404 12.5762Z"
      fill="#68BBDE"
    />
    <path
      d="M6.42822 10.2217L7.47308 13.462L5.86521 13.9884L6.42822 10.2217Z"
      fill="#68BBDE"
    />
    <path
      d="M6.42822 10.2217L5.38336 13.462L6.99124 13.9884L6.42822 10.2217Z"
      fill="#68BBDE"
    />
    <path
      d="M9.64624 33.0176L6.26501 33.0176L6.26501 34.7211L9.64624 33.0176Z"
      fill="#68BBDE"
    />
    <path
      d="M9.64624 33.0176L6.91076 35.0202L5.91705 33.642L9.64624 33.0176Z"
      fill="#68BBDE"
    />
    <path
      d="M8.41809 36.8262L7.37323 33.5859L5.76536 34.1123L8.41809 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M8.41809 36.8262L5.68262 34.8236L6.67634 33.4454L8.41809 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M4.44312 36.8262L7.17859 34.8236L6.18487 33.4454L4.44312 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M4.44312 36.8262L5.48797 33.5859L7.09585 34.1123L4.44312 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M6.43042 30.6631L7.47528 33.9034L5.86741 34.4298L6.43042 30.6631Z"
      fill="#68BBDE"
    />
    <path
      d="M6.43042 30.6631L5.38556 33.9034L6.99343 34.4298L6.43042 30.6631Z"
      fill="#68BBDE"
    />
    <path
      d="M3.21472 33.0166L6.59595 33.0166L6.59595 34.7201L3.21472 33.0166Z"
      fill="#68BBDE"
    />
    <path
      d="M3.21472 33.0166L5.9502 35.0192L6.94392 33.641L3.21472 33.0166Z"
      fill="#68BBDE"
    />
    <path
      d="M17.072 40.5L13.6908 40.5L13.6908 42.2035L17.072 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M17.072 40.5L14.3365 42.5026L13.3428 41.1244L17.072 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M15.8439 44.3086L14.799 41.0683L13.1911 41.5947L15.8439 44.3086Z"
      fill="#68BBDE"
    />
    <path
      d="M15.8439 44.3086L13.1084 42.306L14.1021 40.9278L15.8439 44.3086Z"
      fill="#68BBDE"
    />
    <path
      d="M11.8689 44.3086L14.6044 42.306L13.6107 40.9278L11.8689 44.3086Z"
      fill="#68BBDE"
    />
    <path
      d="M11.8689 44.3086L12.9138 41.0683L14.5216 41.5947L11.8689 44.3086Z"
      fill="#68BBDE"
    />
    <path
      d="M13.8562 38.1455L14.9011 41.3858L13.2932 41.9122L13.8562 38.1455Z"
      fill="#68BBDE"
    />
    <path
      d="M13.8562 38.1455L12.8113 41.3858L14.4192 41.9122L13.8562 38.1455Z"
      fill="#68BBDE"
    />
    <path
      d="M10.6405 40.5L14.0217 40.5L14.0217 42.2035L10.6405 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M10.6405 40.5L13.376 42.5026L14.3697 41.1244L10.6405 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M44.2874 20.4414L45.3322 23.6817L43.7243 24.2081L44.2874 20.4414Z"
      fill="#68BBDE"
    />
    <path
      d="M44.2874 20.4414L43.2425 23.6817L44.8504 24.2081L44.2874 20.4414Z"
      fill="#68BBDE"
    />
    <path
      d="M41.0718 22.7959L44.453 22.7959L44.453 24.4994L41.0718 22.7959Z"
      fill="#68BBDE"
    />
    <path
      d="M41.0718 22.7959L43.8073 24.7985L44.801 23.4203L41.0718 22.7959Z"
      fill="#68BBDE"
    />
    <path
      d="M42.2999 26.6055L43.3448 23.3652L44.9527 23.8916L42.2999 26.6055Z"
      fill="#68BBDE"
    />
    <path
      d="M42.2999 26.6055L45.0354 24.6029L44.0417 23.2247L42.2999 26.6055Z"
      fill="#68BBDE"
    />
    <path
      d="M47.5029 22.7959L44.1217 22.7959L44.1217 24.4994L47.5029 22.7959Z"
      fill="#68BBDE"
    />
    <path
      d="M47.5029 22.7959L44.7675 24.7985L43.7737 23.4203L47.5029 22.7959Z"
      fill="#68BBDE"
    />
    <path
      d="M46.2747 26.6055L45.2298 23.3652L43.6219 23.8916L46.2747 26.6055Z"
      fill="#68BBDE"
    />
    <path
      d="M46.2747 26.6055L43.5392 24.6029L44.5329 23.2247L46.2747 26.6055Z"
      fill="#68BBDE"
    />
    <path
      d="M36.131 8.90137L33.3955 6.89877L34.3892 5.52059L36.131 8.90137Z"
      fill="#68BBDE"
    />
    <path
      d="M36.131 8.90137L35.0861 5.66109L33.4783 6.18751L36.131 8.90137Z"
      fill="#68BBDE"
    />
    <path
      d="M37.3595 5.09277L34.624 7.09537L33.6303 5.7172L37.3595 5.09277Z"
      fill="#68BBDE"
    />
    <path
      d="M37.3595 5.09277L33.9783 5.09277L33.9783 6.79629L37.3595 5.09277Z"
      fill="#68BBDE"
    />
    <path
      d="M34.1437 2.73828L35.1885 5.97856L33.5807 6.50497L34.1437 2.73828Z"
      fill="#68BBDE"
    />
    <path
      d="M34.1437 2.73828L33.0988 5.97856L34.7067 6.50497L34.1437 2.73828Z"
      fill="#68BBDE"
    />
    <path
      d="M32.1562 8.90039L34.8917 6.89779L33.898 5.51962L32.1562 8.90039Z"
      fill="#68BBDE"
    />
    <path
      d="M32.1562 8.90039L33.2011 5.66011L34.809 6.18653L32.1562 8.90039Z"
      fill="#68BBDE"
    />
    <path
      d="M30.928 5.0918L33.6635 7.0944L34.6572 5.71623L30.928 5.0918Z"
      fill="#68BBDE"
    />
    <path
      d="M30.928 5.0918L34.3092 5.0918L34.3092 6.79531L30.928 5.0918Z"
      fill="#68BBDE"
    />
    <path
      d="M39.5818 16.3848L40.6266 13.1445L42.2345 13.6709L39.5818 16.3848Z"
      fill="#68BBDE"
    />
    <path
      d="M39.5818 16.3848L42.3173 14.3822L41.3235 13.004L39.5818 16.3848Z"
      fill="#68BBDE"
    />
    <path
      d="M43.5568 16.3838L40.8213 14.3812L41.815 13.003L43.5568 16.3838Z"
      fill="#68BBDE"
    />
    <path
      d="M43.5568 16.3838L42.5119 13.1435L40.904 13.6699L43.5568 16.3838Z"
      fill="#68BBDE"
    />
    <path
      d="M44.785 12.5752L42.0496 14.5778L41.0558 13.1996L44.785 12.5752Z"
      fill="#68BBDE"
    />
    <path
      d="M44.785 12.5752L41.4038 12.5752L41.4038 14.2787L44.785 12.5752Z"
      fill="#68BBDE"
    />
    <path
      d="M38.3534 12.5742L41.0889 14.5768L42.0826 13.1986L38.3534 12.5742Z"
      fill="#68BBDE"
    />
    <path
      d="M38.3534 12.5742L41.7346 12.5742L41.7346 14.2777L38.3534 12.5742Z"
      fill="#68BBDE"
    />
    <path
      d="M41.5692 10.2197L40.5244 13.46L42.1322 13.9864L41.5692 10.2197Z"
      fill="#68BBDE"
    />
    <path
      d="M41.5692 10.2197L42.6141 13.46L41.0062 13.9864L41.5692 10.2197Z"
      fill="#68BBDE"
    />
    <path
      d="M38.3535 33.0176L41.7347 33.0176L41.7347 34.7211L38.3535 33.0176Z"
      fill="#68BBDE"
    />
    <path
      d="M38.3535 33.0176L41.089 35.0202L42.0827 33.642L38.3535 33.0176Z"
      fill="#68BBDE"
    />
    <path
      d="M39.5818 36.8262L40.6266 33.5859L42.2345 34.1123L39.5818 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M39.5818 36.8262L42.3173 34.8236L41.3235 33.4454L39.5818 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M43.5566 36.8262L40.8212 34.8236L41.8149 33.4454L43.5566 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M43.5566 36.8262L42.5118 33.5859L40.9039 34.1123L43.5566 36.8262Z"
      fill="#68BBDE"
    />
    <path
      d="M41.5693 30.6631L40.5245 33.9034L42.1323 34.4298L41.5693 30.6631Z"
      fill="#68BBDE"
    />
    <path
      d="M41.5693 30.6631L42.6142 33.9034L41.0063 34.4298L41.5693 30.6631Z"
      fill="#68BBDE"
    />
    <path
      d="M44.785 33.0166L41.4038 33.0166L41.4038 34.7201L44.785 33.0166Z"
      fill="#68BBDE"
    />
    <path
      d="M44.785 33.0166L42.0496 35.0192L41.0558 33.641L44.785 33.0166Z"
      fill="#68BBDE"
    />
    <path
      d="M30.9279 40.5L34.3091 40.5L34.3091 42.2035L30.9279 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M30.9279 40.5L33.6633 42.5026L34.6571 41.1244L30.9279 40.5Z"
      fill="#68BBDE"
    />
    <path
      d="M32.1561 44.3096L33.201 41.0693L34.8089 41.5957L32.1561 44.3096Z"
      fill="#68BBDE"
    />
    <path
      d="M32.1561 44.3096L34.8916 42.307L33.8979 40.9288L32.1561 44.3096Z"
      fill="#68BBDE"
    />
    <path
      d="M36.1309 44.3096L33.3954 42.307L34.3891 40.9288L36.1309 44.3096Z"
      fill="#68BBDE"
    />
    <path
      d="M36.1309 44.3096L35.086 41.0693L33.4781 41.5957L36.1309 44.3096Z"
      fill="#68BBDE"
    />
    <path
      d="M34.1437 38.1455L33.0988 41.3858L34.7067 41.9122L34.1437 38.1455Z"
      fill="#68BBDE"
    />
    <path
      d="M34.1437 38.1455L35.1885 41.3858L33.5807 41.9122L34.1437 38.1455Z"
      fill="#68BBDE"
    />
    <path
      d="M37.3594 40.499L33.9781 40.499L33.9781 42.2025L37.3594 40.499Z"
      fill="#68BBDE"
    />
    <path
      d="M37.3594 40.499L34.6239 42.5016L33.6302 41.1235L37.3594 40.499Z"
      fill="#68BBDE"
    /> -->
  </svg>
</template>
