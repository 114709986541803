<template>
  <announcement-article-view v-if="url" />
  <div v-else class="w-full flex flex-col items-center justify-center mb-36">
    <!-- <div
      class="flex flex-row items-center justify-center text-xs font-medium text-navy-100 self-start mb-8 gap-2"
    >
      <div class="cursor-pointer">Announcements</div>
      <chevron-right-icon class="w-4 h-4 stroke-2" />
      <div class="cursor-pointer" @click="router.push({ name: route.name })">
        {{ route.meta.label }}
      </div>
    </div> -->
    <div
      v-if="filteredData.length"
      class="flex flex-col items-center justify-center sm:mb-10"
    >
      <template
        v-for="(item, index) in filteredData.slice(startIndex, endIndex + 1)"
        :key="item.id"
      >
        <div
          v-if="index !== 0"
          class="my-4 border-t dark:border-t-navy-500 border-t-navy-10 w-full"
        />
        <announcement-item
          :title="item.title"
          :content="item.content"
          :type="item.type"
          :update-date="item.date"
          @click="handleClick(item)"
        />
      </template>
      <div class="mt-10 w-full">
        <pagination-component
          @change="handlePageChange"
          :initial-page="1"
          :total="Math.ceil(filteredData.length / 5)"
          :step-disabled="true"
          :step="5"
        />
      </div>
    </div>
    <div v-else class="w-full h-[300px] flex items-center justify-center">
      No available data
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onBeforeMount, defineProps } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import AnnouncementItem from "@/views/Announcement/AnnouncementItem.vue";
import AnnouncementArticleView from "@/views/Announcement/AnnouncementArticleView.vue";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
// import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const startIndex = ref(0);
const endIndex = ref(4);
const route = useRoute();
const router = useRouter();
const url = ref(route.params.url);
const props = defineProps(["searchText"]);
const store = useStore();
const mockData = computed(() => store.getters.announcementList);

watch(
  () => route.params.url,
  (newUrl) => {
    url.value = newUrl;
  }
);

const handlePageChange = ([currentPage, pageSize]) => {
  startIndex.value = (currentPage - 1) * pageSize;
  endIndex.value = currentPage * pageSize - 1;
};

const handleClick = (menuItem) => {
  router.push({
    path: route.path + menuItem.url,
  });
};

const filteredData = computed(() => {
  const searchedData = mockData.value.filter((item) =>
    item.content.toLowerCase().includes(props.searchText.toLowerCase())
  );
  switch (route.name) {
    case "latest-events":
      return searchedData.filter((item) => item.type === 1);
    case "new-listing":
      return searchedData.filter((item) => item.type === 2);
    case "latest-exzi-news":
      return searchedData.filter((item) => item.type === 3);
    case "product-updates":
      return searchedData.filter((item) => item.type === 4);
    case "others":
      return searchedData.filter((item) => item.type === 5);
    case "announcement":
    default:
      return searchedData;
  }
});

onBeforeMount(() => {
  url.value = route.params.url;
  store.dispatch("fetchAnnouncementList");
});
</script>
