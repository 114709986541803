import axios from "@/api/axios";
import { PUZZLE_API_URL } from "@/constants/constants";
import { ref } from "vue";
import { useStore } from "vuex";

export const usePuzzle = (emit, model) => {
  const store = useStore();

  const puzzleWord = ref("");
  const puzzleJumbledWord = ref("");
  const puzzleId = ref("");
  const isOpen = ref(false);

  const handleShowPuzzle = async () => {
    try {
      const res = await axios.get(PUZZLE_API_URL);
      puzzleId.value = res.data.puzzleId;
      puzzleJumbledWord.value = res.data.puzzle.jumbledWord;
      puzzleWord.value = res.data.puzzle.word;
      isOpen.value = true;
    } catch (error) {
      model.value = false;
      store.dispatch("setToast", {
        type: "error",
        message: error,
      });
      store.dispatch("setShowPuzzle", false);
    }
  };

  handleShowPuzzle();

  const handleChangePuzzle = (answer) => {
    if (answer === puzzleWord.value) {
      (async () => {
        try {
          const res = await axios.post(PUZZLE_API_URL, {
            puzzleId: puzzleId.value,
            userAnswer: answer,
          });
          if (res.data.isSucceed === false) {
            throw Error(res.data?.message ?? "");
          }
          store.dispatch("setToast", {
            type: "success",
            message: res.data?.message ?? "",
          });
          emit("success");
          isOpen.value = false;
          store.dispatch("setShowPuzzle", false);
        } catch (error) {
          store.dispatch("setToast", {
            type: "error",
            message: error.toString(),
          });
        }
      })();
    }
  };

  return {
    originalWord: puzzleWord,
    word: puzzleJumbledWord,
    isOpen,
    handleChangePuzzle,
  };
};
