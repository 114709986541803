<template>
  <div class="flex flex-col w-full mb-24">
    <div class="w-full flex flex-row items-center justify-between">
      <div class="text-2xl font-semibold dark:text-white text-navy-800">
        {{ $t("translation.profile.setting.title") }}
      </div>
      <!-- <div class="flex flex-row items-center gap-3">
        <div class="dark:text-white text-xs font-medium text-navy-300">
          {{ $t("translation.profile.setting.statusLabel") }}
        </div>
        <div
          :class="[
            'flex flex-row items-center px-2 py-1.5 bg-green-200 text-green-900 rounded-full gap-2',
            !is_risky
              ? 'bg-green-200 text-green-900'
              : 'bg-red-200 text-red-900',
          ]"
        >
          <shield-check-icon class="size-5 stroke-2" />
          <div class="text-sm font-semibold">
            {{ !is_risky ? "High" : "Low" }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="flex flex-col w-full mt-8 sm:mt-12">
      <profile-list-item
        :icon="LanguageIcon"
        :title="$t('translation.profile.setting.languageTitle')"
        :comment="$t('translation.profile.setting.languageLabel')"
      >
        <list-dropdown
          :items="languageList"
          @change="handleLanguageChange"
          :default-selected="defaultLanguageId"
        />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="CurrencyIcon"
        :title="$t('translation.profile.setting.currencyTitle')"
        :comment="$t('translation.profile.setting.currencyLabel')"
      >
        <list-dropdown
          :items="currencyList"
          @change="handleCurrencyChange"
          :default-selected="defaultCurrencyId"
        />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="ThemeIcon"
        :title="$t('translation.profile.setting.themeTitle')"
        :comment="$t('translation.profile.setting.themeLabel')"
      >
        <list-dropdown
          :items="themeData"
          @change="handleThemeChange"
          :default-selected="defaultThemeId"
        />
      </profile-list-item>
      <div
        class="dark:text-white text-navy-800 mt-16 text-xl font-semibold mb-8"
      >
        {{ $t("translation.profile.setting.notificationTitle") }}
      </div>
      <profile-list-item
        :icon="ManageIcon"
        :title="$t('translation.profile.setting.assetsTitle')"
        :comment="$t('translation.profile.setting.assetsLabel')"
      >
        <switch-component v-model="manageAssetState" />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="TradeIcon"
        :title="$t('translation.profile.setting.tradeTitle')"
        :comment="$t('translation.profile.setting.tradeLabel')"
      >
        <switch-component v-model="tradeState" />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="PriceAlertIcon"
        :title="$t('translation.profile.setting.alertTitle')"
        :comment="$t('translation.profile.setting.alertLabel')"
      >
        <switch-component v-model="customPriceAlertState" />
      </profile-list-item>
      <div
        class="dark:text-white text-navy-800 mt-16 text-xl font-semibold mb-8"
      >
        {{ $t("translation.profile.setting.marketUpdateTitle") }}
      </div>
      <profile-list-item
        :icon="PriceAlertIcon"
        :title="$t('translation.profile.setting.deleteFeatureTitle')"
        :comment="$t('translation.profile.setting.deleteFeatureLabel')"
      >
        <switch-component v-model="deleteNewFeaturesState" />
      </profile-list-item>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { languageList } from "@/constants/constants";
import ProfileListItem from "@/views/Profile/Items/ProfileListItem.vue";
import SwitchComponent from "@/components/Switch/SwitchComponent.vue";
import ListDropdown from "@/views/Profile/Items/ListDropdown.vue";
import CurrencyIcon from "@/assets/icons/common/CurrencyIcon.vue";
import LanguageIcon from "@/assets/icons/common/LanguageIcon.vue";
import ManageIcon from "@/assets/icons/common/ManageIcon.vue";
import PriceAlertIcon from "@/assets/icons/common/PriceAlertIcon.vue";
import ThemeIcon from "@/assets/icons/common/ThemeIcon.vue";
import TradeIcon from "@/assets/icons/common/TradeIcon.vue";
//import { ShieldCheckIcon } from "@heroicons/vue/24/outline";
import DarkIcon from "@/assets/icons/common/DarkIcon.vue";
import LightIcon from "@/assets/icons/common/LightIcon.vue";

const store = useStore();

const notifyManageAssets = computed(() => store.getters.notifyManageAssets);
const notifyTrade = computed(() => store.getters.notifyTrade);
const notifyCustomPriceAlert = computed(
  () => store.getters.notifyCustomPriceAlert
);
const notifyDeleteNewFeatures = computed(
  () => store.getters.notifyDeleteNewFeatures
);
const currentLanguage = computed(() => store.getters.currentLanguage);
const currentTheme = computed(() => store.getters.currentTheme);
const currentCurrency = computed(() => store.getters.currency);
//const profile = computed(() => store.getters.profile);
//const is_risky = computed(() => profile.value?.profile?.isRisky ?? false);

const manageAssetState = ref(notifyManageAssets.value);
const tradeState = ref(notifyTrade.value);
const customPriceAlertState = ref(notifyCustomPriceAlert.value);
const deleteNewFeaturesState = ref(notifyDeleteNewFeatures.value);

const defaultThemeId = ref(1);
const defaultLanguageId = ref(1);
const defaultCurrencyId = ref(1);

watch(
  () => manageAssetState.value,
  (newVal) => {
    store.dispatch("setNotifyManageAssets", newVal);
  }
);

watch(
  () => tradeState.value,
  (newVal) => {
    store.dispatch("setNotifyTrade", newVal);
  }
);

watch(
  () => customPriceAlertState.value,
  (newVal) => {
    store.dispatch("setNotifyCustomPriceAlert", newVal);
  }
);

watch(
  () => deleteNewFeaturesState.value,
  (newVal) => {
    store.dispatch("setNotifyDeletenewFeatures", newVal);
  }
);

onMounted(() => {
  if (currentTheme.value === "dark") {
    defaultThemeId.value = 1;
  } else {
    defaultThemeId.value = 2;
  }
  defaultCurrencyId.value =
    currencyList.find((item) => currentCurrency.value === item.label)?.id ?? 1;
  defaultLanguageId.value =
    languageList.find((item) => currentLanguage.value === item.label)?.id ?? 1;
});

const handleThemeChange = (item) => {
  if (item.label === "Dark") {
    store.dispatch("setTheme", "dark");
  } else {
    store.dispatch("setTheme", "light");
  }
};

const handleLanguageChange = (item) => {
  store.dispatch("setLanguage", {
    label: item.label,
    locale: item.locale,
  });
};

const handleCurrencyChange = (item) => {
  store.dispatch("setCurrency", item.label);
};

const currencyList = [
  {
    id: 0,
    label: "USD",
  },
  {
    id: 1,
    label: "CYN",
  },
  {
    id: 2,
    label: "RUB",
  },
  {
    id: 3,
    label: "JPY",
  },
  {
    id: 4,
    label: "EUR",
  },
  {
    id: 5,
    label: "THB",
  },
];

const themeData = [
  {
    id: 1,
    label: "Dark",
    icon: DarkIcon,
  },
  {
    id: 2,
    label: "Light",
    icon: LightIcon,
  },
];
</script>
