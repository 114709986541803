<template>
  <table class="w-full">
    <thead>
      <tr class="h-10">
        <th
          v-for="item in headerItems"
          :key="item.id"
          :class="[
            'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
            item.hideable === true ? 'max-sm:hidden' : '',
            `text-${item.align}`,
          ]"
        >
          {{ item.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in props.displayData"
        :key="item.id"
        :class="['dark:hover:bg-navy-700 hover:bg-navy-10 h-[72px]']"
      >
        <td class="px-3">
          <div class="flex flex-row items-center justify-start">
            <img :src="item.imgUrl" class="size-6 rounded-full mr-2" />
            <div
              class="text-sm font-semibold dark:text-white text-navy-800 mr-1"
            >
              {{ item.token1 }}
            </div>
            <div class="text-sm font-semibold text-navy-100">
              {{ item.token2 }}
            </div>
          </div>
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800"
        >
          ${{ addCommasToNumber(item.rate, -2) }}
        </td>
        <td class="text-left px-3 text-sm font-semibold">
          <percent-display :value="item.percent" />
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800 max-sm:hidden"
        >
          ${{ Date(item.timeStart).toISOString().split("T")[0] }}
        </td>
        <td class="text-right px-3 max-sm:hidden text-primary-700">
          <div
            class="cursor-pointer"
            @click="router.push({ name: 'trading', params: { pair: item.id } })"
          >
            Trading
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-if="displayData.length === 0" class="w-full">
    <div class="h-40 flex flex-col items-center justify-center w-full gap-3">
      <div>
        <double-coin-icon class="w-8 h-8 dark:text-navy-400 text-navy-300" />
      </div>
      <div class="text-navy-100 font-semibold text-sm">No Data</div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { addCommasToNumber } from "@/utils/NumberFormating";
import PercentDisplay from "@/common/PairTable/PercentDisplay.vue";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";

const props = defineProps(["displayData"]);
const router = useRouter();

const headerItems = [
  {
    id: 1,
    label: "Pair",
    align: "left",
    hideable: false,
  },
  {
    id: 2,
    label: "Last Price",
    align: "left",
    hideable: false,
  },
  {
    id: 3,
    label: "24h Change",
    align: "left",
    hideable: false,
  },
  {
    id: 4,
    label: "Markets",
    align: "left",
    hideable: true,
  },
  {
    id: 5,
    label: "Action",
    align: "right",
    hideable: true,
  },
];
</script>
