import { createRouter, createWebHistory, RouterView } from "vue-router";
import { h, ref } from "vue";
// import { useStore } from "vuex";
import Tr from "@/i18n/translation";
import { scrollToTop } from "@/utils/utils";
// import { languageList } from "@/constants/constants";
import sessionManager from "@/utils/SessionManager";
import HomeView from "../views/Home/HomeView.vue";
import AuthLayout from "@/layout/AuthLayout/AuthLayout.vue";
import MainLayout from "@/layout/MainLayout/MainLayout.vue";
import SidebarLayout from "@/layout/SidebarLayout/SidebarLayout.vue";
import NonExisting from "@/views/Error/NonExisting.vue";
import SignIn from "@/views/Auth/Signin/SignIn.vue";
import VerificationCode from "@/views/Auth/VerificationCode/VerificationCode.vue";
// import Signup from "@/views/Auth/Signup/SignUp.vue";
import TradingView from "@/views/Trading/TradingView.vue";
import ConvertView from "@/views/Convert/ConvertView.vue";
import MarketView from "@/views/Market/MarketView.vue";
import ForgetPassword from "@/views/Auth/ForgetPassword/ForgetPassword.vue";
import RecoveryPassword from "@/views/Auth/Recovery/RecoveryPassword.vue";
import BlogOverview from "@/views/Blog/BlogOverview.vue";
import BlogDetail from "@/views/Blog/BlogDetail.vue";
import HelpCenter from "@/views/HelpCenter/HelpCenter.vue";
import HelpCenterArticleView from "@/views/HelpCenter/HelpCenterArticleView.vue";
import AnnouncementTop from "@/views/Announcement/AnnouncementTop.vue";
import AnnouncementView from "@/views/Announcement/AnnouncementView.vue";
import CareerHomeView from "@/views/Career/CareerHomeView.vue";
import CareerDetailView from "@/views/Career/CareerDetailView.vue";
import LaucnpadHomeView from "@/views/Launchpad/LaucnpadHomeView.vue";
import LaunchpadSubscriptionHistoryView from "@/views/Launchpad/LaunchpadSubscriptionHistoryView.vue";
import LaunchpadProjectDetailView from "@/views/Launchpad/LaunchpadProjectDetailView.vue";
import FaqView from "@/views/FAQ/FaqView.vue";
import RewardsHub from "@/views/RewardsHub/RewardsHub.vue";
import TaskHistory from "@/views/RewardsHub/TaskHistory.vue";
import BeginnerGuide from "@/views/BeginnerGuide/BeginnerGuide.vue";
import ReferralHome from "@/views/Referral/ReferralHome.vue";
import BuySellView from "@/views/BuySell/BuySellView.vue";
import FeeSchedule from "@/views/FeeSchedule/FeeSchedule.vue";
import ProofFunds from "@/views/ProofFunds/ProofFunds.vue";
import WaitlistView from "@/views/Auth/Waitlist/WaitlistView.vue";
import { assetMenuItems } from "@/views/Asset/assetSidebar";
import {
  profileSidebarItems,
  buildProfileSidebarRouter,
} from "@/views/Profile/profileSidebar";
import { announcementMenuItems } from "@/views/Announcement/announcementSidebar";

const routes = [
  {
    path: "",
    name: "home",
    component: (
      <MainLayout>
        <HomeView />
      </MainLayout>
    ),
    meta: {
      authPage: false,
    },
  },
  {
    path: "signin",
    name: "signin",
    component: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
    meta: {
      authPage: true,
    },
  },
  // {
  //   path: "signup",
  //   name: "signup",
  //   component: (
  //     <AuthLayout>
  //       <Signup />
  //     </AuthLayout>
  //   ),
  //   meta: {
  //     authPage: true,
  //   },
  // },
  {
    path: "signup",
    name: "signup",
    component: (
      <MainLayout>
        <WaitlistView />
      </MainLayout>
    ),
    meta: {
      authPage: true,
    },
  },
  {
    path: "verification-code",
    name: "verification-code",
    component: (
      <AuthLayout>
        <VerificationCode />
      </AuthLayout>
    ),
  },
  {
    path: "forget-password",
    name: "forget-password",
    component: (
      <AuthLayout>
        <ForgetPassword />
      </AuthLayout>
    ),
  },
  {
    path: "recovery/:key?",
    name: "recovery",
    component: (
      <AuthLayout>
        <RecoveryPassword />
      </AuthLayout>
    ),
  },
  {
    path: "trading/:pair?/:overview?",
    name: "trading",
    component: (
      <MainLayout is-trading>
        <TradingView />
      </MainLayout>
    ),
  },
  {
    path: "profile",
    children: buildProfileSidebarRouter(profileSidebarItems),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "asset",
    children: assetMenuItems.map((item) => {
      const params = {};
      if (!item.hasSubItems) {
        params.component = {
          render() {
            return h(
              MainLayout,
              {},
              {
                default: () =>
                  h(
                    SidebarLayout,
                    { menuData: assetMenuItems, full: true },
                    {
                      top: () => <div class="h-10" />,
                      content: () => h(item.content),
                    }
                  ),
              }
            );
          },
        };
      } else {
        params.children = item.subItems.map((subItems) => ({
          path: subItems.path,
          name: subItems.name,
          component: {
            render() {
              return h(
                MainLayout,
                {},
                {
                  default: () =>
                    h(
                      SidebarLayout,
                      { menuData: assetMenuItems, full: true },
                      {
                        top: () => <div class="h-10" />,
                        content: () => h(subItems.content),
                      }
                    ),
                }
              );
            },
          },
        }));
      }
      return {
        path: item.path,
        name: item.name,
        ...params,
      };
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "convert/:mainToken?/:secondToken?",
    name: "convert",
    component: (
      <MainLayout>
        <ConvertView />
      </MainLayout>
    ),
  },
  {
    path: "markets",
    name: "markets",
    component: (
      <MainLayout>
        <MarketView />
      </MainLayout>
    ),
  },
  {
    path: "help-center",
    children: [
      {
        path: "",
        name: "help-center",
        component: (
          <MainLayout>
            <HelpCenter />
          </MainLayout>
        ),
      },
      {
        path: ":url",
        name: "help-center-article",
        component: (
          <MainLayout>
            <HelpCenterArticleView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "referral",
    children: [
      {
        path: "",
        name: "referral",
        component: (
          <MainLayout>
            <ReferralHome />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "blog",
    children: [
      {
        path: "",
        name: "blog",
        component: (
          <MainLayout>
            <BlogOverview />
          </MainLayout>
        ),
      },
      {
        path: ":url",
        name: "blog-detail",
        component: (
          <MainLayout>
            <BlogDetail />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "announcement",
    children: announcementMenuItems.map((item) => ({
      path: item.path,
      name: item.name,
      meta: {
        label: item.label,
      },
      component: {
        setup() {
          const searchText = ref("");
          const handleSearchTextChange = (newText) => {
            searchText.value = newText;
          };
          return {
            searchText,
            handleSearchTextChange,
          };
        },
        render() {
          return h(
            MainLayout,
            {},
            {
              default: () =>
                h(
                  SidebarLayout,
                  { menuData: announcementMenuItems, full: false },
                  {
                    top: () =>
                      h(AnnouncementTop, {
                        handleChange: this.handleSearchTextChange,
                      }),
                    content: () =>
                      h(AnnouncementView, {
                        searchText: this.searchText,
                      }),
                  }
                ),
            }
          );
        },
      },
    })),
  },
  {
    path: "career",
    children: [
      {
        path: "",
        name: "career",
        component: (
          <MainLayout>
            <CareerHomeView />
          </MainLayout>
        ),
      },
      {
        path: ":url",
        name: "career-detail",
        component: (
          <MainLayout>
            <CareerDetailView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "launchpad",
    children: [
      {
        path: "",
        name: "launchpad",
        component: (
          <MainLayout>
            <LaucnpadHomeView />
          </MainLayout>
        ),
      },
      {
        path: "subscription-history",
        name: "launchpad-subscription-history",
        component: (
          <MainLayout>
            <LaunchpadSubscriptionHistoryView />
          </MainLayout>
        ),
      },
      {
        path: "project/:url",
        name: "launchpad-project-detail",
        component: (
          <MainLayout>
            <LaunchpadProjectDetailView />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "buysell/:mainToken?/:secondToken?",
    name: "buy-sell",
    component: (
      <MainLayout>
        <BuySellView />
      </MainLayout>
    ),
  },
  {
    path: "faq",
    name: "faq",
    component: (
      <MainLayout>
        <FaqView />
      </MainLayout>
    ),
  },
  {
    path: "rewards-hub",
    children: [
      {
        path: "",
        name: "rewards-hub",
        component: (
          <MainLayout>
            <RewardsHub />
          </MainLayout>
        ),
      },
      {
        path: "history",
        name: "rewards-task-history",
        component: (
          <MainLayout>
            <TaskHistory />
          </MainLayout>
        ),
      },
    ],
  },
  {
    path: "beginner-guide",
    name: "beginner-guide",
    component: (
      <MainLayout>
        <BeginnerGuide />
      </MainLayout>
    ),
  },
  {
    path: "fee-schedule",
    name: "fee-schedule",
    component: (
      <MainLayout>
        <FeeSchedule />
      </MainLayout>
    ),
  },
  {
    path: "proof-funds",
    name: "proof-funds",
    component: (
      <MainLayout>
        <ProofFunds />
      </MainLayout>
    ),
  },
  {
    path: ":pathMatch(.*)*",
    name: "NotFound",
    component: <NonExisting />,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: <RouterView />,
      beforeEnter: Tr.routeMiddleware,
      children: routes,
    },
  ],
});

router.beforeEach((to, from, next) => {
  scrollToTop();
  // document.title = `EXZi/${to.name.toUpperCase()}`;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!sessionManager.isLoggedIn()) {
      next({ name: "signup" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.authPage)) {
    if (sessionManager.isLoggedIn()) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next();
  }
  // }
});

export default router;
