<template>
  <div>
    <dropdown-select width="full" @select="handleSelectToken">
      <template #header="{ handleContentClick, isShowingMenu }">
        <div class="font-semibold">
          <p v-if="!isEmpty(labelText)" class="text-sm mb-2">
            {{ labelText }}
          </p>
          <text-view
            :value="selectedNetwork?.nice_name"
            placeholder="Select Network"
            width="full"
            rounded="xl"
            :error="error"
            :filled="filled"
            @focus="handleContentClick(true)"
          >
            <template #prefix v-if="showSearchIcon">
              <magnifying-glass-icon class="w-6 h-6 mr-4 text-navy-200" />
            </template>
            <template #content>
              <span>{{ selectedNetwork?.nice_name }}</span>
            </template>
            <template #suffix>
              <chevron-down-icon v-if="!isShowingMenu" class="w-6 h-6" />
              <chevron-up-icon v-else class="w-6 h-6" />
            </template>
          </text-view>
        </div>
      </template>
      <template #menu="{ handleMenuItemClick }">
        <div
          :class="[
            filled ? 'dark:bg-navy-500' : 'dark:bg-navy-600',
            'bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-2 dark:shadow-none shadow-md',
          ]"
        >
          <div
            class="flex dark:text-navy-50 text-primary-800 text-sm font-medium items-center gap-2 p-2 dark:bg-navy-500 bg-primary-200 rounded-xl mb-6"
          >
            <exclamation-circle-icon class="w-5 h-5" />
            <p>Select the same network as the withdrawal platform</p>
          </div>
          <div class="max-h-60 overflow-auto custom-scrollbar">
            <div
              v-for="network in networks"
              :key="network.id"
              class="px-3 py-2 flex items-center justify-between font-semibold gap-3 hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
              @click="handleMenuItemClick(network)"
            >
              <div>
                <p class="text-base">{{ network.name }}</p>
                <p class="text-xs dark:text-navy-100 text-navy-200">
                  {{ network.nice_name }}
                </p>
              </div>
              <div class="text-right">
                <p>= {{ network.deposit.min_amount }} mins</p>
                <p
                  class="text-xs font-semibold dark:text-navy-100 text-navy-200"
                >
                  {{ network.deposit.min_confirmations }} Confirmations
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dropdown-select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import { isEmpty } from "@/utils/utils";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";
import TextView from "@/components/TextEdit/TextView.vue";

const props = defineProps({
  showSearchIcon: {
    type: Boolean,
    default: true,
  },
  defaultNetwork: Object,
  labelText: String,
  networks: Object,
  error: String,
  filled: Boolean,
});
const emits = defineEmits(["select"]);

watch(
  () => props.defaultNetwork,
  (newValue) => {
    selectedNetwork.value = newValue;
  }
);

const selectedNetwork = ref(props.defaultNetwork);

const handleSelectToken = (network) => {
  selectedNetwork.value = network;
  emits("select", network);
};
</script>
