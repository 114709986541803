<template>
  <div
    class="px-4 py-3 flex gap-4 items-center dark:bg-navy-400 bg-navy-10 rounded-xl cursor-pointer"
    :click="handleClick"
  >
    <img
      src="/assets/images/verification/start-verification/camera.svg"
      class="dark:stroke-white stroke-navy-800"
    />
    <div class="text-base dark:text-white text-navy-800">
      Take photo using webcam
    </div>
  </div>
</template>
<script setup>
const handleClick = () => {};
</script>
