<template>
  <div
    @mousedown="(event) => !disabled && handleMouseDown(event)"
    @mousemove="(event) => !disabled && handleMouseMove(event)"
    @mouseup="(event) => !disabled && handleMouseUp(event)"
    ref="container"
    :class="[
      'relative h-[2px] dark:bg-navy-400 bg-navy-50 mt-2 mb-6',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    ]"
  >
    <div class="bg-primary-600 h-1 mt-2" ref="slider" style="width: 0"></div>

    <div
      v-for="(_, index) of count"
      :key="index"
      :class="[
        'absolute',
        Math.floor((value / 100) * (count - 1)) == index
          ? 'top-[-6px]'
          : 'top-[-4px]',
      ]"
      :style="{ left: `${index * interval - 8}px` }"
      @click="!disabled && handleClickPoints(index)"
    >
      <div
        :class="[
          'dark:bg-navy-800 bg-white rounded-full relative',
          Math.floor((value / 100) * (count - 1)) == index
            ? 'size-4 border-[3px] dark:border-white border-navy-300'
            : 'size-3 border-2 dark:border-navy-200 border-navy-100',
        ]"
      />
      <p
        :class="[
          'text-navy-100 text-xs leading-[18px] font-medium absolute mt-1',
          index == 0 ? 'left-0' : '-translate-x-1',
          index == count - 1 ? '-right-1' : '',
        ]"
      >
        {{ Math.floor((index * 100) / (count - 1)) }}%
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineModel } from "vue";
import { useResizeObserver } from "@vueuse/core";

const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  enableDrag: {
    type: Boolean,
    default: false,
  },
});

const container = ref(null);
const slider = ref(null);
const value = defineModel(0);
const isDragging = ref(false);
const interval = ref(0);

watch(value, () => {
  slider.value.style.width = `${
    (value.value / 100) * container.value.offsetWidth
  }px`;
});

useResizeObserver(container, (entries) => {
  const entry = entries[0];
  const { width } = entry.contentRect;
  interval.value = width / (props.count - 1);
});

const handleMouseDown = (event) => {
  if (props.enableDrag) {
    isDragging.value = true;
    const width = event.clientX - container.value.getBoundingClientRect().left;
    slider.value.style.width = `${width}px`;
    const updateValue = (width / container.value.offsetWidth) * 100;
    value.value = updateValue > 100 ? 100 : updateValue;
  }
};
const handleMouseMove = (event) => {
  if (isDragging.value) {
    const width = event.clientX - container.value.getBoundingClientRect().left;
    slider.value.style.width = `${width}px`;
    const updateValue = (width / container.value.offsetWidth) * 100;
    value.value = updateValue > 100 ? 100 : updateValue;
  }
};

const handleMouseUp = () => {
  isDragging.value = false;
};

const handleClickPoints = (index) => {
  const width = interval.value * index;
  slider.value.style.width = `${width}px`;
  const updateValue = (index * 100) / (props.count - 1);
  value.value = updateValue > 100 ? 100 : updateValue;
};
</script>
