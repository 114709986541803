<template>
  <dialog-view :is-open="isOpen" title="Puzzle" @change-is-open="handleClose">
    <template #content>
      <div class="dark:text-white text-navy-800 w-full text-center mb-4 mt-2">
        Arrange the below word to: {{ originalWord }}
      </div>
      <Container @drop="onDrop" orientation="horizontal">
        <Draggable v-for="item in letters" :key="item.id">
          <digit-puzzle-item :letter="item.data" />
        </Draggable>
      </Container>
    </template>
  </dialog-view>
</template>

<script setup>
import { ref, watch, defineEmits, defineModel } from "vue";
import { Container, Draggable } from "vue-dndrop";
import DialogView from "@/components/Dialog/DialogView.vue";
import DigitPuzzleItem from "@/components/DigitPuzzle/DigitPuzzleItem.vue";
import { usePuzzle } from "@/components/DigitPuzzle/puzzle";
import { useStore } from "vuex";

const emit = defineEmits(["success"]);
const model = defineModel();
const store = useStore();
const { word, originalWord, isOpen, handleChangePuzzle } = usePuzzle(
  emit,
  model
);

const formatProp = (words) =>
  words.split("").map((item, index) => ({ id: item + index, data: item }));

watch(word, (newValue) => {
  letters.value = formatProp(newValue);
});

const letters = ref(formatProp(word.value));

const onDrop = ({ removedIndex, addedIndex }) => {
  const temp = letters.value[removedIndex];
  letters.value.splice(removedIndex, 1);
  letters.value.splice(addedIndex, 0, temp);
  handleChangePuzzle(letters.value.map((item) => item.data).join(""));
};

const handleClose = () => {
  model.value = false;
  store.dispatch("setShowPuzzle", false);
};
</script>
