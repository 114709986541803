<template>
  <div
    :class="[
      'flex flex-row items-center gap-1',
      fontWeightClass,
      fontSizeClass,
      disabled
        ? 'text-navy-200 cursor-not-allowed'
        : 'text-primary-700 cursor-pointer hover:text-primary-800 ',
    ]"
    @click="() => !disabled && emit('click')"
  >
    <span v-if="label">{{ label }}</span>
    <span v-if="icon"><component :is="icon" class="size-3 stroke-2" /></span>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  label: String,
  disabled: Boolean,
  icon: Object,
  weight: String,
  fontSize: String,
});
const emit = defineEmits(["click"]);

const fontWeightClass = computed(() => {
  switch (props.weight) {
    case "semibold":
      return "font-semibold";
    default:
      return "font-medium";
  }
});

const fontSizeClass = computed(() => {
  switch (props.fontSize) {
    case "xs":
      return "text-xs";
    case "sm":
    default:
      return "text-sm";
  }
});
</script>
