<template>
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.398 37.9836L38.0575 23.4936C38.6939 22.8232 39.1876 22.0338 39.5277 21.1796C39.8459 20.3577 40.0104 19.4927 39.9995 18.606C39.9885 17.7409 39.813 16.8866 39.4728 16.0864C38.7487 14.432 37.3554 13.1452 35.6219 12.5829C34.788 12.3017 33.8993 12.1936 33.0107 12.2585C32.111 12.3234 31.2333 12.5613 30.4214 12.9505C29.5766 13.3615 28.8196 13.9346 28.1832 14.6158L14.6005 29.5491C13.9971 30.198 13.5473 30.9657 13.262 31.7875C12.9877 32.5661 12.867 33.3987 12.9219 34.2205C13.0097 35.8966 13.7996 37.4646 15.1052 38.5675C15.7635 39.119 16.5205 39.5516 17.3434 39.8003C18.1443 40.0598 18.9891 40.1571 19.8229 40.103C21.5674 39.9949 23.2022 39.2272 24.398 37.962V37.9836Z"
      fill="url(#paint0_linear_3231_62285)"
    />
    <path
      d="M19.5209 0.169922C23.2622 0.169922 26.2793 3.15443 26.2793 6.83099V33.5077C26.2793 37.1951 23.2512 40.1688 19.5209 40.1688C15.7906 40.1688 12.7625 37.1843 12.7625 33.5077V6.8418C12.7625 3.15443 15.7906 0.169922 19.5209 0.169922Z"
      fill="url(#paint1_linear_3231_62285)"
    />
    <path
      d="M19.0869 0.198912C18.1763 0.263793 17.2876 0.512502 16.4757 0.923412C15.6199 1.35595 14.8629 1.95069 14.2375 2.66437L1.62032 17.2625C-0.146089 19.3062 -0.497176 22.1934 0.709686 24.594C2.36638 27.8813 6.40388 29.2113 9.72823 27.5785C10.5072 27.2 11.2094 26.6702 11.7799 26.0214L24.4958 11.8558C25.7466 10.4609 26.4158 8.64419 26.361 6.78429C26.339 5.88677 26.1196 4.98926 25.7575 4.16744C25.3845 3.32399 24.8359 2.55624 24.1338 1.92906C23.4426 1.30188 22.6307 0.826091 21.753 0.523315C20.8972 0.231353 19.9866 0.123218 19.0869 0.188099"
      fill="url(#paint2_linear_3231_62285)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3231_62285"
        x1="37.7949"
        y1="13.7676"
        x2="16.039"
        y2="37.9158"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#40BDEB" />
        <stop offset="1" stop-color="#40BDEB" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3231_62285"
        x1="25.1791"
        y1="2.36149"
        x2="4.09228"
        y2="10.5005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#39CAFF" stop-opacity="0.24" />
        <stop offset="1" stop-color="#40BDEB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3231_62285"
        x1="24.2182"
        y1="1.70987"
        x2="2.21649"
        y2="25.282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#40BDEB" stop-opacity="0" />
        <stop offset="1" stop-color="#40BDEB" />
      </linearGradient>
    </defs>
  </svg>
</template>
