<template>
  <div class="relative w-full overflow-x-clip">
    <div class="absolute w-full -z-50 -top-12">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/rewards-hub/rewards-hub-ellipse-dark.svg'
            : '/assets/images/rewards-hub/rewards-hub-ellipse-light.svg'
        "
        class="w-full"
      />
    </div>
    <div class="w-full px-2 sm:px-16">
      <div
        ref="container"
        class="container mx-auto max-w-[1088px] w-full flex flex-col relative"
      >
        <div class="absolute top-[70px] right-0 -z-50 max-sm:hidden">
          <img
            :src="
              currentTheme === 'dark'
                ? '/assets/images/rewards-hub/rewards-hub-dark-bg.svg'
                : '/assets/images/rewards-hub/rewards-hub-light-bg.svg'
            "
          />
        </div>
        <div class="absolute -z-50 sm:hidden right-0 top-[127px]">
          <img
            :src="
              currentTheme === 'dark'
                ? '/assets/images/rewards-hub/rewards-hub-mobile-dark-bg.svg'
                : '/assets/images/rewards-hub/rewards-hub-mobile-light-bg.svg'
            "
          />
        </div>
        <div
          class="mt-12 sm:mt-16 font-bold text-5xl leading-[56px] -tracking-[1.2px] sm:leading-[64px]"
        >
          {{ $t("translation.rewardsHub.title") }}
        </div>
        <div
          class="font-medium text-lg -tracking-[0.4px] dark:text-navy-100 text-navy-200 mt-8"
        >
          {{ $t("translation.rewardsHub.myRewards") }}
        </div>
        <div class="flex flex-row mt-4 items-center">
          <div
            class="size-12 sm:size-16 rounded-full dark:bg-navy-400 bg-primary-300 flex items-center justify-center mr-4 sm:mr-6"
          >
            <exzi-logo-icon class="sm:size-10 size-8 rounded-full" />
          </div>
          <div
            class="dark:text-white text-navy-800 font-semibold sm:text-[40px] sm:leading-[48px] sm:-tracking-[1.2px] text-[32px] leading-[40px] -tracking-[0.8px] mr-2"
          >
            1,800
          </div>
          <div
            class="dark:text-white text-navy-800 font-medium text-xl leading-[32px] -tracking-[0.4px]"
          >
            {{ $t("translation.rewardsHub.pointUnits") }}
          </div>
        </div>
        <div class="flex flex-row gap-4 sm:mt-10 mt-8">
          <fill-button color="primary" size="md" class="max-sm:grow">
            {{ $t("translation.rewardsHub.leaderboard") }}
          </fill-button>
          <outline-button
            color="primary"
            size="md"
            class="max-sm:grow"
            @click="handleTaskHistoryClick"
          >
            {{ $t("translation.rewardsHub.taskHistory") }}
          </outline-button>
          <outline-button color="primary" size="md" class="max-sm:hidden">
            <div class="flex flex-row gap-2">
              <share-fat-icon class="size-5" />
              <div>{{ $t("translation.rewardsHub.share") }}</div>
            </div>
          </outline-button>
          <div
            class="sm:hidden size-10 rounded-full border border-primary-700 flex items-center justify-center shrink-0"
          >
            <share-fat-icon class="size-5" />
          </div>
        </div>
        <div class="sm:mt-16 mt-10">
          <underline-tab
            :items="tabTitles"
            :default-selected="tabTitles[0]"
            @change="handleTabChange"
          />
        </div>
        <div
          class="flex flex-col sm:mt-12 mt-8 relative mb-[104px]"
          v-if="currentTab === 1"
        >
          <div
            class="dark:text-white text-navy-800 font-semibold text-2xl leading-9 -tracking-[0.4px]"
          >
            {{ $t("translation.rewardsHub.dialyCheckIn") }}
          </div>
          <div
            class="flex flex-row gap-6 items-center justify-between overflow-visible mt-6 sm:mt-8"
          >
            <template v-for="item in weekDayNames" :key="item.id">
              <div
                class="w-10 border-t dark:border-t-navy-300 border-t-navy-10 shrink-0"
                v-if="item.id !== 1"
              />
              <div class="flex flex-col items-center shrink-0">
                <div
                  :class="[
                    'rounded-full',
                    currentTheme === 'dark'
                      ? item.id === 1
                        ? 'bg-primary-700'
                        : 'bg-navy-500'
                      : item.id === 1
                      ? 'bg-neutral-800'
                      : 'bg-navy-10',
                    currentTheme === 'dark'
                      ? 'text-white'
                      : item.id === 1
                      ? 'text-white'
                      : 'text-navy-200',
                    'px-4 py-2 shrink-0',
                  ]"
                >
                  {{ item.name }}
                </div>
                <down-arrow-icon
                  :class="
                    currentTheme === 'dark'
                      ? item.id === 1
                        ? 'text-primary-700'
                        : 'text-navy-500'
                      : item.id === 1
                      ? 'text-neutral-800'
                      : 'text-navy-10'
                  "
                />
              </div>
            </template>
          </div>
          <div class="flex flex-row sm:gap-8 gap-4 mt-8 w-max">
            <daily-bonus-item
              v-for="item in weekBonusCardsData"
              :key="item.id"
              :points="item.point"
              :active="item.id === 1"
              :title="item.title"
              :content="item.content"
            />
          </div>
          <div
            class="mt-[104px] sm:mt-[72px] font-semibold text-2xl leading-9 -tracking-[0.4px] dark:text-white text-navy-800"
          >
            {{ $t("translation.rewardsHub.dialyCheckIn") }}
          </div>
          <div
            class="mt-6 sm:mt-8 w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-2"
          >
            <bonus-item
              v-for="item in bonusCardsData"
              :key="item.id"
              :title="item.title"
              :label="item.label"
              :point="item.point"
            />
          </div>
          <div class="mt-[104px] sm:mt-[168px] flex flex-col">
            <div
              class="dark:text-white text-navy-800 font-semibold text-2xl leading-9 -tracking-[0.4px]"
            >
              {{ $t("translation.rewardsHub.vipTitle") }}
            </div>
            <div
              class="mt-4 sm:max-w-[622px] font-medium text-lg -tracking-[0.4px] dark:text-navy-100 text-navy-300"
            >
              {{ $t("translation.rewardsHub.vipLabel") }}
            </div>
            <div
              class="mt-6 sm:mt-10 w-full grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-2"
            >
              <div
                class="rounded-3xl relative overflow-hidden"
                style="
                  background: linear-gradient(
                    23.94deg,
                    #181d2d 1.11%,
                    #2347b9 50.19%,
                    #2154f4 99.28%
                  );
                "
              >
                <div class="absolute w-full bottom-0 z-10">
                  <img
                    src="/assets/images/rewards-hub/vip-bg-lines-blue.svg"
                    class="w-full"
                  />
                </div>
                <div class="sm:p-8 p-6 w-full flex flex-col relative z-20">
                  <div
                    class="text-white font-semibold text-2xl leading-9 -tracking-[0.4px]"
                  >
                    {{ $t("translation.rewardsHub.vipContent.0.title") }}
                  </div>
                  <div
                    class="mt-3 text-white font-medium text-base -tracking-[0.2px] max-w-[361px]"
                  >
                    {{ $t("translation.rewardsHub.vipContent.0.content") }}
                  </div>
                  <div class="sm:mt-10 mt-4 flex flex-row items-center gap-2">
                    <div
                      class="text-white font-semibold text-[40px] leading-[48px] -tracking-[1.2px]"
                    >
                      +30,000
                    </div>
                    <div
                      class="text-white font-normal text-lg -tracking-[0.4px]"
                    >
                      {{ $t("translation.rewardsHub.pointUnits") }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="rounded-3xl relative overflow-hidden"
                style="
                  background: linear-gradient(
                    23.94deg,
                    #17141b 1.11%,
                    #431481 50.19%,
                    #a156ff 99.28%
                  );
                "
              >
                <div class="absolute w-full bottom-0 z-10">
                  <img
                    src="/assets/images/rewards-hub/vip-bg-lines-purple.svg"
                    class="w-full"
                  />
                </div>
                <div class="w-full sm:p-8 p-6 flex flex-col relative z-20">
                  <div
                    class="text-white font-semibold text-2xl leading-9 -tracking-[0.4px]"
                  >
                    {{ $t("translation.rewardsHub.vipContent.1.title") }}
                  </div>
                  <div
                    class="mt-3 text-white font-medium text-base -tracking-[0.2px] max-w-[422px]"
                  >
                    {{ $t("translation.rewardsHub.vipContent.1.content") }}
                  </div>
                  <div class="sm:mt-10 mt-4 flex flex-row items-center gap-2">
                    <div
                      class="text-white font-semibold text-[40px] leading-[48px] -tracking-[1.2px]"
                    >
                      +100,000
                    </div>
                    <div
                      class="text-white font-normal text-lg -tracking-[0.4px]"
                    >
                      {{ $t("translation.rewardsHub.pointUnits") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:mt-12 mt-8 mb-[104px]"
          v-else-if="currentTab === 2"
        >
          <div class="w-full grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-2">
            <voucher-shop-item
              v-for="item in voucherShopData"
              :key="item.id"
              :active="item.active"
              :label="item.label"
              :title="item.title"
              :point="item.point"
            />
          </div>
          <div
            class="dark:text-white text-navy-800 font-semibold text-2xl leading-9 -tracking-[0.4px] mt-[104px] sm:mt-[168px]"
          >
            {{ $t("translation.rewardsHub.dialyCheckIn") }}
          </div>
          <div class="mt-8 grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-2">
            <expired-shop-item
              v-for="item in expiredItemData"
              :key="item.id"
              :content="item.label"
              :title="item.title"
              :point="item.point"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import DailyBonusItem from "@/views/RewardsHub/Items/DailyBonusItem.vue";
import BonusItem from "@/views/RewardsHub/Items/BonusItem.vue";
import VoucherShopItem from "@/views/RewardsHub/Items/VoucherShopItem.vue";
//import FaqComponent from "@/common/FAQ/FaqComponent.vue";
import DownArrowIcon from "@/assets/icons/common/DownArrowIcon.vue";
import ExziLogoIcon from "@/assets/icons/common/ExziLogoIcon.vue";
import ShareFatIcon from "@/assets/icons/common/ShareFaticon.vue";
import ExpiredShopItem from "@/views/RewardsHub/Items/ExpiredShopItem.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const tabTitles = computed(() => [
  {
    id: 1,
    name: t("translation.rewardsHub.tabNames.0"),
  },
  {
    id: 2,
    name: t("translation.rewardsHub.tabNames.1"),
  },
]);

const weekDayNames = ref();
const weekBonusCardsData = ref();
const bonusCardsData = ref();
const voucherShopData = ref();
const expiredItemData = ref();

const currentTab = ref(1);
//const faqData = ref([]);
const router = useRouter();

(async () => {
  try {
    const response = await fetch("/mock/rewards-hub/data.json");
    const data = await response.json();
    weekDayNames.value = data.weekDayNames;
    weekBonusCardsData.value = data.weekBonusData;
    bonusCardsData.value = data.bonusCardData;
    voucherShopData.value = data.voucherShopData;
    expiredItemData.value = data.expireData;
  } catch (error) {
    console.error(error);
  }
})();

const handleTabChange = (item) => {
  currentTab.value = item.id;
};

const handleTaskHistoryClick = () => {
  router.push({ name: "rewards-task-history" });
};

// (async () => {
//   try {
//     const response = await fetch("/mock/faq/faq.json");
//     const data = await response.json();
//     faqData.value = data;
//   } catch (error) {
//     console.error(error);
//   }
// })();
</script>
