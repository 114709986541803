import { VERIFICATION_TIME_LEFT } from "@/constants/constants";
import moment from "moment";
import { computed, ref, onUnmounted } from "vue";
import { useStore } from "vuex";

export const useVerificationTime = (handleRecendCode) => {
  const store = useStore();
  const timeLeft = ref();
  const verificationReceivedTime = computed(
    () => store.getters.code.timeSentVerificationCode
  );

  timeLeft.value =
    VERIFICATION_TIME_LEFT -
    moment().diff(moment(verificationReceivedTime.value), "seconds");
  const id = setInterval(() => {
    if (timeLeft.value === 0) {
      handleRecendCode();
      clearInterval(id);
      timeLeft.value = VERIFICATION_TIME_LEFT;
      return;
    }
    timeLeft.value--;
  }, 1000);

  onUnmounted(() => {
    clearInterval(id);
  });

  return {
    timeLeft,
  };
};
