<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7883 8.08746L12.5383 1.83746C12.4072 1.70624 12.2402 1.61685 12.0583 1.58059C11.8764 1.54433 11.6879 1.56283 11.5165 1.63376C11.3451 1.70468 11.1987 1.82483 11.0956 1.97902C10.9925 2.1332 10.9375 2.3145 10.9375 2.49996V5.37027C8.92501 5.61402 6.74532 6.61167 4.94141 8.14136C2.66329 10.0742 1.24454 12.5703 0.9461 15.1726C0.915867 15.4338 0.968737 15.6978 1.09719 15.9272C1.22565 16.1565 1.42315 16.3396 1.66161 16.4502C1.90007 16.5609 2.16736 16.5935 2.42545 16.5436C2.68354 16.4936 2.91931 16.3635 3.09923 16.1718C3.91641 15.3015 6.7711 12.5984 10.9375 12.2328V15C10.9377 15.1853 10.9927 15.3664 11.0958 15.5204C11.1988 15.6744 11.3451 15.7944 11.5164 15.8653C11.6876 15.9362 11.8759 15.9548 12.0577 15.9187C12.2394 15.8826 12.4064 15.7934 12.5375 15.6625L18.7875 9.41246C18.9632 9.23678 19.0619 8.99856 19.0621 8.75012C19.0622 8.50168 18.9637 8.26334 18.7883 8.08746ZM12.8125 12.7343V11.25C12.8125 11.0013 12.7137 10.7629 12.5379 10.587C12.3621 10.4112 12.1236 10.3125 11.875 10.3125C8.04688 10.3125 5.11173 11.9968 3.29766 13.446C3.85469 12.0273 4.83126 10.6945 6.15469 9.57183C7.91407 8.07886 10.0524 7.18746 11.875 7.18746C12.1236 7.18746 12.3621 7.08868 12.5379 6.91287C12.7137 6.73705 12.8125 6.4986 12.8125 6.24996V4.76558L16.7969 8.74996L12.8125 12.7343Z"
      fill="#39A3D0"
    />
  </svg>
</template>
