<template>
  <dialog-view
    :contain-header="true"
    v-if="model"
    :title="$t('translation.profile.security.disableTfaDialog.title')"
    :is-open="model"
    @change-is-open="handleChangeOpen"
    bg-color="gray"
  >
    <template #content>
      <div class="w-full flex flex-col">
        <div class="w-full flex flex-col items-start mt-6">
          <div class="w-full">
            <text-edit
              :label="
                $t('translation.profile.security.disableTfaDialog.emailLabel')
              "
              :placeholder="
                $t(
                  'translation.profile.security.disableTfaDialog.emailPlaceholder'
                )
              "
              :fill="false"
              rounded="xl"
              v-model="email_code"
            />
          </div>
          <div class="w-full mt-6">
            <text-edit
              :label="
                $t('translation.profile.security.disableTfaDialog.codeLabel')
              "
              :placeholder="
                $t(
                  'translation.profile.security.disableTfaDialog.codePlaceholder'
                )
              "
              :fill="false"
              rounded="xl"
              v-model="google_code"
            />
          </div>
        </div>
        <div class="mt-12 w-full">
          <fill-button
            color="primary"
            size="lg"
            width="full"
            @click="handleConfirmClick"
          >
            {{ $t("translation.profile.security.disableTfaDialog.confirm") }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import DialogView from "@/components/Dialog/DialogView.vue";
import { postHttpRequest } from "@/api/api";

const { t } = useI18n();
const model = defineModel();
const email_code = ref("");
const google_code = ref("");

const store = useStore();

const handleChangeOpen = () => {
  model.value = false;
};

const handleConfirmClick = async () => {
  const res = await postHttpRequest(store)("/api/account/change-login-type", {
    send_to_email: 0,
    send_to_phone: 0,
    status: 0,
    type: 2,
    email_code: email_code.value,
    google_code: google_code.value,
  });
  if (res.status) {
    store.dispatch("fetchAccountInfo");
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.disableTfaDialog.confirmSuccessToast"
      ),
    });
    model.value = false;
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: t(
        "translation.profile.security.disableTfaDialog.confirmErrorToast"
      ),
    });
  }
};
</script>
