<template>
  <div>
    <p class="text-title">{{ $t("translation.auth.signin.title") }}</p>
    <div class="sm:h-12 h-6" />
    <underline-tab :items="items" @change="handleChangeSelectedTab" />
    <div class="sm:h-10 h-6" />
    <email-address
      v-if="selectedTab == items[0].id"
      @changeEmail="handleChangeEmail"
      @changePassword="handleChangePassword"
    />
    <phone-number
      v-if="selectedTab == items[1].id"
      @changeEmail="handleChangeEmail"
      @changePassword="handleChangePassword"
      @changePhone="handleChangePhone"
    />
    <div class="h-6" />
    <div class="text-sm">
      <link-button
        :label="$t('translation.auth.signin.forgetPassword')"
        @click="handleForgetPassword()"
      />
    </div>
    <div class="sm:h-10 h-6" />
    <filled-button
      :label="$t('translation.auth.signin.login')"
      :disabled="isDisabled"
      :isLoading="isLoading"
      @click="handleSignin"
    >
    </filled-button>
    <div class="h-6" />
    <div class="flex space-x-3 justify-center text-sm font-medium">
      <label class="text-navy-100">{{
        $t("translation.auth.signin.noAccountLabel")
      }}</label>
      <link-button
        :label="$t('translation.auth.signin.signup')"
        @click="handleSignup"
      />
    </div>
    <div class="h-8" />
    <div class="flex items-center">
      <div class="w-full h-px bg-navy-500"></div>
      <label class="w-full pl-4 text-navy-200 text-center text-xs font-medium">
        {{ $t("translation.auth.signin.continue") }}
      </label>
      <div class="w-full h-px bg-navy-500"></div>
    </div>
    <div class="h-4" />
    <div class="flex justify-center space-x-4">
      <div
        class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
        @click="handleSigninWithGoogle"
      >
        <img class="absolute m-auto inset-0" src="@/assets/icons/google.svg" />
      </div>
      <div
        class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
        @click="handleSigninWithMac"
      >
        <img
          v-if="currentTheme === 'light'"
          class="absolute m-auto inset-0"
          src="@/assets/icons/mac_black.svg"
        />
        <img
          v-if="currentTheme === 'dark'"
          class="absolute m-auto inset-0"
          src="@/assets/icons/mac_white.svg"
        />
      </div>
    </div>
  </div>
  <digit-puzzle
    v-if="showPuzzle"
    v-model="isLoading"
    @success="handleSuccessPuzzle"
  />
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import EmailAddress from "@/views/Auth/Signin/SignInWith/EmailAddress.vue";
import PhoneNumber from "@/views/Auth/Signin/SignInWith/PhoneNumber.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import {
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from "@/views/Auth/validation";
import DigitPuzzle from "@/components/DigitPuzzle/DigitPuzzle.vue";
import { useSignin } from "@/views/Auth/Signin/signin";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();
const showPuzzle = computed(() => store.getters.showPuzzle);

const { isLoading, email, phone, password, requestLogin } = useSignin();

const items = computed(() => [
  { id: 1, name: t("translation.auth.signin.tabHeaders.0") },
  { id: 2, name: t("translation.auth.signin.tabHeaders.1") },
]);

const router = useRouter();
const selectedTab = ref(1);

const isDisabled = computed(() => {
  if (selectedTab.value === items.value[0].id) {
    return (
      !validateEmail(email.value) ||
      !validatePassword(password.value).every((item) => item === true)
    );
  } else if (selectedTab.value === items.value[1].id) {
    return (
      !validatePhoneNumber(phone.value) ||
      !validatePassword(password.value).every((item) => item === true)
    );
  }
  return false;
});

const handleSignin = () => {
  store.dispatch("setShowPuzzle", true);
  isLoading.value = true;
};

const handleSuccessPuzzle = () => {
  requestLogin();
};

const handleChangeSelectedTab = (tab) => {
  selectedTab.value = tab.id;
};

// Handle Email login
const handleChangeEmail = (newEmail) => {
  email.value = newEmail;
};

const handleChangePhone = (newPhone) => {
  phone.value = newPhone;
};

const handleChangePassword = (newPassword) => {
  password.value = newPassword;
};

const handleForgetPassword = () => {
  router.push({ name: "forget-password" });
};

const handleSignup = () => {
  router.push({ name: "signup" });
};
const handleSigninWithGoogle = () => {
  // Handle Sign in with google
  console.log("sign in with google");
};
const handleSigninWithMac = () => {
  // Handle Sign in with mac
  console.log("sign in with mac");
};
</script>
