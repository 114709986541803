<template>
  <dialog-view
    :contain-header="false"
    v-if="model"
    :is-open="model"
    bg-color="gray"
    @change-is-open="handleChangeOpen"
  >
    <template #header>
      <div
        class="w-full rounded-tl-3xl rounded-tr-3xl h-[272px] overflow-hidden"
      >
        <div class="py-4 px-6 flex justify-end relative">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200 z-20"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
          <img
            class="absolute top-0 left-0"
            src="@/assets/images/dialog/success_dialog_bg_light.svg"
          />
        </div>
        <div
          class="flex flex-col items-center justify-center mt-4 relative z-[300]"
        >
          <token-circle-display color="only-primary">
            <check-icon class="w-14 h-14" />
          </token-circle-display>
          <p
            class="text-white text-xl leading-[32px] -tracking-[0.4px] font-semibold mt-4"
          >
            {{ headerTitle }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineProps } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenCircleDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/24/outline";

const model = defineModel();
defineProps({
  headerTitle: String,
});

const handleChangeOpen = () => {
  model.value = false;
};

const handleCloseDialog = () => {
  model.value = false;
};
</script>
