<template>
  <div>
    <text-edit
      type="email"
      :filled="false"
      rounded="xl"
      :label="$t('translation.auth.signin.emailLogin.emailLabel')"
      :placeholder="$t('translation.auth.signin.emailLogin.emailPlaceholder')"
      v-model="email"
      :error="emailError"
    />
    <div class="h-6" />
    <password-input
      :label="$t('translation.auth.signin.emailLogin.passwordLabel')"
      :placeholder="
        $t('translation.auth.signin.emailLogin.passwordPlaceholder')
      "
      v-model="password"
      :error="passwordError"
      :hideValidator="true"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { validateEmail, validatePassword } from "@/views/Auth/validation";

const { t } = useI18n();
const email = ref("");
const password = ref("");
const emit = defineEmits(["changeEmail", "changePassword"]);
const emailError = computed(() =>
  email.value && !validateEmail(email.value)
    ? t("translation.auth.signin.emailLogin.emailError")
    : ""
);
const passwordError = computed(() => validatePassword(password.value));

watch(email, (newEmail) => {
  emit("changeEmail", newEmail);
});

watch(password, (newPassword) => {
  emit("changePassword", newPassword);
});
</script>
