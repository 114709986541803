<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-navy-800 bg-opacity-90 z-[150]"
    @click="closeDrawer"
  />
  <div
    :class="[
      'fixed top-0 right-0 h-full w-96 dark:bg-navy-600 bg-white shadow-lg transform z-[150]',
      'rounded-tl-3xl rounded-bl-3xl',
      isOpen ? 'translate-x-0' : 'translate-x-full',
      'transition-transform duration-300 ease-in-out',
    ]"
  >
    <div class="px-6 py-4 flex justify-between items-center">
      <div class="flex gap-2 items-center">
        <ArrowLeftIcon
          v-if="selectedPage"
          class="w-6 h-6 cursor-pointer"
          @click="handleChangePage(null)"
        />
        <h2 class="text-2xl font-semibold">
          {{
            !selectedPage
              ? $t("translation.trading.chartSetting.title")
              : selectedPage.name
          }}
        </h2>
      </div>
      <button @click="closeDrawer" class="dark:text-navy-100 text-navy-200">
        <XMarkIcon class="w-6 h-6" />
      </button>
    </div>
    <template v-if="!selectedPage">
      <div class="mt-4 px-3 text-sm">
        <p
          class="text-xs font-medium dark:text-navy-100 text-navy-200 my-4 px-2"
        >
          {{ $t("translation.trading.chartSetting.generalPage.label") }}
        </p>
        <div class="p-3 font-medium flex items-center justify-between">
          <p>
            {{ $t("translation.trading.chartSetting.generalPage.menuItems.0") }}
          </p>
          <switch-component />
        </div>
        <div v-for="page in pageItems" :key="page.id" class="mt-3">
          <div
            v-if="page.isDivider"
            class="dark:border-navy-500 border-navy-10 border-b"
          />
          <fill-button
            v-else
            width="full"
            size="xs"
            rounded="lg"
            color="navy"
            @click="handleChangePage(page)"
          >
            <div class="w-full flex justify-between items-center">
              <p>{{ page.name }}</p>
              <ChevronRightIcon class="w-5 h-5" />
            </div>
          </fill-button>
        </div>
        <div class="mt-10">
          <p class="p-3">
            {{ $t("translation.trading.chartSetting.generalPage.panelTitle") }}
          </p>
          <div class="mt-3 px-3 flex gap-4">
            <TradingPanelSetting
              mode="right"
              :label="
                $t('translation.trading.chartSetting.generalPage.rightLabel')
              "
              :active="trading_panel === 'right'"
              @click="handleUpdateTradingPanelSetting('right')"
            />
            <TradingPanelSetting
              mode="left"
              :label="
                $t('translation.trading.chartSetting.generalPage.leftLabel')
              "
              :active="trading_panel === 'left'"
              @click="handleUpdateTradingPanelSetting('left')"
            />
          </div>
        </div>
      </div>
    </template>
    <change-basis v-else-if="selectedPage.id === 1" />
    <chart-alerts v-else-if="selectedPage.id === 2" />
  </div>
</template>
<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  XMarkIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
} from "@heroicons/vue/24/outline";
import SwitchComponent from "@/components/Switch/SwitchComponent.vue";
import TradingPanelSetting from "@/components/TradingPanelSetting/TradingPanelSetting.vue";
import ChangeBasis from "@/views/Trading/ChartSettings/ChangeBasis.vue";
import ChartAlerts from "@/views/Trading/ChartSettings/ChartAlerts.vue";

const { t } = useI18n();

const pageItems = [
  {
    id: 1,
    name: t("translation.trading.chartSetting.generalPage.menuItems.1"),
  },
  {
    id: 2,
    name: t("translation.trading.chartSetting.generalPage.menuItems.2"),
  },
  { id: 3, name: "", isDivider: true },
];

const store = useStore();

defineProps({
  isOpen: {
    type: Boolean,
  },
});

const emits = defineEmits(["close"]);

const selectedPage = ref(null);
// const order_confirmation = ref(chartSettingsManager.getOrderConfirmation());
const trading_panel = computed(() => store.getters.chartTradingPanel);

const closeDrawer = () => {
  emits("close");
};

const handleChangePage = (page) => {
  selectedPage.value = page;
};

const handleUpdateTradingPanelSetting = (status) => {
  store.dispatch("setChartTradingPanel", status);
};
</script>
