<template>
  <div class="flex flex-col px-4 sm:px-16">
    <div
      class="font-semibold text-[40px] mb-12 sm:mb-16 text-center sm:font-bold sm:text-5xl dark:text-white text-navy-800 -tracking-[1.2px] leading-[48px]"
    >
      {{ $t("translation.home.main.brandTitle") }}
    </div>
    <div class="sm:hidden mx-auto">
      <carousel-component>
        <template v-for="item in homepageBrandCardsData" :key="item.id">
          <brand-card
            :icon="item.icon"
            :title="item.title"
            :label="item.label"
            :img-url="item.imgUrl"
            :img-light-url="item.imgLightUrl"
          />
        </template>
      </carousel-component>
    </div>
    <div class="max-sm:hidden flex flex-row w-full gap-8">
      <div class="flex flex-col gap-8 flex-1">
        <div
          class="relative flex flex-col w-full rounded-[32px] dark:bg-navy-600 bg-primary-100 overflow-hidden justify-between self-stretch h-[600px]"
        >
          <div class="flex flex-col px-10 mt-10">
            <component
              :is="homepageBrandCardsData[0].icon"
              class="size-12 text-primary-600"
            />
            <div class="max-w-[259px]">
              <div
                class="dark:text-white -tracking-[0.8px] text-navy-800 text-[32px] font-semibold mt-6"
              >
                {{ homepageBrandCardsData[0].title }}
              </div>
              <div
                class="dark:text-white text-navy-800 text-lg -tracking-[0.4px] leading-7 font-normal mt-3 max-w-[222px]"
              >
                {{ homepageBrandCardsData[0].label }}
              </div>
            </div>
          </div>
          <img
            class="absolute bottom-0 right-0"
            :src="
              currentTheme === 'dark'
                ? homepageBrandCardsData[0].imgUrl
                : homepageBrandCardsData[0].imgLightUrl
            "
          />
        </div>

        <div
          class="relative flex flex-col w-full rounded-[32px] dark:bg-navy-600 bg-primary-100 overflow-hidden justify-between self-stretch h-[376px]"
        >
          <div class="flex flex-col px-10 mt-10">
            <component
              :is="homepageBrandCardsData[2].icon"
              class="size-12 text-primary-600"
            />
            <div class="max-w-[323px]">
              <div
                class="dark:text-white -tracking-[0.8px] text-navy-800 text-[32px] font-semibold mt-6"
              >
                {{ homepageBrandCardsData[2].title }}
              </div>
              <div
                class="dark:text-white -tracking-[0.4px] text-navy-800 text-lg font-normal mt-3"
              >
                {{ homepageBrandCardsData[2].label }}
              </div>
            </div>
          </div>
          <img
            class="absolute top-10 right-6"
            :src="
              currentTheme === 'dark'
                ? homepageBrandCardsData[2].imgUrl
                : homepageBrandCardsData[2].imgLightUrl
            "
          />
        </div>
      </div>
      <div class="flex flex-col gap-8 flex-1">
        <div
          class="relative flex flex-col w-full rounded-[32px] dark:bg-navy-600 bg-primary-100 overflow-hidden justify-between self-stretch h-[376px]"
        >
          <div class="flex flex-col px-10 mt-10">
            <component
              :is="homepageBrandCardsData[1].icon"
              class="size-12 text-primary-600"
            />
            <div class="max-w-[323px]">
              <div
                class="dark:text-white -tracking-[0.8px] text-navy-800 text-[32px] font-semibold mt-6"
              >
                {{ homepageBrandCardsData[1].title }}
              </div>
              <div
                class="dark:text-white -tracking-[0.4px] text-navy-800 text-lg font-normal mt-3 max-w-[287px]"
              >
                {{ homepageBrandCardsData[1].label }}
              </div>
            </div>
          </div>
          <img
            class="absolute bottom-14 right-6"
            :src="
              currentTheme === 'dark'
                ? homepageBrandCardsData[1].imgUrl
                : homepageBrandCardsData[1].imgLightUrl
            "
          />
        </div>

        <div
          class="relative flex flex-col w-full rounded-[32px] dark:bg-navy-600 bg-primary-100 overflow-hidden justify-between self-stretch h-[600px]"
        >
          <div class="flex flex-col px-10 mt-10">
            <component
              :is="homepageBrandCardsData[3].icon"
              class="size-12 text-primary-600"
            />
            <div>
              <div
                class="dark:text-white -tracking-[0.8px] text-navy-800 text-[32px] font-semibold mt-6"
              >
                {{ homepageBrandCardsData[3].title }}
              </div>
              <div
                class="dark:text-white -tracking-[0.4px] text-navy-800 text-lg font-normal mt-3"
              >
                {{ homepageBrandCardsData[3].label }}
              </div>
            </div>
          </div>
          <img
            class="absolute bottom-0 right-0"
            :src="
              currentTheme === 'dark'
                ? homepageBrandCardsData[3].imgUrl
                : homepageBrandCardsData[3].imgLightUrl
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BrandCard from "@/views/Home/Cards/BrandCard.vue";
import CarouselComponent from "@/components/Carousel/CarouselComponent.vue";
import EasyToUseIcon from "@/assets/icons/common/EasyToUseIcon.vue";
import TechnologyIcon from "@/assets/icons/common/TechnologyIcon.vue";
import WideRangeCrypto from "@/assets/icons/common/WideRangeCrypto.vue";
import EuIcon from "@/assets/icons/common/EuIcon.vue";

const { t } = useI18n();

const homepageBrandCardsData = computed(() => [
  {
    id: 0,
    icon: EasyToUseIcon,
    title: t("translation.home.main.brandCards.0.title"),
    label: t("translation.home.main.brandCards.0.label"),
    imgUrl: "/assets/images/home/slider/01_dark.svg",
    imgLightUrl: "/assets/images/home/slider/01_light.svg",
  },
  {
    id: 1,
    icon: EuIcon,
    title: t("translation.home.main.brandCards.1.title"),
    label: t("translation.home.main.brandCards.1.label"),
    imgUrl: "/assets/images/home/slider/03_dark.svg",
    imgLightUrl: "/assets/images/home/slider/03_light.svg",
  },
  {
    id: 2,
    icon: TechnologyIcon,
    title: t("translation.home.main.brandCards.2.title"),
    label: t("translation.home.main.brandCards.2.label"),
    imgUrl: "/assets/images/home/slider/02_dark.svg",
    imgLightUrl: "/assets/images/home/slider/02_light.svg",
  },
  {
    id: 3,
    icon: WideRangeCrypto,
    title: t("translation.home.main.brandCards.3.title"),
    label: t("translation.home.main.brandCards.3.label"),
    imgUrl: "/assets/images/home/slider/04_dark.svg",
    imgLightUrl: "/assets/images/home/slider/04_light.svg",
  },
]);
</script>
