<template>
  <div>
    <div
      class="bg-gradient-to-t from-primary-800 to-navy-400 rounded-tl-3xl rounded-tr-3xl -m-6"
    >
      <div class="flex justify-between items-center px-6 py-4 mt-6">
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ $t("translation.profile.idverify.verificationDialog.title") }}
        </h3>
        <button
          @click="handleClickCloseButton"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <x-mark-icon class="size-8" />
        </button>
      </div>
      <div class="p-6">
        <horizontal-circle-step :step-length="3" :current-active-step="1" />
        <div class="text-white text-base mt-6">
          {{ $t("translation.profile.idverify.verificationDialog.subTitle") }}
        </div>
        <div class="text-navy-50 text-sm mt-1">
          {{ $t("translation.profile.idverify.verificationDialog.label") }}
        </div>
        <div class="flex justify-between flex-wrap">
          <div
            class="mt-6 flex flex-col items-center"
            v-for="item in examplesData"
            :key="item.label"
          >
            <img :src="item.imageUrl" />
            <div class="text-white text-xs">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <div class="text-xl mb-4">
        {{ $t("translation.profile.idverify.verificationDialog.label1") }}:
      </div>
      <div
        class="flex items-center mb-4"
        v-for="item in beforeTakingPhotoMakeSureData"
        :key="item.id"
      >
        <check-icon class="w-5 h-5 mr-3 shrink-0" />
        <div class="text-base">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
  <fill-button class="w-full mt-12" @click="handleClickNextButton">
    {{ $t("translation.profile.idverify.verificationDialog.nextButton") }}
  </fill-button>
</template>
<script setup>
import { computed, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/24/outline";
import HorizontalCircleStep from "@/components/HorizontalCircleStep/HorizontalCircleStep.vue";

const { t } = useI18n();
const emits = defineEmits(["close", "next"]);

const examplesData = computed(() => [
  {
    label: t("translation.profile.idverify.verificationDialog.exampleLabel.0"),
    imageUrl: "/assets/images/verification/start-verification/good-example.svg",
  },
  {
    label: t("translation.profile.idverify.verificationDialog.exampleLabel.1"),
    imageUrl: "/assets/images/verification/start-verification/blurry.svg",
  },
  {
    label: t("translation.profile.idverify.verificationDialog.exampleLabel.2"),
    imageUrl: "/assets/images/verification/start-verification/shined.svg",
  },
  {
    label: t("translation.profile.idverify.verificationDialog.exampleLabel.3"),
    imageUrl: "/assets/images/verification/start-verification/out-of-frame.svg",
  },
]);

const beforeTakingPhotoMakeSureData = computed(() => [
  {
    id: 0,
    label: t(
      "translation.profile.idverify.verificationDialog.takePhotoLabel.0"
    ),
  },
  {
    id: 1,
    label: t(
      "translation.profile.idverify.verificationDialog.takePhotoLabel.1"
    ),
  },
  {
    id: 2,
    label: t(
      "translation.profile.idverify.verificationDialog.takePhotoLabel.2"
    ),
  },
]);

const handleClickCloseButton = () => {
  emits("close");
};

const handleClickNextButton = () => {
  emits("next");
};
</script>
