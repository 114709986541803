<template>
  <div v-for="(item, index) in props.faqData" :key="item.id">
    <collapse-view @header-click="(val) => handleHeaderClick(index, val)">
      <template #header="{ handleContentClick: handleButtonClick }">
        <div
          v-show="index != 0"
          class="my-2 border-t dark:border-navy-500 border-navy-50"
        />
        <div
          class="flex flex-row items-center justify-center py-3.5 w-full cursor-pointer"
          @click="handleButtonClick"
        >
          <div
            class="text-navy-100 mr-2 text-base font-normal sm:mr-4 self-start"
          >
            {{ item.number }}
          </div>
          <div class="dark:text-white text-navy-800 text-base font-medium">
            {{ item.title }}
          </div>
          <div class="flex-grow" />
          <div class="w-6 h-6 dark:text-white text-navy-800">
            <chevron-down-icon
              v-if="collapseState[index] === false"
              class="w-6 h-6 stroke-2"
            />
            <chevron-up-icon v-else class="w-6 h-6 stroke-2" />
          </div>
        </div>
      </template>
      <template #content>
        <div class="dark:text-white text-navy-800 p-4 mx-auto font-medium">
          <html-preview :html="item.content" />
        </div>
      </template>
    </collapse-view>
  </div>
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import HtmlPreview from "@/components/Preview/HtmlPreview.vue";

const props = defineProps(["faqData"]);
const collapseState = ref(Array(props.faqData?.length ?? 0).fill(false));

watch(
  () => props.faqData,
  (newVal) => {
    collapseState.value = Array(newVal?.length ?? 0).fill(false);
  }
);

const handleHeaderClick = (index, value) => {
  collapseState.value[index] = value;
};
</script>
