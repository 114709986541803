<template>
  <table class="w-full">
    <thead>
      <tr class="h-10">
        <th
          v-for="item in headerItems"
          :key="item.id"
          :class="[
            'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
            item.hideable === false ? '' : `max-${item.hideable}:hidden`,
          ]"
        >
          <div
            :class="[
              'flex items-center cursor-pointer',
              item.align === 'left' ? 'justify-start' : '',
              item.align === 'right' ? 'justify-end' : '',
              ,
            ]"
            @click="
              () => {
                if (item.sortable === true) {
                  handleSortClicked(item.id);
                }
              }
            "
          >
            <div>{{ item.label }}</div>
            <img
              v-if="item.sortable === true"
              src="@/assets/icons/vector.svg"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in props.displayData"
        :key="item.id"
        class="dark:hover:bg-navy-700 hover:bg-navy-10 h-[72px]"
      >
        <td class="px-3 rounded-l-xl">
          <div class="flex flex-row items-center justify-start">
            <star-icon
              v-if="!isBrief"
              :class="[
                'w-4 h-4 mr-3 cursor-pointer shrink-0',
                item.favorite === true
                  ? 'text-orange-400'
                  : 'dark:text-navy-300 text-navy-50',
              ]"
              @click="
                () => {
                  if (isLoggedIn === false) {
                    return;
                  }
                  if (favoritePairs.includes(item.id)) {
                    store.dispatch('removeFavoritePair', item.id);
                  } else {
                    store.dispatch('addFavoritePair', item.id);
                  }
                }
              "
            />
            <img :src="item.imgUrl" class="size-6 mr-2 rounded-full" />
            <div
              class="text-sm font-semibold dark:text-white text-navy-800 mr-1"
            >
              {{ item.token1 }}
            </div>
            <div class="text-sm font-semibold text-navy-100">
              {{ item.token2 }}
            </div>
          </div>
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800"
        >
          ${{ addCommasToNumber(item.price, -2) }}
        </td>
        <td class="text-left px-3 text-sm font-semibold max-sm:rounded-r-xl">
          <percent-display :value="item.percent" />
        </td>
        <td v-if="!isBrief" class="text-left px-3 max-lg:hidden">
          <market-mini-chart
            :class="item.percent >= 0 ? 'upChart' : 'chart'"
            :symbol="item"
          />
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800 max-sm:hidden max-md:rounded-r-xl"
        >
          ${{ formatNumberInKilo(item.volume) }}
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800 max-lg:hidden"
        >
          ${{ formatNumberInKilo(item.volume24h) }}
        </td>
        <td v-if="!isBrief" class="text-right px-3 max-md:hidden rounded-r-xl">
          <div
            v-if="isLoggedIn === true"
            class="flex items-center justify-end text-primary-700"
          >
            <div
              class="mr-3 cursor-pointer text-sm font-semibold"
              @click="
                () => {
                  router.push({
                    name: 'trading',
                    params: { pair: item.id, overview: 'overview' },
                  });
                }
              "
            >
              {{ $t("translation.market.main.pairTableAction.details") }}
            </div>
            <div class="border-r h-4 border-navy-300" />
            <div
              class="ml-3 cursor-pointer text-sm font-semibold"
              @click="
                () => {
                  router.push({ name: 'trading', params: { pair: item.id } });
                }
              "
            >
              {{ $t("translation.market.main.pairTableAction.trade") }}
            </div>
          </div>
          <div v-else class="flex items-center justify-end text-primary-700">
            <div class="cursor-pointer text-sm font-semibold">
              <span
                @click="
                  () => {
                    router.push({ name: 'signin' });
                  }
                "
                >{{ $t("translation.common.login") }}</span
              >
              <span class="text-navy-100"> or </span>
              <span
                @click="
                  () => {
                    router.push({ name: 'signup' });
                  }
                "
                >{{ $t("translation.common.signUp") }}</span
              >
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { addCommasToNumber, formatNumberInKilo } from "@/utils/NumberFormating";
import { StarIcon } from "@heroicons/vue/24/solid";
import PercentDisplay from "@/common/PairTable/PercentDisplay.vue";
import MarketMiniChart from "@/common/PairTable/MarketMiniChart.vue";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const props = defineProps(["displayData", "isBrief"]);
const emit = defineEmits(["sortClick"]);
const favoritePairs = computed(() => store.getters.favoritePairs);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const headerItems = computed(() =>
  [
    {
      id: 1,
      label: t("translation.market.main.pairTableLabel.0"),
      sortable: false,
      align: "left",
      hideable: false,
    },
    {
      id: 2,
      label: t("translation.market.main.pairTableLabel.1"),
      sortable: true,
      align: "left",
      hideable: false,
    },
    {
      id: 3,
      label: t("translation.market.main.pairTableLabel.2"),
      sortable: true,
      align: "left",
      hideable: false,
    },
    props.isBrief
      ? null
      : {
          id: 4,
          label: t("translation.market.main.pairTableLabel.3"),
          sortable: false,
          align: "left",
          hideable: "lg",
        },
    {
      id: 5,
      label: t("translation.market.main.pairTableLabel.4"),
      sortable: true,
      align: "left",
      hideable: "sm",
    },
    {
      id: 6,
      label: t("translation.market.main.pairTableLabel.5"),
      sortable: true,
      align: "left",
      hideable: "lg",
    },
    props.isBrief
      ? null
      : {
          id: 7,
          label: t("translation.market.main.pairTableLabel.6"),
          sortable: false,
          align: "right",
          hideable: "md",
        },
  ].filter((value) => value)
);

const handleSortClicked = (id) => {
  emit("sortClick", id);
};
</script>
<style scoped>
.chart {
  width: 124px;
  height: 40px;
  filter: hue-rotate(300deg) saturate(210%) brightness(0.7) contrast(170%);
}

.upChart {
  filter: hue-rotate(85deg) saturate(80%) brightness(0.85);
}
</style>
