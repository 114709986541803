import AnnouncementView from "@/views/Announcement/AnnouncementView.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import CalendarBlankIcon from "@/assets/icons/announcement/CalendarBlankIcon.vue";
import ListIcon from "@/assets/icons/announcement/ListIcon.vue";
import ExziLogoIcon from "@/assets/icons/announcement/ExziLogoIcon.vue";
import PackageIcon from "@/assets/icons/announcement/PackageIcon.vue";
import CirclesFourIcon from "@/assets/icons/announcement/CirclesFourIcon.vue";

export const announcementMenuItems = [
  {
    id: 1,
    name: "announcement",
    path: "latest-announcements/:url?",
    label: "translation.announcement.sidebarMenu.0",
    icon: AnnouncementIcon,
    content: AnnouncementView,
  },
  {
    id: 2,
    name: "latest-events",
    path: "latest-events/:url?",
    label: "translation.announcement.sidebarMenu.1",
    icon: CalendarBlankIcon,
    content: AnnouncementView,
  },
  {
    id: 3,
    name: "new-listing",
    path: "new-listing/:url?",
    label: "translation.announcement.sidebarMenu.2",
    icon: ListIcon,
    content: AnnouncementView,
  },
  {
    id: 4,
    name: "latest-exzi-news",
    path: "latest-exzi-news/:url?",
    label: "translation.announcement.sidebarMenu.3",
    icon: ExziLogoIcon,
    content: AnnouncementView,
  },
  {
    id: 5,
    name: "product-updates",
    path: "product-updates/:url?",
    label: "translation.announcement.sidebarMenu.4",
    icon: PackageIcon,
    content: AnnouncementView,
  },
  {
    id: 6,
    name: "others",
    path: "others/:url?",
    label: "translation.announcement.sidebarMenu.5",
    icon: CirclesFourIcon,
    content: AnnouncementView,
  },
];
