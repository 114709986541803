<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.3 11.7464L25.8 2.71262C25.2486 2.40914 24.6294 2.25 24 2.25C23.3706 2.25 22.7514 2.40914 22.2 2.71262L5.7 11.7445C5.10959 12.0675 4.61701 12.5435 4.27391 13.1225C3.93081 13.7015 3.74984 14.3621 3.75 15.0351V32.9639C3.74984 33.6369 3.93081 34.2975 4.27391 34.8765C4.61701 35.4555 5.10959 35.9314 5.7 36.2545L22.2 45.2864C22.7513 45.5902 23.3705 45.7495 24 45.7495C24.6295 45.7495 25.2487 45.5902 25.8 45.2864L42.3 36.2526C42.8901 35.9297 43.3825 35.454 43.7256 34.8754C44.0687 34.2968 44.2498 33.6365 44.25 32.9639V15.0351C44.2498 14.3624 44.0687 13.7022 43.7256 13.1236C43.3825 12.5449 42.8901 12.0693 42.3 11.7464ZM30.75 28.4995V37.447L26.25 39.9107V25.3326L39.75 17.9414V22.2389L31.92 26.5251C31.5658 26.7189 31.2702 27.0045 31.0643 27.3519C30.8585 27.6993 30.7499 28.0957 30.75 28.4995ZM16.08 26.5251L8.25 22.2389V17.9414L21.75 25.3326V39.9107L17.25 37.447V28.4995C17.2501 28.0957 17.1415 27.6993 16.9357 27.3519C16.7298 27.0045 16.4342 26.7189 16.08 26.5251ZM15.5887 11.4614L22.92 15.4739C23.2509 15.6559 23.6224 15.7513 24 15.7513C24.3776 15.7513 24.7491 15.6559 25.08 15.4739L32.4113 11.4614L37.3125 14.1464L24 21.4345L10.6875 14.1464L15.5887 11.4614ZM24 6.85637L27.7256 8.89637L24 10.9364L20.2744 8.89637L24 6.85637ZM8.25 27.3745L12.75 29.8382V34.9889L8.25 32.5195V27.3745ZM35.25 34.9889V29.8326L39.75 27.3745V32.5251L35.25 34.9889Z"
      fill="currentColor"
    />
  </svg>
</template>
