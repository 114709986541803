<template>
  <dialog-view
    :contain-header="false"
    :is-open="isOpen"
    @change-is-open="$emit('close')"
  >
    <template #header>
      <div
        class="w-full h-[260px] bg-gradient-to-t dark:from-orange-800 dark:to-navy-400 from-orange-200 to-white rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="py-4 px-6 flex justify-end">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <token-display color="orange">
            <exclamation-circle-icon class="w-14 h-14" />
          </token-display>
          <p class="text-orange-900 dark:text-white text-xl mt-4">
            {{
              $t("translation.asset.withdrawCryptoPage.riskwarningDialog.title")
            }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <p class="my-8 text-sm font-medium dark:text-white text-navy-300">
          {{
            $t("translation.asset.withdrawCryptoPage.riskwarningDialog.text0")
          }}
        </p>
        <check-box
          class="mb-12 text-sm"
          :label="
            $t(
              'translation.asset.withdrawCryptoPage.riskwarningDialog.checkboxLabel'
            )
          "
          @change="handleChange"
        />
        <fill-button
          width="full"
          size="md"
          color="primary"
          :disabled="!isTrust"
          @click="handleClickNext"
        >
          {{
            $t(
              "translation.asset.withdrawCryptoPage.riskwarningDialog.nextButton"
            )
          }}
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";

defineProps({
  isOpen: Boolean,
});

const emits = defineEmits(["close", "next"]);
const isTrust = ref(false);

const handleCloseDialog = () => {
  emits("close");
};

const handleClickNext = () => {
  emits("next");
};

const handleChange = (checked) => {
  isTrust.value = checked;
};
</script>
