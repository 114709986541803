import { isEmpty } from "@/utils/utils";
import {
  DEFAULT_RESEND_CODE_DURATION,
  DEFAULT_LANGUAGE,
} from "@/constants/constants";

const session_manager = {
  setPrivateKey(token) {
    localStorage.setItem("privateKey", JSON.stringify(token));
  },
  getTokenInfo() {
    const privateKey = localStorage.getItem("privateKey");
    try {
      return JSON.parse(privateKey);
    } catch {
      return {};
    }
  },
  removeToken() {
    localStorage.removeItem("privateKey");
    localStorage.removeItem("user-token");
    localStorage.removeItem("session");
    localStorage.removeItem("user-mail");
    localStorage.removeItem("two_f_need");
    localStorage.removeItem("user-chat");
  },
  set2FactorCodeInfo(codeInfo) {
    localStorage.setItem("two_f_need", JSON.stringify(codeInfo));
  },
  get2FactorCodeInfo() {
    const info = localStorage.getItem("two_f_need");
    return info
      ? JSON.parse(info)
      : {
          required: false,
          requiredType: false,
          codeSentTo: "",
          timeBeforeNewCode: DEFAULT_RESEND_CODE_DURATION,
        };
  },
  remove2FactorRequirement() {
    localStorage.removeItem("two_f_need");
  },
  isLoggedIn() {
    const privateKey = this.getTokenInfo();
    return !!privateKey?.accessToken && !!privateKey?.refreshToken;
  },
  setCurrentPairId(id) {
    localStorage.setItem("pair-id", id);
  },
  getCurrentPairId() {
    localStorage.getItem("pair-id");
  },
  setDemoFlag(str) {
    localStorage.setItem("demo-mode", str);
  },
  getDemoFlag() {
    return localStorage.getItem("demo-mode");
  },
  removeDemoFlag() {
    localStorage.removeItem("demo-mode");
  },
  setTheme(mode) {
    localStorage.setItem("current-theme", mode);
  },
  getTheme() {
    const currentTheme = localStorage.getItem("current-theme");
    return currentTheme === "light" ? "light" : "dark";
  },
  removeTheme() {
    localStorage.removeItem("current-theme");
  },
  setLanguage(language) {
    localStorage.setItem("current-language", language);
  },
  getLanguage() {
    const language = localStorage.getItem("current-language");
    if (isEmpty(language)) {
      localStorage.setItem("current-language", DEFAULT_LANGUAGE);
    }
    return language ? language : DEFAULT_LANGUAGE;
  },
  getLocale() {
    return localStorage.getItem("user-locale");
  },
  removeLanguage() {
    localStorage.removeItem("current-language");
  },
};

export default session_manager;
