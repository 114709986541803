<template>
  <div class="mt-6 sm:mt-10">
    <div
      class="dark:text-navy-50 text-neutral-800 text-lg font-medium sm:mb-6 mb-4"
    >
      {{ $t("translation.market.main.favoriteTab.recommendLabel") }}
    </div>
    <div
      class="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-center justify-center gap-8 max-w-full"
    >
      <coin-overview
        v-for="item in overviewData"
        :key="item.id"
        :img-url="item.imgUrl"
        :token1="item.token1"
        :token2="item.token2"
        :price="item.price"
        :percent="item.percent"
        :id="item.id"
        @click="
          () => {
            if (selectedCardIds.includes(item.id)) {
              selectedCardIds = selectedCardIds.filter((val) => val != item.id);
            } else {
              selectedCardIds.push(item.id);
            }
          }
        "
        :selected="selectedCardIds.includes(item.id)"
      />
    </div>
    <div class="sm:mt-16 mt-8 flex flex-row items-center justify-center">
      <div class="sm:w-[308px] w-full">
        <fill-button size="lg" width="full" @click="handleAddToFavoriteClick">
          {{ $t("translation.market.main.favoriteTab.addToFavorite") }}
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import CoinOverview from "@/views/Market/CoinOverviewCard.vue";

const props = defineProps(["filter"]);
const store = useStore();
const selectedCardIds = ref([]);
const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const favoritePairs = computed(() => store.getters.favoritePairs);
const overviewData = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item) => {
      return {
        id: item.name,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.rate_f,
        percent: item.percent,
        imgUrl: activeCurrencies.value[item.main.id].icon,
      };
    })
    .filter((item) => {
      return (
        !favoritePairs.value.includes(`${item.token1}${item.token2}`) &&
        (item.token1.toLowerCase().includes(props.filter.toLowerCase()) ||
          item.token2.toLowerCase().includes(props.filter.toLowerCase()))
      );
    });
});

const handleAddToFavoriteClick = () => {
  store.dispatch("addFavoritePairs", selectedCardIds.value);
  selectedCardIds.value = [];
};
</script>
