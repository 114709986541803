<template>
  <div class="w-full">
    <slot name="top" />
    <div class="w-full px-4 sm:px-16">
      <div
        :class="['mx-auto w-full', full ? 'container' : 'sm:max-w-[1088px]']"
      >
        <div class="flex sm:flex-row flex-col sm:gap-12 gap-6 w-full">
          <sidebar-menu :menu-data="props.menuData" />
          <slot name="content" :top-data="topData" />
        </div>
      </div>
    </div>
    <slot name="bottom" />
  </div>
</template>
<script setup>
import SidebarMenu from "@/components/SidebarMenu/SidebarMenu.vue";
import { defineProps, ref } from "vue";

const topData = ref("");

const props = defineProps({
  menuData: Array,
  full: Boolean,
});
</script>
