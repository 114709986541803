<template>
  <dialog-view
    :contain-header="false"
    :is-open="isOpen"
    @change-is-open="$emit('close')"
  >
    <template #header>
      <div
        class="w-full bg-gradient-to-t dark:from-green-900 dark:to-navy-400 from-orange-200 to-white rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="py-4 px-6 flex justify-end">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <token-display color="green">
            <check-icon class="w-14 h-14" />
          </token-display>
          <p
            class="text-green-900 dark:text-white mt-4 text-center text-base font-semibold"
          >
            {{ $t("translation.asset.withdrawCryptoPage.successDialog.title") }}
          </p>
          <p class="mt-4 text-green-800 dark:text-navy-100 text-sm font-medium">
            {{
              $t("translation.asset.withdrawCryptoPage.successDialog.subTitle")
            }}
          </p>
          <p class="text-xl font-semibold mb-4">
            {{ order.amount_send_f }} {{ order.iso3 }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <div class="text-sm font-medium mt-6">
          <p class="flex items-center justify-between">
            <span class="dark:text-navy-100 text-navy-200">{{
              $t("translation.asset.withdrawCryptoPage.successDialog.address")
            }}</span>
            <span>{{ order.to_address }}</span>
          </p>
          <p class="flex items-center justify-between mt-3">
            <span class="dark:text-navy-100 text-navy-200">
              {{
                $t(
                  "translation.asset.withdrawCryptoPage.successDialog.withdrawalAmount"
                )
              }}
            </span>
            <span>{{ order.amount_send_f }} {{ order.iso3 }}</span>
          </p>
        </div>
        <div class="flex gap-6 mt-12">
          <outline-button
            width="full"
            size="md"
            color="primary"
            @click="handleGoTrade"
          >
            {{
              $t("translation.asset.withdrawCryptoPage.successDialog.goTrade")
            }}
          </outline-button>
          <fill-button
            width="full"
            size="md"
            color="primary"
            @click="handleClickViewStatus"
          >
            {{
              $t(
                "translation.asset.withdrawCryptoPage.successDialog.viewStatus"
              )
            }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  isOpen: Boolean,
  order: Object,
});

const router = useRouter();
const emits = defineEmits(["close"]);
const handleCloseDialog = () => {
  emits("close");
};

const handleGoTrade = () => {
  router.push({ name: "trading" });
};

const handleClickViewStatus = () => {
  window.open(props.order.explorer_address, "_blank");
};
</script>
