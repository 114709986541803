<template>
  <div class="flex flex-col w-full mb-48">
    <div
      class="font-semibold text-2xl dark:text-white text-navy-800 mb-6 sm:mb-12"
    >
      {{ $t("translation.asset.transactionHistoryPage.title") }}
    </div>
    <div class="w-full custom-scrollbar">
      <underline-tab
        :items="tabHeaders"
        @change="handleChangeTab"
        :default-selected="selectedTab"
      />
    </div>
    <template v-if="selectedTab.id === 1">
      <deposit-history :display-searchbar="true" />
    </template>
    <template v-else-if="selectedTab.id === 2">
      <withdraw-history :display-searchbar="true" />
    </template>
    <template v-else-if="selectedTab.id === 3">
      <convert-history :display-searchbar="true" />
    </template>
    <template v-else-if="selectedTab.id === 4">
      <transfer-history :display-searchbar="true" />
    </template>
    <template v-else-if="selectedTab.id === 5">
      <trade-history :display-searchbar="true" />
    </template>
  </div>
</template>
<script setup>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import DepositHistory from "@/common/TransactionHistory/DepositHistory.vue";
import WithdrawHistory from "@/common/TransactionHistory/WithdrawHistory.vue";
import ConvertHistory from "@/common/TransactionHistory/ConvertHistory.vue";
import TransferHistory from "@/common/TransactionHistory/TransferHistory.vue";
import TradeHistory from "@/common/TransactionHistory/TradeHistory.vue";
import router from "@/router";
import { isEmpty } from "@/utils/utils";

const { t } = useI18n();

const tabHeaders = computed(() => [
  {
    id: 1,
    name: t("translation.asset.transactionHistoryPage.tabHeaders.0"),
    route: "deposit",
  },
  {
    id: 2,
    name: t("translation.asset.transactionHistoryPage.tabHeaders.1"),
    route: "withdraw",
  },
  {
    id: 3,
    name: t("translation.asset.transactionHistoryPage.tabHeaders.2"),
    route: "convert",
  },
  {
    id: 4,
    name: t("translation.asset.transactionHistoryPage.tabHeaders.3"),
    route: "transfer",
  },
  {
    id: 5,
    name: t("translation.asset.transactionHistoryPage.tabHeaders.4"),
    route: "trades",
  },
]);

const route = useRoute();
const selectedTab = ref(tabHeaders.value[0]);

onBeforeMount(() => {
  const url = route.params.url;
  const matchedItem = tabHeaders.value.find((item) => item.route === url);
  if (!isEmpty(matchedItem)) {
    selectedTab.value = matchedItem;
  } else {
    selectedTab.value = tabHeaders.value[0];
  }
});

const handleChangeTab = (item) => {
  router.push({
    name: "asset-transaction-history",
    params: { url: item.route },
  });
};

watch(
  () => route.params.url,
  (newVal) => {
    const matchedItem = tabHeaders.value.find((item) => item.route === newVal);
    if (!isEmpty(matchedItem)) {
      selectedTab.value = matchedItem;
    } else {
      selectedTab.value = tabHeaders.value[0];
    }
  }
);
</script>
