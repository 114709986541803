<template>
  <div>
    <p
      class="text-[32px] mb-6 text-black dark:text-white max-w-[528px]"
      v-if="code.required === 2"
    >
      {{ $t("translation.auth.verificationCode.promptG2A") }}
    </p>
    <p class="text-[32px] mb-6 text-black dark:text-white" v-else>
      {{ $t("translation.auth.verificationCode.promptEmail") }}
      {{ code.codeSentTo === "email" ? shownEmail : code.phone }}
    </p>
    <p class="text-navy-100 mb-12 text-lg font-medium">
      {{ $t("translation.auth.verificationCode.valid") }}
    </p>
    <digit-code :error="error" @on-complete="handleVerifyCode" />
    <div class="h-6" />
    <div class="mb-10">
      <p v-if="!isEmpty(code.timeBeforeNewCode)">
        {{ $t("translation.auth.verificationCode.resendCode") }}
        <span class="text-primary-700">{{ timeLeft }}s</span>
      </p>
    </div>
    <p
      class="text-navy-200 underline cursor-pointer"
      @click="handleResendCodeFn"
    >
      {{ $t("translation.auth.verificationCode.cantReceiveCode") }}
    </p>
  </div>
</template>
<script setup>
import { computed } from "vue";
import DigitCode from "@/components/DigitCode/DigitCode.vue";
import { isEmpty } from "@/utils/utils.js";
import { useVerification } from "./verify";
import { useVerificationTime } from "./verificationTime";
import { VERIFICATION_TIME_LEFT } from "@/constants/constants";

const { error, code, handleVerifyCode, handleResendCodeAsync } =
  useVerification();
const { timeLeft } = useVerificationTime(handleResendCodeAsync);

const handleResendCodeFn = async () => {
  if (await handleResendCodeAsync()) {
    timeLeft.value = VERIFICATION_TIME_LEFT;
  }
};

const shownEmail = computed(() => {
  if (code.value.email) {
    const [name, domain] = code.value.email.split("@");
    return name.split("").slice(0, 3).join("") + "*****@" + domain;
  } else {
    return null;
  }
});
</script>
