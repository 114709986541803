<template>
  <div
    class="flex flex-col lg:flex-row sm:px-16 justify-between gap-[50px] items-start px-4 sm:mt-[121px] mt-8 w-full"
  >
    <div class="flex flex-col relative flex-1 max-w-[576px]">
      <div
        class="text-5xl sm:text-[64px] font-bold text-wrap break-words text-left dark:text-white text-navy-800 -tracking-[1.4px] sm:leading-[76px] leading-[56px]"
      >
        <p>{{ title }}</p>
        <p class="text-primary-400">{{ coloredTitle }}</p>
      </div>
      <div
        class="mt-6 text-lg sm:text-2xl font-medium mb-8 sm:mb-10 dark:text-navy-50 text-navy-300 -tracking-[0.4px] text-left"
      >
        {{ subTitle }}
      </div>
      <div class="w-full sm:w-fit">
        <fill-button
          size="lg"
          width="full"
          @click="handleStartTrading"
          :disabled="disabled"
        >
          {{ buttonTitle }}
        </fill-button>
      </div>
    </div>
    <div>
      <img class="lg:hidden xl:block" :src="image" />
      <img
        class="xl:hidden max-lg:hidden"
        :src="
          currentTheme === 'dark'
            ? '/assets/images/home/phone_small.svg'
            : '/assets/images/home/phone_small_light.svg'
        "
      />
      <!-- Gradient at the bottom [desktop] -->
      <!-- <img
        class="absolute bottom-0"
        :src="
          currentTheme === 'dark'
            ? '/assets/images/home/carousel/gradient_dark.svg'
            : '/assets/images/home/carousel/gradient_light.svg'
        "
      /> -->
      <!-- Gradient at the bottom [desktop] -->
      <!-- <img
        class="absolute bottom-0"
        :src="
          currentTheme === 'dark'
            ? '/assets/images/home/carousel/gradient_mobile_dark.svg'
            : '/assets/images/home/carousel/gradient_mobile_light.svg'
        "
      /> -->
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
defineProps([
  "title",
  "coloredTitle",
  "subTitle",
  "buttonTitle",
  "image",
  "disabled",
]);
const handleStartTrading = () => {
  router.push({ name: "signup" });
};
</script>
