<template>
  <div
    class="h-[82px] flex flex-col p-2 gap-2 hover:dark:bg-navy-500 hover:bg-navy-10 rounded-xl"
  >
    <div class="flex flex-row gap-2 items-center justify-between">
      <div class="size-2 shrink-0 rounded-full bg-red-500"></div>
      <div class="'font-semibold text-sm dark:text-white text-navy-800">
        {{ props.title }}
      </div>
    </div>
    <div class="flex flex-row pl-4 justify-between">
      <div class="text-navy-100 text-xs font-medium">{{ props.date }}</div>
      <div class="text-primary-700 text-xs font-semibold">Show More</div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["title", "date"]);
</script>
