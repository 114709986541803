<template>
  <div
    class="p-4 flex flex-col w-full items-start rounded-2xl dark:bg-navy-700 bg-white dark:border-none border border-navy-50"
  >
    <template v-for="(item, index) in mockData" :key="item.id">
      <div
        class="my-4 w-full border-t dark:border-t-navy-500 border-t-navy-10"
        v-if="index !== 0"
      />
      <div class="w-full cursor-pointer">
        <div class="dark:text-white text-sm font-semibold text-navy-800">
          {{ item.title }}
        </div>
        <div class="text-navy-200 font-medium text-xs mt-2">
          {{ item.date }}
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
const mockData = [
  {
    id: 1,
    title:
      "EXZi to list perpetual for ZENT crypto, along with its margin trading and Simple Earn",
    date: "February 12, 14:19",
  },
  {
    id: 2,
    title: "EXZi to support new USDC spot trading pairs",
    date: "February 12, 14:19",
  },
  {
    id: 3,
    title:
      "EXZi to list perpetual for ZENT crypto, along with its margin trading and Simple Earn",
    date: "February 12, 14:19",
  },
];
</script>
