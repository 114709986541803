<template>
  <div
    class="dark:bg-navy-600 bg-navy-10 rounded-2xl flex justify-between relative"
  >
    <!-- The image (acting as a border) displayed in the parent container -->
    <!-- Camera video feed will be displayed normally without any masking -->
    <div class="camera-feed-container rounded-2xl">
      <camera
        @loading="loading"
        @started="started"
        @stopped="stopped"
        @paused="paused"
        @resumed="resumed"
        @camera-change="cameraChange"
        @snapshot="snapshot"
        @error="error"
      >
        <div class="h-full w-full p-6 flex justify-between">
          <div class="flex self-end">
            <img
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/verification/start-verification/camera-dark.svg'
                  : '/assets/images/verification/start-verification/camera.svg'
              "
            />
          </div>

          <div class="flex self-end">
            <img
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/verification/start-verification/fullscreen-dark.svg'
                  : '/assets/images/verification/start-verification/fullscreen.svg'
              "
            />
          </div>
        </div>
      </camera>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import Camera from "simple-vue-camera";

const emit = defineEmits(["change"]);
const isCameraStarted = ref(false); // Flag to manage camera state

const loading = () => {
  // console.log("Camera is loading and will start any second");
};

const started = () => {
  // console.log("Camera has started");
  isCameraStarted.value = true; // Camera is running
};

const stopped = () => {
  // console.log("Camera has stopped");
  isCameraStarted.value = false;
};

const paused = () => console.log("Video feed has paused");
const resumed = () => console.log("Video feed has resumed");
const cameraChange = (deviceID) => {
  // console.log(`Camera has been changed to ${deviceID}`);
  deviceID;
};

const snapshot = (blob) => {
  // console.log("A snapshot has been taken", blob);
  emit("change", blob);
};
const error = () => {
  // console.log("error");
};
</script>

<style scoped>
/* Ensure the camera feed and image are positioned properly */
.camera-feed-container {
  position: relative;
  z-index: 1;
}
</style>
