<template>
  <dialog-view
    :title="$t('translation.asset.withdrawCryptoPage.verificationDialog.title')"
    :is-open="isOpen"
    @change-is-open="handleClose"
  >
    <template #content>
      <div>
        <div
          class="flex dark:text-navy-50 text-primary-800 text-sm font-medium items-center gap-2 p-2 dark:bg-navy-500 bg-primary-200 rounded-xl my-6"
        >
          <exclamation-circle-icon class="w-5 h-5" />
          <p>
            {{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.subTitle"
              )
            }}
          </p>
        </div>
        <div class="text-sm flex flex-col gap-3">
          <p class="flex justify-between items-center">
            <span class="dark:text-navy-100 text-navy-200">{{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.network"
              )
            }}</span>
            <text-badge
              :label="network"
              size="md"
              class="dark:bg-navy-400 bg-primary-300 dark:text-white text-primary-800 text-[10px]"
            />
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">{{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.payFrom"
              )
            }}</span>
            <span>{{ payFrom }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">
              {{
                $t(
                  "translation.asset.withdrawCryptoPage.verificationDialog.withdrawalAmount"
                )
              }}
            </span>
            <span>{{ withdrawAmount }} {{ token }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">{{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.networkFee"
              )
            }}</span>
            <span>{{ networkFee }} {{ token }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">{{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.wallet"
              )
            }}</span>
            <span>{{
              $t(
                "translation.asset.withdrawCryptoPage.verificationDialog.fundingWallet"
              )
            }}</span>
          </p>
        </div>
        <fill-button
          class="mt-12"
          size="md"
          width="full"
          color="primary"
          @click="handleClickConfirm"
        >
          {{
            $t(
              "translation.asset.withdrawCryptoPage.verificationDialog.confirm"
            )
          }}
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import DialogView from "@/components/Dialog/DialogView.vue";
import TextBadge from "@/components/Badge/TextBadge.vue";

defineProps({
  isOpen: Boolean,
  token: String,
  network: String,
  payFrom: String,
  withdrawAmount: String,
  networkFee: Number,
});

const emits = defineEmits(["close", "confirm"]);

const handleClickConfirm = () => {
  emits("confirm");
};
const handleClose = () => {
  emits("close");
};
</script>
