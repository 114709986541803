<template>
  <div class="w-full sm:mb-[200px] mb-[100px]">
    <breadcrumb-menu :items="breadcrumbItems" />
    <p class="flex gap-6 mt-6 mb-12 w-fit">
      <arrow-left-icon class="w-8 h-8 cursor-pointer" @click="goToBack" />
      <span class="text-2xl font-semibold">{{
        $t("translation.profile.security.loginPasswordPage.title")
      }}</span>
    </p>

    <div class="max-w-[480px]">
      <div class="flex flex-col gap-6 mb-10">
        <password-input
          :label="
            $t(
              'translation.profile.security.loginPasswordPage.currentPasswordLabel'
            )
          "
          :placeholder="
            $t(
              'translation.profile.security.loginPasswordPage.currentPasswordPlaceholder'
            )
          "
          v-model="currentPassword"
          :error="currentPasswordError"
        />
        <password-input
          :label="
            $t(
              'translation.profile.security.loginPasswordPage.newPasswordLabel'
            )
          "
          :placeholder="
            $t(
              'translation.profile.security.loginPasswordPage.newPasswordPlaceholder'
            )
          "
          v-model="newPassword"
          :error="newPasswordError"
        />
        <password-input
          :label="
            $t(
              'translation.profile.security.loginPasswordPage.confirmPasswordLabel'
            )
          "
          :placeholder="
            $t(
              'translation.profile.security.loginPasswordPage.confirmPasswordPlaceholder'
            )
          "
          v-model="confirmPassword"
          :error="confirmPasswordError"
        />
      </div>
      <div>
        <!-- Email -->
        <text-edit
          type="number"
          rounded="xl"
          :label="
            $t('translation.profile.security.loginPasswordPage.codeLabel')
          "
          :placeholder="
            $t('translation.profile.security.loginPasswordPage.codePlaceholder')
          "
          v-model="emailPin"
          :disabled="!canSendCodeEmail"
        >
          <template #suffix>
            <div class="shrink-0">
              <link-button
                class="font-semibold"
                :label="
                  $t(
                    'translation.profile.security.loginPasswordPage.sendCodeButton'
                  )
                "
                color="primary"
                :disabled="!canSendCodeEmail"
                @click="handleSendCode(0)"
              />
            </div>
          </template>
        </text-edit>

        <!-- Phone -->
        <text-edit
          class="mt-6 mb-6"
          type="number"
          rounded="xl"
          :label="
            $t('translation.profile.security.loginPasswordPage.phoneLabel')
          "
          :placeholder="
            $t(
              'translation.profile.security.loginPasswordPage.phonePlaceholder'
            )
          "
          v-model="phonePin"
          :disabled="!canSendCodePhone"
        >
          <template #suffix>
            <div class="shrink-0">
              <link-button
                class="font-semibold"
                :label="
                  $t(
                    'translation.profile.security.loginPasswordPage.phoneSendCodeButton'
                  )
                "
                color="primary"
                :disabled="!canSendCodePhone"
                @click="handleSendCode(1)"
              />
            </div>
          </template>
        </text-edit>

        <check-box
          class="text-xs"
          :label="
            $t('translation.profile.security.loginPasswordPage.checkBoxLabel')
          "
        />

        <fill-button
          class="mt-12"
          size="lg"
          width="full"
          color="primary"
          :disabled="!codeSent"
          @click="handleChangeClick"
        >
          {{
            $t("translation.profile.security.loginPasswordPage.changeButton")
          }}
        </fill-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { postHttpRequest } from "@/api/api";
import { validatePassword } from "@/views/Auth/validation";
import PasswordInput from "@/components/TextEdit/PasswordInput.vue";
import BreadcrumbMenu from "@/components/Breadcrumb/BreadcrumbMenu.vue";
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { isEmpty } from "@/utils/utils";

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const codeSent = ref(false);

const currentPassword = ref("");
const currentPasswordError = computed(() =>
  validatePassword(currentPassword.value)
);
const profile = computed(() => store.getters.profile);
const user_email = computed(() => profile.value?.profile?.email);
const user_phone = computed(() => profile.value?.profile?.phone);

const newPassword = ref("");
const newPasswordError = computed(() => validatePassword(newPassword.value));

const confirmPassword = ref("");
const confirmPasswordError = computed(() => {
  const isValid = validatePassword(confirmPassword.value);
  return isValid.every((item) => item === true)
    ? confirmPassword.value === newPassword.value
      ? isValid
      : t("translation.profile.security.loginPasswordPage.confirmPasswordError")
    : isValid;
});

const canSendCode = computed(() => {
  const testFunc = (value) => value === true;
  if (typeof confirmPasswordError.value === "string") {
    return false;
  }
  return (
    currentPasswordError.value.every(testFunc) &&
    newPasswordError.value.every(testFunc) &&
    confirmPasswordError.value.every(testFunc)
  );
});

const canSendCodeEmail = computed(() => {
  return canSendCode.value && !isEmpty(user_email.value);
});

const canSendCodePhone = computed(() => {
  return canSendCode.value && !isEmpty(user_phone.value);
});

const handleSendCode = async (type) => {
  let typeObj = {};
  if (type === 0) {
    typeObj = {
      send_to_email: 1,
      send_to_phone: 0,
    };
  } else {
    typeObj = {
      send_to_email: 0,
      send_to_phone: 1,
    };
  }
  const res = await postHttpRequest(store)("/api/account/change-password", {
    ...typeObj,
    old_password: currentPassword.value,
    password: newPassword.value,
    retry_password: confirmPassword.value,
  });
  if (res.status) {
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.loginPasswordPage.codeSentToast"
      ),
    });
    codeSent.value = true;
  }
};

const handleChangeClick = async () => {
  const res = await postHttpRequest(store)("/api/account/change-password", {
    send_to_email: 0,
    send_to_phone: 0,
    email_code: emailPin.value,
    old_password: currentPassword.value,
    password: newPassword.value,
    retry_password: confirmPassword.value,
  });
  if (res.status) {
    store.dispatch("setToast", {
      type: "success",
      message: t(
        "translation.profile.security.loginPasswordPage.passwordChangeSuccessToast"
      ),
    });
  } else {
    codeSent.value = false;
    store.dispatch("setToast", {
      type: "error",
      message: t(
        "translation.profile.security.loginPasswordPage.passwordChangeFailToast"
      ),
    });
  }
};

const emailPin = ref();
const phonePin = ref();

const breadcrumbItems = computed(() => [
  {
    id: 1,
    label: t("translation.profile.security.loginPasswordPage.breadcrumb.0"),
    pathName: "security",
  },
  {
    id: 1,
    label: t("translation.profile.security.loginPasswordPage.breadcrumb.1"),
    pathName: "change-login-password",
    active: true,
  },
]);

const goToBack = () => {
  router.push({ name: "security" });
};
</script>
