<template>
  <filter-dialog
    v-model="showFilterDialog"
    @reset="handleResetClick"
    :coin="coinSelect"
    :status="statusSelect"
    :type="typeSelect"
  />
  <div
    class="flex flex-row items-center sm:mt-6 mt-4 gap-4 w-full"
    v-if="props.displaySearchbar"
  >
    <div class="max-sm:hidden">
      <combo-box
        :placeholder="
          $t('translation.common.tradeHistoryTable.coinComboPlaceholder')
        "
        :items="coinComboItem"
        v-model="coinSelect"
      />
    </div>
    <div class="max-sm:hidden">
      <combo-box
        :placeholder="
          $t('translation.common.tradeHistoryTable.typeComboPlaceholder')
        "
        :items="typeComboItem"
        v-model="typeSelect"
      />
    </div>
    <div class="max-sm:hidden">
      <combo-box
        :placeholder="
          $t('translation.common.tradeHistoryTable.statusComboPlaceholder')
        "
        :items="statusComboItem"
        v-model="statusSelect"
      />
    </div>
    <div class="sm:hidden">
      <outline-button color="primary" size="md" @click="handleFilterClick">
        <funnel-icon class="size-5" />
        <div>
          {{ $t("translation.common.tradeHistoryTable.filterButtonLabel") }}
        </div>
      </outline-button>
    </div>
    <div class="sm:w-[250px] w-full">
      <text-edit
        type="text"
        size="sm"
        rounded="full"
        :placeholder="
          $t('translation.common.tradeHistoryTable.searchPlaceholder')
        "
        :filled="false"
        v-model="searchText"
      >
        <template #prefix>
          <div class="mr-2">
            <magnifying-glass-icon
              :class="[
                searchText.length > 0
                  ? 'dark:text-white text-navy-800'
                  : 'text-navy-200',
                'w-6 h-6',
              ]"
            />
          </div>
        </template>
        <template #suffix v-if="searchText.length > 0">
          <div
            class="ml-2"
            @click="
              () => {
                searchText = '';
              }
            "
          >
            <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
          </div>
        </template>
      </text-edit>
    </div>
    <div class="max-sm:hidden">
      <link-button
        :label="$t('translation.common.tradeHistoryTable.resetPlaceholder')"
        @click="handleResetClick"
        :disabled="disableResetButton"
      />
    </div>
  </div>
  <div class="w-full custom-scrollbar">
    <table class="sm:w-full mt-6 mb-4 table-auto w-max text-sm">
      <thead>
        <tr class="h-10">
          <th
            v-for="item in tableHeaderItems"
            :key="item.id"
            :class="[
              'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
              `text-${item.align}`,
              item.hide === false ? '' : `max-${item.hide}:hidden`,
            ]"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in displayData"
          :key="item.id"
          class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer"
        >
          <td class="px-3 rounded-l-xl">
            <div class="flex items-center">
              <img
                :src="getCurrencyFromIso3(item.currency_main_iso3)?.icon"
                class="size-6 mr-2 rounded-full"
              />
              <div class="flex flex-col">
                <div
                  class="text-sm font-semibold dark:text-white text-navy-800"
                >
                  {{ item.currency_main_iso3 }}
                </div>
                <div class="text-xs font-semibold text-navy-100">
                  {{ getCurrencyFromIso3(item.currency_main_iso3)?.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-3">
            <div class="flex items-center">
              <img
                :src="getCurrencyFromIso3(item.currency_second_iso3)?.icon"
                class="size-6 rounded-full mr-2"
              />
              <div class="flex flex-col">
                <div
                  class="text-sm font-semibold dark:text-white text-navy-800"
                >
                  {{ item.currency_second_iso3 }}
                </div>
                <div class="text-xs font-semibold text-navy-100">
                  {{ getCurrencyFromIso3(item.currency_second_iso3)?.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-3">
            {{ moment(item.time_done * 1000).format("MM.DD.YYYY, hh:mm:ss") }}
          </td>
          <td class="px-3">
            {{ parseFloat(item.volume_f).toFixed(4) }}
          </td>
          <td class="px-3">
            {{
              parseFloat(
                item.status === 2 ? item.rate_avg_done_f : item.rate_f
              ).toFixed(4)
            }}
          </td>
          <td class="px-3">
            {{ item.type === 0 ? "Buy" : "Sell" }}
          </td>
          <td class="px-3">
            {{ item.type_trade === 0 ? "Limit" : "Market" }}
          </td>
          <td class="px-3">{{ parseFloat(item.price_f).toFixed(4) }}</td>
          <td class="px-3">{{ item.filled_percent }}/100</td>
          <td
            :class="[
              'px-3 rounded-r-xl ',
              item.status === 2 ? 'text-trade-buy' : 'text-red-500',
            ]"
          >
            {{ item.status_label }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="displayData?.length === 0" class="w-full">
      <div class="h-40 flex flex-col items-center justify-center w-full gap-3">
        <div>
          <double-coin-icon class="w-8 h-8 dark:text-navy-400 text-navy-300" />
        </div>
        <div class="text-navy-100 font-semibold text-sm">
          {{ $t("translation.common.tradeHistoryTable.noData") }}
        </div>
      </div>
    </div>
  </div>
  <pagination-component
    class="mt-12"
    v-show="displayData?.length > 0"
    :total="totalPage"
    :initial-page="1"
    :step-disabled="true"
    step="20"
    @change="handleChangePage"
  />
</template>
<script setup>
import { defineProps, computed, ref, provide, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { isEmpty } from "@/utils/utils";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import FilterDialog from "@/common/TransactionHistory/FilterDialog.vue";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";
import {
  XCircleIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
} from "@heroicons/vue/24/outline";

const { t } = useI18n();
const props = defineProps(["displaySearchbar"]);
const store = useStore();
const searchText = ref("");
const transactions = computed(() => store.getters.orderHistory?.list);
const allPairsList = computed(() => store.getters.activeCurrencies);
const coinSelect = ref(null);
const typeSelect = ref(null);
const statusSelect = ref(null);

provide("coin", coinSelect);
provide("type", typeSelect);
provide("status", statusSelect);

const displayData = computed(() => {
  if (isEmpty(transactions.value)) {
    return [];
  }
  return transactions.value.filter((item) => {
    let statusResult = true;
    if (statusSelect.value?.id === 1) {
      statusResult = item.status === 2;
    } else if (statusSelect.value?.id === 2) {
      statusResult = item.status === 3;
    }
    return (
      (item.currency_main_iso3
        .toLowerCase()
        .includes(searchText.value.toLowerCase()) ||
        item.currency_second_iso3
          .toLowerCase()
          .includes(searchText.value.toLowerCase())) &&
      statusResult
    );
  });
});

const disableResetButton = computed(() => {
  return (
    isEmpty(coinSelect.value) &&
    isEmpty(typeSelect.value) &&
    isEmpty(statusSelect.value)
  );
});

const coinComboItem = computed(() => {
  return Object.values(allPairsList.value).map((item) => ({
    id: item.id,
    label: item.iso3,
  }));
});

const totalPage = computed(
  () => store.getters.orderHistory?.pagination?.totalCountPage
);
const handleChangePage = () => {
  store.dispatch("fetchOrderHistory");
};

onBeforeMount(() => {
  store.dispatch("fetchOrderHistory");
});

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const getCurrencyFromIso3 = (iso3) => {
  if (isEmpty(activeCurrencies.value)) {
    return;
  }
  return Object.values(activeCurrencies.value).find(
    (item) => item.iso3 === iso3
  );
};

const showFilterDialog = ref(false);
const handleFilterClick = () => {
  showFilterDialog.value = true;
};

const handleResetClick = () => {
  coinSelect.value = null;
  typeSelect.value = null;
  statusSelect.value = null;
  searchText.value = "";
};

const typeComboItem = [
  { id: "DEPOSIT", label: "Deposit" },
  { id: "TRADE", label: "Trade" },
  { id: "WITHDRAW", label: "Withdraw" },
  { id: "INTERNAL_TRANSFER", label: "Internal Transfer" },
];

const statusComboItem = computed(() => [
  { id: 1, label: t("translation.common.tradeHistoryTable.statusCompleted") },
  { id: 2, label: t("translation.common.tradeHistoryTable.statusCanceled") },
]);

const tableHeaderItems = computed(() => [
  {
    id: 1,
    label: t("translation.common.tradeHistoryTable.coinComboPlaceholder"),
    align: "left",
    hide: false,
  },
  {
    id: 2,
    label: t("translation.common.tradeHistoryTable.tableHeaders.1"),
    align: "left",
    hide: false,
  },
  {
    id: 3,
    label: t("translation.common.tradeHistoryTable.tableHeaders.2"),
    align: "left",
    hide: false,
  },
  {
    id: 4,
    label: t("translation.common.tradeHistoryTable.tableHeaders.3"),
    align: "left",
    hide: false,
  },
  {
    id: 5,
    label: t("translation.common.tradeHistoryTable.tableHeaders.4"),
    align: "left",
    hide: false,
  },
  {
    id: 6,
    label: t("translation.common.tradeHistoryTable.typeComboPlaceholder"),
    align: "left",
    hide: false,
  },
  {
    id: 7,
    label: t("translation.common.tradeHistoryTable.tableHeaders.6"),
    align: "left",
    hide: false,
  },
  {
    id: 8,
    label: t("translation.common.tradeHistoryTable.tableHeaders.7"),
    align: "left",
    hide: false,
  },
  {
    id: 9,
    label: t("translation.common.tradeHistoryTable.tableHeaders.8"),
    align: "left",
    hide: false,
  },
  {
    id: 8,
    label: t("translation.common.tradeHistoryTable.statusComboPlaceholder"),
    align: "left",
    hide: false,
  },
]);
</script>
