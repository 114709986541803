<template>
  <div
    v-if="props.type === 'default' || isEmpty(props.type)"
    :class="[
      props.outlined === true
        ? `border border-primary-700 text-primary-700 hover:border-primary-800 hover:text-primary-800 cursor-pointer`
        : disabled
        ? `bg-navy-10 text-navy-100 dark:bg-navy-400 dark:text-navy-200 cursor-not-allowed`
        : 'bg-primary-700 text-white cursor-pointer hover:bg-primary-800',
      'w-full rounded-full py-4 text-center font-semibold text-base',
    ]"
    @click="handleClick"
  >
    <div v-if="isLoading" class="w-6 h-6 m-auto">
      <img src="@/assets/icons/spinner.svg" />
    </div>
    <div v-else>
      {{ props.label }}
    </div>
  </div>
  <div
    @click="handleClick"
    v-if="props.type === 'iconLabel'"
    :class="[
      'flex flex-row items-center rounded-full justify-center',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      props.class,
    ]"
  >
    <span class="w-5 h-5 mr-2"><slot name="icon" /></span>
    <span class="font-medium">{{ props.label }}</span>
  </div>
  <div
    @click="handleClick"
    v-if="props.type === 'roundedTextButton'"
    :class="[
      'flex flex-row items-center rounded-full text-sm font-semibold',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      props.class,
    ]"
  >
    {{ props.label }}
  </div>
  <div
    :class="[
      'flex flex-col gap-2 items-center',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    ]"
    @click="handleClick"
    v-if="props.type === 'iconTextButton'"
  >
    <div
      class="w-12 h-12 rounded-full flex flex-row justify-center items-center dark:bg-navy-500 hover:bg-primary-700 dark:text-white bg-navy-10 text-navy-800 hover:text-white"
    >
      <slot />
    </div>
    <div class="dark:text-white text-navy-800 text-sm font-semibold">
      {{ props.label }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";

const router = useRouter();

const props = defineProps([
  "label",
  "disabled",
  "isLoading",
  "type",
  "class",
  "outlined",
  "pathName",
]);

const emit = defineEmits(["click"]);

const handleClick = () => {
  if (props.disabled === true) {
    return;
  }
  emit("click");
  if (!isEmpty(props.pathName)) {
    router.push({ name: props.pathName });
  }
};
</script>
