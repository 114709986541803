<template>
  <div
    :class="[
      `flex justify-between w-full rounded-xl py-3 px-4 cursor-pointer select-none`,
      isSelected
        ? 'border-2 border-primary-700  bg-primary-700 bg-opacity-5'
        : 'border-2 border-navy-50 dark:bg-navy-400 dark:border-navy-400 bg-white',
    ]"
    @click="handleClickItem"
  >
    <slot name="prefix" />
    <slot name="suffix" />
    <div class="flex items-center space-x-3">
      <div
        class="size-5 border-2 border-gray-400 rounded-full items-center justify-center"
        :class="[isSelected ? 'hidden' : 'flex']"
      />
      <div
        class="size-5 rounded-full border-2 border-blue-500 items-center justify-center"
        :class="[isSelected ? 'flex' : 'hidden']"
      >
        <div class="size-3 bg-blue-500 rounded-full" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineModel } from "vue";

const model = defineModel();
const props = defineProps(["index", "isSelected"]);

const handleClickItem = () => {
  model.value = props.index;
};
</script>
