const state = {
  pendingRequest: 0,
};

const mutations = {
  INCREASE_PENDING_REQUEST(state) {
    state.pendingRequest++;
  },
  DECREASE_PENDING_REQUEST(state) {
    state.pendingRequest--;
  },
};

const actions = {
  increasePendingRequest({ commit }) {
    commit("INCREASE_PENDING_REQUEST");
  },
  decreasePendingRequest({ commit }) {
    commit("DECREASE_PENDING_REQUEST");
  },
};

const getters = {
  isLoading: (state) => state.pendingRequest !== 0,
  pendingRequest: (state) => state.pendingRequest,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
