<template>
  <dialog-view
    v-if="isInTransfer"
    title="Transfer"
    :is-open="isInTransfer"
    @change-is-open="handleClose"
    bg-color="gray"
  >
    <template #content>
      <div class="mt-6">
        <div
          class="rounded-2xl dark:bg-navy-600 bg-primary-100 p-6 flex items-center justify-between gap-10 mb-8"
        >
          <div class="w-full">
            <dropdown-select width="full" @select="handleSelectFrom">
              <template #header="{ handleContentClick, isShowingMenu }">
                <div
                  class="flex items-center justify-between cursor-pointer"
                  @click="handleContentClick"
                >
                  <p
                    class="text-sm font-medium flex items-center sm:w-40 w-full"
                  >
                    <span class="dark:text-navy-100 text-primary-800 w-20">
                      From
                    </span>
                    <span class="text-base font-semibold">{{ from.name }}</span>
                  </p>
                  <div class="sm:block hidden">
                    <chevron-up-icon v-if="isShowingMenu" class="w-5 h-5" />
                    <chevron-down-icon v-else class="w-5 h-5" />
                  </div>
                </div>
              </template>
              <template #menu="{ handleMenuItemClick }">
                <div
                  class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-4 dark:shadow-none shadow-md"
                >
                  <div
                    v-for="fromItem in walletTypes"
                    :key="fromItem.id"
                    class="px-3 py-2 font-semibold hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
                    @click="handleMenuItemClick(fromItem)"
                  >
                    {{ fromItem.name }}
                  </div>
                </div>
              </template>
            </dropdown-select>

            <div
              class="my-6 border-b dark:border-navy-500 border-primary-400"
            />

            <dropdown-select width="full" @select="handleSelectTo">
              <template #header="{ handleContentClick, isShowingMenu }">
                <div
                  class="flex items-center justify-between cursor-pointer"
                  @click="handleContentClick"
                >
                  <p
                    class="text-sm font-medium flex items-center sm:w-40 w-full"
                  >
                    <span class="dark:text-navy-100 text-primary-800 w-20">
                      To
                    </span>
                    <span class="text-base font-semibold">{{ to?.name }}</span>
                  </p>
                  <div class="sm:block hidden">
                    <chevron-up-icon v-if="isShowingMenu" class="w-5 h-5" />
                    <chevron-down-icon v-else class="w-5 h-5" />
                  </div>
                </div>
              </template>
              <template #menu="{ handleMenuItemClick }">
                <div
                  class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-4 dark:shadow-none shadow-md"
                >
                  <div
                    v-for="fromItem in toList"
                    :key="fromItem.id"
                    class="px-3 py-2 font-semibold hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
                    @click="handleMenuItemClick(fromItem)"
                  >
                    {{ fromItem.name }}
                  </div>
                </div>
              </template>
            </dropdown-select>
          </div>
          <div
            class="p-5 rounded-full dark:bg-primary-500 bg-primary-700 flex items-center justify-center dark:text-navy-800 text-white cursor-pointer"
            @click="handleConvert"
          >
            <transfer-icon class="w-6 h-6" />
          </div>
        </div>

        <select-token-dropdown
          :filled="currentTheme === 'dark' ? true : false"
          label-text="Asset"
          :default-coin="coin"
          :currencies="activeCurrencies"
          :error="error.coin"
          @select="handleSelectToken"
        />

        <text-edit
          class="mt-6"
          rounded="xl"
          label="Amount"
          :placeholder="`Min amount 0 ${coin?.iso3 ?? ''}`"
          :error="error.amount"
          v-model="amount"
          :filled="currentTheme === 'dark' ? true : false"
        >
          <template #suffix>
            <link-button
              class="font-semibold"
              label="Max"
              @click="handleClickMax"
            />
          </template>
        </text-edit>

        <p class="mt-4">
          <span class="text-navy-200">Available: </span>
          <span>{{ selectedVolume.balance_available_f }} {{ coin?.iso3 }}</span>
        </p>

        <div class="mt-12 flex gap-6">
          <outline-button
            class="basis-1/3"
            width="full"
            color="primary"
            size="lg"
            @click="handleClose"
          >
            Cancel
          </outline-button>
          <fill-button
            width="full"
            color="primary"
            size="lg"
            :disabled="isEmpty(coin) || isEmpty(amount)"
            @click="handleConfirm"
          >
            Confirm
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { walletTypes } from "@/constants/constants.js";
import { isEmpty } from "@/utils/utils.js";
import { postHttpRequest } from "@/api/api.js";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import TransferIcon from "@/assets/icons/common/TransferIcon.vue";
import SelectTokenDropdown from "@/common/SelectCurrencyDropdown/SelectTokenDropdown.vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const store = useStore();
const isInTransfer = computed(() => store.getters.isInTransfer);
const selectedTransferCurrency = computed(
  () => store.getters.selectedTransferCurrency
);
const selectedWalletType = computed(() => store.getters.selectedWalletType);

const selectedVolume = computed(() => {
  if (!isEmpty(coin.value) && !isEmpty(store.getters.balancesVolume)) {
    const balanceInfo = store.getters.balancesVolume.list.find(
      (balance) =>
        balance.deposit_type === from.value.value &&
        balance.currency.id === coin.value.id
    );
    if (!balanceInfo) return {};
    return {
      ...balanceInfo,
      balance_available_f:
        balanceInfo.balance_available /
        Math.pow(10, balanceInfo.currency.decimal),
    };
  } else {
    return {};
  }
});
const activeCurrencies = computed(() =>
  Object.values(store.getters.activeCurrencies)
);
const toList = computed(() =>
  walletTypes.filter((type) => type.id !== from.value.id)
);

const from = ref(
  isEmpty(selectedWalletType.value) ? walletTypes[0] : selectedWalletType.value
);
const to = ref(toList.value[0]);
const coin = ref();
const amount = ref();
const error = ref({
  coin: null,
  amount: null,
});

watch(selectedTransferCurrency, (current) => {
  coin.value = current;
});

watch(selectedWalletType, (current) => {
  if (!isEmpty(current)) {
    from.value = current;
    to.value = toList.value[0];
  }
});

const handleClose = () => {
  store.dispatch("clearTransferData");
};

const handleSelectToken = (token) => {
  coin.value = token;
};

const handleClickMax = () => {
  if (!isEmpty(selectedVolume.value)) {
    amount.value = selectedVolume.value.balance_available_f;
  }
};

const handleSelectFrom = (fromWallet) => {
  from.value = fromWallet;
  to.value = toList.value[0];
};

const handleSelectTo = (toWallet) => {
  to.value = toWallet;
};

const handleConvert = () => {
  const fromValue = from.value;
  from.value = to.value;
  to.value = fromValue;
};

const handleConfirm = async () => {
  if (
    selectedVolume.value &&
    amount.value > selectedVolume.value.balance_available_f
  ) {
    error.value.amount = "Not enough.";
    return;
  } else {
    error.value.amount = null;
  }

  try {
    const res = await postHttpRequest(store)("/api/transactions/transfer", {
      deposit_type_from: from.value.value,
      deposit_type_to: to.value.value,
      currency_id: coin.value.id,
      amount: amount.value,
    });
    if (res.status) {
      store.dispatch("clearTransferData");
    }
  } catch (error) {
    console.error(error);
  }
};
</script>
