<template>
  <div class="w-full flex flex-col container mx-auto sm:px-16 px-2">
    <div class="mt-6 sm:mt-10">
      <breadcrumb-menu :items="breadCrumbMenuData" />
    </div>
    <div
      class="mt-4 sm:mt-6 font-bold text-[32px] leading-10 -tracking-[0.8px] sm:text-[40px] sm:leading-[48px] sm:-tracking-[1.2px] dark:text-white text-navy-800"
    >
      {{ $t("translation.rewardsHub.taskHistory") }}
    </div>
    <div class="mt-4 sm:mt-8">
      <radio-button-list :items="radioListData" @change="handleTypeChange" />
    </div>
    <div class="flex flex-row items-center sm:mt-12 mt-4 gap-4 w-full">
      <div class="max-sm:hidden">
        <combo-box
          :placeholder="
            $t('translation.common.depositHistoryTable.coinComboPlaceholder')
          "
          :items="coinComboItem"
          v-model="coinSelect"
        />
      </div>
      <div
        class="flex flex-row items-center justify-stretch gap-4 max-sm:hidden"
      >
        <div class="sm:w-[200px] w-full">
          <date-input />
        </div>
        <div class="text-sm font-medium dark:text-navy-100">/</div>
        <div class="sm:w-[200px] w-full">
          <date-input />
        </div>
      </div>
      <div class="sm:hidden">
        <outline-button color="primary" size="md" @click="handleFilterClick">
          <funnel-icon class="size-5" />
          <div>
            {{ $t("translation.common.depositHistoryTable.filterButtonLabel") }}
          </div>
        </outline-button>
      </div>
      <div class="sm:w-[250px] w-full sm:hidden">
        <text-edit
          type="text"
          size="sm"
          rounded="full"
          :placeholder="
            $t('translation.common.depositHistoryTable.searchPlaceholder')
          "
          :filled="false"
          v-model="searchText"
        >
          <template #prefix>
            <div class="mr-2">
              <magnifying-glass-icon
                :class="[
                  searchText.length > 0
                    ? 'dark:text-white text-navy-800'
                    : 'text-navy-200',
                  'w-6 h-6',
                ]"
              />
            </div>
          </template>
          <template #suffix v-if="searchText.length > 0">
            <div
              class="ml-2"
              @click="
                () => {
                  searchText = '';
                }
              "
            >
              <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
            </div>
          </template>
        </text-edit>
      </div>
      <div class="max-sm:hidden">
        <link-button
          :label="$t('translation.common.depositHistoryTable.resetPlaceholder')"
          @click="handleResetClick"
        />
      </div>
    </div>
    <div class="w-full mt-6 custom-scrollbar sm:mb-36 mb-[104px]">
      <table class="sm:w-full mt-6 mb-4 table-auto w-max text-sm">
        <thead>
          <tr class="h-10">
            <th
              v-for="item in tableHeaderItems"
              :key="item.id"
              :class="[
                'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
                `text-${item.align}`,
                item.hide === false ? '' : `max-${item.hide}:hidden`,
                item.hide3 === true && currentType === 3 ? 'hidden' : '',
              ]"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tableData"
            :key="item.id"
            class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer"
          >
            <td class="px-3 rounded-l-xl">
              {{ item.task }}
            </td>
            <td class="px-3">
              {{ item.time }}
            </td>
            <td class="px-3" v-if="currentType !== 3">
              {{ item.progress }}
            </td>
            <td class="px-3">
              {{ item.rewards }}
            </td>
            <td
              :class="[
                'px-3',
                item.status === 'Ongoing' ? 'text-trade-buy' : 'text-navy-100',
              ]"
            >
              {{ item.status }}
            </td>
            <td class="px-3 rounded-r-xl" v-if="currentType !== 3">
              <div class="w-fit">
                <link-button
                  :label="$t('translation.rewardsHub.goButton')"
                  @click="handleGoClick"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <share-dialog v-model="showDialog" />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import {
  FunnelIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline";
import BreadcrumbMenu from "@/components/Breadcrumb/BreadcrumbMenu.vue";
import DateInput from "@/components/DateInput/DateInput.vue";
import RadioButtonList from "@/components/Selector/RadioButtonList.vue";
import ShareDialog from "@/views/RewardsHub/Dialog/ShareDialog.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const breadCrumbMenuData = computed(() => [
  {
    id: 1,
    label: t("translation.rewardsHub.title"),
    pathName: "rewards-hub",
  },
  {
    id: 2,
    label: t("translation.rewardsHub.taskHistory"),
    active: true,
  },
]);

const radioListData = computed(() => [
  {
    id: 1,
    label: t("translation.rewardsHub.taskTabHeaders.0"),
  },
  {
    id: 2,
    label: t("translation.rewardsHub.taskTabHeaders.1"),
  },
  {
    id: 3,
    label: t("translation.rewardsHub.taskTabHeaders.2"),
  },
]);

const currentType = ref(1);

const store = useStore();
const allPairsList = computed(() => store.getters.activeCurrencies);
const coinSelect = ref(null);

const coinComboItem = computed(() => {
  return Object.values(allPairsList.value).map((item) => ({
    id: item.id,
    label: item.iso3,
  }));
});

const handleTypeChange = (value) => {
  currentType.value = value;
};

const searchText = ref("");
const showDialog = ref(false);

const handleFilterClick = () => {};

const handleResetClick = () => {
  coinSelect.value = coinComboItem.value[0];
  searchText.value = "";
};

const handleGoClick = () => {
  showDialog.value = true;
};

const tableHeaderItems = computed(() => [
  {
    id: 1,
    label: t("translation.rewardsHub.tableHeaders.0"),
    align: "left",
    hide: false,
    hide3: false,
  },
  {
    id: 2,
    label: t("translation.rewardsHub.tableHeaders.1"),
    align: "left",
    hide: false,
    hide3: false,
  },
  {
    id: 3,
    label: t("translation.rewardsHub.tableHeaders.2"),
    align: "left",
    hide: false,
    hide3: true,
  },
  {
    id: 4,
    label: t("translation.rewardsHub.tableHeaders.3"),
    align: "left",
    hide: false,
    hide3: false,
  },
  {
    id: 5,
    label: t("translation.rewardsHub.tableHeaders.4"),
    align: "left",
    hide: false,
    hide3: false,
  },
  {
    id: 5,
    label: t("translation.rewardsHub.tableHeaders.5"),
    align: "left",
    hide: false,
    hide3: true,
  },
]);

const tableData = ref();

(async () => {
  try {
    const response = await fetch("/mock/rewards-hub/data.json");
    const data = await response.json();
    tableData.value = data.tableData;
  } catch (error) {
    console.error(error);
  }
})();
</script>
