import store from "@/store/index";
import { getHttpRequest } from "@/api/api";

const state = {
  location: {},
  locationNames: [],
  locationFlags: {},
};

const mutations = {
  SET_LOCATION(state, location) {
    state.location = location;
  },
  SET_LOCATION_NAMES(state, locationNames) {
    state.locationNames = locationNames;
  },
  SET_LOCATION_FLAGS(state, locationFlags) {
    state.locationFlags = locationFlags;
  },
};

const actions = {
  setLocation({ commit }, location) {
    commit("SET_LOCATION", location);
  },
  async fetchLocationNames({ commit }) {
    try {
      const result = await getHttpRequest(store)(
        "/api/default/countries-of-residence",
        false
      );
      if (result.status) {
        commit("SET_LOCATION_NAMES", result.data.list);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchLocationFlags({ commit }) {
    try {
      const result = await getHttpRequest(store)("/api/default/flags", false);
      if (result.status) {
        commit("SET_LOCATION_FLAGS", result.data.by_alpha2);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  location: (state) => state.location,
  locationNames: (state) => state.locationNames,
  locationFlags: (state) => state.locationFlags,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
