<template>
  <div
    class="flex-1 w-full dark:border-navy-500 dark:border p-10 flex flex-col rounded-[32px] bg-primary-100 dark:bg-navy-800"
  >
    <div class="text-2xl font-semibold dark:text-white text-navy-800">
      {{ props.title }}
    </div>
    <div class="mt-2 font-normal text-base dark:text-navy-50">
      {{ props.content }}
    </div>
    <div class="md:w-fit mt-8 w-full">
      <fill-button width="full" size="lg" @click="handleClick">
        {{ $t("translation.helpCenter.cards.button") }}
      </fill-button>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps(["title", "content"]);
const emit = defineEmits(["redeemClick"]);

const handleClick = () => {
  emit("redeemClick");
};
</script>
