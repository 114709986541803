<template>
  <div
    :class="[
      'flex justify-between',
      bottomLine
        ? 'border-b border-navy-10 dark:border-navy-500'
        : 'border-none',
    ]"
  >
    <div class="flex space-x-1">
      <chevron-left-icon
        v-if="showArrows"
        :class="[
          'w-5 h-5 stroke-2 cursor-pointer',
          selectedTab.id === 1 ? 'text-navy-100' : 'text-black dark:text-white',
        ]"
        @click="handleClickLeft"
      />
      <div class="flex space-x-6">
        <div
          :class="[
            'cursor-pointer text-center font-medium',
            props.fontSize === 'md' ? 'text-base' : 'text-sm',
            selectedTab.id === item.id
              ? 'text-navy-800 dark:text-white'
              : 'text-navy-100',
          ]"
          v-for="item in items"
          :key="item.id"
          @click="handleChangeTab(item)"
        >
          <div class="flex items-center gap-1">
            <component v-if="item.icon" class="w-4 h-4" :is="item.icon" />
            <p>{{ item.name }}</p>
          </div>
          <div class="h-3" />
          <div class="flex justify-center">
            <div
              :class="[
                selectedTab.id === item.id
                  ? 'bg-navy-500 dark:bg-white'
                  : 'bg-none',
                'h-[2px] w-6 rounded-full',
              ]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <chevron-right-icon
      v-if="showArrows"
      :class="[
        'w-5 h-5 stroke-2 cursor-pointer',
        selectedTab.id === items[items.length - 1].id
          ? 'text-navy-100'
          : 'text-black dark:text-white',
      ]"
      @click="handleClickRight"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

const props = defineProps([
  "items",
  "bottomLine",
  "showArrows",
  "defaultSelected",
  "fontSize",
]);
const selectedTab = ref(props.defaultSelected ?? props.items[0]);
watch(
  () => props.defaultSelected,
  (newValue) => {
    selectedTab.value = newValue ?? props.items[0];
  }
);
const emit = defineEmits(["change"]);
const handleChangeTab = (tab) => {
  selectedTab.value = tab;
  emit("change", tab);
};

const handleClickLeft = () => {
  const index = props.items.findIndex(
    (item) => item.id === selectedTab.value.id
  );
  if (index > 0) {
    emit("change", props.items[index - 1]);
    selectedTab.value = props.items[index - 1];
  }
};
const handleClickRight = () => {
  const index = props.items.findIndex(
    (item) => item.id === selectedTab.value.id
  );
  if (index < props.items.length - 1) {
    emit("change", props.items[index + 1]);
    selectedTab.value = props.items[index + 1];
  }
};
</script>
