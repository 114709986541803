<template>
  <div class="w-full">
    <faq-mobile v-model="showFaqMobile" />
    <div class="flex gap-12">
      <div class="w-full">
        <div class="flex h-fit items-center justify-between mb-12">
          <p class="font-semibold text-2xl">
            {{ $t("translation.asset.withdrawCryptoPage.title") }}
          </p>
          <div class="sm:hidden">
            <link-button
              :label="$t('translation.asset.depositCryptoPage.faq')"
              @click="handleFAQClick"
            />
          </div>
          <!-- <outline-button color="primary" size="sm" @click="goToWithdrawFiat">
            <span>{{ $t("translation.asset.withdrawCryptoPage.button") }}</span>
            <chevron-right-icon class="w-5 h-5" />
          </outline-button> -->
        </div>
        <div>
          <vertical-circle-step
            :step-length="stepLength"
            :current-active-step="currentActiveStep"
          >
            <template #content-1>
              <div class="w-full">
                <p class="font-semibold text-xl mb-7 mt-1">
                  {{ $t("translation.asset.withdrawCryptoPage.selectCoin") }}
                </p>
                <select-token-dropdown
                  :currencies="activeCurrencies"
                  @select="handleSelectCoin"
                />
                <div class="h-6" />
              </div>
            </template>
            <template #content-2>
              <div class="w-full font-semibold">
                <div class="flex items-center justify-between mb-7 mt-1">
                  <p class="text-xl">
                    {{
                      $t("translation.asset.withdrawCryptoPage.walletAddress")
                    }}
                  </p>
                  <link-button
                    :label="
                      $t('translation.asset.withdrawCryptoPage.saveAddress')
                    "
                    color="primary"
                    :disabled="
                      !isEmpty(
                        addresses.find(
                          ({ address }) => address == walletAddress
                        )
                      ) || !isValidWalletAddress(walletAddress)
                    "
                    @click="handleShowAddressSave"
                  />
                </div>

                <wallet-address-auto-complete
                  :addresses="addresses"
                  @select="handleSelectWalletAddress"
                />

                <p class="text-base mb-2 mt-6">
                  {{ $t("translation.asset.withdrawCryptoPage.selectNetwork") }}
                </p>
                <select-network-dropdown
                  :networks="networks"
                  @select="handleSelectNetwork"
                />
                <div class="h-6" />
              </div>
            </template>
            <template #content-3>
              <asset-withdraw-form
                :coin="coin"
                :network="network"
                :available-balance="availableBalance"
                :wallet-address="walletAddress"
                @withdraw="handleClickWithdraw"
              />
            </template>
          </vertical-circle-step>
        </div>
      </div>
      <div class="w-[304px] sm:block hidden shrink-0">
        <faq-small />
      </div>
    </div>

    <div class="mt-24 mb-48">
      <div class="flex items-center gap-6">
        <p class="font-semibold text-2xl">
          {{ $t("translation.asset.withdrawCryptoPage.tableheader") }}
        </p>
        <link-button
          color="primary"
          :label="$t('translation.asset.withdrawCryptoPage.moreButton')"
          :icon="ChevronRightIcon"
          @click="
            router.push({
              name: 'asset-transaction-history',
              params: {
                url: 'withdraw',
              },
            })
          "
        />
      </div>
      <withdraw-history :display-searchbar="false" />
    </div>

    <two-factor-verification-modal
      v-if="verificationConfirm"
      v-model="verificationConfirm"
      @confirm="handleConfirmVerificationConfirm"
      :request="requestResponse"
    />

    <withdraw-success
      v-if="isSuccess"
      :is-open="isSuccess"
      :order="successedOrder"
      @close="handleCloseSuccess"
    />
    <add-address
      v-model="showAddAddress"
      :default-value="{ coin: coin, network: network, address: walletAddress }"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isEmpty, isValidWalletAddress } from "@/utils/utils";
import { postHttpRequest } from "@/api/api.js";
import FaqSmall from "@/common/FAQ/FaqSmall.vue";
import SelectTokenDropdown from "@/common/SelectCurrencyDropdown/SelectTokenDropdown.vue";
import SelectNetworkDropdown from "@/common/SelectNetworkDropdown/SelectNetworkDropdown.vue";
import WalletAddressAutoComplete from "@/common/WalletAddressAutoComplete/WalletAddressAutoComplete.vue";
import WithdrawSuccess from "@/views/Asset/Withdraw/Crypto/WithdrawSuccess.vue";
import TwoFactorVerificationModal from "@/common/TwoFactorVerificationModal/TwoFactorVerificationModal.vue";
import AssetWithdrawForm from "@/views/Asset/Withdraw/Crypto/AssetWithdrawForm.vue";
import VerticalCircleStep from "@/components/VerticalCircleStep/VerticalCircleStep.vue";
import WithdrawHistory from "@/common/TransactionHistory/WithdrawHistory.vue";
import FaqMobile from "@/common/FAQ/FaqMobile.vue";
import AddAddress from "@/common/AddAddress/AddAddress.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();

const coin = ref();
const walletAddress = ref();
const network = ref();
const stepLength = ref(1);
const currentActiveStep = ref(1);
const verificationRequestId = ref();
const verificationConfirm = ref(false);
const isSuccess = ref(false);
const successedOrder = ref();
const showFaqMobile = ref(false);
const showAddAddress = ref(false);
const requestResponse = ref(null);

const balancesVolume = computed(() => store.getters.balancesVolumeModified);
const availableBalance = computed(() => balancesVolume.value[coin.value.id]);

const activeCurrencies = computed(() =>
  Object.values(store.getters.activeCurrencies ?? {})
);

const addresses = computed(() => {
  const addressBook = store.getters.profile.address_book;
  if (coin.value && addressBook) {
    return (addressBook[coin.value.iso3] ?? []).map((address) => ({
      ...address,
      network: networks.value[address.network_type],
    }));
  } else {
    return [];
  }
});

const networks = computed(() => {
  if (!coin.value) return {};
  return Object.values(coin.value.networks);
});

const handleSelectCoin = (selectedCoin) => {
  coin.value = selectedCoin;
  if (!isEmpty(selectedCoin)) {
    currentActiveStep.value = 2;
    stepLength.value = 2;
  }
};

const handleSelectNetwork = async (selectedNetwork) => {
  network.value = selectedNetwork;

  if (!isEmpty(selectedNetwork) && isValidWalletAddress(walletAddress.value)) {
    currentActiveStep.value = 3;
    stepLength.value = 3;
  } else {
    currentActiveStep.value = 2;
    stepLength.value = 2;
  }
};

const handleSelectWalletAddress = (wallet) => {
  walletAddress.value = wallet;

  if (!isEmpty(network.value) && isValidWalletAddress(walletAddress.value)) {
    currentActiveStep.value = 3;
    stepLength.value = 3;
  } else {
    currentActiveStep.value = 2;
    stepLength.value = 2;
  }
};

const handleClickWithdraw = async (requestId) => {
  try {
    verificationRequestId.value = requestId;
    const res = await postHttpRequest(store)("/api/withdraw/confirm", {
      id: requestId,
      send_to_email: 1,
      send_to_phone: 1,
    });
    if (res.status) {
      requestResponse.value = res.data;
      verificationConfirm.value = true;
    }
  } catch (error) {
    console.error(error);
  }
};

const handleConfirmVerificationConfirm = async (data) => {
  try {
    const res = await postHttpRequest(store)("/api/withdraw/confirm", {
      id: verificationRequestId.value,
      ...(!isEmpty(data.emailPin) && { email_code: data.emailPin }),
      ...(!isEmpty(data.phonePin) && { phone_code: data.phonePin }),
      ...(!isEmpty(data.googlePin) && { google_code: data.googlePin }),
      send_to_email: 0,
      send_to_phone: 0,
    });
    verificationConfirm.value = false;
    if (res.status) {
      isSuccess.value = true;
      const currency = activeCurrencies.value.find(
        (currency) => currency.iso3 === res.data.order.iso3
      );
      successedOrder.value = {
        ...res.data.order,
        amount_send_f:
          res.data.order.amount_send / Math.pow(10, currency.decimal),
      };
      store.dispatch("fetchBalancesVolume");
    }
  } catch (error) {
    console.error(error);
  }
};

const handleCloseSuccess = () => {
  isSuccess.value = false;
};

const handleFAQClick = () => {
  showFaqMobile.value = true;
};

const handleShowAddressSave = () => {
  showAddAddress.value = true;
};
</script>
