<template>
  <div class="relative w-full sm:mb-52 mb-24">
    <div class="absolute sm:-top-48 -top-24 opacity-[0.18] -z-50 w-full">
      <img
        src="/assets/images/career/exzi_logo_background.png"
        class="max-w-[800px] mx-auto w-full"
      />
    </div>
    <div class="absolute w-full -z-50 -top-12">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/career/background_ellipse_dark.svg'
            : '/assets/images/career/background_ellipse_light.svg'
        "
        class="mx-auto"
      />
    </div>
    <div
      class="flex flex-col sm:pt-[104px] pt-12 w-full container items-center justify-center mx-auto px-4 sm:px-16"
    >
      <div class="flex flex-col items-center justify-center">
        <div
          class="font-bold sm:text-[56px] dark:text-white text-[40px] text-navy-800 text-center"
        >
          {{ $t("translation.career.title") }}
        </div>
        <div
          class="mt-6 font-medium text-lg dark:text-navy-50 sm:text-2xl text-navy-400 text-center"
        >
          {{ $t("translation.career.subTitle") }}
        </div>
        <div class="sm:mt-10 mt-8">
          <fill-button
            width="fit"
            size="lg"
            @click="handleScrollToOpenPosition"
          >
            {{ $t("translation.career.openPositionButton") }}
          </fill-button>
        </div>
      </div>
      <div class="flex flex-col mt-[120px] w-full max-w-[1088px]">
        <div
          class="font-bold text-[40px] dark:text-white text-navy-800 text-center"
        >
          {{ $t("translation.career.tileHeader") }}
        </div>
        <div
          class="sm:mt-16 mt-12 flex flex-col sm:flex-row items-center justify-between w-full max-sm:gap-10"
        >
          <template v-for="item in valueCardsData" :key="item.id">
            <div class="flex flex-col max-w-[304px]">
              <img
                :src="
                  currentTheme === 'dark' ? item.darkImgUrl : item.lightImgUrl
                "
                class="py-6 px-16"
              />
              <div
                class="dark:text-white font-semibold text-lg text-center mt-4"
              >
                {{ item.title }}
              </div>
              <div
                class="dark:text-navy-100 text-navy-300 font-medium text-base text-center mt-1"
              >
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="flex flex-col w-full sm:mt-52 mt-[168px] relative items-center sm:min-h-[620px]"
      >
        <div class="absolute w-full -z-50 h-full">
          <img
            class="object-none object-top mx-auto h-full"
            :src="
              currentTheme === 'dark'
                ? '/assets/images/career/line_dark.svg'
                : '/assets/images/career/line_light.svg'
            "
          />
        </div>
        <div
          class="sm:mt-[162px] mt-[120px] font-medium text-[40px] leading-[48px] -tracking-[1.2px] max-w-[903px] text-center dark:text-white text-navy-800"
        >
          {{ $t("translation.career.ourSpec.title") }}
        </div>
        <div
          class="sm:mt-[104px] mt-12 flex md:flex-row flex-col sm:py-24 sm:px-28 py-10 px-6 w-full rounded-[32px] sm:gap-16 gap-12 relative"
        >
          <div
            class="w-full h-full dark:bg-navy-700 bg-primary-100 absolute left-0 top-0 -z-40 rounded-[32px]"
          />
          <div
            class="absolute bottom-0 w-full left-0 right-0 rounded-[32px] overflow-hidden -z-30"
          >
            <img
              class="w-full max-sm:hidden"
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/career/card_ellipse_dark.svg'
                  : '/assets/images/career/card_ellipse_light.svg'
              "
            />
            <img
              class="w-full sm:hidden"
              :src="
                currentTheme === 'dark'
                  ? '/assets/images/career/card_ellipse_mobile_dark.svg'
                  : '/assets/images/career/card_ellipse_mobile_light.svg'
              "
            />
          </div>
          <div class="flex flex-col items-start w-full gap-4">
            <div
              class="text-[40px] font-bold leading-[48px] -tracking-[1.2px] dark:text-white text-navy-800"
            >
              {{ $t("translation.career.ourSpec.0.title") }}
            </div>
            <div
              class="text-xl font-semibold leading-[32px] -tracking-[0.4px] dark:text-white text-navy-800"
            >
              {{ $t("translation.career.ourSpec.0.subTitle") }}
            </div>
            <div
              class="text-base font-medium -tracking-[0.2px] text-justify dark:text-navy-100 text-navy-300"
            >
              {{ $t("translation.career.ourSpec.0.content") }}
            </div>
          </div>
          <div class="flex flex-col items-start w-full gap-4">
            <div
              class="text-[40px] font-bold leading-[48px] -tracking-[1.2px] dark:text-white text-navy-800"
            >
              {{ $t("translation.career.ourSpec.1.title") }}
            </div>
            <div
              class="text-xl font-semibold leading-[32px] -tracking-[0.4px] dark:text-white text-navy-800"
            >
              {{ $t("translation.career.ourSpec.1.subTitle") }}
            </div>
            <div
              class="text-base font-medium -tracking-[0.2px] text-justify dark:text-navy-100 text-navy-300"
            >
              {{ $t("translation.career.ourSpec.1.content") }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col sm:mt-52 mt-[168px] w-full max-w-[1088px] items-center"
      >
        <div
          class="font-bold text-[40px] dark:text-white text-navy-800 text-center"
        >
          {{ $t("translation.career.benefitTitle") }}
        </div>
        <div
          class="font-medium text-lg dark:text-navy-100 text-navy-400 text-center max-w-[722px] mt-4"
        >
          {{ $t("translation.career.benefitSubtitle") }}
        </div>
        <div
          class="sm:mt-16 mt-12 flex flex-col sm:flex-row sm:items-start items-center justify-between w-full max-sm:gap-10"
        >
          <template v-for="item in workCardData" :key="item.id">
            <div class="flex flex-col max-w-[304px] w-full">
              <img :src="item.imgUrl" class="w-[88px] h-[88px] mx-auto" />
              <div
                class="dark:text-white font-semibold text-lg text-center mt-4"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="flex flex-col mt-[168px] sm:mt-52 w-full max-w-[1088px] items-center"
      >
        <div
          class="text-[40px] leading-[48px] font-bold text-center -tracking-[1.2px] dark:text-white text-navy-800 max-w-[804px]"
        >
          {{ $t("translation.career.tableTitle") }}
        </div>
        <div
          ref="openPositionElement"
          class="font-bold text-2xl dark:text-white text-navy-800 mb-12 w-full sm:mt-16 mt-12"
        >
          {{ $t("translation.career.tableHeader") }} (12)
        </div>
        <div
          class="flex flex-col sm:flex-row gap-4 items-center justify-start w-full sm:mb-16 mb-12"
        >
          <div class="max-sm:w-full">
            <dropdown-select>
              <template #header>
                <div
                  class="flex flex-row items-center justify-between dark:bg-navy-700 bg-white border dark:border-navy-400 border-navy-50 rounded-xl px-3 py-2.5 sm:w-52 w-full"
                >
                  <div class="text-sm font-medium dark:text-navy-100">
                    {{ $t("translation.career.departmentPlaceholder") }}
                  </div>
                  <chevron-down-icon
                    class="w-4 h-4 stroke-2 dark:text-white text-navy-800 cursor-pointer"
                  />
                </div>
              </template>
              <template #menu></template>
            </dropdown-select>
          </div>
          <div class="sm:ml-4 w-full sm:w-fit">
            <fill-button
              :disabled="true"
              color="primary"
              size="md"
              width="full"
            >
              {{ $t("translation.career.searchJobButton") }}
            </fill-button>
          </div>
        </div>
        <template v-for="item in positionMockData" :key="item.id">
          <div class="w-full flex flex-col gap-4 cursor-pointer mb-16">
            <div class="text-lg font-bold dark:text-white text-navy-800 mb-2">
              {{ item.departmentName }}
            </div>
            <div
              class="flex sm:flex-row flex-col p-6 items-center justify-between dark:bg-navy-700 bg-primary-100 hover:bg-primary-200 dark:hover:bg-navy-600 rounded-2xl group"
              v-for="positionItem in item.position"
              :key="positionItem.id"
              @click="
                router.push({
                  name: 'career-detail',
                  params: { url: positionItem.url },
                })
              "
            >
              <div class="flex flex-col gap-2">
                <div
                  class="text-base font-semibold dark:text-white text-navy-800"
                >
                  {{ positionItem.title }}
                </div>
                <div
                  class="text-base font-medium dark:text-navy-100 text-navy-300"
                >
                  {{ positionItem.info }}
                </div>
              </div>
              <div class="w-full sm:w-fit max-sm:mt-6">
                <outline-button
                  color="primary"
                  size="md"
                  width="full"
                  @click="
                    router.push({
                      name: 'career-detail',
                      params: { url: positionItem.url },
                    })
                  "
                >
                  {{ $t("translation.career.applyButton") }}
                </outline-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const { t } = useI18n();
const router = useRouter();
const openPositionElement = ref();
const store = useStore();

const handleScrollToOpenPosition = () => {
  openPositionElement.value.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const positionMockData = computed(() => store.getters.careerList);

onMounted(() => {
  store.dispatch("fetchCareerList");
});

const valueCardsData = computed(() => [
  {
    id: 1,
    darkImgUrl: "/assets/images/career/user_focused_dark.svg",
    lightImgUrl: "/assets/images/career/user_focused_light.svg",
    title: t("translation.career.valueTiles.0.title"),
    content: t("translation.career.valueTiles.0.body"),
  },
  {
    id: 2,
    darkImgUrl: "/assets/images/career/open_communication_dark.svg",
    lightImgUrl: "/assets/images/career/open_communication_light.svg",
    title: t("translation.career.valueTiles.1.title"),
    content: t("translation.career.valueTiles.1.body"),
  },
  {
    id: 3,
    darkImgUrl: "/assets/images/career/collaboration_dark.svg",
    lightImgUrl: "/assets/images/career/collaboration_light.svg",
    title: t("translation.career.valueTiles.2.title"),
    content: t("translation.career.valueTiles.2.body"),
  },
]);

const workCardData = computed(() => [
  {
    id: 1,
    imgUrl: "/assets/images/career/career_salary_image.svg",
    title: t("translation.career.benefitTils.0.title"),
    content: t("translation.career.benefitTils.0.body"),
  },
  {
    id: 2,
    imgUrl: "/assets/images/career/career_remote_image.svg",
    title: t("translation.career.benefitTils.1.title"),
    content: t("translation.career.benefitTils.1.body"),
  },
  {
    id: 3,
    imgUrl: "/assets/images/career/career_learn_image.svg",
    title: t("translation.career.benefitTils.2.title"),
    content: t("translation.career.benefitTils.2.body"),
  },
]);
</script>
