<template>
  <dialog-view
    :contain-header="false"
    :is-open="model"
    v-if="model"
    bg-color="gray"
    @change-is-open="model = false"
  >
    <template #header>
      <div
        class="w-full h-[284px] bg-gradient-to-t dark:from-orange-800 dark:to-navy-400 from-orange-200 to-white rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="py-4 px-6 flex justify-end">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <token-circle-display color="orange">
            <exclamation-triangle-icon class="size-14" />
          </token-circle-display>
          <p
            class="text-sm font-medium dark:text-navy-100 text-orange-600 mt-4"
          >
            {{ $t("translation.profile.security.deleteNotAllowDialog.title") }}
          </p>
          <p class="text-orange-900 dark:text-white text-xl font-semibold">
            {{
              $t("translation.profile.security.deleteNotAllowDialog.subTitle")
            }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="w-full flex flex-col items-start dark:text-white text-navy-300"
      >
        <p class="sm:mb-8 mb-6 text-sm font-medium">
          {{ $t("translation.profile.security.deleteNotAllowDialog.text.0") }}
        </p>
        <ul class="mb-12 list-disc list-inside text-sm font-medium">
          <li class="mb-4">
            {{ $t("translation.profile.security.deleteNotAllowDialog.text.1") }}
          </li>
          <li class="mb-4">
            {{ $t("translation.profile.security.deleteNotAllowDialog.text.2") }}
          </li>
          <li>
            {{ $t("translation.profile.security.deleteNotAllowDialog.text.3") }}
          </li>
        </ul>
        <div class="w-full">
          <fill-button width="full" color="primary">{{
            $t("translation.profile.security.deleteNotAllowDialog.button")
          }}</fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenCircleDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const model = defineModel();

const handleCloseDialog = () => {
  model.value = false;
};
</script>
