<template>
  <div
    :class="[
      'sm:px-16 px-4 self-center pb-12 pt-4 w-full',
      backgroundColorClass,
    ]"
  >
    <div class="container mx-auto">
      <div class="flex sm:flex-row flex-col gap-x-[75px] gap-y-4 sm:mb-14 mb-8">
        <img
          class="w-[104px]"
          :src="[
            currentTheme === 'dark'
              ? '/assets/icons/footer/logo.svg'
              : '/assets/icons/footer/logo_light.svg',
          ]"
          alt="logo"
        />
        <div
          class="flex flex-col gap-2 text-sm leading-5 -tracking-[0.2px] font-medium dark:text-navy-100 text-navy-300"
        >
          <div class="flex sm:flex-row flex-col gap-x-6 gap-y-2">
            <p>{{ $t("translation.footer.content.0.0") }}</p>
            <p>{{ $t("translation.footer.content.0.1") }}</p>
            <p v-text="$t('translation.footer.content.0.2')"></p>
          </div>
          <p>{{ $t("translation.footer.content.1") }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-3">
        <div
          class="flex flex-col flex-shrink col-span-1 sm:w-fit w-full max-w-[300px]"
        >
          <div class="flex flex-col">
            <p
              class="text-base font-medium dark:text-navy-100 text-navy-200 mb-3"
            >
              {{ $t("translation.footer.download") }}
            </p>
            <download-apple type="apple" mode="outline" />
            <div class="mt-3" />
            <download-apple type="android" mode="outline" />
          </div>
          <div class="flex lg:hidden flex-col gap-4 mb-14">
            <p
              class="text-base font-semibold dark:text-white text-navy-800 mt-8"
            >
              {{ $t("translation.footer.socialIcons") }}
            </p>
            <div
              class="dark:text-navy-50 text-navy-800 flex flex-row gap-8 mt-3"
            >
              <component
                v-for="item in socialIconList"
                :is="item.icon"
                :key="item.id"
                class="w-6 h-6 hover:text-primary-700 cursor-pointer"
                @click="handleOpenLink(item.url)"
              />
            </div>
          </div>
        </div>
        <div
          class="col-span-2 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-8"
        >
          <div
            class="flex flex-col gap-4"
            v-for="(links, index) in footerLinks"
            :key="index"
          >
            <div
              class="text-base sm:text-lg dark:text-white text-navy-800 font-semibold flex flex-row items-center justify-between"
              @click="
                footerLinkVisibility[index] = !footerLinkVisibility[index]
              "
            >
              <p>{{ links.title }}</p>
              <div class="block sm:hidden cursor-pointer">
                <chevron-down-icon
                  v-if="!footerLinkVisibility[index]"
                  class="w-4 h-4 text-navy-100 stroke-2"
                />
                <chevron-up-icon
                  v-else
                  class="w-4 h-4 text-navy-100 stroke-2"
                />
              </div>
            </div>
            <div
              class="sm:text-base text-sm font-normal dark:text-navy-50 text-navy-800 hidden sm:flex flex-col gap-2"
            >
              <p
                v-for="(item, index) in links.links"
                :key="index"
                :class="
                  !item.disabled && item.url
                    ? 'cursor-pointer hover:text-primary-700'
                    : 'cursor-not-allowed text-navy-100 dark:text-navy-200'
                "
                @click="handleNavigateUrl(item)"
              >
                {{ item.sub_title }}
              </p>
            </div>
            <div
              v-show="footerLinkVisibility[index]"
              class="text-sm font-normal dark:text-navy-50 text-navy-800 flex sm:hidden flex-col gap-2"
            >
              <p
                v-for="(item, index) in links.links"
                :key="index"
                class="cursor-pointer hover:text-primary-700"
                @click="handleNavigateUrl(item)"
              >
                {{ item.sub_title }}
              </p>
            </div>
          </div>
          <div class="hidden lg:flex flex-col gap-4">
            <p class="text-[18px] dark:text-white text-navy-800 font-bold">
              {{ $t("translation.footer.socialIcons") }}
            </p>
            <div
              class="text-base dark:text-navy-50 text-navy-800 flex flex-row gap-4"
            >
              <component
                v-for="item in socialIconList"
                :is="item.icon"
                :key="item.id"
                class="w-6 h-6 hover:text-primary-700 cursor-pointer"
                @click="handleOpenLink(item.url)"
              />
            </div>
          </div>
          <div />
        </div>
      </div>
      <div
        class="sm:my-6 my-8 border-t dark:border-t-navy-500 border-t-navy-10"
      />
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-row items-center shrink-0">
          <div class="flex-shrink-0">
            <img src="@/assets/icons/footer/medal.svg" alt="medal" />
          </div>
          <div class="mx-4">
            <div class="h-4 border-r border-r-navy-300" />
          </div>
          <div class="flex-shrink-0">
            <img
              :src="[
                currentTheme === 'dark'
                  ? '/assets/icons/footer/hacken.svg'
                  : '/assets/icons/footer/hacken_light.svg',
              ]"
              alt="hacken"
            />
          </div>
        </div>
        <div class="text-sm font-normal text-navy-100 lg:mx-[88px] max-lg:mt-8">
          {{ $t("translation.footer.mark") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import InstagramIcon from "@/assets/icons/common/InstagramIcon.vue";
import LinkedinIcon from "@/assets/icons/common/LinkedinIcon.vue";
import DownloadApple from "@/components/Button/DownloadApple.vue";
import { isEmpty } from "@/utils/utils";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const backgroundColorClass = computed(() => {
  if (route.name === "trading") {
    return "dark:bg-navy-800 bg-navy-10";
  }
  return "dark:bg-navy-800 bg-white";
});

const handleOpenLink = (url) => {
  if (isEmpty(url)) {
    return;
  }
  window.open(url, "_blank");
};

const socialIconList = [
  {
    id: 0,
    icon: TelegramIcon,
  },
  {
    id: 1,
    icon: TwitterIcon,
    url: "https://x.com/EXZIOfficial",
  },
  {
    id: 2,
    icon: LinkedinIcon,
    url: "https://www.linkedin.com/company/exziofficial/",
  },
  {
    id: 3,
    icon: InstagramIcon,
    url: "https://www.instagram.com/exziofficial/",
  },
];

const footerLinkVisibility = ref([false, false, false]);

const footerLinks = computed(() => [
  {
    title: t("translation.footer.serviceMenu.title"),
    links: [
      {
        sub_title: t("translation.footer.serviceMenu.menu.0"),
        url: "beginner-guide",
      },
      {
        sub_title: t("translation.footer.serviceMenu.menu.1"),
        url: "help-center",
        // disabled: true,
      },
      {
        sub_title: t("translation.footer.serviceMenu.menu.2"),
        url: "",
        // disabled: true,
      },
      {
        sub_title: t("translation.footer.serviceMenu.menu.3"),
        url: "faq",
      },
      {
        sub_title: t("translation.footer.serviceMenu.menu.4"),
        url: "",
        // disabled: true,
      },
    ],
  },
  {
    title: t("translation.footer.companyMenu.title"),
    visible: false,
    links: [
      {
        sub_title: t("translation.footer.companyMenu.menu.0"),
        url: "",
      },
      {
        sub_title: t("translation.footer.companyMenu.menu.1"),
        url: "markets",
      },
      {
        sub_title: t("translation.footer.companyMenu.menu.2"),
        url: "career",
      },
      {
        sub_title: t("translation.footer.companyMenu.menu.3"),
        url: "proof-funds",
      },
      {
        sub_title: t("translation.footer.companyMenu.menu.4"),
        url: "fee-schedule",
      },
      {
        sub_title: t("translation.footer.companyMenu.menu.5"),
        url: "",
      },
    ],
  },
  {
    title: t("translation.footer.communityMenu.title"),
    visible: false,
    links: [
      {
        sub_title: t("translation.footer.communityMenu.menu.0"),
        url: "referral",
      },
      {
        sub_title: t("translation.footer.communityMenu.menu.1"),
        url: "",
      },
      {
        sub_title: t("translation.footer.communityMenu.menu.2"),
        url: "",
      },
    ],
  },
]);

const handleNavigateUrl = (item) => {
  if (!item.disabled) {
    router.push({ name: item.url });
  }
};
</script>
