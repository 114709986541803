<template>
  <div>
    <p class="text-title font-semibold">
      {{ $t("translation.auth.forgetPassword.title") }}
    </p>
    <div class="sm:h-12 h-6" />
    <underline-tab :items="tabHeaderItems" @change="handleChangeSelectedTab" />
    <div class="sm:h-10 h-6" />
    <email-address
      v-if="selectedTab == tabHeaderItems[0].id"
      @change-email="handleChangeEmail"
    />
    <phone-number
      v-if="selectedTab == tabHeaderItems[1].id"
      @change-phone="handleChangePhone"
    />
    <div class="sm:h-10 h-6" />
    <filled-button
      :label="
        selectedTab === tabHeaderItems[1].id
          ? $t('translation.auth.forgetPassword.sendRecover.0')
          : $t('translation.auth.forgetPassword.sendRecover.1')
      "
      :disabled="isDisabled"
      :isLoading="isLoading"
      @click="handleResetClick"
    />
  </div>
  <digit-puzzle v-if="showPuzzle" @success="handleSuccuessPuzzle" />
</template>
<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import EmailAddress from "@/views/Auth/ForgetPassword/RecoverWith/EmailAddress.vue";
import PhoneNumber from "@/views/Auth/ForgetPassword/RecoverWith/PhoneNumber.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import DigitPuzzle from "@/components/DigitPuzzle/DigitPuzzle.vue";
import { validateEmail, validatePhoneNumber } from "@/views/Auth/validation";
import { useForgetPassword } from "@/views/Auth/ForgetPassword/forgetPassword";
import { useStore } from "vuex";
const { t } = useI18n();
const selectedTab = ref(1);
const { email, phone, isLoading, requestForgetPassword } = useForgetPassword();
const store = useStore();
const showPuzzle = computed(() => store.getters.showPuzzle);

const tabHeaderItems = computed(() => [
  { id: 1, name: t("translation.auth.forgetPassword.tabHeaders.0") },
  { id: 2, name: t("translation.auth.forgetPassword.tabHeaders.1") },
]);

const isDisabled = computed(() => {
  if (selectedTab.value === tabHeaderItems.value[0].id) {
    return !validateEmail(email.value);
  } else if (selectedTab.value === tabHeaderItems.value[1].id) {
    return !validatePhoneNumber(phone.value);
  }
  return false;
});

const handleChangeSelectedTab = async (item) => {
  selectedTab.value = item.id;
};

const handleChangeEmail = (newEmail) => {
  email.value = newEmail;
};

const handleChangePhone = (newPhone) => {
  phone.value = newPhone;
};

const handleResetClick = () => {
  store.dispatch("setShowPuzzle", true);
  isLoading.value = true;
};

const handleSuccuessPuzzle = () => {
  requestForgetPassword();
};
</script>
