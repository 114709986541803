<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.0811 33.1253C33.7014 29.0113 30.0342 26.0613 25.7545 24.6628C27.8714 23.4026 29.5161 21.4823 30.4361 19.1969C31.356 16.9115 31.5003 14.3872 30.8468 12.0118C30.1933 9.63646 28.7781 7.54127 26.8185 6.04804C24.859 4.55481 22.4635 3.74609 19.9998 3.74609C17.5362 3.74609 15.1406 4.55481 13.1811 6.04804C11.2216 7.54127 9.80637 9.63646 9.15284 12.0118C8.49932 14.3872 8.64361 16.9115 9.56354 19.1969C10.4835 21.4823 12.1282 23.4026 14.2451 24.6628C9.96544 26.0597 6.29825 29.0097 3.91856 33.1253C3.8313 33.2676 3.77341 33.426 3.74833 33.591C3.72324 33.756 3.73146 33.9244 3.77251 34.0862C3.81355 34.248 3.88658 34.3999 3.9873 34.5331C4.08802 34.6662 4.21437 34.7778 4.35891 34.8613C4.50346 34.9447 4.66326 34.9984 4.82889 35.0192C4.99452 35.0399 5.16262 35.0272 5.32328 34.982C5.48394 34.9367 5.63391 34.8597 5.76432 34.7555C5.89474 34.6513 6.00297 34.522 6.08263 34.3753C9.02638 29.2878 14.2295 26.2503 19.9998 26.2503C25.7701 26.2503 30.9732 29.2878 33.917 34.3753C33.9967 34.522 34.1049 34.6513 34.2353 34.7555C34.3657 34.8597 34.5157 34.9367 34.6763 34.982C34.837 35.0272 35.0051 35.0399 35.1707 35.0192C35.3364 34.9984 35.4962 34.9447 35.6407 34.8613C35.7853 34.7778 35.9116 34.6662 36.0123 34.5331C36.113 34.3999 36.1861 34.248 36.2271 34.0862C36.2682 33.9244 36.2764 33.756 36.2513 33.591C36.2262 33.426 36.1683 33.2676 36.0811 33.1253ZM11.2498 15.0003C11.2498 13.2698 11.763 11.578 12.7245 10.1391C13.6859 8.70018 15.0525 7.57867 16.6513 6.9164C18.2502 6.25413 20.0095 6.08085 21.7069 6.41847C23.4042 6.75609 24.9633 7.58945 26.187 8.81316C27.4107 10.0369 28.2441 11.596 28.5817 13.2933C28.9193 14.9906 28.746 16.75 28.0838 18.3488C27.4215 19.9477 26.3 21.3142 24.8611 22.2757C23.4221 23.2372 21.7304 23.7503 19.9998 23.7503C17.6799 23.7479 15.4558 22.8252 13.8154 21.1848C12.175 19.5444 11.2523 17.3202 11.2498 15.0003Z"
      fill="currentColor"
    />
  </svg>
</template>
