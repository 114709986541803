<template>
  <div
    class="relative min-h-[1027px] flex sm:flex-row flex-col items-center justify-center sm:gap-[92px] max-sm:mt-[186px]"
  >
    <img
      class="absolute top-0 left-0 -z-10 max-sm:hidden"
      :src="
        currentTheme === 'dark'
          ? '/assets/images/home/seamless.svg'
          : '/assets/images/home/seamless_light.svg'
      "
    />

    <img
      class="absolute top-0 left-0 -z-10 sm:hidden"
      :src="
        currentTheme === 'dark'
          ? '/assets/images/home/seamless_mobile_dark.svg'
          : '/assets/images/home/seamless_mobile_light.svg'
      "
    />

    <div class="sm:hidden text-center">
      <div
        class="text-[40px] font-bold mb-4 dark:text-white text-navy-800 -tracking-[1.2px]"
      >
        {{ $t("translation.home.main.tradeSectionSubtitle") }}
      </div>
      <div
        class="text-lg font-normal dark:text-navy-50 text-navy-200 mb-16 -tracking-[0.4px] max-w-[361px] mx-auto"
      >
        {{ $t("translation.home.main.tradeSectionLabel") }}
      </div>
    </div>
    <img
      class="w-[300px]"
      :src="
        currentTheme === 'dark'
          ? '/assets/images/home/seamless/seamless_phone.svg'
          : '/assets/images/home/seamless/seamless_phone_light.svg'
      "
    />

    <div class="max-w-[500px] max-sm:mt-[52px]">
      <div class="max-w-[476px] max-sm:hidden">
        <div
          class="text-[40px] font-bold mb-4 sm:text-5xl dark:text-white text-navy-800 -tracking-[1.2px]"
        >
          {{ $t("translation.home.main.tradeSectionSubtitle") }}
        </div>
        <div
          class="text-lg sm:text-2xl font-normal leading-9 dark:text-navy-50 text-navy-200 mb-16 -tracking-[0.4px] max-w-[476px]"
        >
          {{ $t("translation.home.main.tradeSectionLabel") }}
        </div>
      </div>
      <div class="flex gap-6 flex-wrap mb-14">
        <download-card
          v-for="item in downlaodCardData"
          :key="item.id"
          :top-label="item.topLabel"
          :mainlabel="item.mainLabel"
          :icon="item.icon"
          :popup="item.popup"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import DownloadCard from "@/views/Home/Cards/DownloadCard.vue";
import QrCard from "@/views/Home/Cards/QrCard.vue";
import AppleStoreIcon from "@/assets/icons/home/AppleStoreIcon.vue";
import QrCodeIcon from "@/assets/icons/home/QrCodeIcon.vue";
import GooglePlayIcon from "@/assets/icons/home/GooglePlayIcon.vue";

const { t } = useI18n();

const downlaodCardData = computed(() => [
  {
    id: 0,
    topLabel: t("translation.home.main.tradeDownloads.apple.0"),
    mainLabel: t("translation.home.main.tradeDownloads.apple.1"),
    icon: AppleStoreIcon,
  },
  {
    id: 1,
    topLabel: t("translation.home.main.tradeDownloads.googleplay.0"),
    mainLabel: t("translation.home.main.tradeDownloads.googleplay.1"),
    icon: GooglePlayIcon,
  },
  {
    id: 2,
    // topLabel: t("translation.home.main.tradeDownloads.scanqr.0"),
    // mainLabel: t("translation.home.main.tradeDownloads.scanqr.1"),
    icon: QrCodeIcon,
    popup: QrCard,
  },
]);

// const iconOnlyData = [
//   {
//     id: 0,
//     icon: AppleStoreIcon,
//   },
//   {
//     id: 1,
//     icon: GooglePlayIcon,
//   },
//   {
//     id: 2,
//     icon: QrCodeIcon,
//   },
// ];
</script>
